import ClaimBlock from '@/Components/Claims/ClaimBlock/ClaimBlock.vue';
import ClaimDetails from '@/Components/Claims/ClaimDetails/ClaimDetails.vue';
import ClaimOverview from '@/Components/Claims/ClaimOverview/ClaimOverview.vue';
import ClaimDocuments from '@/Components/Claims/ClaimDocuments/ClaimDocuments.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppClaimBlock: ClaimBlock,
    AppClaimDetails: ClaimDetails,
    AppClaimOverview: ClaimOverview,
    AppClaimDocuments: ClaimDocuments,
} as Components;
