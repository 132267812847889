<script setup lang="ts">
import { watch, onMounted, computed, Ref, onUnmounted } from 'vue';
import Form from '@/assets/libraries/form/form';

const props = defineProps({
    form: { type: Form, default: () => new Form() },
    formFetchSkip: { type: Boolean, default: false },
    formSanitizeSkip: { type: Boolean, default: false },
});

const emit = defineEmits(['enter-pressed', 'change']);

const currentFormHash: Ref<string> = computed(() => {
    return props.form
        .fields()
        .map((field) => field.name + '=' + JSON.stringify(field.value))
        .join('|');
});

const isFormTouched: Ref<boolean> = computed(() => props.form?.isTouched());

watch(
    currentFormHash,
    async () => {
        if (!props.formSanitizeSkip) {
            props.form.sanitize();
        }
        props.form.validate();
        emit('change');
    },
    { immediate: true },
);

onMounted((): void => {
    document.addEventListener('keypress', onKeyPress);
});

onUnmounted((): void => {
    document.removeEventListener('keypress', onKeyPress);
});

function onKeyPress(event: KeyboardEvent): void {
    const targetElement: HTMLInputElement = event.target as HTMLInputElement;
    if (event.key === 'Enter' && props.form.exists(targetElement.name)) {
        emit('enter-pressed');
    }
}
</script>
<template>
    <section
        :id="'form-' + form.name"
        class="form custom-form"
        :data-form-name="form.name"
        :data-form-store="form.name"
        :data-form-fetch-skip="String(formFetchSkip)"
        :data-form-store-skip="String(!isFormTouched)"
    >
        <slot></slot>
    </section>
</template>
