<script setup lang="ts">
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import ButtonPlacement from '@/Components/TwoWayCommunication/Message/Interfaces/ButtonPlacementInterface';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import { computed, reactive, Ref, UnwrapNestedRefs } from 'vue';
import Icon from '@/Components/TwoWayCommunication/MessageCard/Icon.enum';
import { useTranslate } from '@/Composables/Translate';
import { Placement } from '@/Components/TwoWayCommunication/Message/Enums/PlacementEnum';
import { MessageAction } from '@/Components/TwoWayCommunication/Message/Enums/MessageActionEnum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';

const props = defineProps<{
    id: string;
    title: string;
    secondaryTitle?: string;
    body: string;
    author: string;
    date: string;
    isNew: boolean;
    isBta: boolean;
    action?: MessageAction;
    attachments?: Attachment[];
}>();

const emit = defineEmits<{
    (e: 'click', id: string): void;
}>();

const messageBodyLayout: Ref<'vertical' | 'horizontal' | ''> = computed((): 'vertical' | 'horizontal' | '' => {
    return buttonParams.value ? (buttonParams.value.placement === Placement.Right ? 'horizontal' : 'vertical') : '';
});

const defaultButtonParams: Ref<ButtonWithCallbackParams> = computed((): ButtonWithCallbackParams => {
    return {
        title: useTranslate().translate('action_' + props.action),
    };
});

const buttonParams: Ref<(ButtonWithCallbackParams & ButtonPlacement) | null> = computed(
    (): (ButtonWithCallbackParams & ButtonPlacement) | null => {
        let params: (ButtonWithCallbackParams & ButtonPlacement) | null;
        switch (props.action) {
            case MessageAction.Reply:
                params = replyActionButtonParams.value;
                break;
            case MessageAction.View:
                params = viewActionButtonParams.value;
                break;
            default:
                params = null;
        }

        return params
            ? {
                  ...defaultButtonParams.value,
                  ...params,
              }
            : null;
    },
);

const replyActionButtonParams: Ref<ButtonWithCallbackParams & ButtonPlacement> = computed(
    (): ButtonWithCallbackParams & ButtonPlacement => {
        return {
            textColor: ButtonTextColor.Black,
            backgroundColor: ButtonBackground.White,
            backgroundColorHover: ButtonBackground.White,
            icon: 'curved-arrow-left',
            iconColor: ButtonIconColor.Black,
            placement: Placement.Below,
        };
    },
);

const viewActionButtonParams: Ref<ButtonWithCallbackParams & ButtonPlacement> = computed(
    (): ButtonWithCallbackParams & ButtonPlacement => {
        return props.isNew
            ? {
                  textColor: ButtonTextColor.White,
                  backgroundColor: ButtonBackground.Red,
                  placement: Placement.Right,
              }
            : {
                  textColor: ButtonTextColor.Black,
                  backgroundColor: ButtonBackground.Transparent,
                  placement: Placement.Right,
                  backgroundColorHover: ButtonBackground.White,
                  textColorHover: ButtonTextColor.White,
              };
    },
);

const hasAttachments: Ref<boolean> = computed(() => {
    let result: boolean = false;
    if (props.attachments) {
        result = props.attachments.length > 0;
    }

    return result;
});

const combinedAttachments: UnwrapNestedRefs<{ count: number; name: string }> = reactive({
    count: props.attachments?.length ?? 0,
    name: useTranslate().translate('attached_files'),
});

function attachmentExtension(attachment: Attachment): string {
    return attachment.type.toUpperCase();
}

function onClick(): void {
    return emit('click', props.id);
}
</script>

<template>
    <div class="message" :data-id="id">
        <div class="messenger-container">
            <app-user-message-card :name="author" :date="date" :icon="isBta ? Icon.Bta : Icon.None" :with-badge="isNew">
            </app-user-message-card>
        </div>
        <div class="body" :class="messageBodyLayout">
            <div class="expand">
                <div>
                    <div class="title">{{ title }}</div>
                    <div class="body" v-html="body"></div>
                    <div v-if="secondaryTitle" class="secondary-title">{{ secondaryTitle }}</div>
                </div>
                <ul v-if="isBta && hasAttachments" class="attachments">
                    <li v-for="(attachment, key) in attachments" :key="key" class="attachment">
                        <a :href="attachment.downloadLink" download>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M7 10L12 15L17 10"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M12 15V3"
                                    stroke="#E30613"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="attachment-name">{{ attachment.name }}</span>
                        </a>
                        <span class="additional-text">{{ attachmentExtension(attachment) }}</span>
                    </li>
                </ul>
                <ul v-if="!isBta && hasAttachments" class="attachments not-downloadable">
                    <li class="attachment">
                        <span>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21.4398 11.0499L12.2498 20.2399C11.124 21.3658 9.59699 21.9983 8.0048 21.9983C6.41262 21.9983 4.88565 21.3658 3.7598 20.2399C2.63396 19.1141 2.00146 17.5871 2.00146 15.9949C2.00146 14.4027 2.63396 12.8758 3.7598 11.7499L12.9498 2.55992C13.7004 1.80936 14.7183 1.3877 15.7798 1.3877C16.8413 1.3877 17.8592 1.80936 18.6098 2.55992C19.3604 3.31048 19.782 4.32846 19.782 5.38992C19.782 6.45138 19.3604 7.46936 18.6098 8.21992L9.4098 17.4099C9.03452 17.7852 8.52553 17.996 7.9948 17.996C7.46407 17.996 6.95508 17.7852 6.5798 17.4099C6.20452 17.0346 5.99369 16.5256 5.99369 15.9949C5.99369 15.4642 6.20452 14.9552 6.5798 14.5799L15.0698 6.09992"
                                    stroke="#1D1F23"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="attachment-name">{{ combinedAttachments.name }}</span>
                        </span>
                        <span class="additional-text">{{ combinedAttachments.count }}</span>
                    </li>
                </ul>
            </div>
            <div v-if="buttonParams && props.action !== MessageAction.None" class="button-container">
                <button-with-callback v-bind="buttonParams" @button-callback-click="onClick"></button-with-callback>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.message {
    background-color: var(--white);
    border-radius: var(--size-pico);
    display: flex;
    padding: var(--size-normal);
    gap: var(--size-medium);
    flex-direction: column;
    justify-content: space-between;
    min-height: 146px;

    @include respond-above('sm') {
        flex-direction: row;
        padding: var(--size-medium);
    }

    .messenger-container {
        display: flex;
        align-self: start;
        width: 100%;

        @include respond-above('sm') {
            min-width: 200px;
            max-width: 200px;
        }

        .user-message-card {
            @include respond-below('sm') {
                width: 100%;
            }
        }
    }

    .body {
        display: flex;
        flex: 1;

        .expand {
            flex: 1;
        }

        &.horizontal {
            flex-direction: column;
            gap: var(--size-medium);

            .button-container {
                align-self: center;
            }

            @include respond-above('sm') {
                flex-direction: row;
            }
        }

        &.vertical {
            flex-direction: column;

            .button-container {
                align-self: end;
            }
        }

        .button-container {
            display: flex;
            padding: 0;
            width: 100%;

            @include respond-above('sm') {
                width: auto;
            }
        }

        > div:not(.button-container) {
            display: flex;
            flex-direction: column;

            > div:not([class]) {
                display: flex;
                gap: var(--size-nano);
                flex-direction: column;

                .title {
                    font-weight: 600;
                }

                .body {
                    display: block;
                    max-width: 332px;

                    @include respond-below('sm') {
                        max-width: 100%;
                    }

                    :deep(p) {
                        color: var(--text-color-default);
                        font-size: var(--font-size-nano);
                        line-height: var(--size-tiny);
                        max-width: 332px;

                        @include respond-below('sm') {
                            max-width: 100%;
                        }
                    }
                }

                .secondary-title {
                    font-weight: 600;
                    font-size: var(--font-size-nano);
                }
            }

            .attachments {
                padding: var(--size-tiny) 0;

                .attachment {
                    display: flex;
                    justify-content: space-between;
                    border-top: 1px solid var(--black-100);
                    padding: var(--size-nano) 0;

                    a,
                    span {
                        display: flex;
                        align-items: center;

                        .attachment-name {
                            padding-left: var(--size-tiny);
                            font-size: var(--font-size-nano);
                            font-weight: 500;
                        }
                    }

                    .additional-text {
                        display: flex;
                        align-items: center;
                        font-size: var(--font-size-pico);
                        color: var(--text-color-subtlest);
                    }

                    &:last-of-type {
                        border-bottom: 1px solid var(--black-100);
                    }
                }

                &.not-downloadable {
                    padding-bottom: 0;

                    .attachment {
                        &:last-of-type {
                            border-bottom: none;
                        }

                        .attachment-name {
                            font-weight: 600;
                        }

                        .additional-text {
                            font-size: var(--font-size-nano);
                        }
                    }
                }
            }
        }
    }

    :deep(button) {
        min-width: 200px;
        width: 100%;
        margin: 0;
        height: 52px;

        @include respond-above('sm') {
            width: auto;
        }

        &.background-white:hover {
            border-color: var(--button-color-border-secondary-hover);
        }
    }
}
</style>
