import PaymentWidget from '@/interfaces/one_policy/payment.widget.interface';
import InsuredRisk from '@/interfaces/one_policy/insured.risk.interface';
import InsurancesTypes from '@/pages/OneDashboard/Insurances/Enums/InsurancesTypesEnum';
import { HealthCard } from '@/interfaces/resources/health.card.interface';
import PolicyLimit from '@/interfaces/one_policy/PolicyLimit';
import SubscriptionState from '@/Enums/OnePolicy/SubscriptionStateEnum';
import SubscriptionDocument from '@/Enums/OnePolicy/SubscriptionDocumentInterface';
import PolicyDetailsOverview from '@/Components/Policies/PolicyDetailsOverview/Interfaces/PolicyDetailsOverviewInterface';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';
import SubscriptionStatus from '@/Components/Policies/PolicyBlock/Enums/SubscriptionStatusEnum';
import PolicyDetailsOverviewContent from '@/Components/Policies/PolicyDetailsOverview/Interfaces/PolicyDetailsOverviewContentInterface';
import RenewBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/RenewBlockInterface';
import SubscriptionsBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/SubscriptionsBlockInterface';
import UpcomingPolicyBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/UpcomingPolicyBlockInterface';
import AutoPayBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/AutoPayBlockInterface';
import AddPaymentCardBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/AddPaymentCardBlockInterface';
import InsuredRisksBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/InsuredRisksBlockInterface';
import PolicyLimitsBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/PolicyLimitsBlockInterface';
import OverviewBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/OverviewBlockInterface';

export default class PolicyDetailsOverviewBuilder {
    private policyDetailsOverview: PolicyDetailsOverview = this.cleanValues();
    private currentDocumentIndex: number = -1;

    public reset(): this {
        this.policyDetailsOverview = this.cleanValues();

        return this;
    }

    public withPaymentWidgetEnabled(): this {
        this.policyDetailsOverview.content.paymentWidget.enabled = true;

        return this;
    }

    public withPaymentWidgetInvoiceCount(value: number): this {
        this.policyDetailsOverview.content.paymentWidget.invoiceCount = value;

        return this;
    }

    public withPaymentWidgetAmountToPay(value: string): this {
        this.policyDetailsOverview.content.paymentWidget.totalAmount = value;

        return this;
    }

    public withPaymentWidgetAdditionalButtonText(value: string): this {
        this.policyDetailsOverview.content.paymentWidget.additionalButtonText = value;

        return this;
    }

    public withRenewBlockEnabled(): this {
        this.policyDetailsOverview.content.renewBlock.enabled = true;

        return this;
    }

    public withRenewBlockMessage(message: string): this {
        this.policyDetailsOverview.content.renewBlock.message = '<span>' + message + '</span>';

        return this;
    }

    public withRenewBlockDetails(agreement: Agreement): this {
        this.policyDetailsOverview.content.renewBlock.agreement = agreement;

        return this;
    }

    public withSubscriptionsBlockVisible(): this {
        this.policyDetailsOverview.content.subscriptionsBlock.enabled = true;

        return this;
    }

    public withUpcomingPolicyBlockVisible(): this {
        this.policyDetailsOverview.content.upcomingPolicyBlock.enabled = true;

        return this;
    }

    public withPolicyType(policyType: AgreementTypes): this {
        this.policyDetailsOverview.content.subscriptionsBlock.policyType = policyType;

        return this;
    }

    public withInsuranceType(insuranceType: InsurancesTypes): this {
        this.policyDetailsOverview.content.subscriptionsBlock.insuranceType = insuranceType;

        return this;
    }

    public withSubscriptionsBlockIsSubscription(isSubscription: boolean): this {
        this.policyDetailsOverview.content.subscriptionsBlock.isSubscription = isSubscription;

        return this;
    }

    public withSubscriptionsBlockAddStateBadge(newStateBadge: SubscriptionState): this {
        this.policyDetailsOverview.content.subscriptionsBlock.statusBadges.push(newStateBadge);

        return this;
    }

    public withSubscriptionsBlockPolicyId(policyId: string): this {
        this.policyDetailsOverview.content.subscriptionsBlock.policyId = policyId;

        return this;
    }

    public withSubscriptionsBlockHealthCardNumber(cardNumber: string): this {
        this.policyDetailsOverview.content.subscriptionsBlock.healthCardNumber = cardNumber;

        return this;
    }

    public withSubscriptionsBlockHealthCards(value: HealthCard[]): this {
        this.policyDetailsOverview.content.subscriptionsBlock.healthCards = value;

        return this;
    }

    public withSubscriptionsBlockSubscriptionStatus(value: SubscriptionStatus): this {
        this.policyDetailsOverview.content.subscriptionsBlock.subscriptionStatus = value;

        return this;
    }

    public withSubscriptionsBlockInsuredPeriod(insuredPeriod: string): this {
        this.policyDetailsOverview.content.subscriptionsBlock.insuredPeriod = insuredPeriod;

        return this;
    }

    public withSubscriptionsBlockPayments(payments: number): this {
        this.policyDetailsOverview.content.subscriptionsBlock.payments = payments;

        return this;
    }

    public withSubscriptionsBlockPremium(premium: number): this {
        this.policyDetailsOverview.content.subscriptionsBlock.premium = premium;

        return this;
    }

    public withSubscriptionsBlockBrokerPayments(isBrokerPayment: boolean): this {
        this.policyDetailsOverview.content.subscriptionsBlock.isBrokerPayment = isBrokerPayment;

        return this;
    }

    public withSubscriptionsBlockNewDocument(
        title: string,
        url: string,
        extension: string = '',
    ): PolicyDetailsOverviewBuilder {
        this.currentDocumentIndex++;
        this.policyDetailsOverview.content.subscriptionsBlock.subscriptionDocument.push(
            new (class implements SubscriptionDocument {
                public title: string = title;
                public url: string = url;
                public extension: string = extension;
            })(),
        );

        return this;
    }

    public withUpcomingBlockNewDocument(
        title: string,
        url: string,
        extension: string = '',
    ): PolicyDetailsOverviewBuilder {
        this.currentDocumentIndex++;
        this.policyDetailsOverview.content.upcomingPolicyBlock.subscriptionDocument.push(
            new (class implements SubscriptionDocument {
                public title: string = title;
                public url: string = url;
                public extension: string = extension;
            })(),
        );

        return this;
    }

    public withUpcomingPolicyBlockPolicyId(policyId: string): this {
        this.policyDetailsOverview.content.upcomingPolicyBlock.policyId = policyId;

        return this;
    }

    public withUpcomingPolicyBlockInsuredPeriod(insuredPeriod: string): this {
        this.policyDetailsOverview.content.upcomingPolicyBlock.insuredPeriod = insuredPeriod;

        return this;
    }

    public withUpcomingPolicyBlockPrice(price: number): this {
        this.policyDetailsOverview.content.upcomingPolicyBlock.price = price;

        return this;
    }

    public withNoOverduePaymentsBlock(): this {
        this.policyDetailsOverview.content.autoPayBlock.enabled = true;

        return this;
    }

    public withInsuredRisksBlock(): this {
        this.policyDetailsOverview.content.insuredRisksBlock.enabled = true;

        return this;
    }

    public withInsuredRisks(risks: InsuredRisk[]): this {
        this.policyDetailsOverview.content.insuredRisksBlock.risks = risks;

        return this;
    }

    public withPolicyLimits(limits: PolicyLimit[]): this {
        this.policyDetailsOverview.content.policyLimitsBlock.enabled = true;
        this.policyDetailsOverview.content.policyLimitsBlock.limits = limits;

        return this;
    }

    public withSubscriptionsBlockActionsBlockVisible(): this {
        this.policyDetailsOverview.content.actionsBlock.enabled = true;

        return this;
    }

    private cleanValues(): PolicyDetailsOverview {
        return new (class implements PolicyDetailsOverview {
            public component: string = 'AppPolicyDetailsOverview';
            public content: PolicyDetailsOverviewContent = new (class implements PolicyDetailsOverviewContent {
                public paymentWidget: PaymentWidget = new (class implements PaymentWidget {
                    public enabled: boolean = false;
                    public additionalButtonText: string = '';
                    public invoiceCount: number = 0;
                    public totalAmount: string = '';
                })();
                public renewBlock: RenewBlock = new (class implements RenewBlock {
                    public enabled: boolean = false;
                    public message: string = '';
                })();
                public subscriptionsBlock: SubscriptionsBlock = new (class implements SubscriptionsBlock {
                    public policyType: string = '';
                    public enabled: boolean = false;
                    public statusBadges: SubscriptionState[] = [];
                    public policyId: string = '';
                    public healthCardNumber: string = '';
                    public healthCards: HealthCard[] = [];
                    public insuredPeriod: string = '';
                    public payments: number = 0;
                    public price: number = 0;
                    public premium: number = 0;
                    public isBrokerPayment: boolean = false;
                    public subscriptionDocument: SubscriptionDocument[] = [];
                    public isSubscription: boolean = false;
                    public subscriptionStatus: string = '';
                })();
                public upcomingPolicyBlock: UpcomingPolicyBlock = new (class implements UpcomingPolicyBlock {
                    public policyType: string = '';
                    public enabled: boolean = false;
                    public policyId: string = '';
                    public insuredPeriod: string = '';
                    public price: number = 0;
                    public subscriptionDocument: SubscriptionDocument[] = [];
                })();
                public autoPayBlock: AutoPayBlock = new (class implements AutoPayBlock {
                    public enabled: boolean = false;
                })();
                public addPaymentCardBlock: AddPaymentCardBlock = new (class implements AddPaymentCardBlock {
                    public enabled: boolean = false;
                })();
                public insuredRisksBlock: InsuredRisksBlock = new (class implements InsuredRisksBlock {
                    public enabled: boolean = false;
                    public risks: InsuredRisk[] = [];
                })();
                public policyLimitsBlock: PolicyLimitsBlock = new (class implements PolicyLimitsBlock {
                    public enabled: boolean = false;
                    public limits: PolicyLimit[] = [];
                })();
                public actionsBlock: OverviewBlock = new (class implements OverviewBlock {
                    public enabled: boolean = false;
                })();
            })();
        })();
    }

    public build(): PolicyDetailsOverview {
        return this.policyDetailsOverview;
    }
}
