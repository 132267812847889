<script lang="ts">
import { defineComponent, Ref, ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import UrlBuilder from '@/assets/libraries/url/url-builder';
import { useTranslate } from '@/Composables/Translate';

const { language } = useTranslate();

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const dashboardRedirectUrl: Ref<string> = ref('');

        const navigateToDashboard = (): void => {
            window.location.href = new UrlBuilder()
                .withLanguage(language())
                .withUri(dashboardRedirectUrl.value)
                .build();
        };

        function applyDashboardUrl(value: string): void {
            dashboardRedirectUrl.value = value;
        }

        return {
            ...btaBase,
            ...{
                navigateToDashboard,
                applyDashboardUrl,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
    },
});
</script>
