export const useCurrency = (): CurrencyParams => {
    const currency = (targetCurrency: string): string => {
        let sign = '';
        switch (targetCurrency.toUpperCase()) {
            case 'EUR':
                sign = '€';
                break;
            case 'USD':
                sign = '$';
                break;
            default:
                sign = '';
        }
        return sign;
    };

    return { currency };
};

export interface CurrencyParams {
    currency: (currency: string) => string;
}
