import AppItemsButton from '@/Components/Buttons/ItemsButton/ItemsButton.vue';
import AppOverlayButton from '@/Components/Buttons/OverlayButton/OverlayButton.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import Badge from '@/Components/Buttons/Badge/Badge.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppItemsButton: AppItemsButton,
    AppOverlayButton: AppOverlayButton,
    AppButtonWithCallback: AppButtonWithCallback,
    AppBadge: Badge,
} as Components;
