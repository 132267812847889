<script setup lang="ts">
import Sanitizer from '@/services/sanitizer.service';
import { computed, onMounted, ref, Ref } from 'vue';
import { Entity } from '@/interfaces/resources/entity.interface';
import User from '@/services/user.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import { useStrings } from '@/Composables/Strings';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';

const searchInputId: string = '#person-list-search-input';
const listOverflowThreshold: number = 3;
const entities: Ref<Entity[]> = ref([]);
const filter: Ref<string> = ref('');
const loading: Ref<boolean> = ref(false);
const userInstance: User = User.getInstance();
const emit = defineEmits(['person-selected']);
const { translate } = useTranslate();
const { diacriticString } = useStrings();

const isLoaderVisible: Ref<boolean> = computed((): boolean => {
    return loading.value;
});

const searchEnabled: Ref<boolean> = computed((): boolean => {
    return filter.value !== '';
});

const searchCriteria: Ref<string> = computed((): string => {
    return diacriticString(filter.value.toLowerCase());
});

const privatePersonName: Ref<string> = computed((): string => {
    return privateAccount.value ? privateAccount.value.name : '';
});

const currentUserEntity: Ref<DynamicDictionary> = computed((): DynamicDictionary => {
    return {
        id: userInstance.current.personId,
        name: userInstance.current.name,
        code: Sanitizer.cleanName(userInstance.current.personCode),
    };
});

const privateAccount: Ref<DynamicDictionary | null> = computed((): DynamicDictionary | null => {
    let result: DynamicDictionary | null = currentUserEntity.value;
    if (
        searchEnabled.value &&
        !(
            diacriticString(currentUserEntity.value.name.toLowerCase()).includes(searchCriteria.value) ||
            currentUserEntity.value.code.includes(Sanitizer.cleanName(searchCriteria.value))
        )
    ) {
        result = null;
    }

    return result;
});

const legalPersons: Ref<Entity[]> = computed((): Entity[] => {
    return searchEnabled.value
        ? entities.value.filter(
              (entity: Entity): boolean =>
                  diacriticString(entity.name.toLowerCase()).includes(searchCriteria.value) ||
                  entity.registrationCode.includes(searchCriteria.value),
          )
        : entities.value;
});

const hasPrivateAccount: Ref<boolean> = computed((): boolean => {
    return !!privateAccount.value;
});

const hasLegalAccounts: Ref<boolean> = computed((): boolean => {
    return legalPersons.value.length > 0;
});

const showScroll: Ref<boolean> = computed((): boolean => {
    return legalPersons.value.length > listOverflowThreshold;
});

onMounted((): void => {
    if (userInstance.ready) {
        init();
    }
});

function emitSelectedEntity(entity: DynamicDictionary): void {
    emit('person-selected', entity);
}

function showPersonLabel(forLegalPerson: boolean = false): boolean {
    return forLegalPerson ? legalPersons.value.length > 0 : hasPrivateAccount.value;
}

function onPersonClick(entity: DynamicDictionary | null): void {
    if (entity) {
        loading.value = true;
        emitSelectedEntity(entity);
    }
}

function onSearchInput(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    applyFilterValue(target!.value);
}

function onResetSearch(): void {
    filter.value = '';
    $(searchInputId).val('');
}

function init(): void {
    fetchLegalEntities();
}

function fetchLegalEntities(): void {
    entities.value = userInstance.current.legalEntities;
    entities.value.sort((a: Entity, b: Entity) => (diacriticString(a.name) > diacriticString(b.name) ? 1 : -1));
}

function applyFilterValue(value: string): void {
    filter.value = value;
}
</script>

<template>
    <div class="person-list-with-search">
        <div class="wrapper">
            <div class="search-box" :class="{ active: searchEnabled }">
                <div class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                            stroke="#9297A0"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M21 21L16.65 16.65"
                            stroke="#9297A0"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <input
                    id="person-list-search-input"
                    class="search-input"
                    :placeholder="translate('person_list_with_search_placeholder')"
                    @input="onSearchInput"
                />
                <button v-if="searchEnabled" class="icon" @click="onResetSearch">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M18 6L6 18M6 6L18 18"
                            stroke="#9297A0"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
            <div class="person-container">
                <span v-if="showPersonLabel()" class="person-type">{{
                    translate('person_list_with_search_private_accounts')
                }}</span>
                <ul v-if="hasPrivateAccount" id="private-accounts" class="persons">
                    <li class="person-item">
                        <button class="person" @click="onPersonClick(privateAccount)">
                            <svg
                                width="52"
                                height="52"
                                viewBox="0 0 52 52"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="52" height="52" rx="26" fill="#00B4AD" fill-opacity="0.12" />
                                <path
                                    d="M26 26C28.7614 26 31 23.7614 31 21C31 18.2386 28.7614 16 26 16C23.2386 16 21 18.2386 21 21C21 23.7614 23.2386 26 26 26Z"
                                    fill="white"
                                    stroke="#00B4AD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M36 36L35.5513 34.9231C34.3091 31.9419 31.3963 30 28.1667 30H23.8333C20.6037 30 17.6909 31.9419 16.4487 34.9231L16 36"
                                    stroke="#00B4AD"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="person-name">{{ privatePersonName }}</span>
                        </button>
                    </li>
                </ul>
                <span v-if="showPersonLabel(true)" class="person-type">
                    {{ translate('person_list_with_search_business_accounts') }}
                    <span class="person-count">{{ legalPersons.length }}</span>
                </span>
                <ul
                    v-if="hasLegalAccounts"
                    id="legal-accounts"
                    class="persons fixed-height"
                    :class="{ 'with-scroll': showScroll }"
                >
                    <li v-for="(person, index) in legalPersons" :key="index" class="person-item">
                        <button class="person" @click="onPersonClick(person)">
                            <svg
                                width="52"
                                height="52"
                                viewBox="0 0 52 52"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect width="52" height="52" rx="26" fill="#5448C8" fill-opacity="0.12" />
                                <path
                                    d="M34 21H18C16.8954 21 16 21.8954 16 23V33C16 34.1046 16.8954 35 18 35H34C35.1046 35 36 34.1046 36 33V23C36 21.8954 35.1046 21 34 21Z"
                                    fill="white"
                                    stroke="#5448C8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M30 35V19C30 18.4696 29.7893 17.9609 29.4142 17.5858C29.0391 17.2107 28.5304 17 28 17H24C23.4696 17 22.9609 17.2107 22.5858 17.5858C22.2107 17.9609 22 18.4696 22 19V35"
                                    stroke="#5448C8"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <span class="person-name">{{ person.name }}</span>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
        <app-popup v-if="isLoaderVisible" class="loading" :disable-close="true">
            <app-content-loader></app-content-loader>
        </app-popup>
    </div>
</template>

<style lang="scss" scoped>
.person-list-with-search {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--color-black-fade);

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: var(--size-medium) 0;
        padding: 0 var(--size-small);

        .search-box {
            height: 40px;
            border-radius: 8px;
            border: 2px solid transparent;
            padding: 0 var(--size-tiny);
            background-color: var(--color-black-fade);
            display: flex;
            align-items: center;
            transition: 0.5s;
            margin-bottom: var(--size-medium);

            .icon {
                width: 24px;
                height: 24px;
            }

            .search-input {
                width: 100%;
                height: 100%;
                background: none;
                border: none;
                padding-left: 17px;
                font-family: 'Gilroy', sans-serif;

                &::placeholder {
                    font-family: var(--text-font-stack);
                    font-size: var(--font-size-nano);
                    color: var(--black-500);
                }
            }

            &.active {
                background-color: var(--white);
                border-color: var(--brand-blue);
            }
        }

        .person-container {
            .person-type {
                font-size: var(--font-size-nano);

                .person-count {
                    margin-left: 5px;
                    background-color: var(--black-200);
                    color: var(--brand-blue);
                    border-radius: 4px;
                    padding: 3px var(--size-femto);
                    font-size: var(--font-size-pico);
                    line-height: 11px;
                }
            }

            .persons {
                margin: var(--size-tiny) 0 var(--size-medium);
                display: flex;
                flex-direction: column;
                gap: 16px;

                .person-item {
                    scroll-snap-align: start;
                    height: 84px;
                    background-color: var(--white);
                    border-radius: 16px;

                    .person {
                        display: flex;
                        align-items: center;
                        padding: var(--size-tiny);
                        width: 100%;

                        .person-name {
                            margin-left: var(--size-tiny);
                            font-size: var(--font-size-tiny);
                            text-align: left;
                        }

                        svg {
                            min-width: 52px;
                        }
                    }
                }

                &.with-scroll {
                    scroll-snap-type: y proximity;
                    overflow-y: scroll;
                    scrollbar-color: var(--black-500) var(--black-200);
                    scrollbar-width: thin;

                    &::-webkit-scrollbar-thumb {
                        background-color: var(--black-200);
                    }

                    &::-webkit-scrollbar-track {
                        background: var(--black-500);
                    }
                }

                &.fixed-height {
                    height: 284px;
                }
            }
        }

        @include respond-above('sm') {
            width: 480px;
            align-self: center;
            padding: 0;
        }
    }
}
</style>
