import DateFormat from '@/Enums/DateFormatEnum';

import { usePrice } from '@/Composables/Price';
import { useTranslate } from '@/Composables/Translate';
import { useDate } from '@/Composables/Date';
import { useCurrency } from '@/Composables/Currency';
import { useStrings } from '@/Composables/Strings';

export default class Transform {
    public static currency(currency: string): string {
        return useCurrency().currency(currency);
    }

    public static price(
        targetPrice: string | number,
        isCents: boolean = true,
        currency?: string,
        hideEndZeros: boolean = false,
    ): string {
        return usePrice().price(targetPrice, isCents, currency, hideEndZeros);
    }

    public static sparse(targetPrice: number, noCents: boolean): string {
        return usePrice().sparse(targetPrice, noCents);
    }

    public static capitalize(value: string): string {
        return useStrings().capitalize(value);
    }

    public static replaceAll(haystack: string, needle: string, replace: string): string {
        return useStrings().replaceAll(haystack, needle, replace);
    }

    public static date(date: Date): string {
        return useDate().date(date);
    }

    public static transformedDateString(
        date: string,
        formatFrom: string = DateFormat.Default.Short,
        formatTo?: string,
    ): string {
        return useDate().dateString(date, formatFrom, formatTo);
    }

    public static translate(key: string, type: string = '', replaceParts?: { [key: string]: string | number }): string {
        return type
            ? useTranslate().translateForType(key, type, replaceParts)
            : useTranslate().translate(key, replaceParts);
    }
}
