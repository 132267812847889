import Tooltipster from '@/interfaces/tooltipster.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';

export class InputOptionBuilder {
    private inputOption: InputOption = {
        disabled: false,
        value: '',
        name: '',
        icon: '',
        custom: '',
        hidden: false,
        badgeText: '',
        emit: false,
    };

    public setDisabled(value: boolean): this {
        this.inputOption.disabled = value;

        return this;
    }

    public setValue(value: string | number | boolean): this {
        this.inputOption.value = value;

        return this;
    }

    public setCustom(custom: LimitedVariant): this {
        this.inputOption.custom = custom;

        return this;
    }

    public setName(name: string): this {
        this.inputOption.name = name;

        return this;
    }

    public setBadgeText(value: string): this {
        this.inputOption.badgeText = value;

        return this;
    }

    public setIcon(icon: string): this {
        this.inputOption.icon = icon;

        return this;
    }

    public setTipster(title: string, description: string): this {
        this.inputOption.tooltip = new (class implements Tooltipster {
            public title: string = title;
            public description: string = description;
        })();

        return this;
    }

    public setHidden(hidden: boolean): this {
        this.inputOption.hidden = hidden;

        return this;
    }

    public setEmit(): this {
        this.inputOption.emit = true;

        return this;
    }

    public build(): InputOption {
        return this.inputOption;
    }
}
