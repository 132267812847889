<script setup lang="ts">
import { computed, Ref } from 'vue';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/Services/ProcessInnerToasterService';
import InnerToasterState from '@/Components/Tooltips/ProcessInnerToaster/Enums/InnerToasterStateEnum';

const service: ProcessInnerToaster = ProcessInnerToaster.getInstance();

const stateIsNone: Ref<boolean> = computed(() => {
    return service.content().state === InnerToasterState.None;
});

const stateIsProcess: Ref<boolean> = computed(() => {
    return service.content().state === InnerToasterState.Process;
});

const stateIsComplete: Ref<boolean> = computed(() => {
    return service.content().state === InnerToasterState.Complete;
});

const slotClasses: Ref<string> = computed(() => {
    const result: string[] = [''];
    if (!stateIsNone.value) {
        result.push('disabled');
    } else {
        result.push('enabled');
    }

    return result.join(' ');
});

const toasterClasses: Ref<string> = computed(() => {
    const result: string[] = ['middle'];
    if (!stateIsNone.value) {
        result.push('enabled');
    } else {
        result.push('disabled');
    }

    return result.join(' ');
});

const plateText: Ref<string> = computed(() => {
    let result: string;
    switch (service.content().state) {
        case InnerToasterState.Process:
            result = service.content().processTitle;
            break;
        case InnerToasterState.Complete:
            result = service.content().completeTitle;
            break;
        default:
            result = '';
    }

    return result;
});
</script>

<template>
    <div class="toaster-content">
        <div class="slot-content" :data-slot="service.slotDataName()" :class="slotClasses">
            <slot></slot>
        </div>
        <div class="toaster" :class="toasterClasses">
            <div class="toaster-plate">
                <img
                    v-if="stateIsComplete"
                    src="images/one/success--icon--big.svg"
                    alt="spinner"
                    width="40"
                    height="40"
                />
                <app-content-loader v-if="stateIsProcess" :icon-type="'loader-thin'"></app-content-loader>
                <div class="title">{{ plateText }}</div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.toaster-content {
    position: relative;

    .slot-content {
        overflow: hidden;
        height: auto;
        transition:
            opacity 0.5s,
            max-height 0.5s,
            margin-bottom 0.5s;

        &.enabled {
            opacity: 1;
            margin-bottom: 0;
        }

        &.disabled {
            opacity: 0;
            max-height: 65px !important;
        }
    }

    .toaster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;

        &.enabled {
            animation: fade-in 0.5s forwards;
        }

        &.disabled {
            animation: fade-out 0.5s forwards;
        }

        .toaster-plate {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 656px;
            height: 60px;
            border-radius: var(--size-tiny);
            background-color: var(--component-color-background-base);
            padding: 0 var(--size-medium) 0 var(--size-medium);
            border: 1px solid var(--component-color-border-default);

            :deep(.content-loader) {
                width: 40px;
                height: 40px;

                img {
                    width: 100%;
                }
            }

            .title {
                margin-left: var(--size-small);
                font-size: var(--font-size-small);
                font-weight: 700;
            }
        }
    }

    @keyframes fade-in {
        0% {
            overflow: initial;
            height: 100%;
            width: 100%;
            opacity: 0;
        }

        100% {
            height: 100%;
            width: 100%;
            opacity: 1;
        }
    }

    @keyframes fade-out {
        0% {
            height: 100%;
            width: 100%;
            opacity: 1;
        }

        99% {
            height: 100%;
            width: 100%;
            opacity: 0;
        }

        100% {
            overflow: hidden;
            height: 1px;
            width: 1px;
            opacity: 0;
        }
    }
}
</style>
