<script setup lang="ts">
import { computed, onMounted, PropType, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import IconSide from '@/Components/Tooltips/TextWithTip/IconSide.enum';
import CoveredPopupAutomationIterator from '@/Components/Popups/CoveredPopup/Classes/CoveredPopupAutomationIterator';
import { Renters } from '@/Apps/Renters/Interfaces/RentersInterface';
import { CoverageRisk } from '@/Apps/Renters/Interfaces/CoverageRiskInterface';
import { useFormatter } from '@/Composables/Formatter';
import RentersRiskGroups from '@/Apps/Renters/Enums/RentersRiskGroups';
import RentersRisks from '@/Apps/Renters/Enums/RentersRisks';
import Steps from '@/Apps/Renters/Enums/Steps';

export interface CoveredRisks {
    id: string;
    risks: CoverageRisk[];
}

export interface RiskMapping {
    risks: Ref<CoveredRisks[]>;
    group: RentersRiskGroups;
}

const props = defineProps({
    title: { type: String, default: '' },
    policies: { type: Array as PropType<Renters[]>, default: () => [] },
    excludedRisks: { type: Array, default: () => [] },
    coveragePlanKey: { type: Number, default: 0 },
    selectedRisks: { type: Array as PropType<CoverageRisk[]>, default: () => [] },
    mode: { type: String, default: '' },
    translationType: { type: String, default: '' },
    selectedAdditionalRisks: { type: Array, default: () => [] },
});

const { sparsePrice } = useFormatter();
const { translate, translateForType } = useTranslate();
const viewIsReady: Ref<boolean> = ref(false);

const propertyRisks: Ref<CoveredRisks[]> = ref([]);
const liabilityRisks: Ref<CoveredRisks[]> = ref([]);
const territory: Ref<CoveredRisks[]> = ref([]);
const additionalRisks: Ref<CoveredRisks[]> = ref([]);
const riskMappings: RiskMapping[] = [
    { risks: propertyRisks, group: RentersRiskGroups.Property },
    { risks: liabilityRisks, group: RentersRiskGroups.Liability },
    { risks: territory, group: RentersRiskGroups.Territory },
    { risks: additionalRisks, group: RentersRiskGroups.Additional },
];

const isSummaryModeEnabled: Ref<boolean> = computed((): boolean => {
    return props.mode === Steps.Summary;
});

onMounted((): void => {
    init();
    CoveredPopupAutomationIterator.init();
});

function init(): void {
    riskMappings.forEach(({ risks, group }): void => {
        setupRisks(risks, group);
    });
    viewIsReady.value = true;
}

function setupRisks(risks: Ref<CoveredRisks[]>, group: RentersRiskGroups): void {
    props.policies.forEach((policy: Renters): void => {
        risks.value.push({
            id: policy.id,
            risks: policy.risks.filter((risk: CoverageRisk) => risk.riskGroup === group && risk.isWhatCovered),
        });
    });
}

function risksStatusInfo(plan: CoveredRisks, riskId: null | string): string {
    let result: string = '';
    const risk: CoverageRisk | undefined | null = plan.risks.find((value) => value?.id === riskId);
    if (risk) {
        result = risk.riskGroup === RentersRiskGroups.Additional ? monthlyPrice(risk.price) : statusSumOrIcon(risk);
    }

    return result;
}

function additionalRisksStatusInfo(plan: CoveredRisks, riskId: null | string): string {
    let result: string = '';
    const risk: CoverageRisk | undefined | null = plan.risks.find((value) => value?.id === riskId);
    if (risk) {
        result = isSummaryModeEnabled.value ? iconOrPrice(risk) : risksStatusInfo(plan, riskId);
    }
    return result;
}

function iconOrPrice(risk: CoverageRisk): string {
    return props.selectedAdditionalRisks.includes(risk.id)
        ? insuranceSum(risk.insuredSum)
        : `${iconContent(crossIconAttributes())}`;
}

function insuranceSum(insuredSum: number): string {
    return `${sparsePrice(insuredSum, true)}&nbsp;&euro;`;
}

function monthlyPrice(fullPrice: number): string {
    return `+&nbsp;${sparsePrice(fullPrice, false)}&nbsp;${translate('btar_policy_price_text')}`;
}

function statusSumOrIcon(risk: CoverageRisk): string {
    return risk.withoutInsuredSum ? selectIcon(risk) : sparsePrice(risk.insuredSum, true) + '&nbsp;&euro;';
}

function selectIcon(risk: CoverageRisk): string {
    return risk.isIncluded ? `${iconContent(checkmarkIconAttributes())}` : `${iconContent(crossIconAttributes())}`;
}

function coverageTitle(): string {
    return props.policies[0].id === 'RENTER' ? 'covered_single_renters_title' : 'covered_single_renters+_title';
}

function checkmarkIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon checkmark',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}

function crossIconAttributes(): ImageTagAttributes {
    return {
        class: 'item icon',
        src: 'images/one/cross-icon.svg',
        width: 14,
        height: 14,
    };
}

function iconContent(iconAttributes: ImageTagAttributes): string {
    return useHtml().imgTag(iconAttributes);
}
</script>

<template>
    <div v-if="viewIsReady" class="covered-popup-single">
        <div class="coverage-title">
            {{
                translateForType(coverageTitle(), translationType, {
                    '%PROGRAM_NAME%': translateForType(policies[0].id, translationType).toLowerCase(),
                })
            }}
        </div>
        <div class="no-plans"></div>
        <div class="covered-information-single">
            <div class="coverage-text">
                <div class="title">{{ translate('btar_summary_insurance_title') }}</div>
                <div class="description">{{ translateForType('coverage_description', translationType) }}</div>
            </div>
            <div :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()" class="risks">
                <div class="section-title">{{ translateForType('property_risks', translationType) }}</div>
                <div class="coverage">
                    <span>{{ translateForType('insurance_limits', translationType) }}</span>
                    <span class="price"
                        >{{
                            sparsePrice(props.policies[0].coveragePlans[props.coveragePlanKey].insuredSum, true)
                        }}&nbsp;&euro;</span
                    >
                </div>
                <div
                    v-for="(risk, index) in propertyRisks[0].risks"
                    :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index"
                    :key="index"
                    class="risk additional"
                >
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                        :value="risksStatusInfo(propertyRisks[0], risk.id)"
                    >
                    </app-text-with-tip>
                </div>
            </div>
            <div :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()" class="risks">
                <div class="section-title">{{ translateForType('liability_risks', translationType) }}</div>
                <div
                    v-for="(risk, index) in liabilityRisks[0].risks"
                    :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index"
                    :key="index"
                    class="risk additional"
                >
                    <div v-if="risk.id === RentersRisks.LiabilitySum" class="coverage">
                        <span>{{ translateForType(risk.id + '_DETAILED', translationType) }}</span>
                        <span class="price" v-html="risksStatusInfo(liabilityRisks[0], risk.id)"></span>
                    </div>
                    <app-text-with-tip
                        v-if="risk.id !== RentersRisks.LiabilitySum"
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                        :value="risksStatusInfo(liabilityRisks[0], risk.id)"
                    >
                    </app-text-with-tip>
                </div>
            </div>
            <div :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()" class="risks">
                <div class="section-title">{{ translateForType('territory', translationType) }}</div>
                <div
                    v-for="(risk, index) in territory[0].risks"
                    :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index"
                    :key="index"
                    class="risk additional"
                >
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                        :value="risksStatusInfo(territory[0], risk.id)"
                    >
                    </app-text-with-tip>
                </div>
            </div>
            <div :id="'covered-popup-single-' + CoveredPopupAutomationIterator.blockIndexIterate()" class="risks">
                <div class="options">
                    <span class="options-title">{{ translateForType('additional_options', translationType) }}</span>
                    <div v-if="!isSummaryModeEnabled">
                        <br />
                        <span class="options-description">{{
                            translateForType('options_description', translationType)
                        }}</span>
                    </div>
                </div>
                <div
                    v-for="(risk, index) in additionalRisks[0].risks"
                    :id="'covered-popup-single-risk-' + CoveredPopupAutomationIterator.blockIndex() + '-' + index"
                    :key="index"
                    class="risk additional"
                >
                    <app-text-with-tip
                        class="sub-title"
                        :info-icon-side="IconSide.Before"
                        :title="translateForType(risk.id + '_DETAILED', translationType)"
                        :tip-description="translateForType(risk.id + '_DETAILED_TOOLTIP', translationType)"
                        :value="additionalRisksStatusInfo(additionalRisks[0], risk.id)"
                    >
                    </app-text-with-tip>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.covered-popup-single {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .no-plans {
        background-color: var(--background-light);
        min-height: 98px;
        border-top-left-radius: var(--size-tiny);
        border-top-right-radius: var(--size-tiny);

        @include respond-above('sm') {
            height: 120px;
        }
    }

    .covered-information-single {
        width: 100%;
        padding: 0 var(--size-small) var(--size-small);
        overflow-y: auto;
        max-height: 650px;

        @include respond-above('sm') {
            padding: var(--size-small) var(--size-small) 0 var(--size-big);
        }

        .coverage-text {
            .title {
                font-size: var(--size-normal);
                margin-bottom: var(--size-nano) !important;
                margin-top: var(--size-small) !important;
                font-weight: 700;
            }

            .description {
                text-align: left;
                margin-bottom: var(--size-small);
            }
        }

        .coverage {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            border-bottom: 1px solid var(--black-100);
            margin: var(--size-small) 0 0 !important;
            padding-bottom: var(--size-small);

            .price {
                font-weight: 500;
                text-align: right;
            }
        }

        .risks {
            width: 100%;

            .risk {
                border-bottom: 1px solid var(--black-100);

                :deep(.text-with-tip) {
                    .inline {
                        .value {
                            font-weight: 500;
                        }
                    }
                }
            }

            .options {
                width: 100%;
                margin: var(--size-small) 0 0 !important;
                background: var(--system-color-success-light, rgb(0 180 173 / 0.08));
                border-radius: var(--size-femto, 4px);
                padding: 16px 20px;

                @include respond-above('sm') {
                    top: 50px;
                    left: var(--size-big);
                    width: 100%;
                }

                .options-title,
                .options-description {
                    display: block;
                }

                .options-title {
                    font-size: var(--size-small);
                    font-weight: 700;
                }

                .options-description {
                    font-weight: 500;
                    font-size: var(--font-size-tiny);
                    color: var(--text-color-subtle);
                }
            }
        }

        .sub-title {
            font-size: var(--size-tiny);
            font-weight: 600;
        }
    }

    .popup-title {
        position: absolute;
        padding-bottom: var(--size-small);
        border-bottom: 1px solid var(--black-100);
    }

    .coverage-title {
        position: absolute;
        max-width: 200px;
        top: 30px;
        left: 25px;
        font-size: var(--size-normal);
        font-weight: 700;

        @include respond-above('sm') {
            top: 50px;
            left: var(--size-big);
            max-width: 600px;
        }
    }

    .section-title {
        margin: var(--size-small) 0 0 !important;
        background: var(--system-color-success-light, rgb(0 180 173 / 0.08));
        border-radius: var(--size-femto, 4px);
        font-size: var(--size-small);
        font-weight: 700;
        padding: 16px 20px;
    }

    .sub-title {
        font-size: var(--size-tiny);
        font-weight: 600;
        margin: var(--size-small) 0 !important;
    }
}
</style>
