import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class ValidRoutes {
    private validRoutes: boolean[] = [];

    private static instance: ValidRoutes;

    public static getInstance(): ValidRoutes {
        if (!ValidRoutes.instance) {
            ValidRoutes.instance = new ValidRoutes();
        }

        return ValidRoutes.instance;
    }

    public applyRoute(routeId: number, isValid: boolean): void {
        this.validRoutes[routeId] = isValid;
    }

    public routeIsValid(routeId: number): boolean {
        return this.validRoutes[routeId];
    }

    public routes(): DynamicDictionary {
        return {
            routes: this.validRoutes,
        };
    }
}
