import VehicleProduct from '@/Enums/VehicleProductEnum';
import VueModel from '@/services/vue.model.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class VehicleSumCalculation {
    public calculateDiscountSum(prices: DynamicDictionary, product: string): number {
        const productInUpperCase: string = product.toUpperCase();
        let result: number;
        const year: number = 12;
        if (productInUpperCase === VehicleProduct.Casco) {
            result = prices.cascoMonthlySum ? year * prices.cascoMonthlySum : prices.cascoDiscountSum;
        } else if (productInUpperCase === VehicleProduct.Bundle) {
            result = prices.mtplDiscountSum;
            result += prices.cascoMonthlySum ? year * prices.cascoMonthlySum : prices.cascoDiscountSum;
        } else {
            result = VueModel.modelValueByName(product + 'DiscountSum', prices) as number;
        }

        return result;
    }
}
