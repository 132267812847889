import { AxiosResponse } from 'axios';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Url from '@/Enums/UrlEnum';
import OneBaseService from '@/services/OneBaseService';
import { useAxios } from '@/Composables/Axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class Offers {
    private static instance: Offers;
    private activeOffers: string = '';

    public static getInstance(): Offers {
        if (!Offers.instance) {
            Offers.instance = new Offers();
        }

        return Offers.instance;
    }

    public fetchOffersCount(): void {
        if (OneBaseService.getInstance().user.isLogged()) {
            const params: DynamicDictionary = {};
            const headers: DynamicDictionary = {};
            useAxios()
                .post(Url.Ajax.activeOffersCount, params, {
                    headers: headers,
                })
                .then((value: AxiosResponse): void => {
                    this.activeOffers =
                        value.data.data.body.activeOffersCount === 0 ? '' : value.data.data.body.activeOffersCount;
                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'fetchOffersCount', reason);
                });
        }
    }
}
