import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import GridContent from '@/Components/Lists/SmartGrid/Interfaces/GridContentInterface';
import ColumnsDefinitions from '@/Components/Lists/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import { TemplateCellRenderer } from '@/Components/Lists/SmartGrid/Renderers/TemplateCellRenderer';
import CellRendererTemplate from '@/Components/Lists/SmartGrid/Interfaces/CellRendererTemplateInterface';
import LegalInsuranceProgram from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuranceProgramInterface';
import { reactive, UnwrapNestedRefs } from 'vue';

export default class LegalPrograms {
    private gridContent: UnwrapNestedRefs<GridContent> = reactive(
        new (class implements GridContent {
            public columnDefs: ColumnsDefinitions[] = [];
            public rowData: DynamicDictionary[] = [];
        })(),
    );

    private context: DynamicDictionary = {};

    public addContext(context: DynamicDictionary): void {
        this.context = context;
    }

    public rebuildGrid(): void {
        this.gridContent.columnDefs = [
            {
                field: 'program',
                headerName: this.app.translated('insurance_program'),
                sortable: false,
                suppressMovable: true,
            },
            /*INFO: uncomment if IC is needed
            {
                field: 'code',
                headerName: this.app.translated('insurance_program_code'),
                maxWidth: 106,
            },
            /**/
            {
                field: 'premium',
                headerName: this.app.translated('yearly_premium'),
                maxWidth: 106,
                suppressSizeToFit: true,
                valueFormatter: this.premiumValueFormatter.bind(this),
                sortable: false,
                suppressMovable: true,
            },
            {
                field: 'persons',
                headerName: this.app.translated('persons_insured'),
                maxWidth: 140,
                suppressSizeToFit: true,
                cellRenderer: TemplateCellRenderer,
                cellRendererParams: this.cellRendererTemplate.bind(this),
                sortable: false,
                suppressMovable: true,
            },
        ];
        this.gridContent.rowData = [];
        this.app.legalAvailablePrograms
            .filter((program: LegalInsuranceProgram): boolean => program.showInList)
            .forEach((program: LegalInsuranceProgram): void => {
                this.gridContent.rowData.push({
                    program: program.name,
                    //INFO: uncomment if IC is needed
                    //code: program.id,
                    premium: program.premium,
                    persons: program.objectsCount,
                });
            });
    }

    private premiumValueFormatter(params: DynamicDictionary): string {
        return Number(params.value.amount).toFixed(2) + ' ' + this.app.localized('btar_currency');
    }

    private cellRendererTemplate(params: DynamicDictionary): CellRendererTemplate {
        const programId: string = this.app.legalAvailablePrograms[params.rowIndex].id!;
        const anchor: string = this.app.legalAvailablePrograms[params.rowIndex].descriptionFileAvailable
            ? '<a href="' + this.buildPrintoutUrl(programId) + '" target="_blank"></a>'
            : '';

        return new (class implements CellRendererTemplate {
            public template: string = '<div class="grid-cell-persons-insured"><span>@</span>' + anchor + '</div>';
        })();
    }

    private buildPrintoutUrl(id: string): string {
        return 'Printouts/program/' + id;
    }

    private get app(): DynamicDictionary {
        return this.context;
    }
}
