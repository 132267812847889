<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { watch, onMounted, PropType, reactive, Ref, computed, ref, nextTick, UnwrapNestedRefs } from 'vue';
import Form from '@/assets/libraries/form/form';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import { useTranslate } from '@/Composables/Translate';
import CreditCards from '@/Enums/CreditCardsEnum';
import CreditCardType from '@/interfaces/credit.card.type.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Sanitizer from '@/services/sanitizer.service';
import Validation from '@/services/validation.service';
import InputCreditCardNumber from '@/Components/Inputs/InputCreditCardNumber/InputCreditCardNumber.vue';
import MasterCard from '@/Components/Inputs/InputCreditCardNumber/CreditCards/MasterCard';
import Visa from '@/Components/Inputs/InputCreditCardNumber/CreditCards/Visa';
import DefaultCard from '@/Components/Inputs/InputCreditCardNumber/CreditCards/DefaultCard';

const props = defineProps({
    formField: { type: Object as PropType<FormField<string>>, default: () => new FormField('') },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    externalErrorMessage: { type: String, default: '' },
    supportedCards: {
        type: Array as PropType<string[]>,
        default: () => {
            return [CreditCards.Visa, CreditCards.MasterCard];
        },
    },
});

const emit = defineEmits(['change', 'keyup']);

const { translate } = useTranslate();

const form: Form = new Form();
const cardNumberFormatThreshold: number = 4;
const cardNumberSplitPattern: RegExp = /.{1,4}/g;
const cardNumberJoinString: string = '  ';
let selectedCardType: UnwrapNestedRefs<CreditCardType> = reactive(defaultCardType());
const selectedCardLabel: Ref<string> = ref('');
const selectedCardMaxLength: Ref<number> = ref(22);
const supportedCardTypes: Ref<CreditCardType[]> = ref([]);

const cardNumberMaxLength: Ref<number> = computed((): number => {
    return selectedCardMaxLength.value;
});

const showInternalMessage: Ref<boolean> = computed((): boolean => {
    return form.field('creditCardNumber').isNotEmpty() && !selectedCardType.name;
});

watch(
    () => props.formField.value,
    (currentValue, previousValue) => {
        selectedCardType = creditCardTypeFromCardNumber(cleanValue(props.formField.value));
        updateCardLabel();
        if (previousValue !== currentValue) {
            emit('change', props.formField.value);
        }
    },
);

onMounted((): void => {
    setupForm();
    nextTick(() => init());
});

function onCardNumberChange(): void {
    selectedCardType = creditCardTypeFromCardNumber(form.field('creditCardNumber').value);
    updateCardLabel();
    updateCardMaxLength();
}

function onCardNumberInput(value: string): void {
    selectedCardType = creditCardTypeFromCardNumber(value);
    updateCardLabel();
    updateCardMaxLength();
    props.formField.patch(cleanValue(form.field('creditCardNumber').value));
    patchFormattedVisibleCardNumber(value);
    emit('keyup', props.formField.value);
}

function onCardNumberBlur(): void {
    props.formField.touch();
}

function creditCardTypeFromCardNumber(cardNumber: string): CreditCardType {
    return (
        supportedCardTypes.value.find((cardType) => cardType.startDigit === Number(String(cardNumber).slice(0, 1))) ||
        defaultCardType()
    );
}

function init(): void {
    applySupportedCards();
    applyFormFieldSanitizer();
    applyFormFieldValidators();
    props.formField.onClear.subscribe((): void => {
        form.fields().forEach((field: FormField): void => {
            field.clear();
        });
    });
    props.formField.onPatch.subscribe((): void => {
        patchFormattedVisibleCardNumber(props.formField.value);
    });
    props.formField.onTouch.subscribe((): void => {
        props.formField.validate().then();
    });
}

function setupForm(): void {
    form.addField(new FormField('creditCardNumber'));
}

function addCardNumberSegmentFields(fieldCount: number): void {
    for (let i: number = 0; i < fieldCount; i++) {
        if (!fieldExists('cardSegment' + i)) {
            form.addField(new FormField('cardSegment' + i));
        }
    }
}

function fieldExists(fieldName: string): boolean {
    return form.exists(fieldName);
}

function clearCardNumberSegmentFields(): void {
    form.fields().forEach((field: FormField) => {
        if (field.name.includes('cardSegment')) {
            field.clear();
        }
    });
}

function cleanValue(value: string): string {
    return value.toString().replace(/[^\d]/g, '');
}

function patchFormattedVisibleCardNumber(value: string): void {
    let formattedNumber: string = '';
    const explodedNumber: string[] | null = cleanValue(value).match(cardNumberSplitPattern);
    clearCardNumberSegmentFields();
    if (explodedNumber) {
        addCardNumberSegmentFields(explodedNumber.length);
        explodedNumber.forEach((numberSegment: string, index: number) => {
            if (numberSegment.length === cardNumberFormatThreshold) {
                const suffix: string =
                    String(value).slice(-1) === ' ' && index === explodedNumber.length - 1 ? '' : cardNumberJoinString;
                form.field('cardSegment' + index).patch(numberSegment + suffix);
            } else {
                form.field('cardSegment' + index).patch(numberSegment);
            }
        });
    }
    if (value.length < cardNumberFormatThreshold) {
        formattedNumber = value;
    } else if (!form.field('cardSegment1').isEmpty()) {
        formattedNumber = formattedVisibleNumber();
    } else {
        formattedNumber = form.field('cardSegment0').value;
    }
    form.field('creditCardNumber').patch(formattedNumber);
}

function formattedVisibleNumber(): string {
    let formattedNumber: string = '';
    form.fields().forEach((field: FormField) => {
        if (field.name.includes('cardSegment')) {
            formattedNumber += field.value;
        }
    });
    return formattedNumber.slice(0, selectedCardType.maxDigitsFormatted);
}

function applySupportedCards(): void {
    supportedCardTypes.value = props.supportedCards.map((cardName: string): CreditCardType => {
        let card: CreditCardType = defaultCardType();
        let reason: string;
        switch (cardName) {
            case CreditCards.Visa:
                card = new Visa();
                break;
            case CreditCards.MasterCard:
                card = new MasterCard();
                break;
            default:
                reason = 'Unknown card type - ' + cardName;
                Error.log(ErrorType.Error, InputCreditCardNumber.name + ':', reason, true);
        }
        return card;
    });
}

function updateCardLabel(): void {
    selectedCardLabel.value = isSetCardType() ? selectedCardType.icon : '';
}

function updateCardMaxLength(): void {
    selectedCardMaxLength.value = selectedCardType.maxDigitsFormatted;
}

function isSetCardType(): boolean {
    return selectedCardType && Object.keys(selectedCardType).length > 0;
}

function defaultCardType(): CreditCardType {
    return new DefaultCard();
}

function applyFormFieldSanitizer(): void {
    props.formField.addSanitizer(Sanitizer.cleanInputInteger);
}

function applyFormFieldValidators(): void {
    props.formField.addValidators(cardNumberValidators());
}

function cardNumberValidators(): object {
    return {
        isValidCardNumber: () => {
            return props.formField.isTouched
                ? Validation.isValidCreditCardNumber(selectedCardType, props.formField.value)
                : true;
        },
    };
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-credit-card-number"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <app-input-text
            :id="'-visibleNumber'"
            :label="translate('btar_credit_card_number')"
            :form-field="form.field('creditCardNumber')"
            :data-store-disabled="true"
            :inside-label="selectedCardLabel"
            :max-length="cardNumberMaxLength"
            :autocomplete="'cc-number'"
            @keyup="onCardNumberInput"
            @change="onCardNumberChange"
            @blur="onCardNumberBlur"
        >
        </app-input-text>
        <div v-if="showInternalMessage" :id="formField.name + '-error-message-internal'" class="error-message internal">
            {{ translate('btar_credit_card_unsupported_type') }}
        </div>
        <div
            v-if="externalErrorMessage"
            :id="formField.name + '-error-message-external'"
            class="error-message external"
        >
            {{ translate(externalErrorMessage) }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-credit-card-number {
    width: 100%;

    :deep(.input-text) {
        .wrapper {
            .inside-label {
                &::before {
                    width: 0;
                }
            }
        }
    }

    .error-message {
        background-color: var(--system-color-error-light);
        width: 100%;
        margin-top: var(--size-pico);
        padding: var(--size-small);
        display: flex;
        align-items: center;
    }
}
</style>
