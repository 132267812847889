<script setup lang="ts">
import { AxiosParams, useAxios } from '../../../Composables/Axios';
import Url from '../../../Enums/UrlEnum';

const request: AxiosParams = useAxios();

function exitPreviewMode() {
    request.post(Url.Ajax.exitPreviewMode, []).finally(() => {
        const url = new URL(window.location.href);
        url.searchParams.delete('mode');
        window.location.href = url.toString();
    });
}
</script>

<template>
    <div id="preview-mode-header">
        <p class="preview-text">You are currently in a preview-mode</p>
        <p class="exit-button" @click="exitPreviewMode">Disable preview-mode</p>
    </div>
</template>

<style lang="scss" scoped>
#preview-mode-header {
    z-index: 999;
    background-color: rgb(0 180 173 / 0.5);
    display: flex;
    justify-content: space-evenly;

    .preview-text {
        color: white;
    }

    .exit-button {
        color: black;
        cursor: pointer;

        &:hover {
            color: white;
        }
    }
}
</style>
