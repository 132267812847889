<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import { computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import { useTranslate } from '@/Composables/Translate';
import { useClaimsMtplHtml } from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import ExtraDetailsPanelsNames from '@/Apps/ClaimsMtpl/Enums/ExtraDetailsPanelsNamesEnum';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import FetchParams from '@/Components/Maps/MapWithPlaces/FetchParams';
import SettingsService from '@/services/settings.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import { Router, useRouter } from 'vue-router';
import Translations from '@/services/translations.service';
import Value from '@/assets/libraries/form/value';
import { useDefine } from '@/Composables/Define';
import OneConfirmBackgroundColorType from '@/assets/libraries/popups/Enums/OneConfirmBackgroundColorTypeEnum';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import MtplClaimsAjaxCalls from '@/Apps/ClaimsMtpl/Enums/MtplClaimsAjaxCallsEnum';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypesEnum';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import EventBus from '@/services/event.bus.service';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import moment from 'moment';
import DateRange from '@/interfaces/date.range.interface';
import ClaimsMtplObject from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplObjectInterface';
import RequestService from '@/services/request.service';
import BreakPoints from '@/Enums/BreakPointsEnum';
import { useScroll } from '@/Composables/Scroll';
import AppCountry from '@/assets/libraries/app/app-country';
import ClaimsMtplOption from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionInterface';

const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();
const btaBase: OneBase = OneBaseService.getInstance();
const router: Router = useRouter();
const requestService: RequestService = RequestService.getInstance();

const { translate, translateForType } = useTranslate();
const { scrollToPanel } = useClaimsMtplHtml();
const { isSet } = useDefine();

const isSearchInProgress: Ref<boolean> = ref(false);

const Step: number = 3;
const CompensationTypeService: string = 'service';
const CompensationTypeMoney: string = 'myself';
const CompensationTypeOtherService: string = 'other';
const ServiceTypeOther: string = 'other_repair';

const form: Form = new Form();
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});
const canClearFormsAhead: Ref<boolean> = ref(false);

const extraDetailsPanelsNames: Ref<typeof ExtraDetailsPanelsNames> = computed(() => {
    return ExtraDetailsPanelsNames;
});

const involvedVehicle: Ref<ClaimsMtplObject> = computed(() => {
    return claimsMtplService.insuredObjects.value.find(
        (object: ClaimsMtplObject) => object.id === claimsMtplService.fields.whatVehicleWasInvolved!.object,
    )!;
});

const repairServicesFetchParams: Ref<FetchParams> = computed(() => {
    let result: FetchParams;
    const params: FetchParams = {
        insuredObjectId: involvedVehicle.value.id,
        storageFacility: 'one-claims-mtpl',
        storageDataType: 'mtpl-object',
        showInClaims: 'true',
        serviceType: ServiceTypeOther,
    } as FetchParams;
    if (claimsMtplService.fields.typeOfClaim!.selected === ClaimsMtplClaimTypes.DirectClaim) {
        result = {
            ...params,
            vehicleTypeWeb: involvedVehicle.value.vehicleClass,
            vehicleModel: involvedVehicle.value.model,
            vehicleAge: involvedVehicle.value.vehicleAge,
            claimType: ClaimsMtplClaimTypes.DirectClaim,
        } as FetchParams;
    } else {
        const vehicleRegistryData: DynamicDictionary = claimsMtplService.vehicleRegistryData.value!;
        result = {
            ...params,
            vehicleTypeWeb: vehicleRegistryData.vehicleType,
            vehicleModel: vehicleRegistryData.brand,
            vehicleAge: moment().diff(vehicleRegistryData.releaseYear, 'years'),
            claimType: ClaimsMtplClaimTypes.Victim,
        } as FetchParams;
    }

    return result;
});

const serviceDescription: Ref<string> = computed(() => {
    const topPlacesCount: number = SettingsService.getInstance().value('mapTopPlacesCount') as number;
    const replacements: DynamicDictionary = {
        '%count%': topPlacesCount === 0 ? ' ' : topPlacesCount,
    };

    return translateForType('repair_services_description', Translations.getInstance().type, replacements);
});

const canProceed: Ref<boolean> = computed(() => {
    let result: boolean = true;
    const typeOfCompensationField: FormField<{ selected?: string }> = form.field(
        ExtraDetailsPanelsNames.TypeOfCompensation,
    );
    if (typeOfCompensationField.isValid && typeOfCompensationField.value) {
        result =
            typeOfCompensationField.value.selected === CompensationTypeOtherService
                ? !form.field('otherRepairService').isValid
                : false;
    }

    return result;
});

const isProceedButtonVisible: Ref<boolean> = computed(() => {
    const optionWithProceedButton: string[] = [CompensationTypeOtherService, CompensationTypeMoney];

    return optionWithProceedButton.includes(form.field(ExtraDetailsPanelsNames.TypeOfCompensation).value.selected);
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    let result: boolean = false;

    if (btaBase.settings.localeIso() === 'EE') {
        result = inputOptions[ExtraDetailsPanelsNames.RepairService].passed;
    } else {
        switch (form.field(ExtraDetailsPanelsNames.TypeOfCompensation).value.selected) {
            case CompensationTypeMoney:
                result = inputOptions[ExtraDetailsPanelsNames.TypeOfCompensation].passed;
                break;
            case CompensationTypeOtherService:
                result = inputOptions[ExtraDetailsPanelsNames.OtherRepairService].passed;
                break;
            case CompensationTypeService:
                result = inputOptions[ExtraDetailsPanelsNames.RepairService].passed;
                break;
        }
    }

    return result;
});

const isVictimFlow: Ref<boolean> = computed(() => {
    return claimsMtplService.fields.typeOfClaim!.selected === ClaimsMtplClaimTypes.Victim;
});

const accidentEventDateTime: Ref<string> = computed(() => {
    const date: DynamicDictionary = claimsMtplService.fields.whenEventHappened!;
    const formattedDate: string = moment(new Date((date as DateRange).startDate)).format('YYYY-MM-DD');
    const time: string = claimsMtplService.fields.whatTimeDidItHappened!;
    const formattedTime: string = moment(time, 'HH:mm').format('HH:mm:ss');

    return `${formattedDate} ${formattedTime}`;
});

const accidentEventTimestamp: Ref<string> = computed(() => {
    return moment(accidentEventDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
});

const vehicleRegistryDataRequestParams: Ref<DynamicDictionary> = computed(() => {
    return {
        date: accidentEventTimestamp.value,
        registrationNumber: claimsMtplService.fields.vehicleVehicleRegistrationNumber,
    };
});

function setupForm(): void {
    Object.keys(inputOptions).forEach((field: string) => {
        form.addField(new FormField(field));
    });
    form.field(ExtraDetailsPanelsNames.OtherRepairService).addValidators('required');
    form.setReady();
}

function preparePanels(): void {
    for (const key in extraDetailsPanelsNames.value) {
        const panelName: string = extraDetailsPanelsNames.value[key as keyof ExtraDetailsPanelsNames];
        inputOptions[panelName] = reactive(
            new (class implements ClaimsMtplOption {
                public enabled: boolean = true;
                public passed: boolean = false;
                public visible: boolean = false;
                public value: LimitedVariant = '';
                public options: InputOption[] = [];
            })(),
        );
    }
}

function buildInitialOptions(): void {
    buildTypeOfCompensationOptions();
}

function buildTypeOfCompensationOptions(): void {
    inputOptions[ExtraDetailsPanelsNames.TypeOfCompensation].options = [];
    inputOptions[ExtraDetailsPanelsNames.TypeOfCompensation].options.push(
        new InputOptionBuilder().setName(localized('repair_bta_service')).setValue(CompensationTypeService).build(),
        new InputOptionBuilder().setName(localized('compensation_money')).setValue(CompensationTypeMoney).build(),
        new InputOptionBuilder()
            .setName(localized('repair_other_service'))
            .setValue(CompensationTypeOtherService)
            .build(),
    );
}

function proceedButton(): DynamicDictionary {
    return {
        title: localized('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function transformAndApplyValueOnOptions(field: string): void {
    const resultValue: LimitedVariant = form.field(field).value;
    applyValueOnOption(field, resultValue);
}

function applyPassedOnOption(option: string, passed: boolean): void {
    inputOptions[option].passed = passed;
}

function applyVisibleOnOption(option: string, visible: boolean): void {
    if (optionIsPresent(option)) {
        inputOptions[option].visible = visible;
    }
}

function optionIsPresent(option: string): boolean {
    return isSet(inputOptions[option]);
}

function applyResetOnOption(option: string): void {
    applyValueOnOption(option, '');
    applyPassedOnOption(option, false);
    applyVisibleOnOption(option, false);
    form.field(option).clear().then();
}

function applyValueOnOption(option: string, newValue: LimitedVariant): void {
    if (optionIsPresent(option)) {
        inputOptions[option].value = newValue;
    }
}

function onFormPanelInputChange(panel: string): void {
    transformAndApplyValueOnOptions(panel);
    let clear: boolean = false;
    Object.keys(inputOptions).forEach((key: string): void => {
        if (clear) {
            applyResetOnOption(key);
        }
        if (key === panel) {
            clear = true;
            applyPassedOnOption(key, false);
        }
    });
    togglePanelVisibility(false);
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
}

function isPanelVisible(panelName: string): boolean {
    return inputOptions[panelName].visible;
}

function storeFormToService(): void {
    Object.keys(ExtraDetailsPanelsNames).forEach((field: string) => {
        const keyName: string = ExtraDetailsPanelsNames[field as keyof ExtraDetailsPanelsNames];
        claimsMtplService.fields[keyName as keyof ClaimsMtplFormFields] = form.field(keyName).value;
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(inputOptions).forEach((field: string) => {
        if (isSet(storedValues[field]) && new Value(storedValues[field]).isNotEmpty()) {
            form.field(field).setValue(storedValues[field]);
        }
    });
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
}

function togglePanelVisibility(isRestore: boolean): void {
    if (btaBase.settings.localeIso() === 'EE') {
        applyVisibleOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, false);
        applyVisibleOnOption(ExtraDetailsPanelsNames.RepairService, true);
        scrollToPanel(ExtraDetailsPanelsNames.RepairService);
    } else {
        applyVisibleOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, true);
        switch (form.field(ExtraDetailsPanelsNames.TypeOfCompensation).value.selected) {
            case CompensationTypeService:
                applyPassedOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, true);
                applyVisibleOnOption(ExtraDetailsPanelsNames.RepairService, true);
                applyPassedOnOption(ExtraDetailsPanelsNames.RepairService, false);
                scrollToPanel(ExtraDetailsPanelsNames.RepairService);
                break;
            case CompensationTypeOtherService:
                applyPassedOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, true);
                applyVisibleOnOption(ExtraDetailsPanelsNames.OtherRepairService, true);
                applyPassedOnOption(ExtraDetailsPanelsNames.OtherRepairService, isRestore);
                scrollToPanel(ExtraDetailsPanelsNames.TypeOfCompensation);
                break;
            case CompensationTypeMoney:
                applyPassedOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, isRestore);
                break;
            default:
                break;
        }
    }
}

function onProceed(): void {
    if (form.field(ExtraDetailsPanelsNames.TypeOfCompensation).value.selected === CompensationTypeMoney) {
        if (shouldAutomaticallyConfirmMoneyPayout()) {
            confirmMoneyPayout();
        } else {
            openMoneyPopup();
        }
    } else {
        applyPassedOnOption(ExtraDetailsPanelsNames.OtherRepairService, true);
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
        ClaimsMtplService.getInstance().clearFormsAhead(Step);
        proceed();
    }
}

function shouldAutomaticallyConfirmMoneyPayout(): boolean {
    return new AppCountry().isLT();
}

function onRepairServiceSelect(): void {
    applyPassedOnOption(ExtraDetailsPanelsNames.RepairService, true);
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
    ClaimsMtplService.getInstance().clearFormsAhead(Step);
    proceed();
}

function proceed(): void {
    updateStepper();
    storeFormToService();
    router.push({ name: MtplClaimsStepUid.UploadFiles });
}

function onSelectFromList(): void {
    const topOffset: number = 80;
    const width: number = btaBase.width.value;
    if (width < BreakPoints.Lg) {
        useScroll().scrollToViewByDataScroll('map-with-places', topOffset);
    }
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function openMoneyPopup() {
    PopupService.getInstance().show(
        new OnePopup()
            .withType()
            .oneConfirm.withTitle(localized('money_payout'))
            .withDescription(localized('money_payout_description'))
            .withDescriptionBackgroundColorType(OneConfirmBackgroundColorType.Warning)
            .withButtons([
                {
                    title: localized('choose_another_option'),
                    textColor: ButtonTextColor.Black,
                    backgroundColor: ButtonBackground.White,
                    backgroundColorHover: ButtonBackground.Transparent,
                    borderColor: ButtonBorder.Pale,
                    callback() {
                        chooseAnotherOption();
                    },
                },
                {
                    title: localized('confirm_owner'),
                    textColor: ButtonTextColor.White,
                    backgroundColor: ButtonBackground.Red,
                    icon: ButtonIcon.LongArrowRight,
                    iconPosition: ButtonIconPosition.Right,
                    callback() {
                        confirmMoneyPayout();
                    },
                },
            ]),
    );
}

function chooseAnotherOption(): void {
    PopupService.getInstance().hide();
}

function confirmMoneyPayout(): void {
    applyPassedOnOption(ExtraDetailsPanelsNames.TypeOfCompensation, true);
    GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
    ClaimsMtplService.getInstance().clearFormsAhead(Step);
    PopupService.getInstance().hide();
    proceed();
}

function mustFetchVehicleRegistryData(): boolean {
    return isVictimFlow.value && claimsMtplService.vehicleRegistryData.value === null;
}

function fetchVehicleRegistryData(): Promise<void> {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;
    claimsMtplService.vehicleRegistryData.value = null;

    return requestService
        .get({
            uri: MtplClaimsAjaxCalls.VehicleRegistryData,
            content: vehicleRegistryDataRequestParams.value,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (isValidVehicleRegistryDataResponse(response)) {
                claimsMtplService.vehicleRegistryData.value = response.data.data.body.response;
            }
        })
        .catch((reason: LimitedVariant): void => {
            btaBase.error.show(ErrorType.Error, 'fetchVehicleRegistryData', reason as DynamicDictionary);
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function isValidVehicleRegistryDataResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.response) &&
        isSet(response.data.data.body.response.releaseYear)
    );
}

function updateStepper(): void {
    EventBus.getInstance().emit('updateStepVisibility', [
        {
            stepName: MtplClaimsStepUid.IndemnityReceiver,
            isVisible: claimsMtplService.isIndemnityReceiverPageVisible(),
        },
    ]);
}

function scrollToLastPanel(): void {
    let lastVisiblePanel: string = '';
    Object.keys(inputOptions).forEach((panelKey: string, index: number) => {
        if (inputOptions[panelKey].enabled && inputOptions[panelKey].visible && index !== 0) {
            lastVisiblePanel = panelKey;
        }
    });
    if (lastVisiblePanel !== '') {
        useClaimsMtplHtml().scrollToPanel(lastVisiblePanel);
    }
}

preparePanels();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    setupForm();
    buildInitialOptions();
    restoreValues();
    Promise.all([form.validate(), ...(mustFetchVehicleRegistryData() ? [fetchVehicleRegistryData()] : [])]).finally(
        () => {
            togglePanelVisibility(true);
            scrollToLastPanel();
        },
    );
});

defineExpose({
    claimsMtplService,
    inputOptions,
    form,
    onRepairServiceSelect,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form v-if="form.isReady()" :form="form" class="form" @change="storeFormToService()">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <router-link
                    v-slot="{ navigate }"
                    class="back back-margin"
                    :to="{ name: MtplClaimsStepUid.AccidentDetails }"
                    :disabled="!form.isValid()"
                >
                    <button @click="navigate">
                        <img src="images/one/arrow-left.svg" alt="back" />
                        <span>{{ translate('back_button') }}</span>
                    </button>
                </router-link>
                <label>{{ localized('extra_details') }}</label>
                <div
                    v-if="isPanelVisible(ExtraDetailsPanelsNames.TypeOfCompensation)"
                    class="whiteboard"
                    :data-scroll="ExtraDetailsPanelsNames.TypeOfCompensation"
                >
                    <h4 class="title">
                        {{ localized('type_of_compensation') }}
                    </h4>
                    <p
                        class="header-description"
                        v-html="localized('how_you_want_to_receive_compensation_description')"
                    ></p>
                    <app-options-smart-list
                        :options="inputOptions[ExtraDetailsPanelsNames.TypeOfCompensation].options"
                        :form-field="form.field(ExtraDetailsPanelsNames.TypeOfCompensation)"
                        :required="true"
                        :show-error-borders="false"
                        :skip-options-change-form-reset="!canClearFormsAhead"
                        :option-class="'filled'"
                        :type="'radio'"
                        @change="onFormPanelInputChange(ExtraDetailsPanelsNames.TypeOfCompensation)"
                    >
                    </app-options-smart-list>
                    <app-input-textarea
                        v-if="isPanelVisible(ExtraDetailsPanelsNames.OtherRepairService)"
                        :form-field="form.field(ExtraDetailsPanelsNames.OtherRepairService)"
                        :placeholder="localized('repair_other_service_placeholder')"
                        @change="onFormPanelInputChange(ExtraDetailsPanelsNames.OtherRepairService)"
                    >
                    </app-input-textarea>
                    <app-button-with-callback
                        v-if="isProceedButtonVisible"
                        class="button"
                        v-bind="proceedButton()"
                        :disabled="canProceed"
                        @button-callback-click="onProceed()"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(ExtraDetailsPanelsNames.RepairService)"
                    class="whiteboard"
                    :data-scroll="ExtraDetailsPanelsNames.RepairService"
                >
                    <h4>
                        {{ localized('repair_service') }}
                        <app-tooltipster
                            :title="localized('repair_service_tip_title')"
                            :description="localized('repair_service_tip_description')"
                        >
                        </app-tooltipster>
                    </h4>
                    <p class="header-description header-description-blue">{{ serviceDescription }}</p>
                    <app-map-with-places
                        :fetch-url="MtplClaimsAjaxCalls.RepairServices"
                        :fetch-params="repairServicesFetchParams"
                        :form-field="form.field(ExtraDetailsPanelsNames.RepairService)"
                        @list-select="onSelectFromList"
                        @change="onRepairServiceSelect()"
                    >
                    </app-map-with-places>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .input:not(:last-child) {
        margin-bottom: 24px;
    }

    .header-description {
        margin-bottom: var(--size-normal);
    }

    .button {
        margin-top: var(--size-medium);
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .options-smart-list {
        margin-bottom: var(--size-nano);
    }
}
</style>
