import { reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import { validate, ValidationResult } from 'vee-validate';
import { Subject } from 'rxjs';
import Error from '@/services/error.service';
import Value from '@/assets/libraries/form/value';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Debounce from '@/services/debounce.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import ErrorType from '@/Enums/ErrorTypeEnum';

export type SanitizerCallback<T> = (value: T) => T;
type ValidatorCallback = (value: LimitedVariant) => boolean;

interface Validator {
    name: string;
    isValid: ValidatorCallback;
}

export default class FormField<ValueType = any> {
    public onPatch: Subject<ValueType> = new Subject();
    public onTouch: Subject<void> = new Subject();
    public onUntouched: Subject<void> = new Subject();
    public onClear: Subject<void> = new Subject();
    public onRestore: Subject<ValueType> = new Subject();
    private localName: Ref<string> = ref('');
    private localValue: Ref<ValueType> = ref('') as Ref<ValueType>;
    private localStates: UnwrapNestedRefs<{ valid: boolean; touched: boolean; restored: boolean }> = reactive({
        valid: false,
        touched: false,
        restored: false,
    });
    private sanitizer?: SanitizerCallback<ValueType>;
    private localValidators: Ref<string> = ref('');
    private localCustomValidators: Ref<Validator[]> = ref([]);
    private localErrors: Ref<string[]> = ref([]);
    private isLazy: boolean = false;
    private sanitizeDebounce: Function = Debounce.getInstance().applyDebounce(this.sanitizeDebounceCallback, this);

    public constructor(
        name: string,
        value: ValueType = '' as ValueType,
        validators: string | Record<string, any> = '',
        sanitizer?: SanitizerCallback<ValueType>,
    ) {
        this.localName.value = name;
        this.localValue.value = value;
        this.sanitizer = sanitizer;
        this.addValidators(validators);
    }

    public get name(): string {
        return this.localName.value;
    }

    /** @deprecated Should avoid using name setter. Name should only be set in constructor. */
    public set name(name: string) {
        this.localName.value = name;
    }

    public get value(): ValueType {
        return this.localValue.value;
    }

    public set value(value: ValueType) {
        this.localValue.value = value;
    }

    public get isValid(): boolean {
        return this.localStates.valid;
    }

    public get isTouched(): boolean {
        return this.localStates.touched;
    }

    public get isRestored(): boolean {
        return this.localStates.restored;
    }

    public classes(): {
        error: boolean;
        valid: boolean;
        invalid: boolean;
        touched: boolean;
        untouched: boolean;
    } {
        return {
            error: this.isTouched && !this.isValid,
            valid: this.isValid,
            invalid: !this.isValid,
            touched: this.isTouched,
            untouched: !this.isTouched,
        };
    }

    public touch(): FormField<ValueType> {
        this.localStates.touched = true;
        this.onTouch.next();
        return this;
    }

    public makeLazy(): void {
        this.isLazy = true;
    }

    public setValue(value: ValueType): void {
        this.localValue.value = value;
    }

    public setIsValid(value: boolean): void {
        this.localStates.valid = value;
        this.sanitize().validate().then();
    }

    public patch(value: ValueType, touch: boolean = true): void {
        this.localValue.value = value;
        if (touch) {
            this.touch().sanitize().validate().then();
            this.onPatch.next(this.value);
        } else {
            this.sanitize().validate().then();
        }
    }

    public patchStandalone(value: ValueType): void {
        this.localValue.value = value;
        this.onPatch.next(this.value);
    }

    public markAsUntouched(): void {
        this.localStates.touched = false;
        this.onUntouched.next();
    }

    public markAsRestored(): void {
        this.localStates.restored = true;
        this.onRestore.next(this.value);
    }

    public markAsFresh(): void {
        this.localStates.restored = false;
        this.onRestore.next(this.value);
    }

    public isEmpty(): boolean {
        return new Value(this.value).isEmpty();
    }

    public isNotEmpty(): boolean {
        return !this.isEmpty();
    }

    public isThisField(fieldName: string): boolean {
        return this.localName.value === fieldName;
    }

    public addValidators(validators: string | Record<string, any>): FormField<ValueType> {
        this.sortAndAppendValidators(validators);
        this.sanitize();
        this.validate().then();

        return this;
    }

    public addSanitizer(sanitizer: SanitizerCallback<ValueType>): FormField<ValueType> {
        this.sanitizer = sanitizer;

        return this;
    }

    private sanitizeDebounceCallback(): void {
        if (this.sanitizer) {
            this.localValue.value = this.sanitizer(this.localValue.value);
        }
    }

    public sanitize(): FormField<ValueType> {
        if (this.isLazy) {
            this.sanitizeDebounce();
        } else {
            this.sanitizeDebounceCallback();
        }

        return this;
    }

    public async validate(): Promise<void> {
        this.localErrors.value = [];
        return new Promise((resolve) => {
            validate(this.value, this.localValidators.value)
                .then((validationResult: ValidationResult): void => {
                    this.isValidAllCustomValidators().then((isValidAllCustomValidators: boolean): void => {
                        this.localStates.valid = validationResult.valid && isValidAllCustomValidators;
                        resolve();
                    });
                })
                .catch((reason: DynamicDictionary): void => {
                    Error.log(ErrorType.Error, 'FormField::validate()', reason);
                    this.localStates.valid = false;
                });
        });
    }

    public async clear(): Promise<void> {
        this.localValue.value = this.clearValue(this.value) as any;
        this.localStates.touched = false;
        this.onClear.next();
        return this.validate().then();
    }

    public errors(): string[] {
        return this.localErrors.value;
    }

    public hasError(validatorName: string): boolean {
        return !!this.localErrors.value.find((validator: string): boolean => validator === validatorName);
    }

    public clearCustomValidators(): void {
        this.localCustomValidators.value = [];
    }

    public clearValidators(): void {
        this.localValidators.value = '';
    }

    public clearSanitizer(): void {
        this.sanitizer = undefined;
    }

    private clearValue(value: any): object | string {
        let result: any = '';

        if (typeof value === 'object') {
            result = {};
            Object.keys(value).forEach(
                (propertyName: string) => (result[propertyName] = this.clearValue(value[propertyName])),
            );
        }

        return result;
    }

    private async isValidAllCustomValidators(): Promise<boolean> {
        return Promise.all(
            this.localCustomValidators.value.map((validator: Validator): boolean => {
                const isValid: boolean = validator.isValid(this.value as LimitedVariant);
                if (!isValid) {
                    this.localErrors.value.push(validator.name);
                }
                return isValid;
            }),
        ).then((results: boolean[]): boolean => !results.some((result: boolean): boolean => !result));
    }

    private sortAndAppendValidators(validators: string | Record<string, string | ValidatorCallback>): void {
        if (typeof validators === 'string') {
            this.appendLocalValidator(validators);
        } else {
            Object.keys(validators).forEach((validatorName: string): void => {
                const validator: string | ValidatorCallback = validators[validatorName];
                if (typeof validators[validatorName] === 'string') {
                    this.appendLocalValidator(validator as string);
                }
                if ({}.toString.call(validator) === '[object Function]') {
                    this.appendLocalCustomValidator({
                        name: validatorName,
                        isValid: validator as ValidatorCallback,
                    });
                }
            });
        }
    }

    private appendLocalValidator(validator: string): void {
        this.localValidators.value = this.localValidators.value
            .split('|')
            .concat(validator.split('|'))
            .filter((currentValidator: string): boolean => currentValidator !== '')
            .join('|');
    }

    private appendLocalCustomValidator(validator: { name: string; isValid: Function }): void {
        this.localCustomValidators.value = this.localCustomValidators.value.filter(
            (localValidator: Validator): boolean => localValidator.name !== validator.name,
        );
        this.localCustomValidators.value.push(validator as Validator);
    }
}
