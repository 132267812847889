import BackendRoute from '@/Core/Routing/Interfaces/BackendRouteInterface';
import VueApp from '@/Core/Routing/VueApp';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import { ComponentPublicInstance } from 'vue';
import { App } from 'vue';
import Router from '@/Core/Routing/Router';
import { createPinia } from 'pinia';
import GlobalDirectives from '@/Directives/Directives';
import AppComponent from '@/Apps/ClaimsMtpl/App.vue';
import StepperLayout from '@/Layouts/StepperLayout.vue';
import RouteStepper from '@/Components/Routing/RouteStepper/RouteStepper.vue';
import AccidentDataPage from '@/Apps/ClaimsMtpl/Pages/AccidentDataPage.vue';
import DamagePage from '@/Apps/ClaimsMtpl/Pages/DamagePage.vue';
import AccidentDetailsPage from '@/Apps/ClaimsMtpl/Pages/AccidentDetailsPage.vue';
import ExtraDetailsPage from '@/Apps/ClaimsMtpl/Pages/ExtraDetailsPage.vue';
import UploadFilesPage from '@/Apps/ClaimsMtpl/Pages/UploadFilesPage.vue';
import InsuranceIndemnityReceiverPage from '@/Apps/ClaimsMtpl/Pages/InsuranceIndemnityReceiverPage.vue';
import YourContactDetailsPage from '@/Apps/ClaimsMtpl/Pages/YourContactDetailsPage.vue';
import BelongingsDamage from '@/Apps/ClaimsMtpl/Components/BelongingsDamage.vue';
import PersonDamage from '@/Apps/ClaimsMtpl/Components/PersonDamage.vue';
import PropertyDamage from '@/Apps/ClaimsMtpl/Components/PropertyDamage.vue';
import VehicleDamage from '@/Apps/ClaimsMtpl/Components/VehicleDamage.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import InsuredObjectsBlocks from '@/Components/Lists/InsuredObjectsBlocks/InsuredObjectsBlocks.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import Popup from '@/Components/Popups/Popup/Popup.vue';
import Tooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import ContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import InputText from '@/Components/Inputs/InputText/InputText.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import InputMultiCheckbox from '@/Components/Inputs/InputMultiCheckbox/InputMultiCheckbox.vue';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import PersonListWithSearch from '@/Components/Lists/PersonListWithSearch/PersonListWithSearch.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import InputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import FilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import Drawer from '@/Components/Modals/Drawer/Drawer.vue';
import OptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import InputTime from '@/Components/Inputs/InputTime/InputTime.vue';
import InputPlateNumber from '@/Components/Inputs/InputPlateNumber/InputPlateNumber.vue';
import ObjectList from '@/Components/Lists/ObjectList/ObjectList.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import InputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import InputTextarea from '@/Components/Inputs/InputTextarea/InputTextarea.vue';
import MapWithAddress from '@/Components/Maps/MapWithAddress/MapWithAddress.vue';
import OptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import MapWithPlaces from '@/Components/Maps/MapWithPlaces/MapWithPlaces.vue';
import InputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import InputCompanyType from '@/Components/Inputs/InputCompanyType/InputCompanyType.vue';
import InputBankAccount from '@/Components/Inputs/InputBankAccount/InputBankAccount.vue';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import ResultMessage from '@/Components/Other/ResultMessage/ResultMessage.vue';
import TwoWayModalScreen from '../TwoWayCommunication/Components/TwoWayModalScreen.vue';

const pages: Components = {
    AccidentDataPage: AccidentDataPage,
    DamagePage: DamagePage,
    AccidentDetailsPage: AccidentDetailsPage,
    ExtraDetailsPage: ExtraDetailsPage,
    UploadFilesPage: UploadFilesPage,
    InsuranceIndemnityReceiverPage: InsuranceIndemnityReceiverPage,
    YourContactDetailsPage: YourContactDetailsPage,
};

const components: Components = {
    AppStepperLayout: StepperLayout,
    AppRouteStepper: RouteStepper,
    AppBelongingsDamage: BelongingsDamage,
    AppPersonDamage: PersonDamage,
    AppPropertyDamage: PropertyDamage,
    AppVehicleDamage: VehicleDamage,
    AppCustomForm: CustomForm,
    AppInsuredObjectsBlocks: InsuredObjectsBlocks,
    AppLanguageSwitcher: LanguageSwitcher,
    AppPopup: Popup,
    AppTooltipster: Tooltipster,
    AppContentLoader: ContentLoader,
    AppInputRadio: InputRadio,
    AppInputText: InputText,
    AppInputEmail: InputEmail,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppButtonWithCallback: ButtonWithCallback,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppPersonListWithSearch: PersonListWithSearch,
    AppPhoneWithCountry: PhoneWithCountry,
    AppInputCheckbox: InputCheckbox,
    AppInputSelect: InputSelect,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputNumber: InputNumber,
    AppFilesUploader: FilesUploader,
    AppDrawer: Drawer,
    AppOptionsSmartList: OptionsSmartList,
    AppInputTime: InputTime,
    AppInputPlateNumber: InputPlateNumber,
    AppObjectList: ObjectList,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputDate: InputDate,
    AppInputTextarea: InputTextarea,
    AppMapWithAddress: MapWithAddress,
    AppOptionsList: OptionsList,
    AppMapWithPlaces: MapWithPlaces,
    AppInputCountry: InputCountry,
    AppInputCompanyType: InputCompanyType,
    AppInputBankAccount: InputBankAccount,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppResultMessage: ResultMessage,
    AppTwoWayModalScreen: TwoWayModalScreen,
};

export default class {
    public app(selector: string, routes: BackendRoute[]): ComponentPublicInstance {
        const app: App = new VueApp(AppComponent)
            .withComponents({ ...pages, ...components })
            .withDirectives(GlobalDirectives)
            .create();
        app.use(new Router(app, routes).create());
        app.use(createPinia());
        return app.mount(selector);
    }
}
