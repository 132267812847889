export default class PolicyPeriods {
    public static readonly OneMonthPayment: string = '1';
    public static readonly TwoMonthsPayment: string = '2';
    public static readonly ThreeMonthsPayment: string = '3';
    public static readonly SixMonthsPayment: string = '6';
    public static readonly NineMonthsPayment: string = '9';
    public static readonly OneYearPayment: string = '12';
    public static readonly SubscriptionPayment: string = '1';

    public static memberValueByName(memberName: string): string {
        const firstCharIndex: number = 0;
        const secondCharIndex: number = 1;
        const propertyDescriptor: PropertyDescriptor | undefined = Object.getOwnPropertyDescriptor(
            PolicyPeriods,
            memberName.charAt(firstCharIndex).toUpperCase() + memberName.slice(secondCharIndex),
        );

        return propertyDescriptor !== undefined ? String(propertyDescriptor.value) : '';
    }
}
