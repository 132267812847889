<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import { computed, getCurrentInstance, onMounted, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import { useClaimsMtplHtml } from '@/Apps/ClaimsMtpl/Composables/ClaimsMtplHtml';
import ClaimsMtplOptions from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionsInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { InputOption } from '@/interfaces/InputOptionInterface';
import AccidentDataPanelsNames from '@/Apps/ClaimsMtpl/Enums/AccidentDataPanelsNamesEnum';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import moment from 'moment';
import Translations from '@/services/translations.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useAxios } from '@/Composables/Axios';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useDefine } from '@/Composables/Define';
import OneDate from '@/assets/libraries/Date/OneDate';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import { Router, useRouter } from 'vue-router';
import Value from '@/assets/libraries/form/value';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ClaimsMtplObject from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplObjectInterface';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import ClaimsMtplClaimTypes from '@/Apps/ClaimsMtpl/Enums/ClaimsMtplClaimTypesEnum';
import MtplClaimsAjaxCalls from '@/Apps/ClaimsMtpl/Enums/MtplClaimsAjaxCallsEnum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import OneConfirmBackgroundColorType from '@/assets/libraries/popups/Enums/OneConfirmBackgroundColorTypeEnum';
import GuardsService from '@/Apps/ClaimsMtpl/Services/GuardsService';
import MtplClaimsStepUid from '@/Apps/ClaimsMtpl/Enums/MtplClaimsStepUidEnum';
import EventBus from '@/services/event.bus.service';
import DateRange from '@/interfaces/date.range.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import RequestService from '@/services/request.service';
import Sanitizer from '@/services/sanitizer.service';
import ClaimsMtplOption from '@/Apps/ClaimsMtpl/Interfaces/ClaimsMtplOptionInterface';

const { translate, translateForType, hasLocalization } = useTranslate();
const { isSet } = useDefine();
const router: Router = useRouter();

const base: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();
const requestService: RequestService = RequestService.getInstance();

const Step: number = 0;
const DamageStepNumber: number = 1;
const RedirectSkipRouteName: string = MtplClaimsStepUid.AccidentDetails;
const TextSeparator: string = '::';
const TimeFormat: string = 'HH:mm';
const AccidentTypeAgreedStatement: string = 'AGREED_STATEMENT';

const externalErrorTextMessage: Ref<string> = ref('');
const canClearFormsAhead: Ref<boolean> = ref(false);
const form: Form = new Form();
const insuredObjects: Ref<InputOption[]> = ref([]);
const inputOptions: UnwrapNestedRefs<ClaimsMtplOptions> = reactive({});
const isSearchInProgress: Ref<boolean> = ref(false);
const foundElectronically: Ref<string> = ref('');

const calendarMinDate: Ref<Date> = computed(() => {
    const minDateYears: number = 5;
    return moment().subtract(minDateYears, 'years').toDate();
});

const calendarMaxDate: Ref<Date> = computed(() => {
    return moment().toDate();
});

const selectDate: Ref<string> = computed(() => {
    return localized('select_date');
});

const weFoundElectronicallyTitle: Ref<string> = computed(() => {
    const replacements: DynamicDictionary = reactive({
        '%address%': foundElectronically.value,
    });

    return localized('we_found_electronically', replacements);
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    let result: boolean = true;
    Object.keys(inputOptions).forEach((panelKey: string) => {
        if (inputOptions[panelKey].enabled && !inputOptions[panelKey].passed) {
            result = false;
        }
    });

    return result;
});

const isVictim: Ref<boolean> = computed(() => {
    return form.field(AccidentDataPanelsNames.TypeOfClaim).value.selected === ClaimsMtplClaimTypes.Victim;
});

const isGuilty: Ref<boolean> = computed(() => {
    return form.field(AccidentDataPanelsNames.TypeOfClaim).value.selected === ClaimsMtplClaimTypes.Guilty;
});

const isDirectClaim: Ref<boolean> = computed(() => {
    return form.field(AccidentDataPanelsNames.TypeOfClaim).value.selected === ClaimsMtplClaimTypes.DirectClaim;
});

const noObjectOverrideCallback: Ref<Function | null> = computed(() => {
    return isVictim.value ? overrideCallback : null;
});

const weFoundElectronicallySelectedYes: Ref<boolean> = computed(() => {
    return form.field(AccidentDataPanelsNames.WeFoundElectronically).value.selected === 'yes';
});

const accidentEventDateTime: Ref<string> = computed(() => {
    const date: DynamicDictionary = form.field(AccidentDataPanelsNames.WhenEventHappened).value;
    const formattedDate: string = moment(new Date((date as DateRange).startDate)).format('YYYY-MM-DD');
    const time: string = form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).value;
    const formattedTime: string = moment(time, TimeFormat).format('HH:mm:ss');

    return `${formattedDate} ${formattedTime}`;
});

const accidentEventTimestamp: Ref<string> = computed(() => {
    return moment(accidentEventDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
});

const includeHolderCodeInsuredObjectsParams: Ref<boolean> = computed(() => {
    return isGuilty.value;
});

const includePlateNumberInsuredObjectsParams: Ref<boolean> = computed(() => {
    return isVictim.value;
});

const includeBranchInsuredObjectsParams: Ref<boolean> = computed(() => {
    return !isVictim.value;
});

const insuredObjectsRequestParams: Ref<DynamicDictionary> = computed(() => {
    return {
        eventDate: accidentEventDateTime.value,
        ...(!new AppCountry().isLV()
            ? {
                  directClaim: isDirectClaim.value,
              }
            : {}),
        ...(includeHolderCodeInsuredObjectsParams.value
            ? {
                  holderCode: base.user.current.personCode,
              }
            : {}),
        ...(includePlateNumberInsuredObjectsParams.value
            ? {
                  plateOrVinNumber: form.field(AccidentDataPanelsNames.GuiltyPartyVehicleNumber).value,
              }
            : {}),
        ...(includeBranchInsuredObjectsParams.value
            ? {
                  branch: searchParamBranchValue.value,
              }
            : {}),
    };
});

const accidentRegistryDataRequestParams: Ref<DynamicDictionary> = computed(() => {
    return {
        accidentDate: accidentEventTimestamp.value,
        claimType: form.field(AccidentDataPanelsNames.TypeOfClaim).value.selected,
        selectedObjectId: claimsMtplService.fields.whatVehicleWasInvolved!.object,
        otherVehicleRegistrationNumber: form.field(AccidentDataPanelsNames.GuiltyPartyVehicleNumber).value,
    };
});

const searchParamBranchValue: Ref<string> = computed(() => {
    return new AppCountry().iso();
});

const isPoliceProtocolSelected: Ref<boolean> = computed(() => {
    return form.field(AccidentDataPanelsNames.HowWasTheAccidentHandled).value?.selected === 'police_protocol';
});

function preparePanels(): void {
    Object.keys(AccidentDataPanelsNames).forEach((key: string) => {
        const panelName: string = AccidentDataPanelsNames[key as keyof AccidentDataPanelsNames];
        if (isPanelName(panelName)) {
            inputOptions[panelName] = reactive(
                new (class implements ClaimsMtplOption {
                    public enabled: boolean = true;
                    public passed: boolean = false;
                    public visible: boolean = false;
                    public value: LimitedVariant = '';
                    public options: InputOption[] = [];
                })(),
            );
        }
    });
}

function isPanelName(name: string): boolean {
    return ![AccidentDataPanelsNames.WhatTimeDidItHappened, AccidentDataPanelsNames.PoliceProtocolNumber].includes(
        name,
    );
}

function buildWeFoundElectronically(): void {
    const newInputOptions: InputOption[] = [
        new InputOptionBuilder().setValue('yes').setName(translate('btar_yes')).build(),
        new InputOptionBuilder().setValue('no').setName(translate('btar_no')).build(),
    ];
    applyOptionsOnOption(AccidentDataPanelsNames.WeFoundElectronically, newInputOptions);
}

function buildHowWasTheAccidentHandled(): void {
    const newInputOptions: InputOption[] = [
        new InputOptionBuilder()
            .setValue('police_protocol')
            .setName(localized('police_protocol'))
            .setTipster('', localized('police_protocol_tooltip'))
            .build(),
        new InputOptionBuilder()
            .setValue('agreed_statement')
            .setName(localized('agreed_statement'))
            .setTipster('', localized('agreed_statement_tooltip'))
            .build(),
    ];
    applyOptionsOnOption(AccidentDataPanelsNames.HowWasTheAccidentHandled, newInputOptions);
}

function applyOptionsOnOption(option: string, options: InputOption[]): void {
    inputOptions[option].options = options;
}

function prepareClaimTypes(): void {
    claimsMtplService.claimTypes.forEach((claimType: string) => {
        const builder: InputOptionBuilder = new InputOptionBuilder();
        builder.setName(localized(claimType)).setValue(claimType);
        const itemTipster: string = claimType + '_tooltip';
        if (hasLocalization(itemTipster, Translations.getInstance().type)) {
            builder.setTipster('', localized(itemTipster));
        }
        inputOptions[AccidentDataPanelsNames.TypeOfClaim].options.push(builder.build());
    });
}

function fetchInsuredObjects(): Promise<void> {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;
    const params: DynamicDictionary = insuredObjectsRequestParams.value;

    return useAxios()
        .get(MtplClaimsAjaxCalls.ClaimsMtplObjects, { params })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (isValidGetObjectDataResponse(response)) {
                const objects: ClaimsMtplObject[] = response.data.data.body.objects;
                claimsMtplService.insuredObjects.value = objects;
                claimsMtplService.electronicStatement.value = null;
                buildInsuredObjects(objects);
                calculatePanelsPass();
            } else {
                resetToFirstPanel();
            }
        })
        .catch((reason: LimitedVariant): void => {
            resetToFirstPanel();
            base.error.show(ErrorType.Error, 'fetchInsuredObjects', reason as DynamicDictionary);
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function nextPanelNeedAccidentRegistryData(panelName: string): boolean {
    return isDirectClaim.value && panelName === AccidentDataPanelsNames.WeFoundElectronically;
}

function fetchAccidentRegistryData(): Promise<void> {
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isSearchInProgress.value = true;
    claimsMtplService.electronicStatement.value = null;

    return requestService
        .get({
            uri: MtplClaimsAjaxCalls.AccidentRegistryData,
            content: accidentRegistryDataRequestParams.value,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (isValidAccidentRegistryDataResponse(response)) {
                claimsMtplService.electronicStatement.value = response.data.data.body.response;
                updateWeFoundElectronicallyTitle();
                calculatePanelsPass();
                calculateEnabledPanels();
            }
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isSearchInProgress.value = false;
        });
}

function isValidGetObjectDataResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.data) &&
        isSet(response.data.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.objects)
    );
}

function isValidAccidentRegistryDataResponse(response: DynamicDictionary): boolean {
    return (
        isSet(response.data) &&
        isSet(response.data.data.body) &&
        isSet(response.data.data.body.response) &&
        isSet(response.data.data.body.response.accidentType)
    );
}

function buildInsuredObjects(data: ClaimsMtplObject[]): void {
    insuredObjects.value = [];
    if (data.length > 0 && !isSet(data[0].error)) {
        data.forEach((object: ClaimsMtplObject): void => {
            insuredObjects.value.push(
                new InputOptionBuilder()
                    .setValue(object.id)
                    .setName(object.name)
                    .setCustom({
                        type: object.objectType,
                        policy: object.policyNumber,
                        insuredPersonIdentityNumber: object.insuredPersonIdentityNumber,
                        validFrom: object.validFrom,
                        validTo: object.validTo,
                        errorMessage: insuredObjectCustomErrorMessage(object),
                    })
                    .setDisabled(!!insuredObjectCustomErrorMessage(object))
                    .build(),
            );
        });
    }
}

function insuredObjectCustomErrorMessage(object: ClaimsMtplObject): string {
    const directClaimErrorMessage =
        new AppCountry().isLV() && isDirectClaim.value && !object.isDirectClaim
            ? localized('direct_claim_handling_error')
            : null;

    const samePlateNumberBothPartiesError =
        isDirectClaim.value && object.plateNumber === form.field(AccidentDataPanelsNames.GuiltyPartyVehicleNumber).value
            ? localized('same_plate_number_both_parties')
            : null;

    return directClaimErrorMessage ?? samePlateNumberBothPartiesError ?? '';
}

function localized(stringUid: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(stringUid, Translations.getInstance().type, replaceParts);
}

function setupForm(): void {
    const validators: (string | Record<string, any>)[] = ['', '', timeValidator(), '', '', '', '', ''];
    Object.keys(AccidentDataPanelsNames).forEach((field: string, index: number) => {
        form.addField(
            new FormField(AccidentDataPanelsNames[field as keyof AccidentDataPanelsNames], '', validators[index]),
        );
    });
    form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).addValidators('time');
    form.field(AccidentDataPanelsNames.PoliceProtocolNumber).addSanitizer(Sanitizer.cleanPoliceProtocol);
    form.setReady();
}

function timeValidator(): object {
    return {
        timeIsValid: (value: string) => {
            const inputTime: string = form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).value;
            let result: boolean = true;
            if (isTodaySelected() && !isFutureTime() && isTimeInputEmpty(inputTime)) {
                externalErrorTextMessage.value = translateForType('input_error_future_time', 'errors');
                result = false;
            }
            if (!isValidHours(inputTime) || !isValidMinutes(inputTime)) {
                externalErrorTextMessage.value = translateForType('input_error_max_value', 'errors');
                result = false;
            }

            return result;
        },
    };
}

function isDirectClaimSupported(): boolean {
    const data: DynamicDictionary = form.field(AccidentDataPanelsNames.WhatVehicleWasInvolved).value;
    let result: boolean = true;
    const claim: ClaimsMtplObject | undefined = claimsMtplService.insuredObjects.value.find(
        (object: ClaimsMtplObject): boolean => object.id === data.object,
    );
    if (isSet(claim) && !claim!.isDirectClaim && isDirectClaim.value) {
        result = false;
    }

    return result;
}

function isValidHours(time: string): boolean {
    const result: string[] = time.split(':');
    const maxHours: number = 23;
    const hours: number = parseInt(result[0], 10);

    return isNaN(hours) || hours <= maxHours;
}

function isValidMinutes(time: string): boolean {
    const result: string[] = time.split(':');
    const maxMinutes: number = 59;
    const minutes: number = parseInt(result[1], 10);

    return isNaN(minutes) || minutes <= maxMinutes;
}

function isFutureTime(): boolean {
    return moment(form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).value, TimeFormat).isSameOrBefore(
        moment(),
        'minute',
    );
}

function isTimeInputEmpty(time: string): boolean {
    const result: string[] = time.split(':');

    return time !== ':' && result[0] !== '';
}

function isTodaySelected(): boolean {
    return !moment(new Date(form.field(AccidentDataPanelsNames.WhenEventHappened).value.startDate)).isBefore(
        moment(),
        'day',
    );
}

function storeFormToService(): void {
    Object.keys(AccidentDataPanelsNames).forEach((field: string) => {
        const key: string = AccidentDataPanelsNames[field as keyof AccidentDataPanelsNames];
        claimsMtplService.fields[key as keyof ClaimsMtplFormFields] = form.field(key).value;
    });
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(AccidentDataPanelsNames).forEach((field: string) => {
        const keyName: string = AccidentDataPanelsNames[field as keyof AccidentDataPanelsNames];
        if (isSet(storedValues[keyName]) && new Value(storedValues[keyName]).isNotEmpty()) {
            form.field(keyName).setValue(storedValues[keyName]);
        }
    });
}

function mustFetchInsuredObjects(): boolean {
    return (
        claimsMtplService.insuredObjects.value.length === 0 &&
        previousPanelsPassed(AccidentDataPanelsNames.WhatVehicleWasInvolved)
    );
}

function previousPanelsPassed(panel: string): boolean {
    const panelIndex: number = Object.values(AccidentDataPanelsNames).indexOf(panel);

    return Object.values(AccidentDataPanelsNames)
        .slice(0, panelIndex)
        .filter((value: string): boolean => isPanelName(value) && inputOptions[value].enabled)
        .every((value: string): boolean => inputOptions[value].passed);
}

function restoreInsuredObjects(): Promise<void> {
    return new Promise((resolve): void => {
        if (mustFetchInsuredObjects()) {
            fetchInsuredObjects().finally(() => resolve());
        } else {
            buildInsuredObjects(claimsMtplService.insuredObjects.value);
            resolve();
        }
    });
}

function mustFetchAccidentRegistryData(): boolean {
    return (
        isDirectClaim.value &&
        claimsMtplService.electronicStatement.value === null &&
        previousPanelsPassed(AccidentDataPanelsNames.WeFoundElectronically)
    );
}

function restoreAccidentRegistryData(): Promise<void> {
    return new Promise((resolve): void => {
        if (mustFetchAccidentRegistryData()) {
            fetchAccidentRegistryData().finally(() => resolve());
        } else {
            updateWeFoundElectronicallyTitle();
            resolve();
        }
    });
}

function updateWeFoundElectronicallyTitle(): void {
    if (claimsMtplService.electronicStatement.value) {
        foundElectronically.value = claimsMtplService.electronicStatement.value.address.text;
    }
}

function calendarDaysButtonsParams(stringId: string): ButtonWithCallbackParams {
    return {
        title: localized(stringId),
        mode: 'badge',
        textColor: ButtonTextColor.White,
        textColorHover: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.Black,
        backgroundColorHover: ButtonBackground.White,
    };
}

function objectAlreadyStored(objectId: string): boolean {
    return claimsMtplService.insuredObjects.value.some((object: DynamicDictionary): boolean => object.id === objectId);
}

function proceedButton(): DynamicDictionary {
    return {
        title: localized('claims_mtpl_proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function proceedButtonIsDisabled(panelName: string): boolean {
    return !inputOptions[panelName].passed;
}

function isPanelVisible(panelName: string): boolean {
    return inputOptions[panelName].visible && inputOptions[panelName].enabled;
}

function onFormPanelInputChange(panel: string): void {
    let fromPanel: string = panel;
    if (panel === AccidentDataPanelsNames.WhatTimeDidItHappened) {
        fromPanel = AccidentDataPanelsNames.WhenEventHappened;
    }
    if (panel === AccidentDataPanelsNames.WhatVehicleWasInvolved) {
        base.userStorage.applyStorageData(claimsMtplService.insuredObjects.value);
    }
    clearFormsAhead(fromPanel);
    if (fromPanel === AccidentDataPanelsNames.TypeOfClaim) {
        calculateEnabledPanels();
    }
    buildStateAfterChange();
}

function buildStateAfterChange(): void {
    calculatePanelsVisibility();
    form.validate().then(() => {
        calculatePanelsPass();
        GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value, true);
        setupDamageStepForSkip();
        ClaimsMtplService.getInstance().clearFormsAhead(Step);
    });
}

function setupDamageStepForSkip(): void {
    GuardsService.getInstance().applyStepValidity(DamageStepNumber, weFoundElectronicallySelectedYes.value, true);
}

function calculateEnabledPanels(): void {
    inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled = true;
    inputOptions[AccidentDataPanelsNames.GuiltyPartyVehicleNumber].enabled = true;
    switch (form.field(AccidentDataPanelsNames.TypeOfClaim).value.selected) {
        case ClaimsMtplClaimTypes.Victim:
            inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled = false;
            break;

        case ClaimsMtplClaimTypes.Guilty:
            inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled = false;
            inputOptions[AccidentDataPanelsNames.GuiltyPartyVehicleNumber].enabled = false;
            break;

        case ClaimsMtplClaimTypes.DirectClaim:
            inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled = false;
            if (claimsMtplService.electronicStatement.value) {
                inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled =
                    claimsMtplService.electronicStatement.value.electronicallySigned &&
                    claimsMtplService.electronicStatement.value.accidentType === AccidentTypeAgreedStatement;
            }
            break;

        default:
    }
}

function clearFormsAhead(panel: string): void {
    let clear: boolean = false;
    Object.keys(inputOptions).forEach((panelKey: string) => {
        if (clear) {
            applyResetOnOption(panelKey);
            clearFormValue(panelKey);
        }
        if (panelKey === panel) {
            clear = true;
            inputOptions[panelKey].passed = false;
        }
    });
}

function applyResetOnOption(panel: string): void {
    inputOptions[panel].value = '';
    inputOptions[panel].passed = false;
    inputOptions[panel].visible = false;
    form.field(panel).clear().then();
}

function clearFormValue(panel: string): void {
    form.field(panel).clear();
    if (panel === AccidentDataPanelsNames.WhenEventHappened) {
        form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).clear();
    }
    if (panel === AccidentDataPanelsNames.HowWasTheAccidentHandled) {
        form.field(AccidentDataPanelsNames.PoliceProtocolNumber).clear();
    }
}

function calculatePanelsPass(): void {
    Object.keys(inputOptions).forEach((panelKey: string) => {
        let result: boolean;
        switch (panelKey) {
            case AccidentDataPanelsNames.WhenEventHappened:
                result =
                    form.field(AccidentDataPanelsNames.WhenEventHappened).isNotEmpty() &&
                    form.field(AccidentDataPanelsNames.WhenEventHappened).isValid &&
                    form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).isNotEmpty() &&
                    form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).isValid;
                break;
            case AccidentDataPanelsNames.WhatVehicleWasInvolved:
                result =
                    form.field(panelKey).isNotEmpty() &&
                    form.field(panelKey).isValid &&
                    (new AppCountry().isLV() && isDirectClaim.value ? isDirectClaimSupported() : true);
                break;
            default:
                result = form.field(panelKey).isNotEmpty() && form.field(panelKey).isValid;
        }
        inputOptions[panelKey].passed = result;
    });
}

function calculatePanelsVisibility(): void {
    let previousPanel: string = '';
    Object.keys(inputOptions).forEach((panelKey: string) => {
        if (previousPanel !== '' && inputOptions[previousPanel].enabled) {
            inputOptions[panelKey].visible = inputOptions[previousPanel].passed;
        }
        if (inputOptions[panelKey].enabled) {
            previousPanel = panelKey;
        }
    });
    inputOptions[AccidentDataPanelsNames.HowWasTheAccidentHandled].enabled = !weFoundElectronicallySelectedYes.value;
}

function onTodayClick(): void {
    form.field(AccidentDataPanelsNames.WhenEventHappened).patch({
        startDate: moment().toDate(),
        endDate: '',
    });
}

function onYesterdayClick(): void {
    form.field(AccidentDataPanelsNames.WhenEventHappened).patch({
        startDate: moment().subtract(1, 'days').toDate(),
        endDate: '',
    });
}

function onFinderReceive(mtplObject: ClaimsMtplObject): void {
    if (!objectAlreadyStored(mtplObject.id)) {
        claimsMtplService.insuredObjects.value.push(mtplObject);
    }
    onFormPanelInputChange(AccidentDataPanelsNames.WhatVehicleWasInvolved);
}

function titleTransformerCallback(item: InputOption): string {
    return item.name.split(TextSeparator)[0];
}

function descriptionTransformerCallback(item: InputOption): string {
    const objectNameSplit: string = item.name.split(TextSeparator)[1];
    const objectDescriptionData: string[] = objectNameSplit.split(' ');
    objectDescriptionData!.pop();

    return [objectDescriptionData.join(' '), OneDate.short((item.custom as DynamicDictionary).validTo)].join(' ');
}

function onInsuredReceiveError(): void {
    base.error.show(ErrorType.Error, 'app-insured-objects-blocks', 'btar_error_common');
}

function overrideCallback(): void {
    if (isVictim.value) {
        PopupService.getInstance().show(
            new OnePopup()
                .withType()
                .oneConfirm.withTitle(localized('no_insured_vehicle_is_found_title'))
                .withDescription(localized('no_insured_vehicle_is_found_description'))
                .withDescriptionBackgroundColorType(OneConfirmBackgroundColorType.Error)
                .withButtons([
                    {
                        title: localized('phone_call'),
                        textColor: ButtonTextColor.White,
                        backgroundColor: ButtonBackground.Red,
                        icon: ButtonIcon.Phone,
                        iconPosition: ButtonIconPosition.Left,
                        url: 'tel:' + localized('contact_bta_phone_number'),
                    },
                    {
                        title: localized('email'),
                        textColor: ButtonTextColor.White,
                        backgroundColor: ButtonBackground.Red,
                        icon: ButtonIcon.Mail,
                        iconPosition: ButtonIconPosition.Left,
                        url: 'mailto:' + localized('contact_bta_email'),
                    },
                    {
                        title: localized('back'),
                        textColor: ButtonTextColor.Black,
                        backgroundColor: ButtonBackground.White,
                        backgroundColorHover: ButtonBackground.Transparent,
                        borderColor: ButtonBorder.Pale,
                        icon: ButtonIcon.LongArrowLeft,
                        iconPosition: ButtonIconPosition.Left,
                        iconColor: ButtonIconColor.Black,
                        callback() {
                            confirmButtonBackCallback();
                        },
                    },
                ]),
        );
    }
}

function confirmButtonBackCallback(): void {
    PopupService.getInstance().hide();
    clearFormsAhead(AccidentDataPanelsNames.GuiltyPartyVehicleNumber);
    form.field('guiltyPartyVehicleNumber')
        .clear()
        .then(() => {
            scrollToLastPanel();
        });
}

function finderOptionBuilder(object: DynamicDictionary): InputOption {
    return new InputOptionBuilder()
        .setValue(object.id)
        .setName(object.name)
        .setCustom({
            type: object.type,
            policy: object.policy,
            validFrom: object.validFrom,
            validTo: object.validTo,
            errorMessage: isDirectClaim.value && !object.isDirectClaim ? localized('direct_claim_handling_error') : '',
            isFinderObject: object.isFinderObject,
        })
        .setDisabled(!!(new AppCountry().isLV() && isDirectClaim.value && !object.isDirectClaim))
        .build();
}

function resetToFirstPanel(): void {
    canClearFormsAhead.value = true;
    onFormPanelInputChange(AccidentDataPanelsNames.TypeOfClaim);
    onPanelProceedClick(AccidentDataPanelsNames.TypeOfClaim);
}

function onPanelProceedClick(panelName: string): void {
    if (
        panelName === AccidentDataPanelsNames.HowWasTheAccidentHandled &&
        inputOptions[AccidentDataPanelsNames.WeFoundElectronically].enabled &&
        form.field(AccidentDataPanelsNames.WeFoundElectronically).value.selected === 'yes'
    ) {
        updateStepVisibility();
        storeFormToService();
        router.push({ name: RedirectSkipRouteName });

        return;
    }
    Promise.all([...(nextPanelNeedAccidentRegistryData(panelName) ? [fetchAccidentRegistryData()] : [])]).then(
        (): void => {
            let panel: string = panelName;
            if (panelName === AccidentDataPanelsNames.GuiltyPartyVehicleNumber && !inputOptions[panelName].enabled) {
                panel = AccidentDataPanelsNames.WhatVehicleWasInvolved;
            }
            if (panelName === AccidentDataPanelsNames.WeFoundElectronically && !inputOptions[panelName].enabled) {
                panel = AccidentDataPanelsNames.HowWasTheAccidentHandled;
            }
            if (panel === AccidentDataPanelsNames.WhatVehicleWasInvolved) {
                fetchInsuredObjects().then();
            }
            inputOptions[panel].visible = true;
            useClaimsMtplHtml().scrollToPanel(panel);
        },
    );
}

function scrollToLastPanel(): void {
    let lastVisiblePanel: string = '';
    Object.keys(inputOptions).forEach((panelKey: string, index: number) => {
        if (inputOptions[panelKey].enabled && inputOptions[panelKey].visible && index !== 0) {
            lastVisiblePanel = panelKey;
        }
    });
    if (lastVisiblePanel !== '') {
        useClaimsMtplHtml().scrollToPanel(lastVisiblePanel);
    }
}

function proceedToNextStep(): void {
    updateStepVisibility();
    storeFormToService();
    router.push({ name: MtplClaimsStepUid.Damage });
}

function updateStepVisibility() {
    const steps: DynamicDictionary[] = [
        {
            stepName: MtplClaimsStepUid.Damage,
            isVisible: claimsMtplService.isDamagePageVisible(),
        },
        {
            stepName: MtplClaimsStepUid.ExtraDetails,
            isVisible: claimsMtplService.isDamagePageVisible(),
        },
        {
            stepName: MtplClaimsStepUid.IndemnityReceiver,
            isVisible: claimsMtplService.isDamagePageVisible(),
        },
    ];
    EventBus.getInstance().emit('updateStepVisibility', steps);
}

function proceedButtonParams(): ButtonWithCallbackParams {
    return {
        title: localized('claims_mtpl_proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function onAfterFormRestored(): void {
    if (
        form.field(AccidentDataPanelsNames.WhenEventHappened).isNotEmpty() &&
        form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).isNotEmpty()
    ) {
        form.field(AccidentDataPanelsNames.WhatTimeDidItHappened).touch();
    }
}

preparePanels();
buildWeFoundElectronically();
buildHowWasTheAccidentHandled();
prepareClaimTypes();

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    setupForm();
    restoreValues();
    onAfterFormRestored();
    form.validate()
        .then((): Promise<void> => {
            calculateEnabledPanels();
            calculatePanelsPass();
            GuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
            calculatePanelsVisibility();

            return restoreInsuredObjects();
        })
        .then((): Promise<void> => {
            return restoreAccidentRegistryData();
        })
        .finally((): void => {
            calculateEnabledPanels();
            scrollToLastPanel();
        });
});

defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container horizontal-spacing">
        <app-custom-form v-if="form.isReady()" :form="form" class="form" @change="storeFormToService()">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <button class="back back-margin" :href="claimsMtplService.initialStepUrl">
                    <img src="images/one/arrow-left.svg" alt="back" />
                    <span>{{ translate('back_button') }}</span>
                </button>
                <label>{{ localized('accident_data') }}</label>
                <div class="whiteboard" :data-type="'whiteboard-0'" :data-scroll="AccidentDataPanelsNames.TypeOfClaim">
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('types_of_claim') }}</h4>
                            <app-options-smart-list
                                :options="inputOptions[AccidentDataPanelsNames.TypeOfClaim].options"
                                :form-field="form.field(AccidentDataPanelsNames.TypeOfClaim)"
                                :skip-options-change-form-reset="!canClearFormsAhead"
                                :show-error-borders="false"
                                :required="true"
                                :type="'radio'"
                                :option-class="'filled'"
                                @change="onFormPanelInputChange(AccidentDataPanelsNames.TypeOfClaim)"
                            >
                            </app-options-smart-list>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButtonParams()"
                        :disabled="proceedButtonIsDisabled(AccidentDataPanelsNames.TypeOfClaim)"
                        @button-callback-click="onPanelProceedClick(AccidentDataPanelsNames.WhenEventHappened)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDataPanelsNames.WhenEventHappened)"
                    class="whiteboard"
                    :data-type="'whiteboard-1'"
                    :data-scroll="AccidentDataPanelsNames.WhenEventHappened"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('when_event_happened') }}</h4>
                            <div class="calendar-block-container">
                                <app-input-date-with-calendar
                                    class="calendar"
                                    :disabled="isSearchInProgress"
                                    :number-of-calendars="1"
                                    :number-of-days="364"
                                    :min-date="calendarMinDate"
                                    :max-date="calendarMaxDate"
                                    :return-raw="false"
                                    :date-visual-empty-marker="localized('select_event_date')"
                                    :mobile-button-text="selectDate"
                                    :form-field="form.field(AccidentDataPanelsNames.WhenEventHappened)"
                                    @change="onFormPanelInputChange(AccidentDataPanelsNames.WhenEventHappened)"
                                >
                                </app-input-date-with-calendar>
                                <span class="date-buttons" :class="{ disabled: isSearchInProgress }">
                                    <button
                                        class="button outside calendar-date-button-big"
                                        data-type="button-today"
                                        @click="onTodayClick"
                                    >
                                        {{ localized('today') }}
                                    </button>
                                    <button
                                        class="button outside calendar-date-button-big"
                                        data-type="button-yesterday"
                                        @click="onYesterdayClick"
                                    >
                                        {{ localized('yesterday') }}
                                    </button>
                                    <app-button-with-callback
                                        class="calendar-date-button-small"
                                        v-bind="calendarDaysButtonsParams('today')"
                                        @button-callback-click="onTodayClick"
                                    >
                                    </app-button-with-callback>
                                    <app-button-with-callback
                                        class="calendar-date-button-small"
                                        v-bind="calendarDaysButtonsParams('yesterday')"
                                        @button-callback-click="onYesterdayClick"
                                    >
                                    </app-button-with-callback>
                                </span>
                            </div>
                            <div class="input-block input-time-offset">
                                <h4 class="margin-top">{{ localized('what_time_did_it_happened') }}</h4>
                                <app-input-time
                                    :mode="'split'"
                                    :feedback-message="externalErrorTextMessage"
                                    :form-field="form.field(AccidentDataPanelsNames.WhatTimeDidItHappened)"
                                    @change="onFormPanelInputChange(AccidentDataPanelsNames.WhatTimeDidItHappened)"
                                >
                                </app-input-time>
                            </div>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        :data-type="'event-date-submit'"
                        v-bind="proceedButtonParams()"
                        :disabled="proceedButtonIsDisabled(AccidentDataPanelsNames.WhenEventHappened)"
                        @button-callback-click="onPanelProceedClick(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                    class="whiteboard"
                    :data-type="'whiteboard-2'"
                    :data-scroll="AccidentDataPanelsNames.GuiltyPartyVehicleNumber"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('guilty_party_vehicle_number') }}</h4>
                            <app-input-plate-number
                                class="guilty-vehicle-number"
                                :placeholder="localized('guilty_vehicle_number_placeholder')"
                                :form-field="form.field(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                                @change="onFormPanelInputChange(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                                @keyup="onFormPanelInputChange(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                            >
                            </app-input-plate-number>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        :data-type="'guilty-party-submit'"
                        v-bind="proceedButtonParams()"
                        :disabled="proceedButtonIsDisabled(AccidentDataPanelsNames.GuiltyPartyVehicleNumber)"
                        @button-callback-click="onPanelProceedClick(AccidentDataPanelsNames.WhatVehicleWasInvolved)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDataPanelsNames.WhatVehicleWasInvolved)"
                    class="whiteboard"
                    :data-type="'whiteboard-3'"
                    :data-scroll="AccidentDataPanelsNames.WhatVehicleWasInvolved"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('what_vehicle_was_involved') }}</h4>
                            <app-insured-objects-blocks
                                :icon="'auto'"
                                :object-type="'claims_mtpl'"
                                :translation-type="'claims_mtpl'"
                                :without-branch-value="false"
                                :branch-value="searchParamBranchValue"
                                :options="insuredObjects"
                                :form-field="form.field(AccidentDataPanelsNames.WhatVehicleWasInvolved)"
                                :event-date="accidentEventDateTime"
                                :fetch-url="MtplClaimsAjaxCalls.ClaimsMtplSingleObject"
                                :finder-callback="onFinderReceive"
                                :finder-object-builder-callback="new AppCountry().isLV() ? finderOptionBuilder : ''"
                                :title-transformer-callback="titleTransformerCallback"
                                :description-transformer-callback="descriptionTransformerCallback"
                                :search-by="'plate-or-vin-number'"
                                :fetch-params="insuredObjectsRequestParams"
                                :no-object-override-callback="noObjectOverrideCallback"
                                :skip-options-change-form-reset="!canClearFormsAhead"
                                :automatically-select-the-only-option="true"
                                :force-show-finder="true"
                                :mode="'with-finder'"
                                @change="onFormPanelInputChange(AccidentDataPanelsNames.WhatVehicleWasInvolved)"
                                @error="onInsuredReceiveError"
                            >
                            </app-insured-objects-blocks>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        :data-type="'involved-submit'"
                        v-bind="proceedButtonParams()"
                        :disabled="proceedButtonIsDisabled(AccidentDataPanelsNames.WhatVehicleWasInvolved)"
                        @button-callback-click="onPanelProceedClick(AccidentDataPanelsNames.WeFoundElectronically)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDataPanelsNames.WeFoundElectronically)"
                    class="whiteboard"
                    :data-type="'whiteboard-4'"
                    :data-scroll="AccidentDataPanelsNames.WeFoundElectronically"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ weFoundElectronicallyTitle }}</h4>
                            <app-options-smart-list
                                :options="inputOptions[AccidentDataPanelsNames.WeFoundElectronically].options"
                                :form-field="form.field(AccidentDataPanelsNames.WeFoundElectronically)"
                                :skip-options-change-form-reset="!canClearFormsAhead"
                                :show-error-borders="false"
                                :required="true"
                                :type="'radio'"
                                :option-class="'filled'"
                                @change="onFormPanelInputChange(AccidentDataPanelsNames.WeFoundElectronically)"
                            >
                            </app-options-smart-list>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButtonParams()"
                        :disabled="proceedButtonIsDisabled(AccidentDataPanelsNames.WeFoundElectronically)"
                        @button-callback-click="onPanelProceedClick(AccidentDataPanelsNames.HowWasTheAccidentHandled)"
                    >
                    </app-button-with-callback>
                </div>
            </div>
            <div class="whiteboard-panel">
                <div
                    v-if="isPanelVisible(AccidentDataPanelsNames.HowWasTheAccidentHandled)"
                    class="whiteboard"
                    :data-type="'whiteboard-5'"
                    :data-scroll="AccidentDataPanelsNames.HowWasTheAccidentHandled"
                >
                    <div class="inputs row">
                        <div class="input-block">
                            <h4>{{ localized('how_was_accident_handled') }}</h4>
                            <app-options-smart-list
                                :options="inputOptions[AccidentDataPanelsNames.HowWasTheAccidentHandled].options"
                                :form-field="form.field(AccidentDataPanelsNames.HowWasTheAccidentHandled)"
                                :skip-options-change-form-reset="!canClearFormsAhead"
                                :show-error-borders="false"
                                :required="true"
                                :type="'radio'"
                                :option-class="'filled'"
                                @change="onFormPanelInputChange(AccidentDataPanelsNames.HowWasTheAccidentHandled)"
                            >
                            </app-options-smart-list>
                        </div>
                        <div v-if="isPoliceProtocolSelected" class="input-block protocol-number">
                            <h4>{{ localized('police_protocol_number') }}</h4>
                            <app-input-text
                                class="protocol-number-input"
                                :placeholder="localized('police_protocol_number_placeholder')"
                                :form-field="form.field(AccidentDataPanelsNames.PoliceProtocolNumber)"
                            ></app-input-text>
                            <div class="remark">
                                <img class="img" src="images/icons/hint-icon.svg" alt="hint" />{{
                                    localized('police_protocol_number_leave_empty')
                                }}
                            </div>
                        </div>
                    </div>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        :data-type="'registered-submit'"
                        :data-scroll="1"
                        :disabled="!canProceedToNextStep"
                        @button-callback-click="proceedToNextStep()"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.calendar-block-container {
    display: flex;
    justify-content: space-between;

    @include respond-below('sm') {
        flex-direction: column;
        gap: var(--size-small);
    }
}

.input-time-offset {
    margin-top: var(--size-big);

    :deep(.input-time) {
        .feedback {
            margin-top: 4px;
            line-height: var(--size-tiny);
        }
    }
}

:deep(.split-wrapper) {
    .input:not(:last-child) {
        margin-right: 0;
    }

    .input-text {
        .label.informative {
            margin-bottom: 0;
        }
    }
}

.protocol-number {
    margin-top: var(--size-big);

    :deep(.protocol-number-input) {
        margin-bottom: var(--size-pico);
        width: 100%;
    }

    .remark {
        position: relative;
        display: flex;
        font-size: var(--font-size-nano);
        color: var(--black-500);

        .img {
            position: relative;
            margin-right: var(--size-pico);
        }
    }
}

.form {
    .guilty-vehicle-number {
        width: 100%;
    }

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .button-with-callback {
        height: 52px;
    }

    .input-date-with-calendar {
        margin-right: var(--size-small);
        flex: 1;

        @include respond-below('sm') {
            margin-right: 0;
        }
    }

    .date-buttons {
        display: flex;

        .button:not(:last-child) {
            margin-right: var(--size-small);
        }

        .calendar-date-button-big {
            display: flex;
            @include respond-below('sm') {
                display: none;
            }
        }

        .calendar-date-button-small {
            display: none;
            height: 32px;
            padding: var(--size-femto) var(--size-tiny);
            font-size: var(--font-size-nano);

            @include respond-below('sm') {
                display: block;
            }
        }

        @include respond-below('sm') {
            gap: var(--size-nano);
        }
    }
}
</style>
