import AppFilesUploader from '@/Components/Files/FilesUploader/FilesUploader.vue';
import AppFilesArchive from '@/Components/Files/FilesArchive/FilesArchive.vue';
import CompactFileUploader from '@/Components/Files/CompactFileUploader/CompactFileUploader.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppFilesUploader: AppFilesUploader,
    AppFilesArchive: AppFilesArchive,
    AppCompactFileUploader: CompactFileUploader,
} as Components;
