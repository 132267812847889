import Field from '@/Components/Lists/FieldsetList/Interfaces/Field.interface';
import Fieldset from '@/Components/Lists/FieldsetList/Interfaces/Fieldset.interface';
import FieldName from '@/Components/Lists/FieldsetList/Enums/FieldName.enum';
import FieldComponent from '@/Components/Lists/FieldsetList/Enums/FieldComponent.enum';
import FieldBuilder from '@/Components/Lists/FieldsetList/Builders/FieldBuilder';
import FieldsetBuilder from '@/Components/Lists/FieldsetList/Builders/FieldsetBuilder';
import FieldsetLayout from '@/Components/Lists/FieldsetList/Enums/FieldsetLayout.enum';
import Sanitizer from '@/services/sanitizer.service';

export default class DefaultFieldset {
    public static build(): Fieldset {
        const fieldset: FieldsetBuilder = new FieldsetBuilder();
        DefaultFieldset.fields().forEach((field: Field): void => {
            fieldset.withField(field);
        });
        fieldset.withLayout(FieldsetLayout.TwoColumns);

        return fieldset.build();
    }

    private static fields(): Field[] {
        return [
            new FieldBuilder()
                .withName(FieldName.FirstName)
                .withComponent(FieldComponent.InputText)
                .withLabel('btar_name')
                .withPlaceholder('btar_first_name_placeholder')
                .withSanitizer(Sanitizer.cleanName)
                .withValidator('required')
                .build(),
            new FieldBuilder()
                .withName(FieldName.LastName)
                .withComponent(FieldComponent.InputText)
                .withLabel('btar_surname')
                .withPlaceholder('btar_last_name_placeholder')
                .withSanitizer(Sanitizer.cleanName)
                .withValidator('required')
                .build(),
        ];
    }
}
