import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import MapLocation from '@/interfaces/map.location.interface';

export default class ClaimsMtplFormFields {
    public typeOfClaim?: DynamicDictionary;
    public whenEventHappened?: DynamicDictionary;
    public whatTimeDidItHappened?: string;
    public whatVehicleWasInvolved?: DynamicDictionary;
    public weFoundElectronically?: DynamicDictionary;
    public howWasInvolvedHandled?: DynamicDictionary;
    public policeProtocolNumber?: string;
    public guiltyPartyVehicleNumber?: string;

    public whatWasDamaged?: DynamicDictionary;
    public belongingsDamagedList?: string;
    public belongingsOwnerIsApplicant?: DynamicDictionary;
    public belongingsOwnerDetails?: string;
    public personDetails?: string;
    public propertyLocation?: MapLocation;
    public propertyOwnerIsApplicant?: DynamicDictionary;
    public propertyOwnerDetails?: string;
    public vehicleVehicleRegistrationNumber?: string;
    public vehicleProceedWithAgreedStatement?: DynamicDictionary;
    public vehicleAgreedStatementLabel?: string;
    public vehicleVehicleIsDrivable?: DynamicDictionary;
    public vehicleVehicleDriverIsApplicant?: DynamicDictionary;
    public vehicleVehicleDriverResident?: string;
    public vehicleNonResidentDriverName?: string;
    public vehicleNonResidentDriverSurname?: string;
    public vehicleNonResidentDriverBirthDate?: Date;
    public vehicleNonResidentDriverLicenseNumber?: string;
    public vehicleResidentDriverName?: string;
    public vehicleResidentDriverSurname?: string;
    public vehicleResidentDriverPersonCode?: string;
    public vehicleResidentDriverBirthDate?: Date;
    public vehicleVehicleDamagedBeforeAccident?: DynamicDictionary;
    public vehicleDamageBeforeAccident?: string;

    public whereAccidentHappened?: MapLocation;
    public accidentDescription?: string;
    public didGuiltyLeaveSceneBeforePolice?: DynamicDictionary;

    public typeOfCompensation?: DynamicDictionary;
    public otherRepairService?: string;
    public repairService?: DynamicDictionary;

    public filesUploaded?: boolean;

    public person?: string;
    public personType?: string;
    public resident?: string;
    public countryOfResident?: DynamicDictionary;
    public name?: string;
    public surname?: string;
    public personCode?: string;
    public birthDate?: string;
    public email?: string;
    public mobilePhone?: DynamicDictionary;
    public bankAccountNumber?: string;
    public companyName?: string;
    public companyType?: string;
    public registrationNumber?: string;

    public contactEmail?: string;
    public contactPhoneNumber?: DynamicDictionary;
}
