<script setup lang="ts">
import NotificationVariant from '@/Components/Widgets/Notification/Enums/NotificationVariant';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { useHtml } from '@/Composables/Html';
import { computed, Ref, ref } from 'vue';

const props = defineProps({
    title: { type: String, default: '' },
    body: { type: String, default: '' },
    variant: { type: String, default: NotificationVariant.Neutral },
    withBody: { type: Boolean, default: true },
    withClose: { type: Boolean, default: true },
    withIcon: { type: Boolean, default: true },
    withTitle: { type: Boolean, default: true },
});

const { imgTag } = useHtml();

const isVisible: Ref<boolean> = ref(true);

const renderNotification: Ref<boolean> = computed((): boolean => {
    return isVisible.value ? props.title !== '' || props.body !== '' : false;
});

function iconAttributes(icon: string): ImageTagAttributes {
    const source: string = `images/one/components/notification/${icon}.svg`;
    return {
        class: '',
        src: source,
        width: 24,
        height: 24,
    };
}

function onNotificationCloseClick(): void {
    isVisible.value = false;
}
</script>

<template>
    <div v-if="renderNotification" class="web-notification" :class="props.variant">
        <div class="notification-left">
            <div v-if="props.withIcon" class="icon" v-html="imgTag(iconAttributes(props.variant))"></div>
        </div>
        <div class="notification-body">
            <div v-if="props.withTitle" class="title" v-html="props.title"></div>
            <div v-if="props.withBody" class="content" v-html="props.body"></div>
        </div>
        <div class="notification-right">
            <div
                v-if="props.withClose"
                class="icon"
                @click="onNotificationCloseClick"
                v-html="imgTag(iconAttributes('close'))"
            ></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.web-notification {
    display: grid;
    grid-template-columns: 24px auto 1fr;
    padding: var(--size-tiny);
    gap: var(--size-nano);
    border-radius: var(--size-pico);
    width: 100%;
    justify-content: space-between;
    align-items: baseline;
    max-width: 1252px;

    @include respond-above('sm') {
        grid-template-columns: 24px 800px 1fr;
    }

    &.neutral {
        background-color: var(--black-50);
    }

    &.warning {
        background-color: var(--system-color-warning-light);
    }

    &.error {
        background-color: var(--system-color-error-light);
    }

    &.success {
        background-color: var(--system-color-success-light);
    }

    .notification-body {
        display: grid;
        grid-template-rows: auto auto;
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: var(--font-size-nano);
        line-height: var(--line-height-basic);
        color: var(--text-color-subtle);
        gap: var(--size-pico);

        .title {
            font-weight: 600;
            font-size: var(--font-size-tiny);
            line-height: var(--line-height-accented);
            color: var(--text-color-default);
        }
    }

    .notification-right {
        text-align: right;

        .icon {
            :deep(img) {
                cursor: pointer;
            }
        }
    }
}
</style>
