export default class FilesResizer {
    public static readonly Settings = new (class {
        public ImageMaxWidth: string = 'uploadImageMaxWidth';
        public ImageMaxHeight: string = 'uploadImageMaxHeight';
        public ImageMinIntensy: number = 100000;
    })();

    public static readonly MimeType = new (class {
        public Png: string = 'image/png';
        public Jpg: string = 'image/jpeg';
    })();
}
