export const useFileDownload = (): FileDownloadParams => {
    const downloadBase64File = (type: string, data: string, name: string = ''): void => {
        let prefix: string = '';
        switch (type) {
            case 'pdf':
                prefix = 'data:application/pdf;base64,';
                break;
            case 'xls':
            case 'xlsx':
                prefix = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
                break;
            default:
        }
        const aTag: HTMLAnchorElement = document.createElement('a');
        aTag.target = '_blank';
        aTag.download = name !== '' ? name + '.' + type : 'download.' + type;
        aTag.href = prefix + encodeURI(data);
        aTag.click();
    };

    return {
        downloadBase64File,
    };
};

export interface FileDownloadParams {
    downloadBase64File: (type: string, data: string, name: string) => void;
}
