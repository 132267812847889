import { Subject } from 'rxjs';

export default class SubmitterUrls {
    private static instance: SubmitterUrls;
    private nextStepUri: string = '';
    private previousStepUri: string = '';
    private currentStepUrl: string = '';
    private initialStepUri: string = '';
    public ready: boolean = false;
    public onExternalDataIsReady: Subject<void> = new Subject<void>();

    private constructor() {}

    public static getInstance(): SubmitterUrls {
        if (!SubmitterUrls.instance) {
            SubmitterUrls.instance = new SubmitterUrls();
        }
        return SubmitterUrls.instance;
    }

    public applyStepUrls(nextStepUri: string, prevStepUri: string, initialStepUri: string = ''): void {
        this.nextStepUri = nextStepUri;
        this.previousStepUri = prevStepUri;
        this.initialStepUri = initialStepUri;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public applyNextStepUrl(value: string): void {
        this.nextStepUri = value;
    }

    public applyCurrentUrl(value: string): void {
        this.currentStepUrl = value;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public nextStep(): string {
        return this.nextStepUri;
    }

    public previousStep(): string {
        return this.previousStepUri;
    }

    public initialStep(): string {
        return this.initialStepUri;
    }

    public currentStep(): string {
        return this.currentStepUrl;
    }
}
