import { App, Component, createApp } from 'vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import Directives from '@/Core/App/Interfaces/DirectivesInterface';
import SentryLibrary from '@/Libraries/SentryLibrary';
import SettingsService from '@/services/settings.service';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import Validation from '@/services/validation.service';

export default class VueApp {
    private components: Components = {};
    private directives: Directives = {};
    private settingsService: SettingsService = SettingsService.getInstance();
    private oneBase: OneBase = OneBaseService.getInstance();

    public constructor(private readonly appComponent: Component) {}

    public withComponents(components: Components): this {
        this.components = components;
        return this;
    }

    public withDirectives(directives: Directives): this {
        this.directives = directives;
        return this;
    }

    public create(): App {
        const app: App = createApp(this.appComponent);
        Object.keys(this.components).forEach((name: string): void => {
            app.component(name, this.components[name]);
        });
        Object.keys(this.directives).forEach((name: string): void => {
            app.directive(name, this.directives[name]);
        });
        if (this.settingsService.sentryIsEnabled()) {
            this.initSentry(app);
        }
        new Validation();
        return app;
    }

    private initSentry(app: App): void {
        new SentryLibrary().init(
            app,
            this.settingsService.sentryDsn(),
            this.settingsService.sentryTargets(),
            this.oneBase.correlationId.value,
        );
    }
}
