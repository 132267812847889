import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class Address {
    private static instance: Address;
    private pageLanguage: string = '';

    public static getInstance(): Address {
        if (!Address.instance) {
            Address.instance = new Address();
        }
        return Address.instance;
    }

    public addLanguage(language: string): void {
        this.pageLanguage = language;
    }

    public urlByName(name: string): string {
        let url: string = (Url.Address as DynamicDictionary)[name];
        if (name === 'country') {
            url += this.pageLanguage;
        }

        return url;
    }
}
