<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import { Router, useRouter } from 'vue-router';
import OneBaseService from '@/services/OneBaseService';
import RequestService from '@/services/request.service';
import TwoWayCommunication from '@/Apps/TwoWayCommunication/Services/TwoWayCommunicationService';
import { computed, getCurrentInstance, nextTick, onMounted, ref, Ref, watch } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import TwoWayFormFields from '@/Apps/TwoWayCommunication/Classes/TwoWayFormFields';
import TwoWayGuardsService from '@/Apps/TwoWayCommunication/Services/TwoWayGuardsService';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWaySteps from '@/Apps/TwoWayCommunication/Enums/TwoWayStepsEnum';
import { InputOption } from '@/interfaces/InputOptionInterface';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonsWithCallbackBuilder from '@/Components/Buttons/ButtonWithCallback/Builders/ButtonsWithCallbackBuilder';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import { AxiosResponse } from 'axios';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import SpaUserStorage from '@/services/SpaUserStorageService';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import Url from '@/Enums/UrlEnum';
import UserClaim from '@/interfaces/user.claim.interface';
import OneDate from '@/assets/libraries/Date/OneDate';
import { ClaimStatus } from '@/Components/Claims/ClaimBlock/Enums/ClaimStatus.enum';
import InsurancesContentTab from '@/pages/OneDashboard/Insurances/Enums/InsurancesContentTabEnum';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';

const { translateForType, translate, applyTranslationType, type } = useTranslate();
const { validResponse } = useDefine();
const router: Router = useRouter();

const requestService: RequestService = RequestService.getInstance();
const twoWayCommunicationService: TwoWayCommunication = TwoWayCommunication.getInstance();
const spaUserStorage: SpaUserStorage = SpaUserStorage.getInstance();

const Step: number = 2;
const AllClaims: string = 'all_claims';
const AgreementTypes: string[] = [
    InsurancesContentTab.Active,
    InsurancesContentTab.Terminated,
    InsurancesContentTab.Transport,
    InsurancesContentTab.Property,
    InsurancesContentTab.Health,
    InsurancesContentTab.Accident,
    InsurancesContentTab.Travel,
    InsurancesContentTab.Other,
];

const claimsTabs: Ref<InputOption[]> = ref([]);
const policiesTabs: Ref<InputOption[]> = ref([]);
const isFetchInProgress: Ref<boolean> = ref(false);
const tabsBlockIsVisible: Ref<boolean> = ref(false);

const hasSelectedClaim: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.selectedClaim !== '';
});

const hasSelectedPolicy: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.selectedPolicy !== '';
});

const isReplyOrAddNew: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.isReply || twoWayCommunicationService.storageFields.isAddNewMessage;
});

const isVisibleSupportType: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.None && !isReplyOrAddNew.value;
});

const isCaseSupportType: Ref<boolean> = computed(() => {
    return (
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingClaim ||
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingPolicy
    );
});

const caseSupportIsClaim: Ref<boolean> = computed(() => {
    return twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.ExistingClaim;
});

const tabs: Ref<InputOption[]> = computed(() => {
    return caseSupportIsClaim.value ? claimsTabs.value : policiesTabs.value;
});

const isVisibleCaseSupport: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None &&
        !isReplyOrAddNew.value
    );
});

const isVisibleSelectClaim: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingClaim &&
        !hasSelectedClaim.value &&
        !isReplyOrAddNew.value
    );
});

const isVisibleSelectPolicy: Ref<boolean> = computed(() => {
    return (
        isCaseSupportType.value &&
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingPolicy &&
        !hasSelectedPolicy.value &&
        !isReplyOrAddNew.value
    );
});

const isVisibleMessage: Ref<boolean> = computed(() => {
    return (
        twoWayCommunicationService.storageFields.supportType === TwoWaySupportType.Other ||
        (isCaseSupportType.value &&
            twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingPolicy &&
            hasSelectedPolicy.value) ||
        (isCaseSupportType.value &&
            twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.ExistingClaim &&
            hasSelectedClaim.value) ||
        isReplyOrAddNew.value ||
        twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.New
    );
});

const headerClass: Ref<string> = computed(() => {
    const result: string[] = ['full-width'];
    if (isVisibleMessage.value) {
        result.push('block-small');
    } else {
        result.push(isVisibleSelectClaim.value ? 'block-medium' : 'block-tiny');
    }

    return result.join(' ');
});

const flowTitle: Ref<string> = computed(() => {
    let result: string = '';
    if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                result = translated('communication_subject_title');
                break;
            case TwoWaySupportType.ExistingClaim:
                result = translated('communication_support_type_title');
                break;
            case TwoWaySupportType.ExistingPolicy:
                result = translated('communication_support_type_title');
                break;
            case TwoWaySupportType.Other:
                result = translated('communication_new_message_title');
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.ExistingClaim:
                if (!hasSelectedClaim.value) {
                    if (isVisibleSelectClaim.value) {
                        result = translated('communication_claim_case_support_title');
                    } else {
                        result = translated('communication_claim_new_support_title');
                    }
                } else {
                    result = translated('communication_claim_case_support_message');
                }
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (!hasSelectedPolicy.value) {
                    if (isVisibleSelectPolicy.value) {
                        result = translated('communication_policy_case_support_title');
                    } else {
                        result = translated('communication_policy_new_support_title');
                    }
                } else {
                    result = translated('communication_policy_case_support_message');
                }
                break;
            default:
        }
    }

    return result;
});

const flowDescription: Ref<string> = computed(() => {
    let result: string = '';
    if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                result = translated('communication_subject_description');
                break;
            case TwoWaySupportType.ExistingClaim:
                result = translated('communication_claim_support_type_description');
                break;
            case TwoWaySupportType.ExistingPolicy:
                result = translated('communication_policy_support_type_description');
                break;
            case TwoWaySupportType.Other:
                result = translated('communication_new_message_description');
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.ExistingClaim:
                if (!hasSelectedClaim.value) {
                    if (isVisibleSelectClaim.value) {
                        result = translated('communication_my_existing_claim_description');
                    } else {
                        result = translated('communication_my_existing_claim_new_message_description');
                    }
                } else {
                    result = translated('communication_my_existing_claim_selected_message_description');
                }
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (!hasSelectedPolicy.value) {
                    if (isVisibleSelectPolicy.value) {
                        result = translated('communication_my_existing_policy_description');
                    } else {
                        result = translated('communication_my_existing_policy_new_message_description');
                    }
                } else {
                    result = translated('communication_my_existing_policy_selected_message_description');
                }
                break;
            default:
        }
    }

    return result;
});

const filteredClaims: Ref<Claim[]> = computed(() => {
    let result: Claim[] = [];
    if (String(twoWayCommunicationService.storageFields.claimsList) !== '') {
        result = twoWayCommunicationService.storageFields.claimsList.filter((claim: Claim): boolean => {
            const selectedType: string = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
            return (
                selectedType === AllClaims ||
                claim.policyType === (spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value as string)
            );
        });
    }

    return result;
});

const filteredPolicies: Ref<Agreement[]> = computed(() => {
    let result: Agreement[] = [];
    if (String(twoWayCommunicationService.storageFields.policyList) !== '') {
        result = twoWayCommunicationService.storageFields.policyList.filter((policy: Agreement): boolean => {
            const selectedType: string = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
            return policy.tabType === (selectedType as string);
        });
    }

    return result;
});

const canProceedToNextStep: Ref<boolean> = computed(() => {
    return true;
});

const claimCaseLabel: Ref<string> = computed(() => {
    let result: string = '';
    if (twoWayCommunicationService.storageFields.selectedClaim) {
        const claim: Claim | null = twoWayCommunicationService.currentSelectedClaim();
        if (claim) {
            result = claim.title + ' ' + translated('claim_no');
        }
    }

    return result;
});

const policyCaseLabel: Ref<string> = computed(() => {
    let result: string = '*';
    if (twoWayCommunicationService.storageFields.selectedPolicy) {
        const policy: Agreement | null = twoWayCommunicationService.currentSelectedPolicy();
        if (policy) {
            result =
                translateForType('one_dashboard_agreement_tabs_' + policy.tabType, 'dashboard') +
                ' ' +
                translated('policy_no');
        }
    }

    return result;
});

const caseLabel: Ref<string> = computed(() => {
    return caseSupportIsClaim.value ? claimCaseLabel.value : policyCaseLabel.value;
});

const selectedCaseNumber: Ref<string> = computed(() => {
    return caseSupportIsClaim.value
        ? twoWayCommunicationService.storageFields.selectedClaim
        : twoWayCommunicationService.storageFields.selectedPolicy;
});

const claimCaseAttachments: Ref<Attachment[]> = computed(() => {
    //INFO: wait for BTA decision
    return [];
});

const policyCaseAttachments: Ref<Attachment[]> = computed(() => {
    //INFO: wait for BTA decision
    return [];
});

const caseAttachments: Ref<Attachment[]> = computed(() => {
    return caseSupportIsClaim.value ? claimCaseAttachments.value : policyCaseAttachments.value;
});

watch(
    () => isVisibleSupportType.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

watch(
    () => isVisibleCaseSupport.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

watch(
    () => isVisibleSelectClaim.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
            fetchClaimsList().then(() => {
                buildClaimsTabs();
            });
        }
    },
);

watch(
    () => isVisibleSelectPolicy.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
            fetchPoliciesList().then(() => {
                buildPoliciesTabs();
            });
        }
    },
);

watch(
    () => isVisibleMessage.value,
    (value: boolean, oldValue: boolean) => {
        if (value !== oldValue && value) {
            scrollToTop();
        }
    },
);

function setupForm(): void {
    spaUserStorage.setupForm();
}

function translated(stringId: string): string {
    return translateForType(stringId, type());
}

function onSupportTypeClick(supportType: string): void {
    twoWayCommunicationService.storageFields.supportType = supportType;
}

function onCaseSupportClick(caseSupportType: string): void {
    twoWayCommunicationService.storageFields.caseSupportType = caseSupportType;
}

function onBackClick(): void {
    if (isReplyOrAddNew.value) {
        router.replace({ path: twoWayCommunicationService.storageFields.communicationId });
    } else if (twoWayCommunicationService.storageFields.caseSupportType === TwoWaySupportType.None) {
        switch (twoWayCommunicationService.storageFields.supportType) {
            case TwoWaySupportType.None:
                twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                router.push({ name: TwoWaySteps.Communication });
                break;
            case TwoWaySupportType.ExistingClaim:
            case TwoWaySupportType.ExistingPolicy:
            case TwoWaySupportType.Other:
                twoWayCommunicationService.storageFields.supportType = TwoWaySupportType.None;
                scrollToTop();
                break;
            default:
        }
    } else {
        switch (twoWayCommunicationService.storageFields.caseSupportType) {
            case TwoWaySupportType.ExistingClaim:
                if (isCaseSupportType.value && hasSelectedClaim.value) {
                    twoWayCommunicationService.storageFields.selectedClaim = '';
                    spaUserStorage.form.field(TwoWayFormFields.Message).patch('');
                } else {
                    twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch('');
                }
                spaUserStorage.saveUpdatedStorage();
                scrollToTop();
                break;
            case TwoWaySupportType.ExistingPolicy:
                if (isCaseSupportType.value && hasSelectedPolicy.value) {
                    twoWayCommunicationService.storageFields.selectedPolicy = '';
                    spaUserStorage.form.field(TwoWayFormFields.Message).patch('');
                } else {
                    twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch('');
                }
                spaUserStorage.saveUpdatedStorage();
                scrollToTop();
                break;
            case TwoWaySupportType.New:
                twoWayCommunicationService.storageFields.caseSupportType = TwoWaySupportType.None;
                break;
            default:
        }
    }
}

function scrollToTop(): void {
    const scrollSpeed: number = 300;
    nextTick((): void => {
        $('html,body').animate({ scrollTop: 0 }, scrollSpeed);
    });
}

function fetchClaimsList(): Promise<void> {
    twoWayCommunicationService.storageFields.claimsList = [];
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isFetchInProgress.value = true;
    tabsBlockIsVisible.value = false;

    return requestService
        .get({
            uri: Url.Ajax.oneDashboardGetClaims,
            content: {},
            withCache: true,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                const activeClaims: Claim[] = addActiveClaims(response.data.data.body.active);
                const resolvedClaims: Claim[] = addResolvedClaims(response.data.data.body.resolved);
                twoWayCommunicationService.storageFields.claimsList = activeClaims.concat(resolvedClaims);
            }
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isFetchInProgress.value = false;
        });
}

function addActiveClaims(value: UserClaim[]): Claim[] {
    return value.map((claimData: UserClaim): Claim => {
        return {
            id: claimData.Id,
            type: '',
            claimType: '',
            claimNumber: claimData.ClaimCaseNo,
            claimId: claimData.Uuid,
            policyId: claimData.PolicyId,
            policyNumber: claimData.PolicyNumber,
            policyType: claimData.PolicyType,
            submitDate: OneDate.short(claimData.ApplicationDate),
            status: ClaimStatus.InProgress,
            title: claimData.NotificationTypeText,
            attachments: [], //INFO: wait for BTA info
        };
    });
}

function addResolvedClaims(value: UserClaim[]): Claim[] {
    return value.map((claimData: UserClaim): Claim => {
        return {
            id: claimData.Id,
            type: '',
            claimType: '',
            claimNumber: claimData.ClaimCaseNo,
            claimId: claimData.Uuid,
            policyId: claimData.PolicyId,
            policyNumber: claimData.PolicyNumber ?? '',
            policyType: claimData.PolicyType,
            submitDate: OneDate.short(claimData.ApplicationDate),
            status: ClaimStatus.Approved,
            title: claimData.NotificationTypeText,
            attachments: [], //INFO: wait for BTA info
            sumApproved: claimData.SumApproved,
            compensationDate: claimData.EventDate,
            closeDate: OneDate.short(claimData.ClaimCaseCloseDate) ?? '',
        };
    });
}

function buildClaimsTabs(): void {
    claimsTabs.value = [];
    claimsTabs.value.push(new InputOptionBuilder().setValue(AllClaims).setName(translate(AllClaims)).build());
    if (twoWayCommunicationService.storageFields.claimsList.length > 0) {
        twoWayCommunicationService.storageFields.claimsList.forEach((claim: Claim) => {
            if (
                !claimsTabs.value.find((tab: InputOption) => {
                    return tab.value === claim.policyType;
                })
            ) {
                claimsTabs.value.push(new InputOptionBuilder().setValue(claim.policyType).setName(claim.title).build());
            }
        });
    }
    if (twoWayCommunicationService.storageFields.claimsList.length > 0) {
        const formValue: LimitedVariant = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
        if (
            !claimsTabs.value.find((option: InputOption) => {
                return option.value === formValue;
            })
        ) {
            spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(AllClaims);
        }
    }

    //INFO: set only [All claims] for now and wait for BTA decision about them
    spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(AllClaims);
    tabsBlockIsVisible.value = false;
}

function fetchPoliciesList(): Promise<void> {
    twoWayCommunicationService.storageFields.policyList = [];
    PopupService.getInstance().show(new OnePopup().withType().loading);
    isFetchInProgress.value = true;
    tabsBlockIsVisible.value = false;

    return requestService
        .get({
            uri: Url.Ajax.oneDashboardInsurances,
            content: {},
            withCache: true,
        })
        .then((response: AxiosResponse<DynamicDictionary>): void => {
            if (validResponse(response)) {
                buildInsurancesTabsContent(response.data.data.body).then(() => {
                    buildPoliciesTabs();
                });
            }
        })
        .finally((): void => {
            PopupService.getInstance().hide().then();
            isFetchInProgress.value = false;
        });
}

const buildInsurancesTabsContent = (response: DynamicDictionary): Promise<void> => {
    return new Promise((resolve) => {
        AgreementTypes.forEach((agreementType: string) => {
            response[agreementType].forEach((agreement: Agreement) => {
                agreement.tabType = agreementType;
                twoWayCommunicationService.storageFields.policyList.push(agreement);
            });
        });
        resolve();
    });
};

function buildPoliciesTabs(): void {
    policiesTabs.value = [];
    AgreementTypes.forEach((agreementType: string) => {
        policiesTabs.value.push(
            new InputOptionBuilder()
                .setValue(agreementType)
                .setName(translateForType('one_dashboard_agreement_tabs_' + agreementType, 'dashboard'))
                .build(),
        );
    });
    if (twoWayCommunicationService.storageFields.policyList.length > 0) {
        const formValue: LimitedVariant = spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).value;
        if (
            !policiesTabs.value.find((option: InputOption) => {
                return option.value === formValue;
            })
        ) {
            spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs).patch(InsurancesContentTab.Active);
        }
    }
    tabsBlockIsVisible.value = true;
}

function caseBlockButtons(claimId: string): ButtonWithCallbackParams[] {
    return ButtonsWithCallbackBuilder.getInstance()
        .withTitle(caseSupportIsClaim.value ? translated('select_claim') : translated('select_policy'))
        .withBackgroundColor(ButtonBackground.White)
        .withBackgroundColorHover(ButtonBackground.Transparent)
        .withTextColor(ButtonTextColor.Black)
        .withCallback((params: DynamicDictionary): void => {
            if (caseSupportIsClaim.value) {
                twoWayCommunicationService.storageFields.selectedClaim = params.id;
            } else {
                twoWayCommunicationService.storageFields.selectedPolicy = params.id;
            }
        })
        .withCallbackParams({ id: claimId })
        .build();
}

function onDeleteFileClick(id: string): void {}

function onSendMessage(): void {}

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    applyTranslationType('communications');
    spaUserStorage.init(TwoWayFormFields, twoWayCommunicationService.storageFields);
    setupForm();
    buildClaimsTabs();
    buildPoliciesTabs();
    spaUserStorage.form.validate().then((): void => {
        TwoWayGuardsService.getInstance().applyStepValidity(Step, canProceedToNextStep.value);
    });
});

defineExpose({
    twoWayCommunicationService,
    spaUserStorage,
});
</script>

<template>
    <div class="two-way-communication full-width horizontal-spacing horizontal-spacing-desktop">
        <div class="wrapper full-width">
            <app-custom-form
                v-if="spaUserStorage.isReady()"
                :form="spaUserStorage.newForm('two-way-new')"
                class="form full-width"
                @change="spaUserStorage.storeForm()"
            >
                <div class="new-communication full-width">
                    <div class="communication-header">
                        <button data-v-ff6cde2a="" class="back back-margin" :class="headerClass" @click="onBackClick()">
                            <img data-v-ff6cde2a="" src="images/one/arrow-left.svg" alt="back" />
                            <span data-v-ff6cde2a="">{{ translated('back') }}</span>
                        </button>
                        <div class="title" :class="headerClass">{{ flowTitle }}</div>
                        <div class="sub-title" :class="headerClass">{{ flowDescription }}</div>
                    </div>
                    <div class="content">
                        <div v-if="isVisibleSupportType" class="panels-container support-type" :class="headerClass">
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.ExistingClaim)">
                                {{ translated('claim_case_support') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.ExistingPolicy)">
                                {{ translated('policy_support') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onSupportTypeClick(TwoWaySupportType.Other)">
                                {{ translated('other_questions') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                        </div>
                        <div
                            v-if="isVisibleCaseSupport"
                            class="panels-container claim-case-support"
                            :class="headerClass"
                        >
                            <div
                                v-if="caseSupportIsClaim"
                                class="panel"
                                @click="onCaseSupportClick(TwoWaySupportType.ExistingClaim)"
                            >
                                {{ translated('select_my_existing_claim') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div
                                v-if="!caseSupportIsClaim"
                                class="panel"
                                @click="onCaseSupportClick(TwoWaySupportType.ExistingPolicy)"
                            >
                                {{ translated('select_my_existing_policy') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                            <div class="panel" @click="onCaseSupportClick(TwoWaySupportType.New)">
                                {{ translated('start_new_communication') }}
                                <img width="14" height="14" src="images/one/communications/arrow-right.svg" />
                            </div>
                        </div>
                        <div
                            v-if="isVisibleSelectClaim || isVisibleSelectPolicy"
                            class="my-existing-case"
                            :class="headerClass"
                        >
                            <div class="block-medium">
                                <div v-if="tabsBlockIsVisible" class="tabs">
                                    <app-input-radio
                                        :form-field="spaUserStorage.form.field(TwoWayFormFields.inputRadioTabs)"
                                        :options="tabs"
                                        :type="'clear'"
                                    >
                                    </app-input-radio>
                                </div>
                                <template v-if="caseSupportIsClaim">
                                    <app-claim-block
                                        v-for="(claim, index) in filteredClaims"
                                        :key="index"
                                        :item="claim"
                                        :buttons="caseBlockButtons(claim.claimNumber)"
                                        :translation-type="'dashboard'"
                                    >
                                    </app-claim-block>
                                </template>
                                <template v-if="!caseSupportIsClaim">
                                    <app-policy-block
                                        v-for="(policy, index) in filteredPolicies"
                                        :key="index"
                                        :item="policy"
                                        :buttons="caseBlockButtons(policy.agreementNumber)"
                                        :translation-type="'dashboard'"
                                    >
                                    </app-policy-block>
                                </template>
                            </div>
                        </div>
                        <div v-if="isVisibleMessage" class="new-message" :class="headerClass">
                            <app-new-message
                                :form-field="spaUserStorage.form.field(TwoWayFormFields.Message)"
                                :case-number="selectedCaseNumber"
                                :case-number-label="caseLabel"
                                :translation-type="type()"
                                :attachments="caseAttachments"
                                @delete-file="onDeleteFileClick"
                                @send-message="onSendMessage"
                            ></app-new-message>
                        </div>
                    </div>
                </div>
            </app-custom-form>
        </div>
    </div>
</template>

<style scoped lang="scss">
.new-communication {
    .content {
        margin-bottom: 90px;

        .my-existing-case {
            .tabs {
                overflow-x: auto;
                min-height: 50px;
                margin-bottom: var(--size-tiny);

                :deep(.input-radio) {
                    .buttons {
                        .button {
                            white-space: nowrap;
                        }
                    }
                }
            }

            .input-radio {
                margin-bottom: var(--size-normal);

                :deep(.button-badge) {
                    .button {
                        color: var(--text-color-subtlest);
                        font-size: var(--font-size-nano);
                        font-weight: 400;
                        margin-right: var(--size-femto);

                        &.active {
                            background-color: var(--button-color-background-primary-green-default);
                            color: var(--button-color-text-primary-default);
                        }
                    }
                }
            }

            .claims-block {
                margin-bottom: var(--size-nano);
            }

            :deep(.policy-block) {
                padding: var(--size-small);
                margin-bottom: var(--size-nano);

                @include respond-above('lg') {
                    padding: 0 var(--size-normal);
                }

                .insurance-type {
                    width: 180px;
                }

                .wrapper {
                    @include respond-above('lg') {
                        padding-left: var(--size-big);
                    }

                    .insured-item {
                        .additional-info {
                            @include respond-above('lg') {
                                margin: 10px 0;
                            }
                        }
                    }
                }

                .button-container {
                    @include respond-above('lg') {
                        width: 111px;
                    }
                }
            }
        }

        .panels-container {
            .panel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                min-height: 64px;
                background-color: var(--background-base);
                border-radius: var(--size-pico);
                margin-bottom: var(--size-nano);
                padding: var(--size-small);
                cursor: pointer;
            }
        }

        :deep(.new-message) {
            .attach-files {
                .compact-file-uploader {
                    .content {
                        padding: 0;
                    }
                }
            }
        }
    }
}
</style>
