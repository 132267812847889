import Validation from '@/services/validation.service';
import Form from '@/assets/libraries/form/form';
import { Ref, ref } from 'vue';

export default class RecipientDataValidators {
    private form: Form = new Form();
    private isNonResident: Ref<boolean> = ref(false);
    private isInjuredPersonAuthenticatedPerson: Ref<boolean> = ref(false);
    private otherPersonIsBeneficiary: Ref<boolean> = ref(false);
    private currentUserIsBeneficiary: Ref<boolean> = ref(false);

    public init(
        form: Form,
        isNonResident: Ref<boolean>,
        isInjuredPersonAuthenticatedPerson: Ref<boolean>,
        otherPersonIsBeneficiary: Ref<boolean>,
        currentUserIsBeneficiary: Ref<boolean>,
    ): void {
        this.form = form;
        this.isNonResident = isNonResident;
        this.isInjuredPersonAuthenticatedPerson = isInjuredPersonAuthenticatedPerson;
        this.otherPersonIsBeneficiary = otherPersonIsBeneficiary;
        this.currentUserIsBeneficiary = currentUserIsBeneficiary;
    }

    public birthDateValidator(): object {
        return {
            validBirthDate: (): boolean => {
                return this.isNonResident.value && this.form.field('beneficiaryBirthDate').isTouched
                    ? this.form.field('beneficiaryBirthDate').value !== ''
                    : true;
            },
        };
    }

    public emailValidator(): object {
        return {
            validEmail: (): boolean => {
                return this.form.field('beneficiaryEmail').isTouched
                    ? this.form.field('beneficiaryEmail').value !== ''
                    : true;
            },
        };
    }

    public firstNameValidator(): object {
        return {
            validFirstName: (value: string): boolean => {
                return this.form.field('beneficiaryFirstName').isTouched ? Validation.isValidCaption(value) : true;
            },
        };
    }

    public lastNameValidator(): object {
        return {
            validLastName: (value: string): boolean => {
                return this.form.field('beneficiaryLastName').isTouched ? Validation.isValidCaption(value) : true;
            },
        };
    }

    public identityNumberValidator(): object {
        return {
            validIdentityNumber: (): boolean => {
                return this.form.field('beneficiaryIdentityNumber').isTouched ? this.isValidIdentityNumber() : true;
            },
        };
    }

    public phoneValidator(): object {
        return {
            validPhone: (): boolean => {
                return this.form.field('beneficiaryPhone').isTouched
                    ? this.form.field('beneficiaryPhone').value !== ''
                    : true;
            },
        };
    }

    public bankAccountValidator(): object {
        return {
            validBankAccount: (): boolean => {
                return this.form.field('beneficiaryAccountNumber').isTouched
                    ? this.form.field('beneficiaryAccountNumber').value !== ''
                    : true;
            },
        };
    }

    public possibleCompensationValidator(): object {
        return {
            isSelected: (): boolean => {
                return this.form.field('possibleCompensation').isTouched
                    ? this.form.field('possibleCompensation').value.selected !== ''
                    : true;
            },
        };
    }

    public countryValidator(): object {
        return {
            validCountry: (): boolean => {
                return this.form.field('beneficiaryCountry').isTouched
                    ? this.form.field('beneficiaryCountry').value !== ''
                    : true;
            },
        };
    }

    private isValidIdentityNumber(): boolean {
        const useCountry: boolean =
            (this.isNonResident.value && this.otherPersonIsBeneficiary.value) ||
            (!this.isInjuredPersonAuthenticatedPerson.value && this.currentUserIsBeneficiary.value);
        const country: string = this.form.field('beneficiaryCountry').value.iso ?? '';
        return useCountry
            ? Validation.isValidNaturalPersonCode(this.form.field('beneficiaryIdentityNumber').value, country)
            : Validation.isValidNaturalPersonCode(this.form.field('beneficiaryIdentityNumber').value);
    }
}
