import AppPolicyDetails from '@/Components/Policies/PolicyDetails/PolicyDetails.vue';
import AppPolicyDetailsOverview from '@/Components/Policies/PolicyDetailsOverview/PolicyDetailsOverview.vue';
import AppPolicyDetailsInvoices from '@/Components/Policies/PolicyDetailsInvoices/PolicyDetailsInvoices.vue';
import AppPolicyDetailsObjects from '@/Components/Policies/PolicyDetailsObjects/PolicyDetailsObjects.vue';
import AppPolicyDetailsPaymentMethod from '@/Components/Policies/PolicyDetailsPaymentMethod/PolicyDetailsPaymentMethod.vue';
import AppPolicyDetailsPersons from '@/Components/Policies/PolicyDetailsPersons/PolicyDetailsPersons.vue';
import AppPolicyLimits from '@/Components/Policies/PolicyLimits/PolicyLimits.vue';
import AppPolicyBlock from '@/Components/Policies/PolicyBlock/PolicyBlock.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppPolicyDetails: AppPolicyDetails,
    AppPolicyDetailsOverview: AppPolicyDetailsOverview,
    AppPolicyDetailsInvoices: AppPolicyDetailsInvoices,
    AppPolicyDetailsObjects: AppPolicyDetailsObjects,
    AppPolicyDetailsPaymentMethod: AppPolicyDetailsPaymentMethod,
    AppPolicyDetailsPersons: AppPolicyDetailsPersons,
    AppPolicyLimits: AppPolicyLimits,
    AppPolicyBlock: AppPolicyBlock,
} as Components;
