import Counters from '@/interfaces/counters.interface';
import Counter from '@/interfaces/counter.interface';
import Tooltipster from '@/interfaces/tooltipster.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import MultiCountersValue from '@/interfaces/multi.counters.value.interface';
import CounterValue from '@/interfaces/counter.value.interface';

export default class AgeGroups {
    private static instance: AgeGroups;
    private ageGroups: DynamicDictionary = {};
    private v2PoliciesEnabled: boolean = false;
    private optionsSubtitle: string = '';

    private constructor() {}

    public static getInstance(): AgeGroups {
        if (!AgeGroups.instance) {
            AgeGroups.instance = new AgeGroups();
        }
        return AgeGroups.instance;
    }

    public withGroups(groups: DynamicDictionary): AgeGroups {
        this.ageGroups = groups;

        return this;
    }

    public withOptionalSubtitle(optionsSubtitle: string): AgeGroups {
        this.optionsSubtitle = optionsSubtitle;

        return this;
    }

    public withType(v2PoliciesEnabled: boolean): AgeGroups {
        this.v2PoliciesEnabled = v2PoliciesEnabled;

        return this;
    }

    public ageGroupIs(name: string, group: string): boolean {
        let result: boolean = false;
        this.ageGroups.groups.forEach((value: DynamicDictionary) => {
            if (value.name === name && value.icd === parseInt(group, 10)) {
                result = true;
            }
        });

        return result;
    }

    public ageGroupV2(currentValue: MultiCountersValue): string {
        const result: string[] = [];
        currentValue.counters[0].counters.forEach((counterValue: CounterValue) => {
            if (counterValue.value > 0) {
                this.ageGroups.groupsV2.forEach((groupValue: Counter) => {
                    if (counterValue.name === groupValue.name) {
                        result.push(groupValue.ic);
                    }
                });
            }
        });

        return result.join(',');
    }

    public build(): Counters[] {
        const ageGroupsIndex: number = 0;
        const evakIndex: number = 1;
        const ageGroups: Counters[] = [];
        const groupsTitle: string = this.ageGroups.title;
        const evakTitle: string = this.ageGroups.evakTitle;
        const evakOpenerTitle: string =
            this.ageGroups.evakOpenerTitle !== undefined ? this.ageGroups.evakOpenerTitle : this.ageGroups.evakTitle;
        const enablerTooltipster: Tooltipster = this.ageGroups.tooltipster;
        const subtitle: string = this.optionsSubtitle;
        ageGroups[ageGroupsIndex] = new (class implements Counters {
            public counters: Counter[] = [];
            public enabled: boolean = true;
            public available: boolean = false;
            public title: string = groupsTitle;
            public subtitle: string = subtitle;
            public tooltipster: Tooltipster = enablerTooltipster;
            public openerTitle: string = evakOpenerTitle;
        })();
        ageGroups[evakIndex] = new (class implements Counters {
            public counters: Counter[] = [];
            public available: boolean = false;
            public enabled: boolean = true;
            public title: string = evakTitle;
            public openerTitle: string = evakOpenerTitle;
        })();
        const groups: DynamicDictionary = this.v2PoliciesEnabled ? this.ageGroups.groupsV2 : this.ageGroups.groups;
        groups.forEach((value: DynamicDictionary) => {
            value.name = 'age' + value.minAge + 'to' + value.maxAge;
            const countersLimit: number = 2;
            for (let i: number = 0; i < countersLimit; i++) {
                ageGroups[i].counters.push(
                    new (class implements Counter {
                        public ic: string = value.ic;
                        public icd: string = typeof value.icd !== 'undefined' ? value.icd : value.ic;
                        public label: string = value.label;
                        public maxAge: number = value.maxAge;
                        public maxCount: number = value.maxCount;
                        public minAge: number = value.minAge;
                        public minCount: number = value.minCount;
                        public value: number = i === 0 && value.isDefault ? 1 : value.minCount;
                        public name: string = value.name + (i > 0 ? 'evak' : '');
                        public isDefault: boolean = value.isDefault;
                        public isRestricted: boolean = value.isRestricted ?? false;
                        public maxCountMessage: string = '';
                        public restrictedMessage: string = '';
                    })(),
                );
            }
        });

        return ageGroups;
    }
}
