<script setup lang="ts">
import PropertyObject from '@/interfaces/one_policy/property.object.interface';
import { computed, Ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

const props = defineProps({
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

const { translate } = useTranslate();

function objectClasses(object: PropertyObject): string {
    const result: string[] = [];
    if (object.propertyType === '') {
        result.push('no-type');
    }
    if (object.risks && object.risks.length === 0) {
        result.push('no-risks');
    }

    return result.join(' ');
}

const containerTitle: Ref<string> = computed(() => {
    let count: number = 0;
    if (props.content && props.content.objects) {
        count = props.content.objects.length;
    }
    const replacements: DynamicDictionary = {
        '%count%': count,
    };

    return translate('you_have_x_insured_objects', replacements);
});

const containerClasses: Ref<string> = computed(() => {
    const result: string[] = [];
    if (props.content && props.content.objects && props.content.objects.length > 0) {
        if (props.content.objects[props.content.objects.length - 1].risks.length === 0) {
            result.push('no-last-risks');
        }
    }

    return result.join(' ');
});

const titleClasses: Ref<string> = computed(() => {
    const result: string[] = ['title'];
    if (
        props.content &&
        props.content.objects &&
        props.content.objects.length > 0 &&
        props.content.objects[0].propertyType === ''
    ) {
        result.push('no-type');
    }

    return result.join(' ');
});
</script>

<template>
    <div class="policy-details-objects">
        <div class="policy-details-objects-container" :class="containerClasses">
            <h4 :class="titleClasses">{{ containerTitle }}</h4>
            <template v-if="content">
                <div
                    v-for="(object, index) in content.objects"
                    :key="index"
                    class="policy-details-object"
                    :class="objectClasses(object)"
                >
                    <div v-if="object.propertyType !== ''" class="property-type">{{ object.propertyType }}</div>
                    <div class="property-info">
                        <img
                            class="icon"
                            width="24"
                            height="24"
                            src="images/one/components/drawers/shield.svg"
                            alt="shield icon"
                        />
                        <div class="name">{{ object.objectName }}</div>
                    </div>
                    <div v-if="object.risks.length > 0" class="property-risks">
                        <div v-for="risk in object.risks" :key="risk.id" class="risk">
                            <div>{{ risk.name }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-objects {
    .policy-details-objects-container {
        padding: var(--size-big) var(--size-big) 23px;
        background-color: var(--component-color-background-base);
        border-radius: 16px;

        &.no-last-risks {
            padding-bottom: var(--size-nano);
        }

        .title {
            margin-bottom: var(--size-big);

            &.no-type {
                margin-bottom: 22px;
            }
        }

        .policy-details-object {
            margin-top: var(--size-normal);
            padding-bottom: var(--size-normal);
            border-bottom: 1px solid var(--component-color-border-default);

            &.no-type {
                margin-top: 8px;
            }

            &.no-risks {
                padding-bottom: 6px;
            }

            &:last-child {
                padding-bottom: 14px;
                border-bottom: none;
            }

            .property-type {
                font-size: var(--font-size-nano);
                color: var(--black-400);
            }

            .property-info {
                display: flex;
                align-items: center;
                padding-top: var(--size-tiny);
                padding-bottom: 18px;

                .icon {
                    margin-right: var(--size-nano);
                }

                .name {
                    font-weight: 600;
                }
            }

            .property-risks {
                display: flex;

                .risk {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: var(--font-size-pico);
                    font-weight: 600;
                    color: var(--brand-teal);
                    height: 32px;
                    background: var(--teal-100);
                    border-radius: 8px;
                    padding-left: 14px;
                    padding-right: 14px;
                    margin-right: var(--size-pico);

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>
