import UrlBuilder from '@/assets/libraries/url/url-builder';
import Translations from '@/services/translations.service';

export const useNavigate = (): NavigateParams => {
    const navigate = (uri: string): void => {
        window.location.href = new UrlBuilder().withLanguage(Translations.getInstance().language).withUri(uri).build();
    };

    const redirect = (url: string): void => {
        window.location.href = url;
    };

    const replace = (url: string): void => {
        window.location.replace(url);
    };

    return {
        navigate,
        redirect,
        replace,
    };
};

export interface NavigateParams {
    navigate: (uri: string) => void;
    redirect: (url: string) => void;
    replace: (url: string) => void;
}
