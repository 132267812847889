<script lang="ts">
import { defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const places: DynamicDictionary = TransferStateService.getInstance().get('places');
        const selectedCity: Ref<string> = ref('');

        function onSelectCity(city: string): void {
            selectedCity.value = city;
        }

        return {
            ...btaBase,
            ...{ places, selectedCity, onSelectCity },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
    },
});
</script>
