<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import GetInTouchModule from '@/Modules/GetInTouch/GetInTouch.vue';
import DataLayerFacilities from '@/Enums/DataLayerFacilitiesEnum';
import VueEvent from '@/Classes/VueEventClass';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const getInTouchModule = new VueModule(GetInTouchModule).mount();

        const CurrentStep: number = 1;
        const Facility: string = DataLayerFacilities.Contacts;

        function requestCallbackProxy(event: VueEvent): void {
            getInTouchModule.contactsRequestCallback(event);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                getInTouchModule,
                requestCallbackProxy,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.getInTouchModule.create();
        this.getInTouchModule.beforeModuleMounted();
        this.getInTouchModule.moduleMounted();
    },
});
</script>
