import PlaceholderOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/PlaceholderOption';

export default class PlaceholderOptionBuilder {
    private option: PlaceholderOption = new (class implements PlaceholderOption {
        public text: string = '';
        public buttonText: string = '';
        public component: string = 'AppPlaceholderOption';
    })();

    public withText(value: string): PlaceholderOptionBuilder {
        this.option.text = value;

        return this;
    }

    public withButtonText(value: string): PlaceholderOptionBuilder {
        this.option.buttonText = value;

        return this;
    }

    public build(): PlaceholderOption {
        return this.option;
    }
}
