import AppOptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import AppOptionsSmartList from '@/Components/Options/OptionsSmartList/OptionsSmartList.vue';
import OptionsFieldList from '@/Components/Options/OptionsFieldList/OptionsFieldList.vue';
import TravelOptionRentalCarSecurity from '@/Components/Lists/AdditionalOptionsList/Components/TravelOptionRentalCarSecurity.vue';
import MovablePropertyOption from '@/Components/Lists/AdditionalOptionsList/Components/MovablePropertyOption.vue';
import MtplOfferOption from '@/Components/Lists/AdditionalOptionsList/Components/MtplOfferOption.vue';
import MovablePropertyDeviceOption from '@/Components/Options/OptionsFieldList/Components/MovablePropertyDeviceOption.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppOptionsList: AppOptionsList,
    AppOptionsSmartList: AppOptionsSmartList,
    AppOptionsFieldList: OptionsFieldList,
    AppTravelOptionRentalCarSecurity: TravelOptionRentalCarSecurity,
    AppMovablePropertyOption: MovablePropertyOption,
    AppMtplOfferOption: MtplOfferOption,
    AppMovablePropertyDeviceOption: MovablePropertyDeviceOption,
} as Components;
