import { reactive } from 'vue';
import SpaFormStorageService from '@/services/SpaFormStorageService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { Program } from '@/Apps/SolarPanels/Interfaces/ProductProgram';
import { useStore } from '@/Composables/Store/Store';
import { StoreDefinition } from 'pinia';
import { SolarPanelsStore } from '@/Composables/Store/Types/SolarPanels';

interface FormFields {
    selectedProgram: Program | undefined;
    address: DynamicDictionary | undefined;
    marketingConsents: DynamicDictionary;
    agreeTerms: boolean;
    holderEmail: string;
}

export default class Storage {
    public fields!: FormFields;
    public store: StoreDefinition<string, SolarPanelsStore> = useStore().solarPanels();
    private static instance: Storage;
    private spaFormStorageService: SpaFormStorageService = SpaFormStorageService.getInstance();

    public static getInstance(): Storage {
        if (!Storage.instance) {
            Storage.instance = new Storage();
        }
        Storage.instance.init();

        return Storage.instance;
    }

    public init(): void {
        if (!this.fields) {
            this.fields = reactive(this.spaFormStorageService.formStorageFields());
        }
        this.spaFormStorageService.applyDataProviderCallback((): FormFields => this.fields);
    }

    public updateRoute(): void {
        this.store().updateRoute();
    }

    public destroyRoute(): void {
        this.store().destroyRoute();
    }
}
