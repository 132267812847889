<script setup lang="ts">
import { onMounted, PropType, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppOptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import SettingsService from '@/services/settings.service';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { InputOption } from '@/interfaces/InputOptionInterface';

const props = defineProps({
    formField: { type: Object as PropType<FormField<DynamicDictionary>>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
});
const settingsInstance: SettingsService = SettingsService.getInstance();
const options: Ref<InputOption[]> = ref([]);

onMounted((): void => {
    init();
});

function init(): void {
    resetLocalOptions();
    buildMarketingConsentsOptions();
}

function buildMarketingConsentsOptions(): void {
    settingsInstance.marketingConsentTypes().forEach((consentType: string): void => {
        options.value.push(localOption(consentType));
    });
}

function localOption(value: string): InputOption {
    return new InputOptionBuilder().setName(useTranslate().translate(value.toLowerCase())).setValue(value).build();
}

function resetLocalOptions(): void {
    options.value = [];
}
</script>

<template>
    <div :id="'consents-list'" class="consents-list">
        <span class="consents-title">{{ useTranslate().translate('consent_details_title') }}</span>
        <app-options-list :form-field="props.formField" :options="options"></app-options-list>
    </div>
</template>

<style lang="scss" scoped>
.consents-list {
    .consents-title {
        font-size: var(--font-size-nano);
    }

    .options-list {
        margin: var(--size-nano) 0;
        display: flex;
        flex-direction: column;

        @include respond-above('sm') {
            flex-direction: row;
        }

        :deep(.options-list-option) {
            width: auto;
            margin-bottom: 0;
            min-height: initial;
            background-color: transparent;
            border-color: transparent !important;

            .options-list-panel {
                width: fit-content;
                font-size: var(--font-size-nano);
                font-weight: initial;
                padding: 6px 22px 6px 45px;

                .checkbox-icon {
                    left: 5px;
                }

                &::before {
                    margin-left: 0;
                }
            }

            &::before {
                border-color: transparent !important;
            }
        }
    }
}
</style>
