import FileResizeOptions from '@/interfaces/file.resize.interface';
import SettingsService from '@/services/settings.service';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import FilesResizer from '@/Enums/FileResizeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import FilesUploader from '@/Enums/FileUploadEnum';
import UploadFile from '@/interfaces/file.upload.interface';
import CssClass from '@/Enums/CssClassEnum';

export default class FileResizer {
    private static instance: FileResizer;
    private options: FileResizeOptions = new (class implements FileResizeOptions {
        public imageMaxWidth: number = 0;
        public imageMaxHeight: number = 0;
    })();

    public static getInstance(): FileResizer {
        if (!FileResizer.instance) {
            FileResizer.instance = new FileResizer();
        }

        return FileResizer.instance;
    }

    public constructor() {
        this.options.imageMaxWidth = Number(this.settingsService.value(FilesResizer.Settings.ImageMaxWidth));
        this.options.imageMaxHeight = Number(this.settingsService.value(FilesResizer.Settings.ImageMaxHeight));
    }

    public resizedFile(file: File): Promise<UploadFile> {
        const uploadFile: UploadFile = {
            id: '',
            categoryId: 0,
            name: this.transformedFileName(file),
            type: this.fileType(file),
            mime: '',
            sizeBytes: 0,
            class: CssClass.FileUploadProgress,
            fileBase64: '',
            fileThumbnail: {
                id: '',
                width: '',
                height: '',
                fileBase64: '',
            },
        };

        return new Promise((resolve, reject) => {
            const reader: FileReader = new FileReader();
            reader.onload = () => {
                const fileBase64: string = reader.result!.toString();
                const mimeType: string = fileBase64.split(',')[0].split(':')[1].split(';')[0];
                uploadFile.mime = mimeType;
                if (!String(this.settingsService.value(FilesUploader.Settings.ResizableMimeTypes)).includes(mimeType)) {
                    uploadFile.fileBase64 = fileBase64;
                    uploadFile.sizeBytes = file.size;
                    resolve(uploadFile);
                }
                const image: HTMLImageElement = new Image();
                image.src = fileBase64;
                image.onload = () => {
                    uploadFile.fileBase64 = this.resizedImage(image, mimeType, file.size);
                    const base64: string = uploadFile.fileBase64.substring(uploadFile.fileBase64.indexOf(',') + 1);
                    uploadFile.sizeBytes = atob(base64).length;
                    resolve(uploadFile);
                };
            };
            reader.onerror = (reason) => {
                reject(reader.result!.toString());
                Error.log(ErrorType.Error, FileResizer.name + '::' + this.resizedFile.name, reason as LimitedVariant);
            };
            reader.readAsDataURL(file);
        });
    }

    public resizedImage(image: HTMLImageElement, fileMimeType: string, fileSize: number): string {
        let fileBase64: string = image.src;
        const aspectWidth: number = image.width / this.options.imageMaxWidth;
        const aspectHeight: number = image.height / this.options.imageMaxHeight;
        const intensy: number = fileSize / (aspectWidth * aspectHeight);
        if ((aspectWidth > 1 || aspectHeight > 1) && intensy > FilesResizer.Settings.ImageMinIntensy) {
            const mimeType: string =
                fileMimeType !== FilesResizer.MimeType.Png ? FilesResizer.MimeType.Jpg : FilesResizer.MimeType.Png;
            try {
                const canvas: HTMLCanvasElement = document.createElement('canvas');
                if (aspectWidth > aspectHeight) {
                    canvas.width = this.options.imageMaxWidth;
                    canvas.height = image.height / aspectWidth;
                } else {
                    canvas.height = this.options.imageMaxHeight;
                    canvas.width = image.width / aspectHeight;
                }
                canvas.getContext('2d')!.drawImage(image, 0, 0, canvas.width, canvas.height);
                fileBase64 = canvas.toDataURL(mimeType, 1.0);
            } catch (reason) {
                Error.log(ErrorType.Error, FileResizer.name + '::' + this.resizedImage.name, reason as LimitedVariant);
            }
        }

        return fileBase64;
    }

    private transformedFileName(file: File): string {
        return file.name !== 'image.jpg' ? file.name : 'image' + file.lastModified + '.jpg';
    }

    private fileType(file: File) {
        return file.name.split('.').pop()!.toLocaleUpperCase();
    }

    private get settingsService(): SettingsService {
        return SettingsService.getInstance();
    }
}
