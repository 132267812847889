import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class EventBus {
    private static instance: EventBus;
    private events: Record<string, Function> = {};

    private constructor() {}

    public static getInstance(): EventBus {
        if (!EventBus.instance) {
            EventBus.instance = new EventBus();
        }

        return EventBus.instance;
    }

    public subscribe(eventName: string, callback: Function): void {
        this.events[eventName] = callback;
    }

    public emit(eventName: string, params: DynamicDictionary): void {
        if (typeof this.events[eventName] !== 'undefined') {
            this.events[eventName](params);
        }
    }

    public unsubscribe(eventName: string): void {
        if (typeof this.events[eventName] !== 'undefined') {
            delete this.events[eventName];
        }
    }
}
