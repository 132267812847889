<script lang="ts">
import OneBaseService from '@/services/OneBaseService';
import { defineComponent, ref, Ref } from 'vue';
import Form from '@/assets/libraries/form/form';
import { Subscription } from 'rxjs';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useNavigate } from '@/Composables/Navigate';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FormField from '@/assets/libraries/form/form-field';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 2;
        const Facility: string = 'accident-single-day';
        const FormFields: string[] = ['insured-persons'];

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        function onAppReady(): void {
            btaBase.dynamicStepper.enableAll();
        }

        function onSubmitStep(): void {
            form.validate().then((): void => {
                if (form.isValid()) {
                    prepareSubmit();
                    stepsSubmitter.proceedStep('', 0);
                }
            });
        }

        function onBackClick(): void {
            useNavigate().navigate(stepsSubmitter.previousStep());
        }

        function onConfirmSubjects(subjects: DynamicDictionary[]): void {
            stepsSubmitter.addSubmitParam('subjects', subjects, true);
            onSubmitStep();
        }

        function setupForm(): void {
            FormFields.forEach((field: string): void => {
                form.addField(new FormField(field));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', stepsSubmitter.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                onAppReady,
                setupForm,
                onBackClick,
                onConfirmSubjects,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
