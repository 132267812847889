import AppExpandableItem from '@/Components/Expandables/ExpandableItem/ExpandableItem.vue';
import AppExpandableAccordion from '@/Components/Expandables/ExpandableAccordion/ExpandableAccordion.vue';
import Expandable from '@/Components/Expandables/Expandable/Expandable.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppExpandableItem: AppExpandableItem,
    AppExpandableAccordion: AppExpandableAccordion,
    AppExpandable: Expandable,
} as Components;
