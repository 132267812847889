import AppMapWithPlaces from '@/Components/Maps/MapWithPlaces/MapWithPlaces.vue';
import AppMapWithAddress from '@/Components/Maps/MapWithAddress/MapWithAddress.vue';
import AppMapControls from '@/Components/Maps/MapControls/MapControls.vue';
import AppMapPlaces from '@/Components/Maps/MapPlaces/MapPlaces.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppMapWithPlaces: AppMapWithPlaces,
    AppMapWithAddress: AppMapWithAddress,
    AppMapControls: AppMapControls,
    AppMapPlaces: AppMapPlaces,
} as Components;
