import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';
import { Ref, ref } from 'vue';
import ClaimDetailsContent from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContent';
import ClaimDetailsContentTabs from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContentTabs';
import ClaimDetailsContentTab from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContentTab';

export default class ClaimDetails {
    private static instance: ClaimDetails;
    private content: ClaimDetailsContent = new (class implements ClaimDetailsContent {
        public type: AgreementTypes = '';
        public title: string = '';
        public description: string = '';
        public claim: DynamicDictionary = {};
        public tabs: ClaimDetailsContentTabs = new (class implements ClaimDetailsContentTabs {
            public active: Ref<number> = ref(0);
            public elements: ClaimDetailsContentTab[] = [];
        })();
    })();

    public static getInstance(): ClaimDetails {
        if (!ClaimDetails.instance) {
            ClaimDetails.instance = new ClaimDetails();
        }

        return ClaimDetails.instance;
    }

    public addContent(content: ClaimDetailsContent): ClaimDetails {
        this.content = content;

        return this;
    }

    public activateTab(tabId: string | number): void {
        this.content.tabs.active.value = this.tabIndex(tabId);
    }

    public applyTabId(tabId: string | number, id: string): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].id = id;
        }
    }

    public applyTabTitle(tabId: string | number, title: string): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].title = title;
        }
    }

    public applyTabBadge(tabId: string | number, badgeValue: number): void {
        if (this.tabIsPresent(tabId)) {
            this.content.tabs.elements[this.tabIndex(tabId)].badge = badgeValue;
        }
    }

    public tabContent(tabId: string | number): DynamicDictionary {
        let result: DynamicDictionary = {};
        if (this.tabIsPresent(tabId)) {
            result = this.content.tabs.elements[this.tabIndex(tabId)].content;
        }

        return result;
    }

    public isValueCurrentActiveTab(value: string | number): boolean {
        return this.tabIndex(value) === this.content.tabs.active.value;
    }

    public get activeTab(): string {
        return this.content.tabs.elements[this.content.tabs.active.value].id;
    }

    public get policyType(): AgreementTypes {
        return this.content.type;
    }

    public set policyType(type: AgreementTypes) {
        this.content.type = type;
    }

    public get policyTitle(): string {
        return this.content.title;
    }

    public set policyTitle(title: string) {
        this.content.title = title;
    }

    public get descriptionTitle(): string {
        return this.content.description;
    }

    public set descriptionTitle(title: string) {
        this.content.description = title;
    }

    public get hasTabs(): boolean {
        return this.content.tabs.elements.length > 0;
    }

    private tabIndex(tabId: string | number): number {
        let tabIndex: number = -1;
        const searchTab: string = String(tabId);
        this.content.tabs.elements.forEach((tab: ClaimDetailsContentTab, index: number): void => {
            if (tab.id === searchTab) {
                tabIndex = index;
            }
        });

        return tabIndex;
    }

    private tabIsPresent(tabId: string | number): boolean {
        return this.content.tabs.elements.length > 0 && this.tabIndex(tabId) >= 0;
    }
}
