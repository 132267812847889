<script setup lang="ts">
import { PropType } from 'vue';
import InsuredRisk from '@/interfaces/one_policy/insured.risk.interface';
import { useTranslate } from '@/Composables/Translate';
import { useFormatter } from '@/Composables/Formatter';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import PolicyDetails from '@/Components/Policies/PolicyDetails/Services/PolicyDetailsService';
import AgreementTypeMap from '@/Components/Policies/PolicyBlock/Classes/AgreementTypeMap';
import SettingsService from '@/services/settings.service';
import AppCountry from '@/assets/libraries/app/app-country';

const { translate } = useTranslate();
const { sparsePrice } = useFormatter();

const props = defineProps({
    hideFraction: { type: Boolean, default: false },
    content: {
        type: Array as PropType<InsuredRisk[]>,
        default: () => {
            return [];
        },
    },
});

function isValidRisk(risk: InsuredRisk): boolean {
    return typeof risk.sum !== 'undefined';
}

function currency(risk: InsuredRisk): string {
    return translate('btar_currency') + '&nbsp;' + sparsePrice(risk.sum!, props.hideFraction);
}

function isSumVisible(risk: InsuredRisk): boolean {
    const riskHasSum: boolean = typeof risk.sum !== 'undefined' && risk.sum > 0;
    const riskIcRestricted: boolean = restrictedByIc(risk);

    return riskHasSum && !riskIcRestricted;
}

function restrictedByIc(risk: InsuredRisk): boolean {
    let result: boolean = false;
    const currentAgreement: Agreement = PolicyDetails.getInstance().currentAgreement;
    const restrictedRiskIcs: string[] = SettingsService.getInstance().insuredRisksExcludeSumIcs();
    const mapping: AgreementTypeMap = new AgreementTypeMap();
    if (new AppCountry().isEE() && mapping.isTravelAgreement(currentAgreement.typeId)) {
        result = restrictedRiskIcs.includes(risk.id);
    }

    return result;
}
</script>

<template>
    <div class="insured-risks">
        <template v-for="(risk, index) in content" :key="index">
            <div v-if="isValidRisk(risk)" class="person-risk" :data-id="risk.id">
                <div class="risks-left">
                    <img
                        class="icon"
                        width="24"
                        height="24"
                        src="images/one/components/drawers/shield.svg"
                        alt="shield icon"
                    />
                    <div class="risk-name">{{ risk.name }}</div>
                </div>
                <div v-if="isSumVisible(risk)" class="risk-sum" v-html="currency(risk)"></div>
                <div v-else class="icon">
                    <img class="icon" width="14" height="14" src="images/one/checkmark.svg" alt="checkmark icon" />
                </div>
            </div>
        </template>
    </div>
</template>

<style lang="scss" scoped>
.insured-risks {
    .person-risk {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--size-tiny) 0;
        font-weight: 600;
        font-size: var(--font-size-nano);

        .risks-left {
            display: flex;
            align-items: center;
            width: 70%;

            @include respond-above('sm') {
                width: initial;
            }

            .icon {
                margin-right: var(--size-nano);
            }
        }

        .risk-sum {
            font-weight: 500;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            height: 1px;
            width: calc(100% - 36px);
            background-color: var(--black-200);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}
</style>
