import AppIconWithLabel from '@/Components/Tooltips/IconWithLabel/IconWithLabel.vue';
import AppInformationalTextBlock from '@/Components/Tooltips/InformationalTextBlock/InformationalTextBlock.vue';
import AppStatusIcon from '@/Components/Tooltips/StatusIcon/StatusIcon.vue';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import TextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/ProcessInnerToaster.vue';

export default {
    AppIconWithLabel: AppIconWithLabel,
    AppInformationalTextBlock: AppInformationalTextBlock,
    AppStatusIcon: AppStatusIcon,
    AppTooltipster: AppTooltipster,
    AppTextWithTip: TextWithTip,
    AppProcessInnerToaster: ProcessInnerToaster,
} as Components;
