import { markRaw } from 'vue';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Translations from '@/services/translations.service';
import moment from 'moment';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Url from '@/Enums/UrlEnum';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupIcon from '@/Enums/PopupIconEnum';
import BottomNotification from '@/services/bottom.notification.service';
import BottomNotificationLevel from '@/Enums/BottomNotificationLevelEnum';
import Confirm from '@/assets/libraries/popups/types/confirm';
import PopupInput from '@/Enums/PopupInputEnum';
import Sanitizer from '@/services/sanitizer.service';
import { useCurrency } from '@/Composables/Currency';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import PopupService from '@/services/custom.popup.service';
import RequestService from '@/services/request.service';
import { AxiosResponse } from 'axios';

export default class EditPersonTab extends ModalTab {
    public form: Form = markRaw(new Form());
    public customClass: string = 'narrowed-centered';
    private static instance: EditPersonTab;
    private person!: LegalInsuredPerson;

    public static getInstance(): EditPersonTab {
        if (!EditPersonTab.instance) {
            EditPersonTab.instance = new EditPersonTab();
        }

        return EditPersonTab.instance;
    }

    public init(): void {
        this.setupForm().then((): void => {
            this.person = this.modal.personForEdit;
            this.patchEditPersonFields();
            this.form.setReady();
        });
    }

    public destroy(): void {
        this.form.destroy();
    }

    public onSaveChangesClick(): void {
        this.form.touch().then((): void => {
            this.form.validate().then((): void => {
                if (this.form.isValid()) {
                    this.showConfirmChangesPopup();
                }
            });
        });
    }

    public get maxPolicyEndDate(): Date {
        return this.modal.app.currentAgreementEndDate().subtract(1, 'day').toDate();
    }

    public showConfirmChangesPopup(): void {
        const saveEditPopup: Confirm = new OnePopup()
            .withType()
            .confirmPopup.withTitle(this.translated('confirm_changes_title'))
            .withDescription(this.translated('confirm_changes_description'))
            .withCancelButtonText(this.translated('cancel'))
            .withConfirmButtonText(this.translated('confirm_and_submit'))
            .withIcon(PopupIcon.AlertOctagon)
            .withConfirmCallback('onEditPersonConfirm', this);
        if (this.insuranceProgramHasChanged()) {
            saveEditPopup
                .withTitle(this.translated('confirm_changes_program_changed_title'))
                .withDescription('')
                .withSecondaryTitle(this.translated('confirm_changes_title'))
                .withSecondaryDescription(this.translated('confirm_changes_description'))
                .withIcon(PopupIcon.AlertTriangle)
                .withSecondaryIcon(PopupIcon.AlertOctagon)
                .withInput(PopupInput.Calendar)
                .withClassForInput('half-width')
                .withStartDateForCalendar(this.modal.app.minPolicyEndDate)
                .withMinDateForCalendarInput(this.modal.app.minPolicyEndDate)
                .withMaxDateForCalendarInput(this.maxPolicyEndDate);
        }
        this.modal.app.popupServices.show(saveEditPopup);
    }

    public onEditPersonConfirm(param: DynamicDictionary = {}): void {
        PopupService.getInstance()
            .hide()
            .then((): void => {
                this.patchInsuredPerson(param)
                    .then((): void => {
                        this.modal.app.fetchPolicyDetailsWithoutCache().then((): void => {
                            PopupService.getInstance().hide();
                            this.modal.popModalQueue();
                            BottomNotification.getInstance().pushNotification(
                                BottomNotificationLevel.Success,
                                this.modal.app.localized('toastr_success_person_edit'),
                            );
                        });
                    })
                    .catch((): void => {
                        PopupService.getInstance()
                            .hide()
                            .then((): void => {
                                this.modal.showSimpleErrorPopup();
                            });
                    });
            });
    }

    private patchInsuredPerson(param: DynamicDictionary): Promise<DynamicDictionary> {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        const assembledPerson: DynamicDictionary = this.assembledPerson(param);

        return RequestService.getInstance()
            .post({ uri: Url.Ajax.legalPersonEditPerson, content: assembledPerson })
            .then((response: AxiosResponse): DynamicDictionary => {
                return response;
            });
    }

    private assembledPerson(param: DynamicDictionary): DynamicDictionary {
        const person: DynamicDictionary = {
            id: this.person.id,
            personId: this.person.personId,
            agreement: { id: this.modal.app.currentAgreement!.id, number: this.modal.app.policyId },
        };
        if (this.nameOrSurnameChanged()) {
            person.firstName = this.form.field('name').value;
            person.lastName = this.form.field('surname').value;
        }
        if (this.insuranceProgramHasChanged()) {
            person.insuranceProgram = { id: this.form.field('insuranceProgram').value };
            person.startDate = moment(param.startDate).format('YYYY-MM-DD');
        }
        if (this.emailHasChanged()) {
            person.firstName = this.form.field('name').value;
            person.lastName = this.form.field('surname').value;
            person.email = this.form.field('email').value;
        }

        return person;
    }

    private emailHasChanged(): boolean {
        return this.form.field('email').value !== this.modal.personForEdit.email;
    }

    private insuranceProgramHasChanged(): boolean {
        return this.form.field('insuranceProgram').value !== this.modal.personForEdit.insuranceProgram.id;
    }

    private nameOrSurnameChanged(): boolean {
        return (
            this.form.field('name').value !== this.modal.personForEdit.firstName ||
            this.form.field('surname').value !== this.modal.personForEdit.lastName
        );
    }

    private setupForm(): Promise<void> {
        return new Promise((resolve) => {
            this.form.addField(new FormField('name', '', 'required', Sanitizer.cleanUppercaseName));
            this.form.addField(new FormField('surname', '', 'required', Sanitizer.cleanUppercaseName));
            this.form.addField(new FormField('identityNumber'));
            this.form.addField(new FormField('email'));
            this.form.addField(new FormField('dateOfBirth'));
            this.form.addField(new FormField('legalStatus'));
            this.form.addField(new FormField('policyStartDate'));
            this.form.addField(new FormField('policyEndDate'));
            this.form.addField(new FormField('insuranceProgram'));
            this.form.addField(new FormField('yearlyPremium'));
            this.form.addField(new FormField('countryIc'));
            resolve();
        });
    }

    private patchEditPersonFields(): void {
        if (this.person) {
            this.form.field('name').patch(this.person.firstName);
            this.form.field('surname').patch(this.person.lastName);
            this.form.field('identityNumber').patch(this.person.personCode);
            this.form.field('email').patch(this.person.email);
            this.form.field('dateOfBirth').patch(moment(this.person.birthDate).format(this.modal.app.DateFormat));
            this.form.field('legalStatus').patch(this.legalStatus());
            this.form.field('policyStartDate').patch(moment(this.person.startDate).format(this.modal.app.DateFormat));
            this.form.field('policyEndDate').patch(moment(this.person.endDate).format(this.modal.app.DateFormat));
            this.form.field('insuranceProgram').patch(this.person.insuranceProgram.id);
            this.form
                .field('yearlyPremium')
                .patch(this.person.premium!.amount! + ' ' + useCurrency().currency(this.person.premium!.currency!));
            if (this.person.countryIc) {
                this.form.field('countryIc').patch({ ic: this.person.countryIc });
            }
        }
    }

    private legalStatus(): string {
        return this.person.resident ? this.translated('popup_column_resident') : this.translated('non_resident');
    }

    private translated(key: string): string {
        return Translations.getInstance().localized(key, 'legal');
    }
}
