<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';

const { translateForType } = useTranslate();

const props = defineProps({
    title: { type: String, default: '' },
    translationType: { type: String, default: '' },
});
</script>
<template>
    <div class="badge">
        <span class="radio-icon">
            <span class="red-dot"></span>
        </span>
        <div class="title">{{ translateForType(title, translationType) }}</div>
    </div>
</template>

<style lang="scss" scoped>
.badge {
    display: flex;
    align-self: center;
    align-items: center;
    gap: var(--size-femto);
    padding: 0 var(--size-pico) 0 var(--size-femto);
    background: var(--red-50);
    border-radius: 8px;
    border: 1px solid var(--red-50);
    height: var(--size-small);
    width: fit-content;

    &.green {
        background: var(--teal-50);
        border-color: var(--teal-50);

        .radio-icon {
            background-color: var(--teal-50);

            .red-dot {
                background-color: var(--brand-teal);
            }
        }

        .title {
            color: var(--text-color-link);
        }
    }

    .radio-icon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--red-50);
        display: flex;
        justify-content: center;
        align-items: center;

        .red-dot {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: var(--brand-red);
        }
    }

    .title {
        font-size: var(--font-size-pico);
        color: var(--text-color-attention);
    }
}
</style>
