export default class VueAppPageElement {
    private readonly PageSelector: string = 'div.vue-app';
    private readonly AdditionalFooterSelector: string = 'footer.additional-footer';
    private readonly AdditionalFooterClass: string = 'with-additional-footer';
    private static instance: VueAppPageElement;

    public static getInstance(): VueAppPageElement {
        if (!VueAppPageElement.instance) {
            VueAppPageElement.instance = new VueAppPageElement();
        }
        return VueAppPageElement.instance;
    }

    public init(): void {
        if (this.isAdditionalFooterElementExists()) {
            this.updateAdditionalFooterClassForPageElement();
            this.watchAdditionalFooterElement();
        }
    }

    private watchAdditionalFooterElement(): void {
        const observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
            mutations.forEach((mutation: MutationRecord) => this.updateAdditionalFooterClassForPageElement());
        });
        observer.observe(this.additionalFooterElement(), { attributes: true, attributeFilter: ['class'] });
    }

    private updateAdditionalFooterClassForPageElement(): void {
        const element: HTMLElement = this.additionalFooterElement();
        const isHidden: boolean = element.classList.contains('hidden');
        isHidden ? this.removeClass(this.AdditionalFooterClass) : this.addClass(this.AdditionalFooterClass);
    }

    private isAdditionalFooterElementExists(): boolean {
        let exists: boolean;
        try {
            exists = !!this.additionalFooterElement();
        } catch (error) {
            exists = false;
        }
        return exists;
    }

    private pageElement(): HTMLElement {
        return this.element(this.PageSelector);
    }

    private additionalFooterElement(): HTMLElement {
        return this.element(this.AdditionalFooterSelector);
    }

    private element(selector: string): HTMLElement {
        const element: HTMLElement = document.querySelector(selector) as HTMLElement;
        if (!element) {
            throw new Error('Element `' + selector + '` not found');
        }
        return element;
    }

    private addClass(className: string): void {
        this.pageElement().classList.add(className);
    }

    private removeClass(className: string): void {
        this.pageElement().classList.remove(className);
    }
}
