import Player from '@vimeo/player';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import VimeoOptionsInterface from '@/interfaces/vimeo.options.interface';

export default class ItemsBannerVimeo {
    private embedPlayer: string = '#vimeo-';
    private allPlayersIds: string[] = [];
    private allPlayersUrls: string[] = [];
    private players: VimeoPlayer[] = [];
    private mute: boolean = false;
    private background: boolean = true;

    public init(): void {
        this.setupVideos();
    }

    public embedVideoParams(element: string): void {
        const parsedElement: DynamicDictionary = JSON.parse(ItemsBannerVimeo.transformedVueSafeString(element));
        if (parsedElement) {
            this.allPlayersIds.push(this.embedPlayer + parsedElement.id);
            this.allPlayersUrls.push(parsedElement.url);
        }
    }

    private setupVideos(): void {
        if (this.allPlayersIds.length > 0) {
            this.allPlayersIds.forEach((item, index) => {
                this.playerInit(item, index);
            });
        }
    }

    private playerInit(item: string, i: number): void {
        this.players[i] = new Player($(item), this.options(this.allPlayersUrls[i]));
        this.players[i].ready().then(() => {
            this.players[i].play().catch(() => {
                this.players[i].setMuted(!this.mute);
                this.players[i].play().catch(() => {
                    this.players[i].destroy();
                });
            });
        });
    }

    private options(url: string): object {
        return <VimeoOptionsInterface>{
            url: url,
            muted: this.mute,
            keyboard: false,
            dnt: true,
            controls: false,
            background: this.background,
        };
    }

    private static transformedVueSafeString(vueSafe: string): string {
        return vueSafe.replace(/\|o\|/g, '{{').replace(/\|c\|/g, '}}').replace(/\|s\|/g, '\\"');
    }
}
