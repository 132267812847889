import { StoreDefinition } from 'pinia';
import { SolarPanelsStore, useSolarPanels } from '@/Composables/Store/Types/SolarPanels';
import { RentersStore, useRenters } from '@/Composables/Store/Types/Renters';

export const useStore = (): StoreParams => {
    const solarPanels = (): StoreDefinition<string, SolarPanelsStore> => {
        return useSolarPanels().store();
    };

    const renters = (): StoreDefinition<string, RentersStore> => {
        return useRenters().store();
    };

    return {
        solarPanels,
        renters,
    };
};

export interface StoreParams {
    solarPanels: () => StoreDefinition<string, SolarPanelsStore>;
    renters: () => StoreDefinition<string, RentersStore>;
}
