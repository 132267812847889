import { Subject } from 'rxjs';
import PaymentMethod from '@/Components/Widgets/PaymentWidget/Interfaces/PaymentMethod.interface';

export default class PaymentMethods {
    private static instance: PaymentMethods;
    private options: PaymentMethod[] = [];
    public ready: boolean = false;
    public onExternalDataIsReady: Subject<void> = new Subject<void>();

    private constructor() {}

    public static getInstance(): PaymentMethods {
        if (!PaymentMethods.instance) {
            PaymentMethods.instance = new PaymentMethods();
        }
        return PaymentMethods.instance;
    }

    public addOptions(value: PaymentMethod[]): void {
        this.options = value;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public paymentMethods(): PaymentMethod[] {
        return this.options;
    }
}
