import { useScroll } from '@/Composables/Scroll';

export const useClaimsMtplHtml = (): MtplPageInterface => {
    const scrollToPanel = (id: string): void => {
        const stepperHeightOffset: number = 70;
        useScroll().scrollToViewByDataScroll(id, stepperHeightOffset);
    };

    return {
        scrollToPanel,
    };
};

export interface MtplPageInterface {
    scrollToPanel(id: string): void;
}
