import ContactUsButton from '@/Components/Other/ContactUs/Interfaces/ContactUsButtonInterface';
import ContactusButtonType from '@/Components/Other/ContactUs/Enums/ContactusButtonTypeEnum';

export default class ContactUsButtonsBuilder {
    private static instance: ContactUsButtonsBuilder;
    private buttons: ContactUsButton[] = [];

    private constructor() {}

    public static getInstance(): ContactUsButtonsBuilder {
        if (!ContactUsButtonsBuilder.instance) {
            ContactUsButtonsBuilder.instance = new ContactUsButtonsBuilder();
        }

        return ContactUsButtonsBuilder.instance;
    }

    public clear(): this {
        this.buttons = [];

        return this;
    }

    public widthUrlButton(title: string, url: string): this {
        this.buttons.push({
            type: ContactusButtonType.Url,
            title: title,
            actions: url,
        });

        return this;
    }

    public widthMailButton(title: string, mail: string): this {
        this.buttons.push({
            type: ContactusButtonType.Mail,
            title: title,
            actions: mail,
        });

        return this;
    }

    public widthPhoneButton(title: string, phone: string): this {
        this.buttons.push({
            type: ContactusButtonType.Phone,
            title: title,
            actions: phone,
        });

        return this;
    }

    public build(): ContactUsButton[] {
        return this.buttons;
    }
}
