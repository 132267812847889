import PolicyDetailsPaymentMethod from '@/Components/Policies/PolicyDetailsPaymentMethod/Interfaces/PolicyDetailsPaymentMethodInterface';
import PolicyDetailsPaymentMethodContent from '@/Components/Policies/PolicyDetailsPaymentMethod/Interfaces/PolicyDetailsPaymentMethodContentInterface';

export default class PolicyDetailsPaymentMethodBuilder {
    private policyDetailsPaymentMethod: PolicyDetailsPaymentMethod = new (class implements PolicyDetailsPaymentMethod {
        public component: string = 'AppPolicyDetailsPaymentMethod';
        public content: PolicyDetailsPaymentMethodContent = new (class implements PolicyDetailsPaymentMethodContent {
            public cardNumbers: string = '';
            public cardStatus: string = '';
        })();
    })();

    public withCardNumbers(value: string): PolicyDetailsPaymentMethodBuilder {
        this.policyDetailsPaymentMethod.content.cardNumbers = value;

        return this;
    }

    public withCardStatus(value: string): PolicyDetailsPaymentMethodBuilder {
        this.policyDetailsPaymentMethod.content.cardStatus = value;

        return this;
    }

    public isEnabled(): boolean {
        return (
            this.policyDetailsPaymentMethod.content.cardNumbers !== '' &&
            this.policyDetailsPaymentMethod.content.cardStatus !== ''
        );
    }

    public build(): PolicyDetailsPaymentMethod {
        return this.policyDetailsPaymentMethod;
    }
}
