<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import Form from '@/assets/libraries/form/form';
import ExtractDataService from '@/services/extract.data.service';
import TravelDataLayer from '@/pages/Travel/TravelDataLayer';
import SummaryValidators from '@/pages/Travel/Summary/SummaryValidators';
import moment from 'moment/moment';
import PolicyHolderType from '@/Enums/PolicyHolderTypeEnum';
import { useTranslate } from '@/Composables/Translate';
import AppCountry from '@/assets/libraries/app/app-country';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import DateFormat from '@/Enums/DateFormatEnum';
import { useDefine } from '@/Composables/Define';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Value from '@/assets/libraries/form/value';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import OneDate from '@/assets/libraries/Date/OneDate';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import TravelSteps from '@/pages/Travel/Classes/TravelSteps';
import UserStorage from '@/services/user.storage.service';
import { useFormatter } from '@/Composables/Formatter';
import User from '@/services/user.service';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        const { translate, language } = useTranslate();
        const { isSet } = useDefine();
        const { sparsePrice } = useFormatter();
        const stepsSubmitter = useStepsSubmitter();
        const CurrentStep: number = 5;
        const TopPosition: number = 300;
        const ConsentsGlue: string = ':';
        const validators: SummaryValidators = new SummaryValidators();
        const dataLayer: TravelDataLayer = new TravelDataLayer();
        const extractDataService: ExtractDataService = new ExtractDataService();
        const form: Form = new Form();
        const user: User = User.getInstance();

        let travelStartDate!: moment.Moment;
        let travelEndDate!: moment.Moment;
        let activityStartDate!: moment.Moment;
        let activityEndDate!: moment.Moment;
        const bankLinkId: Ref<number> = ref(0);
        const multipleTripsDuration: Ref<number | null> = ref(null);
        const informationText: Ref<string | null> = ref('');
        const skipValidation: Ref<boolean> = ref(false);

        const policyHolderTypes: Ref<InputOption[]> = computed(() => {
            return [
                new InputOptionBuilder()
                    .setValue(PolicyHolderType.Personal)
                    .setName(translate('one_travel_private'))
                    .build(),
                new InputOptionBuilder()
                    .setValue(PolicyHolderType.Business)
                    .setName(translate('one_travel_corporate'))
                    .build(),
            ];
        });

        const isVisiblePaymentInfo: Ref<boolean> = computed(() => {
            return bankLinkId.value > 0 && !!informationText.value;
        });

        const policyHolderType: Ref<string> = computed(() => {
            return form.field('policyHolderType').value;
        });

        const policyHolderIsResident: Ref<boolean> = computed(() => {
            return form.field('policyHolderIsResident').value;
        });

        const isPolicyHolderTypePrivate: Ref<boolean> = computed(() => {
            return policyHolderType.value === PolicyHolderType.Personal;
        });

        const isPaymentMethodSelected: Ref<boolean> = computed(() => {
            return bankLinkId.value !== 0;
        });

        const isResidentCheckboxDisabled: Ref<boolean> = computed(() => {
            return btaBase.user.isLogged() || (btaBase.settings.disableTravelNonResidents() && !isIso('LT'))
                ? true
                : !isPolicyHolderTypePrivate.value ||
                      (isPolicyHolderTypePrivate.value && form.field('defaultPolicyHolder').value);
        });

        const isLockDisabled: Ref<boolean> = computed(() => {
            return btaBase.cmsFieldIsEnabled('residentLocked');
        });

        const isWideInput: Ref<boolean> = computed(() => {
            return isIso('LT') && form.field('policyHolderIsResident').value;
        });

        const isDefaultPolicyHolder: Ref<boolean> = computed(() => {
            return btaBase.user.isLogged() ? true : form.field('defaultPolicyHolder').value;
        });

        const isMobileDevice: Ref<boolean> = computed(() => {
            return btaBase.isMobile.value;
        });

        const inputsClass: Ref<string> = computed(() => {
            return new AppCountry().isLT() ? 'full-size-inputs-private' : 'full-size-inputs-corporate';
        });

        const isRequiredBirthDate: Ref<boolean> = computed(() => {
            return requiredBirthDate.value && isPolicyHolderTypePrivate.value && !btaBase.user.isLogged();
        });

        const isVisiblePolicyHolderIdentityNumber: Ref<boolean> = computed(() => {
            return isIso('LT') ? !btaBase.user.isLogged() : true;
        });

        const isMobileModeEnabled: Ref<boolean> = computed(() => {
            return !new AppCountry().isLT();
        });

        const requiredBirthDate: Ref<boolean> = computed(() => {
            let result: boolean;
            if (!isIso('LT')) {
                const isResidentForOneCountries: boolean = new AppCountry().isResidentForOneCountries(
                    validators.policyHolderResidenceCountry,
                );
                result =
                    !isResidentForOneCountries ||
                    (isResidentForOneCountries &&
                        validators.isValidPersonCodeWithoutDate(
                            form.field('policyHolderIdentityNumber').value,
                            validators.policyHolderResidenceCountry,
                        )) ||
                    !isPolicyHolderTypePrivate.value;
            } else {
                result = !form.field('policyHolderIsResident').value;
            }

            return result;
        });

        const showMarketingConsents: Ref<boolean> = computed((): boolean => {
            return (
                !btaBase.user.isLogged() || (isPolicyHolderTypePrivate.value && !btaBase.user.current.receiveMarketing)
            );
        });

        const emptyCredentials: Ref<DynamicDictionary> = computed(() => {
            return {
                policyHolderFirstName: '',
                policyHolderLastName: '',
                policyHolderIdentityNumber: '',
                policyHolderIsResident: true,
                policyHolderBirthDate: '',
                policyHolderPhoneNumber: { country: btaBase.settings.value('USER_PHONE_CODE'), phone: '' },
                policyHolderCountryPrivate: { iso: '' },
            };
        });

        const hasAgreedToTerms: Ref<boolean> = computed(() => {
            return form.field('agreeToTerms').value;
        });

        const isSelectedCountryEqualToLocale: Ref<boolean> = computed(() => {
            return form.field('policyHolderIsResident').value
                ? false
                : form.field('policyHolderCountryPrivate').value.iso === new AppCountry().iso();
        });

        const country: Ref<string> = computed(() => {
            return user.isLogged()
                ? btaBase.settings.value('LOCALE_ISO')
                : form.field('policyHolderCountryPrivate').value.iso;
        });

        function isIso(iso: string): boolean {
            return btaBase.settings.value('LOCALE_ISO') === iso;
        }

        function choosePayment(event: VueEvent): void {
            informationText.value = '';
            const targetBankLinkId: number = event.params.bankLinkId;
            const targetInformationText: string = event.params.informationText;
            if (targetBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = targetBankLinkId === bankLinkId.value ? 0 : targetBankLinkId;
                informationText.value = targetInformationText
                    ? translate(String(targetInformationText).toLowerCase())
                    : '';
            }
            $('.payments > .title').removeClass(CssClass.NotFilled);
        }

        function isVisibleInformationalText(): boolean {
            return isVisiblePaymentInfo.value;
        }

        function applyTravelStartDate(date: string): void {
            travelStartDate = moment(date);
        }

        function applyTravelEndDate(date: string): void {
            travelEndDate = moment(date);
        }

        function applyActivityStartDate(date: string): void {
            activityStartDate = moment(date);
        }

        function applyActivityEndDate(date: string): void {
            activityEndDate = moment(date);
        }

        function applyMultipleTripsDuration(duration: string): void {
            multipleTripsDuration.value = Number(duration);
        }

        function durationText(type: string): string {
            let duration: string = '';
            let daysText: string = '';
            switch (type) {
                case 'multipleTrips':
                    switch (language().toUpperCase()) {
                        case 'LV':
                            daysText = ' dienas';
                            break;
                        case 'EE':
                            daysText = ' päeva';
                            break;
                        case 'RU':
                            daysText = ' дней';
                            break;
                        case 'LT':
                            daysText = ' dienų';
                            break;
                        case 'EN':
                            daysText = ' days';
                            break;
                        default:
                    }
                    duration = multipleTripsDuration.value + daysText;
                    break;
                case 'trip':
                    duration = dateDifference(travelStartDate, travelEndDate);
                    break;
                case 'activity':
                    duration = dateDifference(activityStartDate, activityEndDate);
                    break;
                default:
            }

            return duration;
        }

        function handleFormSubmit(): void {
            if (canSubmitForm()) {
                dataLayer.pushDataLayer('');
                nextTick((): void => {
                    submit();
                });
            } else {
                btaBase.captcha.resetCaptcha();
                handleFormErrors();
            }
        }

        function resetTermsError(): void {
            const target: JQuery = $('.with-border');
            target.removeClass(CssClass.BorderWithColor);
        }

        function submit(): void {
            stepsSubmitter.clearSubmitter();
            stepsSubmitter.addSubmitParam('nextStep', btaBase.nextStep(), false);
            stepsSubmitter.addSubmitParam('facility', btaBase.facility(), false);
            stepsSubmitter.addSubmitParam('policyHolderDetails', assembledPolicyHolder(), false);
            stepsSubmitter.addSubmitParam('bankLinkId', bankLinkId.value, false);
            stepsSubmitter.addSubmitParam('tripDuration', tripDuration(), false);
            // TODO receiveMarketingOffers field doesn't exist, it always valuates to empty string. Remove line here and update backend to get param from PolicyHolderDetails marketingConsents field
            stepsSubmitter.addSubmitParam('receiveMarketing', form.field('receiveMarketingOffers').value, false);
            stepsSubmitter.addSubmitParam('paymentFailRedirectUrl', btaBase.currentUrl.value, false);
            stepsSubmitter.addAjaxResponseCallbackAfterStepsStorage(redirectToBank);
            stepsSubmitter.submitMethod(Method.Post);
            stepsSubmitter.submitStep(Url.Ajax.travelSummary);
        }

        function patchDefaultPolicyHolder(): void {
            form.field('defaultPolicyHolder').value ? patchFirstTravelerAsPolicyHolder() : patchEmptyPolicyHolder();
        }

        function patchResidentStatus(): void {
            if (btaBase.settings.disableTravelNonResidents() && !isPolicyHolderTypePrivate.value) {
                form.field('policyHolderIsResident').patch(true);
            }
        }

        function receiverCallback(param: DynamicDictionary): DynamicDictionary {
            const result: DynamicDictionary = param;
            if (new AppCountry().isLT()) {
                const parts: string[] = String(param.label).split(',');
                const expectedParts: number = 2;
                if (parts.length >= expectedParts) {
                    result.label = parts[0] + ', ' + parts[1];
                }
            }

            return result;
        }

        function oneDate(value: string): string {
            return OneDate.short(value);
        }

        function dateDifference(startDate: moment.Moment, endDate: moment.Moment): string {
            const locale: string = MomentBuilder.mappedLanguage();
            const yearTripDurationDays: number = 364;
            endDate.locale(locale);
            startDate.locale(locale);
            const differenceText: string = endDate.endOf('day').from(startDate.startOf('day'), true);
            const substringStart: number = 2;
            const substringEnd: number = 4;
            const differenceTextEn: string = differenceText.substr(substringStart, substringEnd);
            const diffTexts: string = locale === 'en' ? '1 ' + differenceTextEn : '1 ' + differenceText;

            return endDate.diff(startDate, 'days') === 0 || endDate.diff(startDate, 'days') === yearTripDurationDays
                ? diffTexts
                : differenceText;
        }

        function patchFirstTravelerAsPolicyHolder(): void {
            const firstTraveler: DynamicDictionary = JSON.parse(
                btaBase.userStorage.stepStorageData.travelersFromForm,
            )[0];
            form.field('policyHolderFirstName').patch(firstTraveler.firstName);
            form.field('policyHolderLastName').patch(firstTraveler.lastName);
            form.field('policyHolderIdentityNumber').patchStandalone(firstTraveler.personCode);
            form.field('policyHolderIsResident').patch(firstTraveler.isResident);
            form.field('policyHolderBirthDate').patch(firstTraveler.birthDate);
            form.field('policyHolderPhoneNumber').patch(
                {
                    country: firstTraveler.address.phoneCode,
                    phone: '',
                },
                false,
            );
            form.field('policyHolderCountryPrivate').patch({ iso: firstTraveler.address.countryCodeIso });
        }

        function patchEmptyPolicyHolder(): void {
            Object.keys(emptyCredentials.value).forEach((key: string) => {
                form.field(key).patch(emptyCredentials.value[key]);
                form.field(key).markAsUntouched();
            });
        }

        function handleFormErrors(): void {
            resetMarkedErrors();
            switch (true) {
                case !form.isValid():
                    scrollToFormAndMark();
                    break;
                case isSelectedCountryEqualToLocale.value:
                    scrollToFormAndShowPopup();
                    break;
                case !hasAgreedToTerms.value:
                    scrollToTermsAndMark();
                    break;
                case !isPaymentMethodSelected.value:
                    scrollToBanksListAndShowPopup();
                    break;
                default:
            }
        }

        function canSubmitForm(): boolean {
            return (
                form.isValid() &&
                hasAgreedToTerms.value &&
                isPaymentMethodSelected.value &&
                !isSelectedCountryEqualToLocale.value
            );
        }

        function tripDuration(): number {
            const duration: number = Math.round(
                travelEndDate.endOf('day').diff(travelStartDate.startOf('day'), 'days', true),
            );
            const fixedDuration: number = duration === 0 ? duration + 1 : duration;

            return multipleTripsDuration.value ? multipleTripsDuration.value : fixedDuration;
        }

        function redirectToBank(): void {
            btaBase.navigate(btaBase.formattedUrl(Url.PaymentsApi.paymentsPay));
        }

        function setupForm(): void {
            form.addField(
                new FormField(
                    'policyHolderFirstName',
                    '',
                    validators.nameValidator('policyHolderFirstName', PolicyHolderType.Personal),
                    Sanitizer.cleanName,
                ),
            );
            form.addField(
                new FormField(
                    'policyHolderLastName',
                    '',
                    validators.nameValidator('policyHolderLastName', PolicyHolderType.Personal),
                    Sanitizer.cleanName,
                ),
            );
            form.addField(new FormField('policyHolderIdentityNumber', '', validators.personCodeValidator()));
            form.addField(new FormField('policyHolderEmailAddress', '', 'required'));
            form.addField(new FormField('policyHolderPhoneNumber', '', 'required'));
            form.addField(new FormField('policyHolderType', PolicyHolderType.Personal));
            form.addField(new FormField('policyHolderIsResident', true));
            form.addField(new FormField('defaultPolicyHolder', false));
            form.addField(new FormField('policyHolderBirthDate', '', validators.birthDateValidator()));
            form.addField(
                new FormField(
                    'policyHolderCountryPrivate',
                    '',
                    validators.countryValidator('policyHolderCountryPrivate', PolicyHolderType.Personal, true),
                ),
            );
            form.addField(
                new FormField(
                    'policyHolderCompanyName',
                    '',
                    validators.nameValidator('policyHolderCompanyName', PolicyHolderType.Business),
                ),
            );
            form.addField(
                new FormField(
                    'policyHolderCompanyNumber',
                    '',
                    validators.companyNumberValidator(),
                    Sanitizer.cleanCompanyRegistrationNumber,
                ),
            );
            form.addField(
                new FormField(
                    'policyHolderCountryCorporate',
                    '',
                    validators.countryValidator('policyHolderCountryCorporate', PolicyHolderType.Business, true),
                ),
            );
            form.addField(new FormField('marketingConsents'));
            form.addField(new FormField('agreeToTerms', false));
            form.addField(new FormField('expandableContent1'));
            form.addField(new FormField('expandableContent2'));
            form.addField(new FormField('expandableContent3'));
            if (new AppCountry().isLT()) {
                form.addField(new FormField('address'));
                form.addField(new FormField('apartment'));
            }
            if (!new AppCountry().isLT()) {
                form.addField(
                    new FormField(
                        'policyHolderCompanyType',
                        '',
                        validators.companyTypeValidator('policyHolderCompanyType', PolicyHolderType.Business, false),
                    ),
                );
            }
            form.setReady();
        }

        function onUserStorageReady(): void {
            setupForm();
        }

        function onAppReady(): void {
            if (hasAdditionalOptions()) {
                btaBase.dynamicStepper.applyEnabled(TravelSteps.additional());
            } else {
                btaBase.dynamicStepper.applyEnabled(TravelSteps.default());
            }
            form.validate().then();
            fillUser();
        }

        function additionalOptions(): DynamicDictionary[] | undefined {
            return (
                UserStorage.getInstance().stepStorageData.risks ??
                UserStorage.getInstance().stepStorageData.additionalOptions
            );
        }

        function hasAdditionalOptions(): boolean {
            return !!additionalOptions();
        }

        function fillUser(): void {
            if (btaBase.user.isLogged()) {
                patchUserFields();
            } else {
                if (isIso('LT')) {
                    form.field('defaultPolicyHolder').patch(false);
                    patchEmptyPolicyHolder();
                } else {
                    form.field('defaultPolicyHolder').patch(true);
                    patchDefaultPolicyHolder();
                }
            }
        }

        function patchUserFields(): void {
            const fields: DynamicDictionary = {
                policyHolderFirstName: btaBase.user.current.firstname,
                policyHolderLastName: btaBase.user.current.lastname,
                policyHolderIdentityNumber: btaBase.user.current.personCode,
                policyHolderEmailAddress: btaBase.user.current.email,
                policyHolderPhoneNumber: {
                    country: btaBase.user.current.phoneCode,
                    phone: btaBase.user.current.phone,
                },
                policyHolderBirthDate: moment(btaBase.user.current.birthDate, DateFormat.Default.Short).toDate(),
            };
            Object.keys(fields).forEach((field: string): void => {
                form.field(field).patch(fields[field]);
            });
            if (form.field('address').isEmpty()) {
                form.field('address').patch({
                    addressCode: btaBase.user.current.addressCode,
                    label: btaBase.user.current.address,
                    value: btaBase.user.current.address,
                    additional: btaBase.user.current.addressExtra,
                });
                btaBase.lockInput(true);
            }
            form.field('marketingConsents').patch({
                customText: {},
                selected: btaBase.user.current.marketingConsents.join(ConsentsGlue),
            });
        }

        function assembledPolicyHolder(): string {
            const policyHolderDetails: DynamicDictionary = {
                email: form.field('policyHolderEmailAddress').value,
                phoneCode: form.field('policyHolderPhoneNumber').value.country,
                phone: form.field('policyHolderPhoneNumber').value.phone,
                isLegal: form.field('policyHolderType').value === PolicyHolderType.Business,
                isResident: form.field('policyHolderIsResident').value,
            };
            if (isIso('LT')) {
                policyHolderDetails.addressCode = form.field('address').value.addressCode;
                policyHolderDetails.detailed = form.field('address').value.detailed;
                policyHolderDetails.postalCode = form.field('address').value.postCode;
                policyHolderDetails.apartment = form.field('address').value.apartment;
            }
            if (isPolicyHolderTypePrivate.value) {
                policyHolderDetails.firstName = String(form.field('policyHolderFirstName').value).trim();
                policyHolderDetails.lastName = String(form.field('policyHolderLastName').value).trim();
                policyHolderDetails.personCode = form.field('policyHolderIdentityNumber').value;
                policyHolderDetails.birthDate = policyHolderBirthDate();
                policyHolderDetails.marketingConsents = marketingConsents();
            } else {
                policyHolderDetails.companyName = String(form.field('policyHolderCompanyName').value).trim();
                policyHolderDetails.companyNumber = form.field('policyHolderCompanyNumber').value;
                if (!isIso('LT')) {
                    policyHolderDetails.companyType = form.field('policyHolderCompanyType').value.iso;
                }
            }
            if (!policyHolderIsResident.value) {
                policyHolderDetails.countryCode = isPolicyHolderTypePrivate.value
                    ? form.field('policyHolderCountryPrivate').value.ic
                    : form.field('policyHolderCountryCorporate').value.ic;
            }

            return JSON.stringify(policyHolderDetails);
        }

        function marketingConsents(): string[] {
            const selectedConsents: string = form.field('marketingConsents').value.selected;

            return new Value(selectedConsents).isNotEmpty() ? selectedConsents.split(ConsentsGlue) : [];
        }

        function policyHolderBirthDate(): string {
            let result: string = '';
            if (isRequiredBirthDate.value) {
                result = extractDataService.dateWithCustomFormat(form.field('policyHolderBirthDate').value);
            } else {
                if (btaBase.user.isLogged()) {
                    result = extractDataService.dateWithCustomFormat(
                        moment(btaBase.user.current.birthDate, DateFormat.Default.Short).toDate(),
                    );
                } else {
                    const extractedBirthDate: Date | undefined = extractDataService.birthDateFromPersonCode(
                        form.field('policyHolderIdentityNumber').value,
                        form.field('policyHolderCountryPrivate').value.iso,
                    );
                    if (extractedBirthDate) {
                        result = extractDataService.dateWithCustomFormat(extractedBirthDate);
                    }
                }
            }

            return result;
        }

        function birthDateFromPersonCode(personCode: string, residenceCountry: string): Date | undefined {
            return extractDataService.birthDateFromPersonCode(personCode, residenceCountry);
        }

        function scrollToFormAndMark(): void {
            const target: JQuery = $('.policy-holder-form');
            $('html,body').animate({ scrollTop: target.offset()!.top }, TopPosition);
            form.touch().then();
        }

        function scrollToFormAndShowPopup(): void {
            const target: JQuery = $('.policy-holder-form');
            $('html,body').animate({ scrollTop: target.offset()!.top }, TopPosition);
            const popup: SimpleError = new OnePopup()
                .withType()
                .simpleError.withTitle(useTranslate().translate('btar_error'))
                .withDescription(useTranslate().translate('one_travel_country_mismatch'));
            PopupService.getInstance().show(popup);
        }

        function scrollToBanksListAndShowPopup(): void {
            const target: JQuery = $('[data-alias="banks-list"]');
            $('html,body').animate({ scrollTop: target.offset()!.top }, TopPosition);
            $('.payments > .title').addClass(CssClass.NotFilled);
            const popup: SimpleError = new OnePopup()
                .withType()
                .simpleError.withTitle(useTranslate().translate('btar_error_payment_method_not_selected_title'))
                .withDescription(useTranslate().translate('btar_error_payment_method_not_selected_description'));
            PopupService.getInstance().show(popup);
        }

        function scrollToTermsAndMark(): void {
            if (btaBase.settings.useIframeTermsAndConditions()) {
                const target: JQuery = $('[data-store="agreeToTerms"]');
                const scrollTarget: JQuery = $('.terms-and-conditions');
                const heightOfStepper: number = 56;
                $('html,body').animate({ scrollTop: scrollTarget.offset()!.top - heightOfStepper }, TopPosition);
                target.children('input').addClass(CssClass.BorderWithColor);
            } else {
                const target: JQuery = $('.with-border');
                $('html,body').animate({ scrollTop: target.offset()!.top }, TopPosition);
                target.addClass(CssClass.BorderWithColor);
            }
        }

        function resetMarkedErrors(): void {
            $('.payments > .title').removeClass(CssClass.NotFilled);
            resetTermsError();
        }

        function onAddressLockChange(isLocked: boolean): void {
            const showApartment: boolean =
                isSet(form.field('address').value.apartment) && form.field('address').value.apartment !== '';
            if (isLocked && !showApartment) {
                skipValidation.value = true;
                $('.apartment').hide();
            } else {
                skipValidation.value = false;
                $('.apartment').show();
            }
        }

        function onPolicyHolderCountryPrivateChange(): void {
            form.validate().then();
        }

        function onIsResidentClick(): void {
            const currentCountry: string = new AppCountry().iso();
            if (
                !policyHolderIsResident.value &&
                (form.field('policyHolderCountryPrivate').isEmpty() ||
                    form.field('policyHolderCountryPrivate').value.iso === currentCountry)
            ) {
                form.field('policyHolderCountryPrivate').clear();
                nextTick(() => {
                    form.field('policyHolderIdentityNumber').clearSanitizer();
                });
            } else {
                form.field('policyHolderCountryPrivate').value.iso = currentCountry;
                form.field('policyHolderIdentityNumber').addValidators(validators.personCodeValidator());
            }
        }

        const excludedCoverageShowingRisks: string[] = [
            'EE_TRAVEL_V2_R_COVID19_TRIP_CANCELLATION',
            'EE_TRAVEL_V2_R_TRIP_INTERRUPTION_EXTENDED_COVERAGE',
        ];

        function coverageWithExcludedRisks(price: number, riskIc: string): string {
            let result: string = '';
            if (!excludedCoverageShowingRisks.includes(riskIc)) {
                result = sparsePrice(price, true) + ' &euro;';
            }

            return result;
        }

        return {
            ...btaBase,
            ...{
                form,
                skipValidation,
                showMarketingConsents,
                isMobileModeEnabled,
                policyHolderType,
                policyHolderTypes,
                policyHolderIsResident,
                inputsClass,
                isPolicyHolderTypePrivate,
                isLockDisabled,
                isRequiredBirthDate,
                isResidentCheckboxDisabled,
                isVisiblePolicyHolderIdentityNumber,
                isDefaultPolicyHolder,
                informationText,
                isIso,
                isMobileDevice,
                choosePayment,
                isVisibleInformationalText,
                CurrentStep,
                applyTravelStartDate,
                applyTravelEndDate,
                applyActivityStartDate,
                applyActivityEndDate,
                onPolicyHolderCountryPrivateChange,
                onIsResidentClick,
                applyMultipleTripsDuration,
                durationText,
                handleFormSubmit,
                resetTermsError,
                submit,
                patchDefaultPolicyHolder,
                patchResidentStatus,
                receiverCallback,
                dateDifference,
                patchFirstTravelerAsPolicyHolder,
                validators,
                onAddressLockChange,
                onUserStorageReady,
                onAppReady,
                oneDate,
                sparsePrice,
                country,
                coverageWithExcludedRisks,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-travel');
        this.setStorageUsage(true);
        this.validators.init(this.form, this.isRequiredBirthDate);
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady.subscribe((): void => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onAppIsReadySubscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsReadySubscription.unsubscribe();
        });
    },
});
</script>
