<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, PropType, Ref, watch } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import MovablePropertyDeviceOptionEmitters from '@/Components/Options/OptionsFieldList/Enums/MovablePropertyDeviceOptionEmitters';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import { useTranslate } from '@/Composables/Translate';

const props = defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    options: { type: Array as PropType<DynamicDictionary[]>, default: () => [] },
    description: { type: String, default: '' },
});
const { translate } = useTranslate();
const emit = defineEmits([
    MovablePropertyDeviceOptionEmitters.Toggle,
    MovablePropertyDeviceOptionEmitters.Input,
    MovablePropertyDeviceOptionEmitters.Continue,
]);

let deviceField: FormField | undefined = props.options.length > 0 ? props.options[0].field : undefined;

const continueIsDisabled: Ref<boolean> = computed(() => {
    return hasEnabledOption() ? !deviceField?.isValid : true;
});

watch(
    () => props.options,
    () => {
        if (currentEnabledOption()) {
            deviceField = currentEnabledOption().field;
        }
    },
);

function onListContinue(): void {
    emit(MovablePropertyDeviceOptionEmitters.Continue);
}

function onOptionToggle(optionEmit: DynamicDictionary): void {
    const returnEmit: DynamicDictionary = {};
    props.options.forEach((option: DynamicDictionary): void => {
        returnEmit[option.device.optionIc] = {
            state: optionEmit[option.device.optionIc] ? optionEmit[option.device.optionIc].state : false,
            value: '',
        };
    });
    emit(MovablePropertyDeviceOptionEmitters.Toggle, returnEmit);
}

function onOptionFieldInput(): void {
    const returnEmit: DynamicDictionary = {};
    props.options.forEach((option: DynamicDictionary): void => {
        returnEmit[option.device.optionIc] = {
            state: option.enabled.value,
            value: currentEnabledOption().device.optionIc === option.device.optionIc ? deviceField?.value : '',
        };
    });
    emit(MovablePropertyDeviceOptionEmitters.Input, returnEmit);
}

function currentEnabledOption(): DynamicDictionary {
    return props.options.filter((option: DynamicDictionary): boolean => option.enabled.value)[0];
}

function hasEnabledOption(): boolean {
    return props.options.filter((option: DynamicDictionary): boolean => option.enabled.value).length > 0;
}

function hasHint(): boolean {
    return hasEnabledOption() ? currentEnabledOption().hint !== '' : false;
}

function optionHint(): string {
    return currentEnabledOption().fieldHint;
}

function optionLabel(): string {
    return currentEnabledOption().fieldLabel;
}

function optionPlaceholder(): string {
    return currentEnabledOption().fieldPlaceholder;
}

function continueButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_continue'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}
</script>

<template>
    <div
        :id="formField.name"
        class="options-field-list"
        :class="{ ...formField.classes() }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="list-container">
            <component
                :is="option.component"
                v-for="(option, index) in options"
                :id="'list-option-' + index"
                :key="index"
                :option="option"
                :data-store-disabled="true"
                @device-option-toggle="onOptionToggle($event)"
            >
            </component>
        </div>
        <template v-if="deviceField">
            <app-input-text
                v-if="hasEnabledOption()"
                class="option-field"
                :data-store-disabled="true"
                :label="optionLabel()"
                :placeholder="optionPlaceholder()"
                :form-field="deviceField"
                :disable-error-text="true"
                @input="onOptionFieldInput()"
            ></app-input-text>
            <div v-if="hasHint()" class="field-hint" v-html="optionHint()"></div>
            <div v-if="description && !deviceField.isValid" class="description" v-html="description"></div>
        </template>
        <app-button-with-callback
            v-bind="continueButtonParams()"
            :disabled="continueIsDisabled"
            @button-callback-click="onListContinue()"
        >
        </app-button-with-callback>
    </div>
</template>

<style lang="scss" scoped>
.options-field-list {
    width: 100%;

    .list-container {
        gap: var(--size-normal);
        display: flex;
        flex-direction: column;
    }

    .option-field {
        width: 100%;
        margin-top: var(--size-medium);
    }

    .field-hint {
        margin-top: var(--size-pico);
        color: var(--text-color-subtlest);
        display: flex;
        gap: var(--size-nano);
        font-size: var(--font-size-nano);
    }

    .description {
        margin-top: var(--size-small);
        font-size: var(--font-size-nano);
        color: var(--text-color-subtlest);

        @include respond-above('sm') {
            align-self: flex-start;
        }
    }

    .button-with-callback {
        height: 52px;
        width: 100%;
        margin-top: var(--size-small);

        @include respond-above('sm') {
            width: 168px;
            align-self: flex-start;
        }
    }

    @include respond-above('sm') {
        width: 440px;
    }
}
</style>
