import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import GridCellTooltipRenderer from '@/Components/Lists/SmartGrid/Interfaces/GridCellTooltipRendererInterface';
import CellRendererTemplate from '@/Components/Lists/SmartGrid/Interfaces/CellRendererTemplateInterface';
import { useStrings } from '@/Composables/Strings';
import ValueObjectField from '@/Components/Lists/SmartGrid/Interfaces/ValueObjectFieldInterface';

export default class TooltipRenderer implements GridCellTooltipRenderer {
    private id: string = '';
    private dom?: JQuery;
    private templateHtml: string = '';
    private tooltipBaseClass: string = '';
    private tooltipCustomClass: string = '';

    public init(id: string, params: DynamicDictionary, template: Function): void {
        this.id = id;
        this.dom = $('#' + id);
        if (this.dom.length > 0) {
            const target: JQuery = $(params.event.originalTarget || params.event.target);
            const left: number = target.closest('.ag-cell').position().left;
            const top: number = target.closest('.ag-cell').parent().position().top;
            const topOffset: number = params.node.rowHeight;
            this.tooltipBaseClass = 'grid-' + id + '-custom-tooltip';
            this.tooltipCustomClass = 'grid-' + id + '-custom-tooltip-' + params.rowIndex + '-' + params.colDef.field;
            const style: string = 'left:' + left + 'px;top:' + (top + topOffset) + 'px;visibility:hidden;';
            const templateObject: CellRendererTemplate = template();
            let value: string = params.value as string;
            let templateHtml: string = templateObject.template;
            if (templateObject.valueObjectFieldName) {
                value = params.value[templateObject.valueObjectFieldName];
            }
            if (templateObject.valueObjectFields) {
                templateObject.valueObjectFields.forEach((field: ValueObjectField): void => {
                    if (params.value[field.name].enabled) {
                        value = params.value[field.name].value;
                        templateHtml = field.template;
                    }
                });
            }
            this.templateHtml =
                '<div class="grid-custom-tooltip ' +
                this.tooltipBaseClass +
                ' ' +
                this.tooltipCustomClass +
                '" style="' +
                style +
                '" ' +
                '>' +
                useStrings().replaceAll(String(templateHtml), '@', value) +
                '</div>';
            this.show();
            this.alignTooltipContent(left);
        }
    }

    public show(): void {
        if (this.dom) {
            if (this.dom.has('.' + this.tooltipCustomClass).length > 0) {
                TooltipRenderer.clear(this.id);
            } else {
                TooltipRenderer.clear(this.id);
                this.dom.append(this.templateHtml);
            }
        }
    }

    public static clear(id: string): void {
        const tooltipBaseClass: string = 'grid-' + id + '-custom-tooltip';
        $('#' + id)
            .children('.' + tooltipBaseClass)
            .remove();
    }

    private alignTooltipContent(left: number): void {
        const children: JQuery = this.dom!.children('.' + this.tooltipBaseClass).children();
        if (children.length > 0) {
            const gridWidth: number | undefined = this.dom!.outerWidth();
            const width: number | undefined = $(children[0]).outerWidth();
            let marginLeft: number = parseInt($(children[0]).css('margin-left'), 10);
            if (isNaN(marginLeft)) {
                marginLeft = 0;
            }
            if (width && left && gridWidth) {
                if (left + width > gridWidth) {
                    const leftOffset: number = left + (gridWidth - (left + width)) - marginLeft;
                    this.dom!.children('.' + this.tooltipCustomClass).css('left', leftOffset + 'px');
                }
            }
        }
        this.dom!.children('.' + this.tooltipBaseClass).css('visibility', 'visible');
    }
}
