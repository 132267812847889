import CoveredPopup from '@/interfaces/covered.popup.interface';
import FormField from '@/assets/libraries/form/form-field';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import CoveredPopupDeductible from '@/interfaces/covered.popup.deductible.interface';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import { computed, markRaw, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import { Subscription } from 'rxjs';
import VehicleProduct from '@/Enums/VehicleProductEnum';
import OneBaseService from '@/services/OneBaseService';
import { useDefine } from '@/Composables/Define';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import OneBase from '@/interfaces/OneBaseInterface';
import { AutoForms } from '@/pages/Auto/Composables/AutoForms';
import PolicyPeriods from '@/Enums/PolicyPeriodsEnum';
import PolicyPeriodStrings from '@/Enums/PolicyPeriodStringsEnum';
import AutoFormsService from '@/pages/Auto/Services/AutoFormsService';
import { AutoStorage } from '@/pages/Auto/Composables/AutoStorage';
import AppCountry from '@/assets/libraries/app/app-country';
import { useFormatter } from '@/Composables/Formatter';
import PolicySums from '@/interfaces/policy.sums.interface';
import PolicySum from '@/interfaces/policy.sum.interface';
import AutoStorageService from '@/pages/Auto/Services/AutoStorageService';
import User from '@/services/user.service';
import PriceType from '@/Enums/PriceTypeEnum';
import VueModel from '@/services/vue.model.service';
import Error from '@/services/error.service';
import Popup from '@/services/popup.service';
import UserStorage from '@/services/user.storage.service';
import SettingsService from '@/services/settings.service';
import CacheHash from '@/interfaces/cache.hash.interface';
import { useTransforms } from '@/Composables/Transforms';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { StepsAssemblerParams } from '@/Composables/StepsAssembler';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useGenerator } from '@/Composables/Generator';
import { useTranslate } from '@/Composables/Translate';
import PaymentType from '@/Enums/PaymentTypeEnum';
import StepAssemblerService from '@/pages/Auto/Services/StepAssemblerService';

export const useAuto = (): Auto => {
    const { isSet, arrayContains } = useDefine();
    const { numberToFixedNoRounding } = useFormatter();
    const { translate } = useTranslate();
    const request: AxiosParams = useAxios();

    const autoStorage: AutoStorage = AutoStorageService.getInstance();
    const stepAssembler: StepsAssemblerParams = StepAssemblerService.getInstance();
    const forms: AutoForms = AutoFormsService.getInstance();
    const error: Error = Error.getInstance();
    const popup: Popup = Popup.getInstance();
    const userStorage: UserStorage = UserStorage.getInstance();
    const settings: SettingsService = SettingsService.getInstance();

    const NoDiscountInDatabase: number = -1;
    const PercentMultiplier: number = 100;
    const ConsentsGlue: string = ':';
    const dateFormat: string = 'MMM DD, YYYY';
    const dateFormatLT: string = 'YYYY-MM-DD';
    const AdditionalRisksStep: number = 3;
    const facility: string = 'auto-policy';

    let onStorageReadySubscription: Subscription | null = null;
    let onAfterFormRestoredSubscription: Subscription | null = null;

    const coveredPopup: UnwrapNestedRefs<CoveredPopup> = reactive(
        new (class implements CoveredPopup {
            public default: FormField<LimitedVariant> = markRaw(new FormField('covered-default'));
            public contentID: string = '';
            public deductibles: CoveredPopupDeductible = new (class implements CoveredPopupDeductible {
                public btar_vehicle_damage_total_loss_deductible: string = '';
                public btar_damage_deductible: string = '';
                public btar_glass_deductible: string = '';
                public btar_theft_deductible: string = '';
                public btar_total_loss_deductible: string = '';
                public btar_deductible: string = '';
                public theft_and_total_loss_deductible: string = '';
            })();
            public descriptionMtpl: string = '';
            public descriptionCasco: string = '';
            public insuredRisksMTPL: StringDictionary = new (class implements StringDictionary {
                [key: string]: string;
            })();
            public insuredRisksCASCO: StringDictionary = new (class implements StringDictionary {
                [key: string]: string;
            })();
            public title: string = '';
        })(),
    );
    const vehicleProduct: Ref<string> = ref('');
    const additionalOptions: Ref<DynamicDictionary> = ref({});
    const CurrentStepAuto: Ref<number> = ref(0);
    const initialStepUrl: Ref<string> = ref('');
    const fetchMtplDiscountFromJson: Ref<boolean> = ref(false);
    const fetchCascoDiscountFromJson: Ref<boolean> = ref(false);
    const vehicleProductAdditionalRisksMapping: Ref<DynamicDictionary[]> = ref([]);
    const mtplDiscountPercent: Ref<number> = ref(0);
    const cascoDiscountPercent: Ref<number> = ref(0);
    const additionalOptionsCombined: Ref<string> = ref('');
    const additionalOptionsPricesIncludesProductPrice: Ref<boolean> = ref(false);
    const productSums: UnwrapNestedRefs<PolicySums> = reactive(
        new (class implements PolicySums {
            public authenticated: PolicySum = new (class implements PolicySum {
                public bundleDiscountSum: number = 0;
                public bundleFullSum: number = 0;
                public bundlePercent: number = 0;
                public cascoDiscountSum: number = 0;
                public cascoFullSum: number = 0;
                public cascoMonthlySum: number = 0;
                public finalDiscountSum: number = 0;
                public finalFullSum: number = 0;
                public mtplDiscountSum: number = 0;
                public mtplFullSum: number = 0;
                public bundleSubscriptionFullPrice: number = 0;
                public bundleSubscriptionPrice: number = 0;
                public mtplSubscriptionBundlePrice: number = 0;
                public cascoSubscriptionBundlePrice: number = 0;
                public mtplSubscriptionFullPrice: number = 0;
                public mtplSubscriptionPrice: number = 0;
                public cascoSubscriptionFullPrice: number = 0;
                public cascoSubscriptionPrice: number = 0;
                public cascoBundlePrice: number = 0;
                public mtplBundlePrice: number = 0;
            })();
            public guest: PolicySum = new (class implements PolicySum {
                public bundleDiscountSum: number = 0;
                public bundleFullSum: number = 0;
                public bundlePercent: number = 0;
                public cascoDiscountSum: number = 0;
                public cascoFullSum: number = 0;
                public cascoMonthlySum: number = 0;
                public finalDiscountSum: number = 0;
                public finalFullSum: number = 0;
                public mtplDiscountSum: number = 0;
                public mtplFullSum: number = 0;
                public bundleSubscriptionFullPrice: number = 0;
                public bundleSubscriptionPrice: number = 0;
                public mtplSubscriptionBundlePrice: number = 0;
                public cascoSubscriptionBundlePrice: number = 0;
                public mtplSubscriptionFullPrice: number = 0;
                public mtplSubscriptionPrice: number = 0;
                public cascoSubscriptionFullPrice: number = 0;
                public cascoSubscriptionPrice: number = 0;
                public cascoBundlePrice: number = 0;
                public mtplBundlePrice: number = 0;
            })();
        })(),
    );
    const onlyJsonDiscountsIsUsed: Ref<boolean> = ref(false);
    const step: Ref<number> = ref(0);
    const hasDeductibleOptions: Ref<boolean> = ref(false);
    const fetchSumsIsLocked: Ref<boolean> = ref(false);
    const cachedRequests: Ref<CacheHash> = ref({});
    const isApprovalCaseNecessary: Ref<boolean> = ref(false);

    const isSubscriptionByForm: Ref<boolean> = computed(() => {
        return forms.form.exists('paymentType') ? forms.form.field('paymentType').value === 'subscription' : false;
    });

    const isSubscription: Ref<boolean> = computed(() => {
        let newIsSubscription: boolean | null = userStorage.storageData.isSubscription;
        if (!isSet(newIsSubscription)) {
            newIsSubscription = userStorage.stepStorageData.isSubscription;
        }

        return isSet(isSubscription) ? (newIsSubscription as boolean) || isSubscriptionByForm.value : false;
    });

    const paymentTypeIsMonthly: Ref<boolean> = computed(() => {
        const field: FormField = forms.form.field('paymentOption');
        let paymentType: string;
        if (isSubscription.value) {
            paymentType = 'monthly';
        } else if (CurrentStepAuto.value === 3) {
            paymentType = userStorage.stepStorageData.paymentType === PaymentType.Fixed ? 'fix' : 'monthly';
        } else {
            paymentType = OneBaseService.getInstance().cmsFields.paymentOption.enabled
                ? field.value
                : OneBaseService.getInstance().cmsFields.paymentOption.value;
        }

        return paymentType === 'monthly';
    });

    const isFixedPaymentType: Ref<boolean> = computed(() => {
        return !paymentTypeIsMonthly.value;
    });

    const coveredPopupIsVisible: Ref<boolean> = computed(() => {
        let hasDeductibles: boolean = false;
        let hasRisks: boolean = true;
        if (storageIsReady.value) {
            hasDeductibles = isSet(userStorage.storageData.attributes.vehicleDeductibles);
            hasRisks =
                productHasPreSelectedRisks(VehicleProduct.Mtpl) || productHasPreSelectedRisks(VehicleProduct.Casco);
        }

        return hasDeductibles || hasRisks;
    });

    const storageIsReady: Ref<boolean> = computed(() => {
        return !userStorage.storageIsEmpty;
    });

    const hasAdditionalRisksSelected: Ref<boolean> = computed(() => {
        return additionalOptionsCombined.value !== '' || hasSpecificDeductiblesLv.value;
    });

    const productIsBundle: Ref<boolean> = computed(() => {
        let result: boolean;
        const summaryStep: number = 4;
        if (CurrentStepAuto.value === summaryStep) {
            result = userStorage.stepStorageData ? userStorage.stepStorageData.vehicleProduct : '';
        } else {
            result = vehicleProduct.value === VehicleProduct.Bundle;
        }

        return result;
    });

    const policyPeriodString: Ref<string> = computed(() => {
        const policyStep: number = 2;
        const additionalStep: number = 3;
        let result: string = '';
        if (CurrentStepAuto.value === policyStep) {
            result =
                vehicleProduct.value === VehicleProduct.Casco
                    ? PolicyPeriodStrings.OneYearPayment
                    : forms.form.field('policyPeriod').value;
        } else if (CurrentStepAuto.value === additionalStep) {
            result = userStorage.stepStorageData.policyPeriodString;
        }

        return result;
    });

    const hasSpecificDeductiblesLv: Ref<boolean> = computed(() => {
        let result: boolean = false;
        const country: AppCountry = new AppCountry();
        if (
            country.ready &&
            country.isLV() &&
            vehicleProduct.value !== VehicleProduct.Mtpl &&
            isSet(userStorage.storageData.attributes) &&
            isSet(userStorage.storageData.attributes.vehicleDeductibleOptions) &&
            isSet(userStorage.storageData.attributes.vehicleDeductibleOptions.damageDeductibleIc) &&
            Object.keys(userStorage.storageData.attributes.vehicleDeductibleOptions.damageDeductibleIc).length > 0
        ) {
            if (
                isSet(
                    userStorage.storageData.attributes.vehicleDeductibleOptions.damageDeductibleIc[territoryCode.value],
                )
            ) {
                result =
                    userStorage.storageData.attributes.vehicleDeductibleOptions.damageDeductibleIc[territoryCode.value]
                        .length > 0;
            }
        }

        return result;
    });

    const territoryCode: Ref<string> = computed(() => {
        const DefaultLvTerritory: string = 'EU';

        return useDefine().isSet(userStorage.storageData.attributes) &&
            useDefine().isSet(userStorage.storageData.attributes.territoryCode)
            ? userStorage.storageData.attributes.territoryCode
            : DefaultLvTerritory;
    });

    const isAdditionalRisksStep: Ref<boolean> = computed(() => {
        return step.value === AdditionalRisksStep;
    });

    const bundleRiskIsVisible = (additionalOptionId: string): boolean => {
        let result: boolean = false;
        if (CurrentStepAuto.value === 1) {
            const risk: DynamicDictionary = mappedRisk(additionalOptionId);
            if (isSet(risk.risk_ic)) {
                result = risk.bundle === '1';
            }
        } else {
            const risk: DynamicDictionary = mappedRisk(additionalOptionId);
            result = isSet(risk.risk_ic) ? risk.bundle === '1' : false;
        }

        return result;
    };

    const mappedRisk = (riskId: string): DynamicDictionary => {
        let result: DynamicDictionary = {};
        vehicleProductAdditionalRisksMapping.value.forEach((risk: DynamicDictionary): void => {
            if (risk.risk_ic === riskId) {
                if (risk.mtpl_period !== '' && risk.mtpl === '1') {
                    const period: string = transformedMtplPeriod();
                    if (risk.mtpl_period === period) {
                        result = risk;
                    }
                } else {
                    result = risk;
                }
            }
        });

        return result;
    };

    const transformedMtplPeriod = (): string => {
        const selectedPeriod: string = transformedPeriod();

        return selectedPeriod === '12' ? '1YEAR' : selectedPeriod + 'MON';
    };

    const transformedPeriod = (): string => {
        let result: string = PolicyPeriods.OneYearPayment;
        if (isFixedPaymentType.value) {
            result = PolicyPeriods.memberValueByName(String(policyPeriodString.value));
        }

        return result;
    };

    const prepare = (onUserStorageReady: Function, onAfterFormRestored: Function): void => {
        const btaBase: OneBase = OneBaseService.getInstance();
        btaBase.setStep(CurrentStepAuto.value);
        btaBase.setFacility(facility);
        btaBase.setStorageUsage(true);
        setStep(CurrentStepAuto.value);
        onStorageReadySubscription = btaBase.userStorage.onStorageDataIsReady.subscribe((): void => {
            onUserStorageReady();
            if (onStorageReadySubscription) {
                onStorageReadySubscription.unsubscribe();
            }
        });
        onAfterFormRestoredSubscription = btaBase.userStorage.onFormStorageDataIsReady.subscribe(
            (value: number): void => {
                onAfterFormRestored(value);
                if (onAfterFormRestoredSubscription) {
                    onAfterFormRestoredSubscription.unsubscribe();
                }
            },
        );
    };

    const productHasPreSelectedRisks = (product: string): boolean => {
        let risksCount: number = 0;
        const newVehicleProduct: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[product];
        if (isSet(newVehicleProduct.risks) && isSet(newVehicleProduct.risks.isSelected)) {
            for (const o in newVehicleProduct.risks.isSelected) {
                const risk: string = String(newVehicleProduct.risks.isSelected[o]);
                if (productHasPreSelectedRisk(product, risk)) {
                    risksCount++;
                }
            }
        }

        return risksCount > 0;
    };

    const productHasPreSelectedRisk = (product: string, risk: string): boolean => {
        let result: boolean = false;
        const newVehicleProduct: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[product];
        if (isSet(newVehicleProduct.risks) && isSet(newVehicleProduct.risks.isSelected)) {
            result = arrayContains(newVehicleProduct.risks.isSelected, risk);
        }

        return result;
    };

    const appendBasicRisksToCoveredPopup = (product: string): void => {
        const newVehicleProduct: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[product];
        if (isSet(newVehicleProduct.risks) && isSet(newVehicleProduct.risks.isSelected)) {
            for (const o in newVehicleProduct.risks.isSelected) {
                const risk: string = String(newVehicleProduct.risks.isSelected[o]);
                if (productHasPreSelectedRisk(product, risk)) {
                    coveredPopup['insuredRisks' + product][risk] = risk + '_DESCRIPTION';
                    appendRiskChildren(product, risk);
                }
            }
        }
    };

    const appendRiskChildren = (product: string, riskParent: string): void => {
        const childRisks: DynamicDictionary = userStorage.storageData.attributes.childRisks;
        if (isSet(childRisks[riskParent])) {
            for (const o in childRisks[riskParent]) {
                const risk: string = childRisks[riskParent][o];
                coveredPopup['insuredRisks' + product][risk] = risk + '_DESCRIPTION';
            }
        }
    };

    const applyDiscount = (product: string, value: number): void => {
        switch (vehicleProduct.value) {
            case VehicleProduct.Mtpl:
                mtplDiscountPercent.value = value;
                break;
            case VehicleProduct.Casco:
                cascoDiscountPercent.value = value;
                break;
            default:
        }
    };

    const productPriceForSelectedPeriod = (newVehicleProduct: string): number => {
        const prices: DynamicDictionary = autoStorage.storageDataPrices(newVehicleProduct, selectedDeductible());
        let productPrice: number = 0;
        if (prices) {
            if (isSubscription.value) {
                productPrice = prices.subscriptionPayment;
            } else {
                productPrice = isFixedPaymentType.value
                    ? prices.fixedPeriodPayment[policyPeriodString.value]
                    : prices.monthlyPayment;
            }
        }
        return productPrice;
    };

    const priceOfAdditionalItem = (vehicleProductId: string, additionalOptionId: string): string => {
        let period: string = policyPeriodString.value;
        if (
            new AppCountry().isLT() &&
            vehicleProduct.value === VehicleProduct.Bundle &&
            vehicleProductId === VehicleProduct.Casco
        ) {
            /**
             * @kvecvagars hardcoded for LT
             */
            period = PolicyPeriodStrings.OneYearPayment;
        }
        const risks: DynamicDictionary = new AppCountry().isLT()
            ? autoStorage.additionalStorageRisks()
            : autoStorage.storageDataPreCalculatedAdditionalPricesByUserType(
                  vehicleProductId,
                  OneBaseService.getInstance().user.isLogged(),
              );
        const requestedRisk: DynamicDictionary = risks.find(
            (risk: DynamicDictionary) => risk.id === additionalOptionId && risk.period === period,
        );
        const requestedRiskPrice: number = requestedRisk ? requestedRisk.price : 0;
        const productPrice: number = productPriceForSelectedPeriod(vehicleProductId);
        const fixedDigitsCount: number = 2;
        return additionalOptionsPricesIncludesProductPrice.value
            ? numberToFixedNoRounding(requestedRiskPrice - productPrice, fixedDigitsCount)
            : numberToFixedNoRounding(requestedRiskPrice, fixedDigitsCount);
    };

    const setStep = (newStep: number): void => {
        step.value = newStep;
    };

    const applyDeductibles = (hasDeductibles: boolean): void => {
        hasDeductibleOptions.value = hasDeductibles;
    };

    const calculatePrices = (): void => {
        applyDiscountPercents();
        applyDiscountPrices();
        applyCascoMonthlyPrices();
        if (isAdditionalRisksStep.value) {
            applyAdditionalOptionsPrices();
        }
        applySubscriptionPrices();
        calculateSubscriptionsFullPrice();
        calculateFullSums();
        if (bundleExists()) {
            calculateBundlePercent();
        }
    };

    const resetCascoBundlePrice = (): void => {
        const additionalPricesBundle = autoStoragePricesByUserType(
            VehicleProduct.Bundle,
            User.getInstance().isLogged(),
        );
        if (additionalPricesBundle) {
            const prices: DynamicDictionary = additionalPricesBundle[VehicleProduct.Casco.toLowerCase()];
            if (prices) {
                const payment: number = additionalOptionsPriceForProduct(
                    VehicleProduct.Casco.toLowerCase(),
                    prices,
                    prices,
                );
                if (User.getInstance().isLogged()) {
                    (productSums.authenticated as DynamicDictionary)[
                        VehicleProduct.Casco.toLowerCase() + 'BundlePrice'
                    ] = payment;
                } else {
                    (productSums.guest as DynamicDictionary)[VehicleProduct.Casco.toLowerCase() + 'BundlePrice'] =
                        payment;
                }
            }
        }
    };

    const mtplAdditionalOptionsPrice = (logged: boolean): number => {
        const additionalRiskPrices: DynamicDictionary = autoStorage.storageDataPreCalculatedAdditionalPricesByUserType(
            vehicleProduct.value,
            logged,
        );
        const mtplAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Mtpl);

        return additionalRiskPrices
            .filter(
                (additionalRisk: DynamicDictionary) =>
                    mtplAdditionalRisks.includes(additionalRisk.id) && additionalRisk.period === policyPeriod(),
            )
            .map((additionalRisk: DynamicDictionary) => additionalRisk.price)
            .reduce((totalPrice: number, price: number) => totalPrice + price, 0);
    };

    const bundlePercent = (): number => {
        return User.getInstance().isLogged()
            ? productSums.authenticated.bundlePercent
            : productSums.guest.bundlePercent;
    };

    const applyAdditionalOptionsPrice = (): DynamicDictionary => {
        const result: DynamicDictionary = {};
        const guestPrices: number = mtplAdditionalOptionsPreCalculatedPrice(false);
        const authenticatedPrices: number = mtplAdditionalOptionsPreCalculatedPrice(true);
        const newPolicyPeriod: string = policyPeriod();
        result.attributes = {};
        result.attributes.vehicleProducts = {};
        result.attributes.vehicleProducts[vehicleProduct.value] = {};
        result.attributes.vehicleProducts[vehicleProduct.value].guestPrices = {};
        result.attributes.vehicleProducts[vehicleProduct.value].authenticatedPrices = {};
        if (isSubscription.value) {
            result.attributes.vehicleProducts[vehicleProduct.value].guestPrices.subscriptionPayment = guestPrices;
            result.attributes.vehicleProducts[vehicleProduct.value].authenticatedPrices.subscriptionPayment =
                authenticatedPrices;
        } else {
            if (isFixedPaymentType.value) {
                result.attributes.vehicleProducts[vehicleProduct.value].guestPrices.fixedPeriodPayment = {};
                result.attributes.vehicleProducts[vehicleProduct.value].authenticatedPrices.fixedPeriodPayment = {};
                result.attributes.vehicleProducts[vehicleProduct.value].guestPrices.fixedPeriodPayment[
                    newPolicyPeriod
                ] = guestPrices;
                result.attributes.vehicleProducts[vehicleProduct.value].authenticatedPrices.fixedPeriodPayment[
                    newPolicyPeriod
                ] = authenticatedPrices;
            } else {
                result.attributes.vehicleProducts[vehicleProduct.value].guestPrices.monthlyPayment = guestPrices;
                result.attributes.vehicleProducts[vehicleProduct.value].authenticatedPrices.monthlyPayment =
                    authenticatedPrices;
            }
        }

        return result;
    };

    const selectedAdditionalOptionsForProduct = (vehicleProductId: string): string[] => {
        const product: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[vehicleProductId];
        const risks = product.risks ? [...(product.risks.isSelected || []), ...(product.risks.notSelected || [])] : [];

        return Object.keys(additionalOptions.value)
            .filter((option) => risks.indexOf(option) !== -1)
            .sort();
    };

    const combinedAdditionalOptionsForBundle = (vehicleProductId: string): string => {
        const result: string[] = [];
        const product: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[vehicleProductId];
        const risks = product.risks ? [...(product.risks.isSelected || []), ...(product.risks.notSelected || [])] : [];
        const productRisks: string[] = Object.keys(additionalOptions.value)
            .filter((option) => risks.indexOf(option) !== -1)
            .sort();
        productRisks.forEach((value: string) => {
            if (bundleRiskIsVisible(value)) {
                result.push(value);
            }
        });

        return result.join(',');
    };

    const policyPeriod = (): string => {
        let result: string;
        if (step.value === AdditionalRisksStep) {
            result = userStorage.stepStorageData.policyPeriodString;
        } else {
            result = forms.form.field('policyPeriod').value;
        }

        return result;
    };

    const applyDiscountPercents = (): void => {
        const priceType: string = User.getInstance().isLogged() ? PriceType.AuthenticatedPrices : PriceType.GuestPrices;
        const policyPeriodValue = policyPeriod();
        const discountPeriod: string = transformPolicyPeriodToDiscountPeriod(
            policyPeriodValue || PolicyPeriodStrings.OneYearPayment,
        );
        const vehicleProducts: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts;
        const mtplExists: boolean = productExists(VehicleProduct.Mtpl);
        const cascoExists: boolean = productExists(VehicleProduct.Casco);
        const prices: DynamicDictionary = {
            mtplPrices: mtplExists ? vehicleProducts.MTPL[priceType].discountPercents : {},
            cascoPrices: cascoExists ? cascoPrice(vehicleProducts)[priceType].discountPercents : {},
        };
        const hasMtplJsonDiscounts: boolean = mtplExists && isSet(vehicleProducts.MTPL[priceType].discountPercents);
        const hasCascoJsonDiscounts: boolean =
            cascoExists && isSet(cascoPrice(vehicleProducts)[priceType].discountPercents);
        const hasMtplDbDiscounts: boolean = !fetchMtplDiscountFromJson.value;
        const hasCascoDbDiscounts: boolean = !fetchCascoDiscountFromJson.value;
        let useMtplJsonDiscounts: boolean =
            (!hasMtplJsonDiscounts && !hasMtplDbDiscounts) || (hasMtplJsonDiscounts && !hasMtplDbDiscounts);
        let useCascoJsonDiscounts: boolean =
            (!hasCascoJsonDiscounts && !hasCascoDbDiscounts) || (hasCascoJsonDiscounts && !hasCascoDbDiscounts);
        if (useMtplJsonDiscounts && mtplExists && !new AppCountry().isLV()) {
            applyDiscountForProduct(VehicleProduct.Mtpl, prices, discountPeriod);
        } else if (!mtplExists) {
            useMtplJsonDiscounts = true;
        }
        if (useCascoJsonDiscounts && cascoExists && !new AppCountry().isLV()) {
            applyDiscountForProduct(VehicleProduct.Casco, prices, discountPeriod);
        } else if (!cascoExists) {
            useCascoJsonDiscounts = true;
        }
        onlyJsonDiscountsIsUsed.value = useMtplJsonDiscounts && useCascoJsonDiscounts;
    };

    const cascoPrice = (products: DynamicDictionary): DynamicDictionary => {
        let result = {};
        if (new AppCountry().isLV()) {
            const deductiblePremiums = products.CASCO.damageDeductiblePremiums;
            if (isSet(selectedDeductible()) && selectedDeductibleNotEmpty()) {
                result = products.CASCO.damageDeductiblePremiums[selectedDeductible()];
            } else {
                result = products.CASCO.damageDeductiblePremiums[Object.keys(deductiblePremiums)[0]];
            }
        } else {
            result = products.CASCO;
        }

        return result;
    };

    const productExists = (product: string): boolean => {
        return !isSet(userStorage.storageData.attributes.vehicleProducts[product].error);
    };

    const bundleExists = (): boolean => {
        return isSet(userStorage.storageData.attributes.vehicleProducts.BUNDLE);
    };

    const applyDiscountForProduct = (product: string, prices: DynamicDictionary, discountPeriod: string): void => {
        const discountProduct: string = String(product).toLowerCase();
        if (isSet(prices[discountProduct + 'Prices'])) {
            if (isFixedPaymentType.value) {
                applyDiscount(discountProduct, fixedPeriodDiscount(prices[discountProduct + 'Prices'], discountPeriod));
            } else {
                applyDiscount(discountProduct, prices[discountProduct + 'Prices'][discountPeriod]);
            }
        }
    };

    const transformPolicyPeriodToDiscountPeriod = (newPolicyPeriod: string): string => {
        return String(newPolicyPeriod).replace('Payment', 'Discount');
    };

    const applyDiscountPrices = (): void => {
        if (isFixedPaymentType.value) {
            const policyPeriodValue: string = policyPeriod();
            const newPolicyPeriod: string = policyPeriodValue || PolicyPeriodStrings.OneYearPayment;
            productSums.guest.mtplDiscountSum = fixedPeriodPaymentForProductAndPeriod(
                VehicleProduct.Mtpl,
                newPolicyPeriod,
                false,
            );
            productSums.guest.bundleDiscountSum = fixedPeriodPaymentBundleForProductAndPeriod(
                VehicleProduct.Bundle,
                newPolicyPeriod,
                false,
            );
            productSums.guest.cascoDiscountSum = fixedPeriodPaymentForProductAndPeriod(
                VehicleProduct.Casco,
                newPolicyPeriod,
                false,
            );
            productSums.authenticated.mtplDiscountSum = fixedPeriodPaymentForProductAndPeriod(
                VehicleProduct.Mtpl,
                newPolicyPeriod,
                true,
            );
            productSums.authenticated.bundleDiscountSum = fixedPeriodPaymentBundleForProductAndPeriod(
                VehicleProduct.Bundle,
                newPolicyPeriod,
                true,
            );
            productSums.authenticated.cascoDiscountSum = fixedPeriodPaymentForProductAndPeriod(
                VehicleProduct.Casco,
                newPolicyPeriod,
                true,
            );
            if (storageVehicleProductExists(VehicleProduct.Bundle)) {
                productSums.guest.cascoBundlePrice = fixedPeriodPaymentBundleForProductAndPeriod(
                    VehicleProduct.Casco,
                    newPolicyPeriod,
                    false,
                );
                productSums.guest.mtplBundlePrice = fixedPeriodPaymentBundleForProductAndPeriod(
                    VehicleProduct.Mtpl,
                    newPolicyPeriod,
                    false,
                );
                productSums.authenticated.cascoBundlePrice = fixedPeriodPaymentBundleForProductAndPeriod(
                    VehicleProduct.Casco,
                    newPolicyPeriod,
                    true,
                );
                productSums.authenticated.mtplBundlePrice = fixedPeriodPaymentBundleForProductAndPeriod(
                    VehicleProduct.Mtpl,
                    newPolicyPeriod,
                    true,
                );
            }
        } else {
            productSums.guest.mtplDiscountSum = monthlyPaymentForProduct(VehicleProduct.Mtpl, false);
            productSums.authenticated.mtplDiscountSum = monthlyPaymentForProduct(VehicleProduct.Mtpl, true);
            if (storageVehicleProductExists(VehicleProduct.Bundle)) {
                productSums.guest.bundleDiscountSum = monthlyPaymentForProduct(VehicleProduct.Bundle, false);
                productSums.authenticated.bundleDiscountSum = monthlyPaymentForProduct(VehicleProduct.Bundle, true);
                productSums.guest.cascoBundlePrice = bundleMonthlyPaymentForProduct(VehicleProduct.Casco, false);
                productSums.guest.mtplBundlePrice = bundleMonthlyPaymentForProduct(VehicleProduct.Mtpl, false);
                productSums.authenticated.cascoBundlePrice = bundleMonthlyPaymentForProduct(VehicleProduct.Casco, true);
                productSums.authenticated.mtplBundlePrice = bundleMonthlyPaymentForProduct(VehicleProduct.Mtpl, true);
            } else {
                productSums.guest.bundleDiscountSum = 0;
                productSums.authenticated.bundleDiscountSum = 0;
            }
            productSums.guest.cascoDiscountSum = monthlyPaymentForProduct(VehicleProduct.Casco, false);
            productSums.authenticated.cascoDiscountSum = monthlyPaymentForProduct(VehicleProduct.Casco, true);
        }
    };

    const applyAdditionalOptionsPrices = (): void => {
        if (!userStorage.additionalOptionsStorageIsEmpty) {
            if (isAdditionalRisksStep.value) {
                applySelectedRisksPricesForProduct(VehicleProduct.Mtpl);
                applySelectedRisksPricesForProduct(VehicleProduct.Casco);
                applySelectedRisksPricesForBundle();
            }
            if (!productIsBundle.value) {
                applyAdditionalOptionsBundlePrice(VehicleProduct.Mtpl, false);
                applyAdditionalOptionsBundlePrice(VehicleProduct.Mtpl, true);
            }
            applyAdditionalOptionsBundlePrice(VehicleProduct.Casco, false);
            applyAdditionalOptionsBundlePrice(VehicleProduct.Casco, true);
            applyAdditionalOptionsCascoMonthlyPrice(VehicleProduct.Casco, false);
            applyAdditionalOptionsCascoMonthlyPrice(VehicleProduct.Casco, true);
        }
    };

    const applyCascoMonthlyPrices = (): void => {
        productSums.guest.cascoMonthlySum = monthlyPaymentForProduct(VehicleProduct.Casco, false);
        productSums.authenticated.cascoMonthlySum = monthlyPaymentForProduct(VehicleProduct.Casco, true);
    };

    const calculateFullSums = (): void => {
        calculateFullSumsByUserType(productSums.guest);
        calculateFullSumsByUserType(productSums.authenticated);
    };

    const calculateFullSumsByUserType = (sumType: PolicySum): void => {
        sumType.mtplFullSum =
            (sumType.mtplDiscountSum / (PercentMultiplier - mtplDiscountPercent.value)) * PercentMultiplier;
        sumType.cascoFullSum =
            (sumType.cascoDiscountSum / (PercentMultiplier - cascoDiscountPercent.value)) * PercentMultiplier;
        sumType.bundleFullSum = calculateBundleFullSum(sumType);
    };

    const calculateBundleFullSum = (sumType: PolicySum): number => {
        let result;
        if (!new AppCountry().isEE()) {
            result =
                Number.parseFloat(sumType.mtplFullSum.toFixed(2)) + Number.parseFloat(sumType.cascoFullSum.toFixed(2));
        } else {
            result = sumType.mtplFullSum + sumType.cascoFullSum;
        }
        return result;
    };

    const calculateSubscriptionsFullPrice = (): void => {
        const sumType: PolicySum = User.getInstance().isLogged() ? productSums.authenticated : productSums.guest;
        sumType.mtplSubscriptionFullPrice =
            (sumType.mtplSubscriptionPrice / (PercentMultiplier - mtplDiscountPercent.value)) * PercentMultiplier;
        sumType.cascoSubscriptionFullPrice =
            (sumType.cascoSubscriptionPrice / (PercentMultiplier - cascoDiscountPercent.value)) * PercentMultiplier;
        sumType.bundleSubscriptionFullPrice = sumType.mtplSubscriptionFullPrice + sumType.cascoSubscriptionFullPrice;
        sumType.bundleSubscriptionFullPrice =
            Number.parseFloat(sumType.mtplSubscriptionFullPrice.toFixed(2)) +
            Number.parseFloat(sumType.cascoSubscriptionFullPrice.toFixed(2));
    };

    const calculateBundlePercent = (): void => {
        if (isSubscription.value) {
            const sumType: PolicySum = User.getInstance().isLogged() ? productSums.authenticated : productSums.guest;
            productSums.guest.bundlePercent =
                (1 - sumType.bundleSubscriptionPrice / sumType.bundleSubscriptionFullPrice) * PercentMultiplier;
            productSums.authenticated.bundlePercent =
                (1 - sumType.bundleSubscriptionPrice / sumType.bundleSubscriptionFullPrice) * PercentMultiplier;
        } else {
            if (onlyJsonDiscountsIsUsed.value && !new AppCountry().isLV()) {
                const policyPeriodValue = policyPeriod();
                const discountPeriod: string = transformPolicyPeriodToDiscountPeriod(
                    policyPeriodValue || 'oneYearDiscount',
                );
                const vehicleProducts: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts;
                const productPrices: DynamicDictionary = User.getInstance().isLogged()
                    ? vehicleProducts.BUNDLE[PriceType.AuthenticatedPrices].discountPercents
                    : vehicleProducts.BUNDLE[PriceType.GuestPrices].discountPercents;
                if (isSet(productPrices)) {
                    if (isFixedPaymentType.value) {
                        if (User.getInstance().isLogged()) {
                            productSums.authenticated.bundlePercent = fixedPeriodDiscount(
                                productPrices,
                                discountPeriod,
                            );
                        } else {
                            productSums.guest.bundlePercent = fixedPeriodDiscount(productPrices, discountPeriod);
                        }
                    } else {
                        if (User.getInstance().isLogged()) {
                            productSums.authenticated.bundlePercent = productPrices[discountPeriod];
                        } else {
                            productSums.guest.bundlePercent = productPrices[discountPeriod];
                        }
                    }
                }
            } else {
                productSums.guest.bundlePercent = bundleDiscount(productSums.guest);
                productSums.authenticated.bundlePercent = bundleDiscount(productSums.authenticated);
            }
        }
    };

    const bundleDiscount = (sumType: PolicySum): number => {
        return (1 - sumType.bundleDiscountSum / (sumType.mtplFullSum + sumType.cascoFullSum)) * PercentMultiplier;
    };

    const applySubscriptionPrices = (): void => {
        if (isSubscription.value) {
            productSums.authenticated.mtplSubscriptionPrice = subscriptionPrices(VehicleProduct.Mtpl, true);
            productSums.authenticated.cascoSubscriptionPrice = subscriptionPrices(VehicleProduct.Casco, true);
            productSums.guest.mtplSubscriptionPrice = subscriptionPrices(VehicleProduct.Mtpl, false);
            productSums.guest.cascoSubscriptionPrice = subscriptionPrices(VehicleProduct.Casco, false);
            if (storageVehicleProductExists(VehicleProduct.Bundle)) {
                productSums.authenticated.bundleSubscriptionPrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Bundle,
                    true,
                );
                productSums.authenticated.mtplSubscriptionBundlePrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Mtpl,
                    true,
                );
                productSums.authenticated.cascoSubscriptionBundlePrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Casco,
                    true,
                );
                productSums.guest.bundleSubscriptionPrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Bundle,
                    false,
                );
                productSums.guest.mtplSubscriptionBundlePrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Mtpl,
                    false,
                );
                productSums.guest.cascoSubscriptionBundlePrice = bundleSubscriptionPriceForProduct(
                    VehicleProduct.Casco,
                    false,
                );
            }
        }
    };

    const applySelectedRisksPricesForProduct = (product: string): void => {
        if (foundEnabledRisksForProduct(product) || hasDeductibleOptions.value) {
            applyAdditionalOptionsPriceForProduct(product, true);
            applyAdditionalOptionsPriceForProduct(product, false);
        }
    };

    const applySelectedRisksPricesForBundle = (): void => {
        if (
            foundEnabledRisksForProduct(VehicleProduct.Mtpl) ||
            foundEnabledRisksForProduct(VehicleProduct.Casco) ||
            hasDeductibleOptions.value
        ) {
            applyAdditionalOptionsPriceForProduct(VehicleProduct.Bundle, true);
            applyAdditionalOptionsPriceForProduct(VehicleProduct.Bundle, false);
        }
    };

    const foundEnabledRisksForProduct = (product: string): boolean => {
        let result: boolean = false;
        const newVehicleProduct: DynamicDictionary = userStorage.storageData.attributes.vehicleProducts[product];
        let risks: string[] = [];
        if (isSet(newVehicleProduct.risks) && isSet(newVehicleProduct.risks.notSelected)) {
            risks = newVehicleProduct.risks.notSelected;
        }
        risks.forEach((value: string): void => {
            if (isSet(additionalOptions.value[value])) {
                result = true;
            }
        });

        return result;
    };

    const applyAdditionalOptionsPriceForProduct = (product: string, logged: boolean): void => {
        const prices: DynamicDictionary = autoStoragePricesByUserType(product, logged);
        const hasAdditionalOptions: boolean = additionalOptionsExistForProduct(product, logged);
        if (prices && hasAdditionalOptions) {
            const payment: number = new AppCountry().isLT()
                ? combinedRisksPayment(prices)
                : additionalOptionsPaymentForProduct(product, prices, logged);
            const productPriceName: string =
                String(product).toLowerCase() + (isSubscription.value ? 'SubscriptionPrice' : 'DiscountSum');
            if (payment > 0) {
                if (logged) {
                    (productSums.authenticated as DynamicDictionary)[productPriceName] = payment;
                } else {
                    (productSums.guest as DynamicDictionary)[productPriceName] = payment;
                }
            }
        }
    };

    const combinedRisksPayment = (prices: DynamicDictionary): number => {
        let result: number = 0;
        const selectedRisks: string[] = additionalOptionsCombined.value.split(',');
        const additionalRisks: DynamicDictionary[] = autoStorage.additionalStorageRisks();
        selectedRisks.forEach((riskIc: string): void => {
            const requestedRisk: DynamicDictionary | undefined = additionalRisks.find(
                (risk: DynamicDictionary): boolean =>
                    risk.id === riskIc &&
                    (risk.period === policyPeriodString.value || risk.period === PolicyPeriodStrings.OneYearPayment),
            );
            if (requestedRisk) {
                result += requestedRisk.price;
            }
        });
        if (prices) {
            if (isFixedPaymentType.value) {
                if (prices.fixedPeriodPayment) {
                    result += prices.fixedPeriodPayment[policyPeriodString.value];
                }
            } else {
                if (prices.monthlyPayment) {
                    result += prices.monthlyPayment[policyPeriodString.value];
                }
            }
        }

        return result;
    };

    const additionalOptionsPaymentForProduct = (
        product: string,
        prices: DynamicDictionary,
        logged: boolean,
    ): number => {
        const additionalPrices: DynamicDictionary = new AppCountry().isLT()
            ? prices
            : autoAdditionalStoragePricesByUserType(product, logged);

        return additionalOptionsPriceForProduct(product, prices, additionalPrices);
    };

    const additionalOptionsPriceForProduct = (
        product: string,
        prices: DynamicDictionary,
        additionalPrices: DynamicDictionary,
    ): number => {
        let payment: number;
        const productType: string = product.toUpperCase();
        const defaultSum: number = 0;
        const newPolicyPeriod: string = policyPeriod();
        if (isSubscription.value) {
            payment = isSet(additionalPrices.subscriptionPayment) ? additionalPrices.subscriptionPayment : defaultSum;
        } else {
            if (isFixedPaymentType.value) {
                const isCascoSpecificPeriod: boolean = productType === VehicleProduct.Casco;
                const period: string = isCascoSpecificPeriod ? PolicyPeriodStrings.OneYearPayment : newPolicyPeriod;
                payment = isSet(additionalPrices.fixedPeriodPayment)
                    ? additionalPrices.fixedPeriodPayment[period]
                    : defaultSum;
                if (productType === VehicleProduct.Bundle && !additionalPrices.mtpl) {
                    payment += prices.mtpl.fixedPeriodPayment[newPolicyPeriod];
                } else if (productType === VehicleProduct.Mtpl && new AppCountry().isLV()) {
                    const optionPricesIncludedInProductPrice: boolean =
                        VueModel.modelValueByName('additionalOptionsPricesIncludesProductPrice') === 'true';
                    if (optionPricesIncludedInProductPrice) {
                        payment += prices.fixedPeriodPayment[period];
                    }
                }
            } else {
                payment = isSet(additionalPrices.monthlyPayment) ? additionalPrices.monthlyPayment : defaultSum;
                if (productType === VehicleProduct.Bundle) {
                    payment += prices.mtpl.monthlyPayment;
                }
            }
        }

        return payment;
    };

    const additionalOptionsCascoMonthlyPayment = (additionalPrices: DynamicDictionary, product: string): number => {
        let payment: number = 0;
        if (VehicleProduct.Casco === product) {
            payment = isSet(additionalPrices.monthlyPayment) ? additionalPrices.monthlyPayment : 0;
        }

        return payment;
    };

    const additionalOptionsExistForProduct = (product: string, logged: boolean): boolean => {
        const additionalPrices: DynamicDictionary = autoAdditionalStoragePricesByUserType(product, logged);

        return !Array.isArray(additionalPrices);
    };

    const mtplAdditionalOptionsPreCalculatedPrice = (logged: boolean): number => {
        const newPolicyPeriod: string = policyPeriod();
        const additionalRiskPrices: DynamicDictionary = autoStorage.storageDataPreCalculatedAdditionalPricesByUserType(
            vehicleProduct.value,
            logged,
        );
        const productPrice: number = productPriceForSelectedPeriod(vehicleProduct.value);
        const mtplAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Mtpl);
        const totalAdditionalRisksPrice: number = mtplAdditionalRisks
            .map((optionId) => {
                const additionalOptionRiskPriceKey: string | undefined = Object.keys(additionalRiskPrices).find(
                    (key) => {
                        return (
                            additionalRiskPrices[key].id === optionId &&
                            additionalRiskPrices[key].period === newPolicyPeriod
                        );
                    },
                );
                return additionalOptionRiskPriceKey ? additionalRiskPrices[additionalOptionRiskPriceKey] : null;
            })
            .filter((option: DynamicDictionary) => !!option)
            .reduce(
                (totalPrice: number, option: DynamicDictionary) =>
                    totalPrice + parseFloat(priceOfAdditionalItem(vehicleProduct.value, option.id)),
                0,
            );

        return productPrice + totalAdditionalRisksPrice;
    };

    const bundleMonthlyPaymentForProduct = (product: string, logged: boolean): number => {
        const pricesBundle: DynamicDictionary = autoStoragePricesByUserType(VehicleProduct.Bundle, logged);
        const prices: DynamicDictionary = pricesBundle ? pricesBundle[product.toLocaleLowerCase()] : null;

        return prices ? prices.monthlyPayment : 0;
    };

    const bundleSubscriptionPriceForProduct = (product: string, logged: boolean): number => {
        let price: DynamicDictionary | null = null;
        let pricesBundle: DynamicDictionary = autoStoragePricesByUserType(VehicleProduct.Bundle, logged);
        if (isAdditionalRisksStep.value && hasAdditionalRisksSelected.value) {
            const additionalDataForBundle: DynamicDictionary = autoAdditionalStoragePricesByUserType(
                VehicleProduct.Bundle,
                logged,
            );
            if (additionalDataForBundle.length !== 0) {
                pricesBundle = additionalDataForBundle;
            }
        }
        if (pricesBundle) {
            price = product === VehicleProduct.Bundle ? pricesBundle : pricesBundle[product.toLowerCase()];
        }

        return price ? price.subscriptionPayment : 0;
    };

    const monthlyPaymentForProduct = (product: string, logged: boolean): number => {
        const prices: DynamicDictionary = autoStoragePricesByUserType(product, logged);

        return prices ? prices.monthlyPayment : 0;
    };

    const fixedPeriodPaymentBundleForProductAndPeriod = (product: string, period: string, logged: boolean): number => {
        const pricesBundle: DynamicDictionary = autoStoragePricesByUserType(VehicleProduct.Bundle, logged);
        let prices: DynamicDictionary | null;
        if (product === VehicleProduct.Bundle) {
            prices = pricesBundle ? pricesBundle : null;
        } else {
            prices = pricesBundle ? pricesBundle[product.toLocaleLowerCase()] : null;
        }

        return prices ? fixedPeriodPaymentForPeriod(prices, period) : 0;
    };

    const storageVehicleProductExists = (product: string): boolean => {
        return (
            isSet(userStorage.storageData.attributes.vehicleProducts[product]) &&
            !isSet(userStorage.storageData.attributes.vehicleProducts[product].error)
        );
    };

    const fixedPeriodPaymentForProductAndPeriod = (product: string, period: string, logged: boolean): number => {
        const prices: DynamicDictionary = autoStoragePricesByUserType(product, logged);

        return fixedPeriodPaymentForPeriod(prices, period);
    };

    const fixedPeriodPaymentForPeriod = (
        prices: DynamicDictionary,
        period: string,
        isDiscount: boolean = false,
    ): number => {
        let result: number = 0;
        const priceTypeFull: string = 'fixedPeriod' + (isDiscount ? 'Discount' : 'Payment');
        if (isSet(prices) && isSet(prices[priceTypeFull]) && isSet(prices[priceTypeFull][period])) {
            result = prices[priceTypeFull][period];
        } else if (isSet(prices) && isSet(prices[priceTypeFull])) {
            result = Object.values(prices[priceTypeFull])[0] as number;
        }

        return result;
    };

    const fixedPeriodDiscount = (prices: DynamicDictionary, period: string): number => {
        let result: number = 0;
        if (isSet(prices) && isSet(prices.fixedPeriodDiscount) && isSet(prices.fixedPeriodDiscount[period])) {
            result = prices.fixedPeriodDiscount[period];
        } else if (isSet(prices) && isSet(prices.fixedPeriodDiscount)) {
            result = prices.fixedPeriodDiscount[0];
        }

        return result;
    };

    const subscriptionPrices = (product: string, logged: boolean): number => {
        let result: number = 0;
        const pricesForProduct: DynamicDictionary = autoStoragePricesByUserType(product, logged);
        if (
            isSet(pricesForProduct) &&
            useDefine().objectMembersCount(pricesForProduct) > 0 &&
            isSet(pricesForProduct.subscriptionPayment)
        ) {
            result = pricesForProduct.subscriptionPayment;
            if (isAdditionalRisksStep.value && hasAdditionalRisksSelected.value) {
                const additionalDataForProduct: DynamicDictionary = autoAdditionalStoragePricesByUserType(
                    product,
                    logged,
                );
                if (additionalDataForProduct.length !== 0) {
                    result = additionalDataForProduct.subscriptionPayment;
                }
            }
        }

        return result;
    };

    const applyAdditionalOptionsBundlePrice = (product: string, logged: boolean): void => {
        const additionalPricesBundle = autoStorage.storageDataAdditionalPricesByUserType(
            VehicleProduct.Bundle,
            logged,
            selectedDeductible(),
        );
        if (additionalPricesBundle) {
            const prices: DynamicDictionary = additionalPricesBundle[product.toLowerCase()];
            if (isSet(prices)) {
                const payment: number = new AppCountry().isLT()
                    ? combinedRisksPayment(prices)
                    : additionalOptionsPriceForProduct(product, prices, prices);
                if (logged) {
                    (productSums.authenticated as DynamicDictionary)[product.toLowerCase() + 'BundlePrice'] = payment;
                } else {
                    (productSums.guest as DynamicDictionary)[product.toLowerCase() + 'BundlePrice'] = payment;
                }
            }
        }
    };

    const applyAdditionalOptionsCascoMonthlyPrice = (product: string, logged: boolean): void => {
        const additionalPricesCasco: DynamicDictionary = autoStorage.storageDataAdditionalPricesByUserType(
            product,
            logged,
            selectedDeductible(),
        );
        if (additionalPricesCasco) {
            const payment: number = new AppCountry().isLT()
                ? combinedRisksPayment(additionalPricesCasco)
                : additionalOptionsCascoMonthlyPayment(additionalPricesCasco, product);
            if (logged) {
                (productSums.authenticated as DynamicDictionary)[product.toLowerCase() + 'MonthlySum'] = payment;
            } else {
                (productSums.guest as DynamicDictionary)[product.toLowerCase() + 'MonthlySum'] = payment;
            }
        }
    };

    const fetchSumWithAdditionalOptions = (
        requestParams: DynamicDictionary,
        reloadMtplSums: boolean,
    ): Promise<void> => {
        stepAssembler.addParams(assembleRequestWithAdditionalOptions(requestParams, reloadMtplSums));
        stepAssembler.addCustomParams(requestParams);
        const params: DynamicDictionary = stepAssembler.assembledParams();
        const hash: string = buildHashForSumWithAdditionalOptions(params);
        if (isSet(cachedRequests.value[hash])) {
            applyIsApprovalCaseNecessary(cachedRequests.value[hash]);
            autoStorage.applyAdditionalOptionsSum(cachedRequests.value[hash]);

            return new Promise((resolve: (value: void | PromiseLike<void>) => void) => resolve());
        }

        return fetchSums(params)
            .then((responseBody: DynamicDictionary) => {
                applyIsApprovalCaseNecessary(responseBody.data);
                autoStorage.applyAdditionalOptionsSum(responseBody.data);
                cachedRequests.value[hash] = useTransforms().deepClonedObjectWithoutVueReactivity(
                    userStorage.storageAdditionalData,
                );
                OneBaseService.getInstance().stepper.disableAhead(CurrentStepAuto.value - 1);
            })
            .catch((reason: DynamicDictionary) => {
                if (String(reason) !== 'locked') {
                    error.show(ErrorType.Error, 'fetchSumWithAdditionalOptions', reason);
                }
                throw reason;
            });
    };

    const fetchRiskPrices = (risks: DynamicDictionary): Promise<void> => {
        stepAssembler.addParams(assembleRequestWithRisks(risks));
        const params: DynamicDictionary = stepAssembler.assembledParams();

        return fetchSums(params)
            .then((responseBody: DynamicDictionary): void => {
                autoStorage.applyAdditionalOptionsSum(responseBody.data);
            })
            .catch((reason: DynamicDictionary): void => {
                if (String(reason) !== 'locked') {
                    error.show(ErrorType.Error, 'fetchRiskPrices', reason);
                }
                throw reason;
            });
    };

    const fetchSumsForOwner = (requestParams: DynamicDictionary): Promise<void> => {
        stepAssembler.addParams(assembleRequestForFetchingSums());
        stepAssembler.addCustomParams(requestParams);
        const params: DynamicDictionary = stepAssembler.assembledParams();
        const hash: string = buildHashForFetchSums(params);
        if (isSet(cachedRequests.value[hash])) {
            return new Promise((resolve) => {
                applyIsApprovalCaseNecessary(cachedRequests.value[hash]);
                userStorage.applyStorageData(cachedRequests.value[hash]);
                resolve();
            });
        } else {
            return fetchSums(params)
                .then((responseBody: DynamicDictionary) => {
                    cachedRequests.value[hash] = responseBody.data;
                    applyIsApprovalCaseNecessary(responseBody.data);
                    userStorage.applyStorageData(responseBody.data);
                    OneBaseService.getInstance().stepper.disableAhead(CurrentStepAuto.value - 1);
                })
                .catch((reason: DynamicDictionary) => {
                    if (String(reason) !== 'locked') {
                        error.show(ErrorType.Error, 'fetchSumsForOwner', reason);
                    }
                });
        }
    };

    const fetchIsApprovalCaseNecessary = (hasSecurityDevices: boolean): Promise<void> => {
        if (cachedRequestsHasNoRecords()) {
            createDefaultApprovalCaseNecessary();
        }
        isApprovalCaseNecessary.value = !hasSecurityDevices;
        const params: DynamicDictionary = assembleRequestForIsApprovalCaseNecessary(hasSecurityDevices);
        const hash: string = buildHashForIsApprovalCaseNecessary(params);
        if (isSet(cachedRequests.value[hash])) {
            return new Promise((resolve: (value: void | PromiseLike<void>) => void) => {
                applyIsApprovalCaseNecessary(cachedRequests.value[hash]);
                resolve();
            });
        } else {
            return fetchSums(params)
                .then((responseBody: DynamicDictionary) => {
                    applyIsApprovalCaseNecessary(responseBody.data);
                    cachedRequests.value[hash] = responseBody.data;
                })
                .catch((reason: DynamicDictionary) => {
                    if (String(reason) !== 'locked') {
                        error.show(ErrorType.Error, 'fetchIsApprovalCaseNecessary', reason);
                    }
                });
        }
    };

    const assembleRequestForFetchingSums = (): DynamicDictionary => {
        const storage: DynamicDictionary = userStorage.storageData;
        const stepStorage: DynamicDictionary = userStorage.stepStorageData;
        stepAssembler.addParam('id', storage.id);
        stepAssembler.addParam('facility', facility);
        if (isSet(storage.attributes.special)) {
            stepAssembler.addCustomParam('special', storage.attributes.special);
        }
        if (isSet(storage.attributes.vehicleUsage)) {
            stepAssembler.addCustomParam('specialUseType', storage.attributes.vehicleUsage);
        }
        if (isSet(storage.attributes.keylessCar) && !isApprovalCaseNecessary.value) {
            stepAssembler.addCustomParam('keylessCar', storage.attributes.keylessCar);
        }
        if (isSet(storage.attributes.alarmGroup) && !isApprovalCaseNecessary.value) {
            stepAssembler.addCustomParam('alarmGroup', storage.attributes.alarmGroup);
        }
        if (isSet(storage.attributes.sumInsured)) {
            stepAssembler.addCustomParam('sumInsured', storage.attributes.sumInsured);
        }
        if (isSet(storage.attributes.leasing)) {
            stepAssembler.addCustomParam('leasing', storage.attributes.leasing ? 'true' : 'false');
        }
        if (isSet(storage.attributes.vehicleType)) {
            stepAssembler.addCustomParam('vehicleType', storage.attributes.vehicleType);
        }
        if (isSet(storage.attributes.seatCount)) {
            stepAssembler.addCustomParam('seatCount', storage.attributes.seatCount);
        }
        if (isSet(storage.attributes.enginePower)) {
            stepAssembler.addCustomParam('enginePower', storage.attributes.enginePower);
        }
        if (isSet(storage.attributes.engineCapacity)) {
            stepAssembler.addCustomParam('engineCapacity', storage.attributes.engineCapacity);
        }
        if (isSet(storage.attributes.fullWeight)) {
            stepAssembler.addCustomParam('fullWeight', storage.attributes.fullWeight);
        }
        if (isSet(storage.attributes.yearOfManufacture)) {
            stepAssembler.addCustomParam('yearOfManufacture', storage.attributes.yearOfManufacture);
        }
        if (isSet(storage.attributes.administrativeCode)) {
            stepAssembler.addCustomParam('administrativeCode', storage.attributes.administrativeCode);
        }
        if (isSet(stepStorage.ownerPersonCode)) {
            stepAssembler.addCustomParam('ownerPersonCode', stepStorage.ownerPersonCode);
        }
        if (isSet(stepStorage.ownerRegistrationCode)) {
            stepAssembler.addCustomParam('ownerRegistrationCode', stepStorage.ownerRegistrationCode);
        }
        if (isSet(stepStorage.disabilityCertificateNumber)) {
            stepAssembler.addCustomParam('disabilityCertificateNumber', stepStorage.disabilityCertificateNumber);
        }
        if (isSet(stepStorage.vehicleRegCertificateNumber)) {
            stepAssembler.addCustomParam('vehicleRegCertificateNumber', stepStorage.vehicleRegCertificateNumber);
        }
        if (isSet(stepStorage.territoryCode)) {
            stepAssembler.addCustomParam('territory', stepStorage.territoryCode);
        }
        if (isSet(stepStorage.youngestDriver)) {
            stepAssembler.addCustomParam('youngestDriverAge', stepStorage.youngestDriver);
        }
        if (isSet(stepStorage.drivingExperience)) {
            stepAssembler.addCustomParam('drivingExperience', stepStorage.drivingExperience);
        }
        if (isSet(stepStorage.paymentType) && !new AppCountry().isLT()) {
            stepAssembler.addCustomParam('paymentCountIc', stepStorage.paymentType);
        }
        if (isSet(stepStorage.drivingExperienceLessThanThreeYears)) {
            stepAssembler.addCustomParam(
                'experienceLessThanThreeYears',
                stepStorage.drivingExperienceLessThanThreeYears ? 'Y' : 'N',
            );
        }
        if (isSet(stepStorage.ownerDrivingLicenceDate)) {
            stepAssembler.addCustomParam('ownerDrivingLicenceDate', stepStorage.ownerDrivingLicenceDate);
        }
        if (isSet(stepStorage.noDriversLicence)) {
            stepAssembler.addCustomParam('noDriversLicence', stepStorage.noDriversLicence);
        }
        if (isSet(stepStorage.special)) {
            stepAssembler.addCustomParam('special', stepStorage.special);
        }
        if (isSet(stepStorage.specialUseType)) {
            stepAssembler.addCustomParam('specialUseType', stepStorage.specialUseType);
        }
        Object.keys(storage.attributes.vehicleDeductibles || {}).forEach((deductible: string) => {
            if (!(deductible === 'damageDeductibleIc' && isLatviaCasco())) {
                stepAssembler.addCustomParam(deductible, storage.attributes.vehicleDeductibles[deductible]);
            }
        });

        return stepAssembler.assembledParams();
    };

    const assembleRequestForIsApprovalCaseNecessary = (hasSecurityDevices?: boolean): DynamicDictionary => {
        const params: DynamicDictionary = assembleRequestForFetchingSums();
        const withoutSecurityDevices: boolean = hasSecurityDevices !== undefined ? !hasSecurityDevices : false;
        const withoutSecurityDevicesFacility: string = `${facility}-without-security-devices`;
        const withoutSecurityDevicesParam: string = 'withoutSecurityDevices';
        stepAssembler.addParams(params);
        stepAssembler.addParam('isAdditional', 'true');
        stepAssembler.addParam('filter[vehicleProduct]', vehicleProductFilter());
        if (hasSecurityDevices) {
            stepAssembler.removeCustomParam(withoutSecurityDevicesParam);
            if (params['facility'] === withoutSecurityDevicesFacility) {
                stepAssembler.addParam('facility', facility);
            }
        }
        if (withoutSecurityDevices) {
            stepAssembler.addParam('facility', withoutSecurityDevicesFacility);
            stepAssembler.addCustomParam(withoutSecurityDevicesParam, withoutSecurityDevices);
        }

        return stepAssembler.assembledParams();
    };

    const assembleRequestWithAdditionalOptions = (
        requestParams: DynamicDictionary,
        reloadMtplSums: boolean,
    ): DynamicDictionary => {
        const params: DynamicDictionary = assembleRequestForFetchingSums();
        stepAssembler.addParams(params);
        stepAssembler.addParam('isAdditional', 'true');
        stepAssembler.addParam('filter[vehicleProduct]', vehicleProductFilter());
        if (isAdditionalRisksStep.value) {
            const mtplAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Mtpl);
            const cascoAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Casco);
            const mtplBundleInsuredRisks: string = combinedAdditionalOptionsForBundle(VehicleProduct.Mtpl);
            const cascoBundleInsuredRisks: string = combinedAdditionalOptionsForBundle(VehicleProduct.Casco);
            if ([...mtplAdditionalRisks, ...cascoAdditionalRisks].includes('TL_CHANGEABLE_TRAILER_COVER')) {
                stepAssembler.addCustomParam('defaultSumInsured', settings.value('CASCO_DEFAULT_INSURANCE_SUM'));
            }
            if (mtplAdditionalRisks.length > 0 && reloadMtplSums) {
                stepAssembler.addParam('filter[mtplInsuredRisks]', mtplAdditionalRisks.join(','));
            }
            if (cascoAdditionalRisks.length > 0) {
                stepAssembler.addParam('filter[cascoInsuredRisks]', cascoAdditionalRisks.join(','));
            }
            if (mtplBundleInsuredRisks) {
                stepAssembler.addParam('filter[mtplBundleInsuredRisks]', mtplBundleInsuredRisks);
            }
            if (cascoBundleInsuredRisks) {
                stepAssembler.addParam('filter[cascoBundleInsuredRisks]', cascoBundleInsuredRisks);
            }
            if (hasUserSelectedDeductibleSums(requestParams)) {
                if (!isLatviaCasco()) {
                    stepAssembler.addParam('filter[damageDeductibleIc]', requestParams.damageDeductibleIc);
                }
                stepAssembler.addParam('filter[glassDeductibleIc]', requestParams.glassDeductibleIc);
            }
        }

        return stepAssembler.assembledParams();
    };

    const assembleRequestWithRisks = (risks: DynamicDictionary): DynamicDictionary => {
        const params: DynamicDictionary = assembleRequestForFetchingSums();
        stepAssembler.addParams(params);
        stepAssembler.addParam('isAdditional', 'true');
        stepAssembler.addParam('filter[vehicleProduct]', vehicleProductFilter());
        stepAssembler.addParam('filter[mtplInsuredRisks]', productRisksCombined(risks, VehicleProduct.Mtpl));
        stepAssembler.addParam('filter[cascoInsuredRisks]', productRisksCombined(risks, VehicleProduct.Casco));

        return stepAssembler.assembledParams();
    };

    const vehicleProductFilter = (): string => {
        //INFO: Business requirement: always request additional options for bundle product except for Latvia Casco
        const filteredVehicleProduct: string = isLatviaCasco() ? VehicleProduct.Casco : VehicleProduct.Bundle;

        return filteredVehicleProduct.toLowerCase();
    };

    const productRisksCombined = (risks: DynamicDictionary, vehicleProductId: string): string => {
        return risks[vehicleProductId].map((risk: DynamicDictionary): string => risk.title).join(',');
    };

    const hasUserSelectedDeductibleSums = (requestParams: DynamicDictionary): boolean => {
        return isSet(requestParams.damageDeductibleIc) && isSet(requestParams.glassDeductibleIc);
    };

    const fetchSums = (params: DynamicDictionary): Promise<DynamicDictionary> => {
        return new Promise((resolve: (value: DynamicDictionary) => void, reject: (reason?: LimitedVariant) => void) => {
            if (!fetchSumsIsLocked.value) {
                fetchSumsIsLocked.value = true;
                request
                    .get(Url.Ajax.autoApi, { params })
                    .then((value: AxiosResponse<DynamicDictionary>) => {
                        if (useDefine().validResponse(value)) {
                            resolve(value.data.data.body ?? value.data.data);
                        } else if (storageDataIsMissed(value)) {
                            popup
                                .applyErrorTitle(translate('btar_error'))
                                .applyErrorDetails(translate('btar_error_common'))
                                .applyCallbackModel('redirectToInitialStep', this);
                            PopupService.getInstance().show(new OnePopup().withType().error);
                        } else {
                            Error.log(ErrorType.Error, 'fetchSums::validResponse::false', value);
                            reject(translate('vehicle_product_can_not_be_issued_online'));
                        }
                    })
                    .catch((reason: DynamicDictionary) => {
                        Error.log(ErrorType.Error, 'fetchSums::catch', reason);
                        reject(translate('vehicle_product_can_not_be_issued_online'));
                    })
                    .finally(() => {
                        const isErrorPopup: boolean = PopupService.getInstance().isErrorPopup;
                        if (!isErrorPopup) {
                            PopupService.getInstance().hide();
                        }
                        fetchSumsIsLocked.value = false;
                    });
            }
        });
    };

    const storageDataIsMissed = (value: DynamicDictionary): boolean => {
        return isSet(value.data.errors.title) && value.data.errors.title === 'CAN_NOT_FIND_STORAGE_RECORD';
    };

    const redirectToInitialStep = (): void => {
        const url: string = isSet(initialStepUrl.value) ? initialStepUrl.value : '/';
        OneBaseService.getInstance().navigate(url);
    };

    const applyIsApprovalCaseNecessary = (options: DynamicDictionary | null = null): void => {
        if (options !== null && isSet(options.attributes)) {
            [
                {
                    name: VehicleProduct.Casco,
                    product: options.attributes.vehicleProducts.CASCO,
                },
                {
                    name: VehicleProduct.Bundle,
                    product: options.attributes.vehicleProducts.BUNDLE,
                },
            ].map((product: { name: string; product: DynamicDictionary }): void => {
                resetIsApprovalCaseNecessary(product.name);
                setIsApprovalCaseNecessary(product.product, product.name);
            });
        }
    };

    const resetIsApprovalCaseNecessary = (product: string): void => {
        userStorage.applyIsApprovalCaseNecessaryStorage(product);
        userStorage.applyIsApprovalCaseNecessaryByPaymentPeriodStorage(product);
    };

    const setIsApprovalCaseNecessary = (product: DynamicDictionary, productName: string): void => {
        if (isSet(product) && product.isApprovalCaseNecessary) {
            userStorage.applyIsApprovalCaseNecessaryStorage(productName, product.isApprovalCaseNecessary);
        }
        if (isSet(product) && product.isApprovalCaseNecessaryByPaymentPeriod) {
            userStorage.applyIsApprovalCaseNecessaryByPaymentPeriodStorage(
                productName,
                product.isApprovalCaseNecessaryByPaymentPeriod,
            );
        }
    };

    const buildHashForSumWithAdditionalOptions = (requestParams: DynamicDictionary): string => {
        const params: DynamicDictionary = {
            youngestDriver: requestParams['customParam[youngestDriver]'] || '',
            drivingExperience: requestParams['customParam[drivingExperience]'] || '',
            experienceLessThanThreeYears: requestParams['customParam[experienceLessThanThreeYears]'] || '',
            territory: requestParams['customParam[territory]'] || '',
        };
        if (isAdditionalRisksStep.value) {
            const mtplAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Mtpl);
            const cascoAdditionalRisks: string[] = selectedAdditionalOptionsForProduct(VehicleProduct.Casco);
            const mtplInsuredBundleRisks: string = combinedAdditionalOptionsForBundle(VehicleProduct.Mtpl);
            const cascoInsuredBundleRisks: string = combinedAdditionalOptionsForBundle(VehicleProduct.Casco);
            params['mtpl'] = mtplAdditionalRisks.join(',');
            params['mtpl-bundle'] = mtplInsuredBundleRisks;
            params['casco'] = cascoAdditionalRisks.join(',');
            params['casco-bundle'] = cascoInsuredBundleRisks;
            if (isSet(requestParams['customParam[damageDeductibleIc]'])) {
                if (!isLatviaCasco()) {
                    params['damageDeductibleIc'] = requestParams['customParam[damageDeductibleIc]'];
                }
                params['glassDeductibleIc'] = requestParams['customParam[glassDeductibleIc]'];
            }
        }

        return 'additional' + '-' + useGenerator().generateHash(params);
    };

    const buildHashForFetchSums = (params: DynamicDictionary): string => {
        return 'fetch-sums-' + useGenerator().generateHash(params);
    };

    const buildHashForIsApprovalCaseNecessary = (params: DynamicDictionary): string => {
        return 'approval-case-necessary-' + useGenerator().generateHash(params);
    };

    const createDefaultApprovalCaseNecessary = (): void => {
        isApprovalCaseNecessary.value = false;
        const params: DynamicDictionary = assembleRequestForIsApprovalCaseNecessary();
        const hash: string = buildHashForIsApprovalCaseNecessary(params);
        cachedRequests.value[hash] = useTransforms().deepClonedObjectWithoutVueReactivity(userStorage.storageData);
    };

    const cachedRequestsHasNoRecords = (): boolean => {
        return Object.keys(cachedRequests.value).length === 0;
    };

    const autoStoragePricesByUserType = (product: string, logged: boolean): DynamicDictionary => {
        return autoStorage.storageDataPricesByUserType(product, logged, selectedDeductible());
    };

    const autoAdditionalStoragePricesByUserType = (product: string, logged: boolean): DynamicDictionary => {
        return autoStorage.storageDataAdditionalPricesByUserType(product, logged, selectedDeductible());
    };

    const selectedDeductible = (): number => {
        return forms.form.field('damageDeductibles').value;
    };

    const selectedDeductibleNotEmpty = (): boolean => {
        return forms.form.field('damageDeductibles').value != '';
    };

    const isLatviaCasco = (): boolean => {
        return new AppCountry().isLV() && VehicleProduct.Casco === vehicleProduct.value;
    };

    return {
        ...forms,
        ...{
            forms,
            CurrentStepAuto,
            NoDiscountInDatabase,
            vehicleProduct,
            fetchSumsIsLocked,
            cachedRequests,
            ConsentsGlue,
            isApprovalCaseNecessary,
            additionalOptions,
            onStorageReadySubscription,
            onAfterFormRestoredSubscription,
            coveredPopupIsVisible,
            coveredPopup,
            storageIsReady,
            isSubscription,
            initialStepUrl,
            dateFormat,
            dateFormatLT,
            fetchMtplDiscountFromJson,
            fetchCascoDiscountFromJson,
            isAdditionalRisksStep,
            hasAdditionalRisksSelected,
            isSubscriptionByForm,
            paymentTypeIsMonthly,
            isFixedPaymentType,
            policyPeriodString,
            mtplDiscountPercent,
            cascoDiscountPercent,
            productIsBundle,
            additionalOptionsCombined,
            additionalOptionsPricesIncludesProductPrice,
            hasSpecificDeductiblesLv,
            productSums,
            PercentMultiplier,
            AdditionalRisksStep,
            onlyJsonDiscountsIsUsed,
            step,
            hasDeductibleOptions,
            vehicleProductAdditionalRisksMapping,
            prepare,
            productHasPreSelectedRisks,
            productHasPreSelectedRisk,
            appendBasicRisksToCoveredPopup,
            appendRiskChildren,
            bundleRiskIsVisible,
            mappedRisk,
            transformedMtplPeriod,
            transformedPeriod,
            applyDiscount,
            productPriceForSelectedPeriod,
            priceOfAdditionalItem,
            setStep,
            applyDeductibles,
            calculatePrices,
            resetCascoBundlePrice,
            mtplAdditionalOptionsPrice,
            bundlePercent,
            applyAdditionalOptionsPrice,
            policyPeriod,
            applyDiscountPercents,
            productExists,
            bundleExists,
            applyDiscountForProduct,
            applyDiscountPrices,
            transformPolicyPeriodToDiscountPeriod,
            applyAdditionalOptionsPrices,
            applyCascoMonthlyPrices,
            calculateFullSums,
            calculateFullSumsByUserType,
            calculateBundleFullSum,
            calculateSubscriptionsFullPrice,
            calculateBundlePercent,
            selectedAdditionalOptionsForProduct,
            combinedAdditionalOptionsForBundle,
            bundleDiscount,
            applySubscriptionPrices,
            applySelectedRisksPricesForProduct,
            applySelectedRisksPricesForBundle,
            foundEnabledRisksForProduct,
            applyAdditionalOptionsPriceForProduct,
            combinedRisksPayment,
            additionalOptionsPaymentForProduct,
            additionalOptionsPriceForProduct,
            additionalOptionsCascoMonthlyPayment,
            additionalOptionsExistForProduct,
            bundleMonthlyPaymentForProduct,
            bundleSubscriptionPriceForProduct,
            monthlyPaymentForProduct,
            fixedPeriodPaymentBundleForProductAndPeriod,
            storageVehicleProductExists,
            fixedPeriodPaymentForProductAndPeriod,
            fixedPeriodPaymentForPeriod,
            fixedPeriodDiscount,
            subscriptionPrices,
            applyAdditionalOptionsBundlePrice,
            applyAdditionalOptionsCascoMonthlyPrice,
            fetchSumWithAdditionalOptions,
            fetchRiskPrices,
            fetchSumsForOwner,
            fetchIsApprovalCaseNecessary,
            assembleRequestForFetchingSums,
            assembleRequestForIsApprovalCaseNecessary,
            assembleRequestWithAdditionalOptions,
            assembleRequestWithRisks,
            productRisksCombined,
            hasUserSelectedDeductibleSums,
            fetchSums,
            storageDataIsMissed,
            redirectToInitialStep,
            applyIsApprovalCaseNecessary,
            buildHashForSumWithAdditionalOptions,
            buildHashForFetchSums,
            buildHashForIsApprovalCaseNecessary,
            createDefaultApprovalCaseNecessary,
            cachedRequestsHasNoRecords,
        },
    };
};

export interface Auto {
    forms: AutoForms;
    CurrentStepAuto: Ref<number>;
    NoDiscountInDatabase: number;
    vehicleProduct: Ref<string>;
    isSubscription: Ref<boolean>;
    dateFormat: string;
    dateFormatLT: string;
    ConsentsGlue: string;
    additionalOptions: Ref<DynamicDictionary>;
    onStorageReadySubscription: Subscription | null;
    onAfterFormRestoredSubscription: Subscription | null;
    coveredPopupIsVisible: Ref<boolean>;
    coveredPopup: UnwrapNestedRefs<CoveredPopup>;
    storageIsReady: Ref<boolean>;
    initialStepUrl: Ref<string>;
    prepare: (onUserStorageReady: Function, onAfterFormRestored: Function) => void;
    productHasPreSelectedRisks: (product: string) => boolean;
    productHasPreSelectedRisk: (product: string, risk: string) => boolean;
    appendBasicRisksToCoveredPopup: (product: string) => void;
    appendRiskChildren: (product: string, riskParent: string) => void;
    fetchMtplDiscountFromJson: Ref<boolean>;
    fetchCascoDiscountFromJson: Ref<boolean>;
    isSubscriptionByForm: Ref<boolean>;
    paymentTypeIsMonthly: Ref<boolean>;
    isFixedPaymentType: Ref<boolean>;
    bundleRiskIsVisible: (additionalOptionId: string) => boolean;
    mappedRisk: (riskId: string) => DynamicDictionary;
    transformedMtplPeriod: () => string;
    transformedPeriod: () => string;
    policyPeriodString: Ref<string>;
    mtplDiscountPercent: Ref<number>;
    cascoDiscountPercent: Ref<number>;
    applyDiscount: (product: string, value: number) => void;
    productIsBundle: Ref<boolean>;
    additionalOptionsCombined: Ref<string>;
    productPriceForSelectedPeriod: (newVehicleProduct: string) => number;
    additionalOptionsPricesIncludesProductPrice: Ref<boolean>;
    priceOfAdditionalItem: (vehicleProductId: string, additionalOptionId: string) => string;
    hasSpecificDeductiblesLv: Ref<boolean>;
    productSums: UnwrapNestedRefs<PolicySums>;
    PercentMultiplier: number;
    AdditionalRisksStep: number;
    onlyJsonDiscountsIsUsed: Ref<boolean>;
    step: Ref<number>;
    hasDeductibleOptions: Ref<boolean>;
    setStep: (newStep: number) => void;
    applyDeductibles: (hasDeductibles: boolean) => void;
    isAdditionalRisksStep: Ref<boolean>;
    calculatePrices: () => void;
    resetCascoBundlePrice: () => void;
    mtplAdditionalOptionsPrice: (logged: boolean) => number;
    bundlePercent: () => number;
    applyAdditionalOptionsPrice: () => DynamicDictionary;
    selectedAdditionalOptionsForProduct: (vehicleProductId: string) => string[];
    combinedAdditionalOptionsForBundle: (vehicleProductId: string) => string;
    policyPeriod: () => string;
    applyDiscountPercents: () => void;
    productExists: (product: string) => boolean;
    bundleExists: () => boolean;
    applyDiscountForProduct: (product: string, prices: DynamicDictionary, discountPeriod: string) => void;
    applyDiscountPrices: () => void;
    transformPolicyPeriodToDiscountPeriod: (newPolicyPeriod: string) => string;
    applyAdditionalOptionsPrices: () => void;
    applyCascoMonthlyPrices: () => void;
    calculateFullSums: () => void;
    calculateFullSumsByUserType: (sumType: PolicySum) => void;
    calculateBundleFullSum: (sumType: PolicySum) => number;
    calculateSubscriptionsFullPrice: () => void;
    calculateBundlePercent: () => void;
    bundleDiscount: (sumType: PolicySum) => number;
    applySubscriptionPrices: () => void;
    applySelectedRisksPricesForProduct: (product: string) => void;
    applySelectedRisksPricesForBundle: () => void;
    foundEnabledRisksForProduct: (product: string) => boolean;
    applyAdditionalOptionsPriceForProduct: (product: string, logged: boolean) => void;
    combinedRisksPayment: (prices: DynamicDictionary) => number;
    additionalOptionsPaymentForProduct: (product: string, prices: DynamicDictionary, logged: boolean) => number;
    additionalOptionsPriceForProduct: (
        product: string,
        prices: DynamicDictionary,
        additionalPrices: DynamicDictionary,
    ) => number;
    additionalOptionsCascoMonthlyPayment: (additionalPrices: DynamicDictionary, product: string) => number;
    additionalOptionsExistForProduct: (product: string, logged: boolean) => boolean;
    bundleMonthlyPaymentForProduct: (product: string, logged: boolean) => number;
    bundleSubscriptionPriceForProduct: (product: string, logged: boolean) => number;
    monthlyPaymentForProduct: (product: string, logged: boolean) => number;
    fixedPeriodPaymentBundleForProductAndPeriod: (product: string, period: string, logged: boolean) => number;
    storageVehicleProductExists: (product: string) => boolean;
    fixedPeriodPaymentForProductAndPeriod: (product: string, period: string, logged: boolean) => number;
    fixedPeriodPaymentForPeriod: (prices: DynamicDictionary, period: string, isDiscount: boolean) => number;
    fixedPeriodDiscount: (prices: DynamicDictionary, period: string) => number;
    hasAdditionalRisksSelected: Ref<boolean>;
    subscriptionPrices: (product: string, logged: boolean) => number;
    applyAdditionalOptionsBundlePrice: (product: string, logged: boolean) => void;
    applyAdditionalOptionsCascoMonthlyPrice: (product: string, logged: boolean) => void;
    fetchSumWithAdditionalOptions: (requestParams: DynamicDictionary, reloadMtplSums: boolean) => Promise<void>;
    fetchRiskPrices: (risks: DynamicDictionary) => Promise<void>;
    fetchSumsForOwner: (requestParams: DynamicDictionary) => Promise<void>;
    fetchSumsIsLocked: Ref<boolean>;
    cachedRequests: Ref<CacheHash>;
    isApprovalCaseNecessary: Ref<boolean>;
    fetchIsApprovalCaseNecessary: (hasSecurityDevices: boolean) => Promise<void>;
    assembleRequestForFetchingSums: () => DynamicDictionary;
    assembleRequestForIsApprovalCaseNecessary: (hasSecurityDevices?: boolean) => DynamicDictionary;
    assembleRequestWithAdditionalOptions: (
        requestParams: DynamicDictionary,
        reloadMtplSums: boolean,
    ) => DynamicDictionary;
    assembleRequestWithRisks: (risks: DynamicDictionary) => DynamicDictionary;
    productRisksCombined: (risks: DynamicDictionary, vehicleProductId: string) => string;
    hasUserSelectedDeductibleSums: (requestParams: DynamicDictionary) => boolean;
    fetchSums: (params: DynamicDictionary) => Promise<DynamicDictionary>;
    storageDataIsMissed: (value: DynamicDictionary) => boolean;
    redirectToInitialStep: () => void;
    applyIsApprovalCaseNecessary: (options: DynamicDictionary | null) => void;
    buildHashForSumWithAdditionalOptions: (requestParams: DynamicDictionary) => string;
    buildHashForFetchSums: (params: DynamicDictionary) => string;
    buildHashForIsApprovalCaseNecessary: (params: DynamicDictionary) => string;
    createDefaultApprovalCaseNecessary: () => void;
    cachedRequestsHasNoRecords: () => boolean;
    vehicleProductAdditionalRisksMapping: Ref<DynamicDictionary[]>;
}
