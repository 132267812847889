<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { onMounted, PropType, Ref, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { usePrice } from '@/Composables/Price';
import TravelInsuranceOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/TravelInsuranceOptionInterface';
import TravelInsuranceOptionEmitters from '@/Components/Lists/AdditionalOptionsList/Enums/TravelInsuranceOptionEmitters';
import OneDate from '@/assets/libraries/Date/OneDate';
import { InputOption } from '@/interfaces/InputOptionInterface';
import SettingsService from '@/services/settings.service';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import moment from 'moment/moment';
import MomentBuilder from '@/assets/libraries/Date/Builders/MomentBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import UserStorage from '@/services/user.storage.service';
import AppInputSwitch from '@/Components/Inputs/InputSwitch/InputSwitch.vue';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import AppInputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import AppInputCount from '@/Components/Inputs/InputCount/InputCount.vue';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import AppCountry from '@/assets/libraries/app/app-country';

const props = defineProps({
    formField: { type: FormField<DynamicDictionary>, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    option: {
        type: Object as PropType<TravelInsuranceOption>,
        default: () => {},
    },
});
const translationType: string = 'travel';
const { translate, translateForType } = useTranslate();
const { sparse } = usePrice();
const popupService: PopupService = PopupService.getInstance();
const popupInstanceTypeIs = (type: string): boolean => {
    return popupService.activePopupType === type;
};
const emit = defineEmits([
    TravelInsuranceOptionEmitters.Toggle,
    TravelInsuranceOptionEmitters.Confirm,
    TravelInsuranceOptionEmitters.Cancel,
    TravelInsuranceOptionEmitters.Edit,
]);

const deductibleOptions: Ref<InputOption[]> = ref([]);
const firstTimePopupOpened: Ref<boolean> = ref(false);
const rentalCarMaxCount: Ref<number> = ref(0);

onMounted((): void => {
    buildDeductibleOptions();
    applyValuesFromSettings();
    emit(TravelInsuranceOptionEmitters.Toggle, {
        [props.option.risk.id]: props.option.enabled.value,
    });
    if (props.option.enabled.value) {
        firstTimePopupOpened.value = true;
    }
});

function buildDeductibleOptions(): void {
    const deductibleValues: string[] =
        SettingsService.getInstance().travelAdditionalSettings().rentalCarSecurityDeductibles;
    deductibleValues.forEach((value: string): void => {
        const optionName: string = value.replace(/\D/g, '') + '&nbsp;&euro;';
        deductibleOptions.value.push(new InputOptionBuilder().setName(optionName).setValue(value).build());
    });
}

function applyValuesFromSettings(): void {
    rentalCarMaxCount.value = SettingsService.getInstance().travelAdditionalSettings().rentalCarMaxCount;
}

function onAdditionalOptionChange(optionState: boolean): void {
    emit(TravelInsuranceOptionEmitters.Toggle, {
        [props.option.risk.id]: optionState,
    });
    if (optionState && !firstTimePopupOpened.value) {
        onEditClick();
        firstTimePopupOpened.value = true;
    }
}

function optionSubtitle(option: TravelInsuranceOption): string {
    let result: string[] = [translateForType(option.risk.id + '_SWITCHOFF', translationType)];
    if (option.enabled.value) {
        result = [translateForType(option.risk.id + '_SWITCHON', translationType)];
    }

    return result.join('');
}

function amount(value: number): string {
    return '+&nbsp;' + sparse(value, false);
}

function dateFormat(): string {
    return OneDate.shortFormat();
}

function onEditClick(): void {
    if (!props.option.enabled.value) {
        enableOptionManual();
    }
    PopupService.getInstance().show(new OnePopup().withType().travelRentalCarSecurity);
    emit(TravelInsuranceOptionEmitters.Edit);
}

function onCancelClick(): void {
    PopupService.getInstance()
        .hide()
        .then((): void => {
            emit(TravelInsuranceOptionEmitters.Cancel);
        });
}

function onConfirmClick(): void {
    emit(TravelInsuranceOptionEmitters.Confirm);
}

function rentalPeriodText(): string {
    return (
        OneDate.short(moment(props.option.rentalPeriod.value.startDate).toDate()) +
        ' - ' +
        OneDate.short(moment(props.option.rentalPeriod.value.endDate).toDate()) +
        ` (${dateDifference(moment(props.option.rentalPeriod.value.startDate), moment(props.option.rentalPeriod.value.endDate))})`
    );
}

function rentalCarCountText(): string {
    return [translateForType('rental_car_count', translationType), props.option.carCount.value].join(' ');
}

function deductibleText(): string {
    const optionName: string = props.option.deductible.value.replace(/\D/g, '') + '&nbsp;&euro;';
    return [translate('rental_car_deductible'), optionName].join(' ');
}

function dateDifference(startDate: moment.Moment, endDate: moment.Moment): string {
    const locale: string = MomentBuilder.mappedLanguage();
    endDate.locale(locale);
    startDate.locale(locale);
    const differenceText: string = endDate.endOf('day').from(startDate.startOf('day'), true);
    const substringStart: number = 2;
    const substringEnd: number = 3;
    const differenceTextEn: string = differenceText.substr(substringStart, substringEnd);
    const diffTexts: string = locale === 'en' ? '1 ' + differenceTextEn : '1 ' + differenceText;

    return endDate.diff(startDate, 'days') === 0 ? diffTexts : differenceText;
}

function editButtonParams(): ButtonWithCallbackParams {
    return {
        title: translateForType('additional_item_edit_details', translationType),
        textColor: ButtonTextColor.Black,
        backgroundColorHover: ButtonBackground.Transparent,
        backgroundColor: ButtonBackground.Transparent,
        icon: ButtonIcon.Edit,
        iconColor: ButtonIconColor.Black,
        borderColor: ButtonBorder.Pale,
    };
}

function confirmButtonParams(): ButtonWithCallbackParams {
    return {
        title: translate('btar_confirm'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
    };
}

function enableOptionManual(): void {
    const enabler: HTMLDivElement | null = document.querySelector('#enabled-toggle');
    if (!props.option.enabled.value) {
        if (enabler) {
            enabler.dispatchEvent(new Event('click', { bubbles: true }));
        }
    }
}

function deductibleLabel(): DynamicDictionary {
    return {
        title: translateForType('deductible_label', translationType),
        tipDescription: translateForType('deductible_tooltip', translationType),
    };
}

function carRentDurationInDays(): string {
    return dateDifference(
        moment(props.option.rentalPeriod.value.startDate),
        moment(props.option.rentalPeriod.value.endDate),
    );
}

function riskMinDate(): Date {
    return moment(UserStorage.getInstance().stepStorageData.travelStartDate).toDate();
}

function riskMaxDate(): Date {
    return moment(UserStorage.getInstance().stepStorageData.travelEndDate).toDate();
}

function disabledByDefault(): boolean {
    return new AppCountry().isLT();
}
</script>

<template>
    <div
        class="travel-insurance-option"
        :class="{ ...formField.classes(), enabled: option.enabled.value }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="title flex column">
            <div class="switch flex space-between align-center">
                {{ option.name }}
                <app-input-switch
                    v-show="option.risk.price > 0"
                    :class="{ off: !option.enabled.value }"
                    :form-field="option.enabled"
                    :data-store-disabled="true"
                    @change="onAdditionalOptionChange($event)"
                ></app-input-switch>
            </div>
            <div class="subtitle">
                <app-text-with-tip
                    class="subtitle-tooltip"
                    :title="optionSubtitle(option)"
                    :mode="'popup'"
                    :tip-description="translateForType(option.risk.id + '_TOOLTIP', translationType)"
                ></app-text-with-tip>
            </div>
        </div>
        <div class="details flex column">
            <div v-if="option.risk.price > 0" class="price flex space-between align-center">
                {{ translateForType('risk_price_for', translationType) }}
                <span class="green">
                    <span class="amount" v-html="amount(option.risk.price)"></span>
                    <span class="amount-text" v-html="option.paymentFrequency"></span>
                </span>
            </div>
            <hr />
            <section class="flex mobile-column align-center-desktop space-between">
                <div class="flex column half-width">
                    <div class="option-details-title">
                        {{ translateForType('rental_car_details_title', translationType) }}
                    </div>
                    <span class="option-detail">{{ rentalPeriodText() }}</span>
                    <span class="option-detail">{{ rentalCarCountText() }}</span>
                    <span class="option-detail" v-html="deductibleText()"></span>
                </div>
                <app-button-with-callback
                    class="option-button edit-details"
                    v-bind="editButtonParams()"
                    :data-store-disabled="true"
                    @button-callback-click="onEditClick"
                >
                </app-button-with-callback>
            </section>
            <div class="popups">
                <app-popup
                    v-show="popupInstanceTypeIs('travel-rental-car-security')"
                    class="confirm rental-car-security"
                    :disable-close="false"
                    @close="onCancelClick()"
                >
                    <div class="popup-top">
                        <div class="popup-title">{{ translateForType('rental_car_popup_title', translationType) }}</div>
                    </div>
                    <div class="popup-bottom">
                        <div class="popup-title secondary">
                            {{ translateForType('rental_car_popup_title_secondary', translationType) }}
                        </div>
                        <div class="rental-settings flex column">
                            <app-input-date-with-calendar
                                class="full-width"
                                :number-of-days="364"
                                :form-field="option.rentalPeriod"
                                :format="dateFormat()"
                                :disabled="disabledByDefault() || !option.enabled.value"
                                :label="translateForType('rental_period_label', translationType)"
                                :min-date="riskMinDate()"
                                :max-date="riskMaxDate()"
                                :with-icon="!disabledByDefault()"
                                :is-range="true"
                                :return-raw="true"
                                :mobile-button-text="translate('btar_confirm')"
                            >
                                <template #custom>
                                    <span class="post-date-text" v-html="carRentDurationInDays()"> </span>
                                </template>
                            </app-input-date-with-calendar>
                            <div class="flex space-between mobile-column">
                                <app-input-count
                                    v-if="!disabledByDefault()"
                                    class="custom-width"
                                    :label="translateForType('car_count_label', translationType)"
                                    :form-field="option.carCount as FormField<number>"
                                    :min-value="1"
                                    :default-value="1"
                                    :max-value="rentalCarMaxCount"
                                    :disabled="!option.enabled.value"
                                >
                                </app-input-count>
                                <app-input-text
                                    v-else
                                    class="custom-width"
                                    :label="translateForType('car_count_label', translationType)"
                                    :form-field="option.carCount as FormField<string>"
                                    :disabled="true"
                                ></app-input-text>
                                <app-input-select
                                    class="custom-width"
                                    :label-with-tip="deductibleLabel()"
                                    :form-field="option.deductible"
                                    :drop-mode="'up'"
                                    :disabled="!option.enabled.value"
                                    :options="deductibleOptions"
                                >
                                </app-input-select>
                            </div>
                            <app-button-with-callback
                                class="option-button confirm-details"
                                v-bind="confirmButtonParams()"
                                :data-store-disabled="true"
                                @button-callback-click="onConfirmClick"
                            >
                            </app-button-with-callback>
                        </div>
                    </div>
                </app-popup>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.travel-insurance-option {
    background: var(--white);
    width: 100%;
    border-radius: var(--size-pico);
    padding: var(--size-small);
    border: 2px solid transparent;

    &.enabled {
        border-color: var(--component-color-border-active);
        background: linear-gradient(0deg, var(--system-color-success-light) 0%, var(--system-color-success-light) 100%),
            var(--white);
    }

    .title {
        gap: var(--size-pico);

        .switch {
            font-size: var(--font-size-small);
            font-weight: 700;
        }

        .subtitle {
            :deep(.sub-title) {
                font-size: var(--font-size-nano);
                color: var(--text-color-subtle);
                font-weight: 500;
            }
        }
    }

    .details {
        gap: var(--size-tiny);
        margin-top: var(--size-small);
        font-size: var(--font-size-nano);

        .price {
            font-size: var(--font-size-tiny);
            color: var(--text-color-default);
            font-weight: 600;

            .amount {
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .amount-text {
                font-size: var(--font-size-tiny);
            }
        }

        .option-details-title {
            font-size: var(--font-size-tiny);
            margin-bottom: var(--size-pico);
            font-weight: 600;
        }

        .option-detail {
            color: var(--text-color-subtle);
        }

        .option-button {
            height: 52px;
        }

        .popup-title {
            font-size: var(--font-size-medium);
            font-weight: 700;
            line-height: 120%;

            &.secondary {
                font-size: var(--font-size-small);
                margin-bottom: var(--size-small);
            }
        }

        .popups {
            :deep(.single-popup) {
                &.confirm {
                    > .wrapper {
                        align-items: initial;
                        background-color: var(--white);
                        padding: 0;

                        .popup-top {
                            height: 120px;
                            padding: 40px;
                            background: var(--background-light);
                            border-bottom: 1px solid var(--black-100);
                        }

                        .popup-bottom {
                            padding: 40px;
                        }
                    }
                }
            }
        }
    }

    .green {
        color: var(--text-color-link);
    }

    .rental-settings {
        gap: var(--size-small);

        .post-date-text {
            margin-left: var(--size-pico);
            font-weight: 600;

            @media (max-width: 400px) {
                display: none;
            }
        }
    }

    .flex {
        display: flex;

        &.column {
            flex-direction: column;
        }

        &.mobile-column {
            flex-direction: column;
            gap: var(--size-small);

            @include respond-above('sm') {
                flex-direction: row;
            }
        }

        &.space-between {
            justify-content: space-between;
        }

        &.align-center {
            align-items: center;
        }

        &.align-center-desktop {
            @include respond-above('sm') {
                align-items: center;
            }
        }
    }

    .full-width {
        width: 100%;
    }

    .half-width {
        width: 50%;
    }

    .custom-width {
        width: 100%;

        @include respond-above('sm') {
            width: 230px;
        }
    }

    @include respond-above('sm') {
        width: 520px;
    }

    hr {
        border-top: 1px solid var(--black-100);
    }

    #car-count {
        :deep(input) {
            text-align: center;
        }
    }
}
</style>
