<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, watch, onMounted, Ref } from 'vue';
import Banks from '@/services/banks.service';
import AppInputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import { BankInstitution } from '@/interfaces/resources/bank.institution.interface';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';

const props = defineProps({
    skipOwnValidation: { type: Boolean, default: false, required: false },
    formField: { type: FormField<BankInstitution>, default: () => new FormField('') },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String, default: () => '' },
    label: { type: String, default: '' },
    dataStoreDisabled: { type: Boolean, default: false },
});
const emit = defineEmits(['change']);
const banksService: Banks = Banks.getInstance();
const bankIdFormField: FormField = new FormField('bankId');
const banks: Ref<BankInstitution[]> = computed(() => banksService.bankInstitutions());
const options: Ref<InputOption[]> = computed(() => {
    return banks.value.map((bank: BankInstitution) => {
        return new InputOptionBuilder().setValue(bank.id.toString()).setName(bank.name).build();
    });
});

watch(
    () => props.formField.value,
    (newValue) => {
        if (props.formField.value.id.toString() !== bankIdFormField.value) {
            bankIdFormField.patch(newValue.id.toString());
        }
        props.formField.validate();
        emitChange();
    },
);

watch(
    () => props.skipOwnValidation,
    (newSkipOwnValidation) => {
        if (!newSkipOwnValidation) {
            applyChildFieldValidators();
        }
        props.formField.validate().then();
    },
);

onMounted((): void => {
    if (banksService.ready) {
        init();
    } else {
        banksService.onExternalDataIsReady.subscribe(() => {
            init();
        });
    }
});

function onSelectedBankChange(bankId: string): void {
    props.formField.value = banks.value.find((bank: BankInstitution) => bank.id.toString() === bankId)!;
}

function init(): void {
    if (!props.skipOwnValidation) {
        applyChildFieldValidators();
    }
    if (!props.formField.isEmpty()) {
        bankIdFormField.patch(props.formField.value.id.toString());
    }
}

function applyChildFieldValidators(): void {
    props.formField.addValidators('required');
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-bank"
        :class="formField.classes()"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="wrapper select">
            <app-input-select
                :options="options"
                :data-store-disabled="true"
                :placeholder="placeholder"
                :label="label"
                :form-field="bankIdFormField as FormField"
                @change="onSelectedBankChange"
            >
                <template #app-tooltipster>
                    <slot name="app-tooltipster"></slot>
                </template>
            </app-input-select>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-bank {
    width: 100%;
    scroll-margin-top: 4em;

    @include respond-above('md') {
        width: 350px;
    }

    > .wrapper {
        height: 100%;
    }
}
</style>
