import AjaxAgreement from '@/Components/Policies/PolicyBlock/Interfaces/AjaxAgreementInterface';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import { useDefine } from '@/Composables/Define';
import { LimitedVariant } from '@/Types/LimitedVariantType';

export class AjaxAgreementBuilder {
    private ajaxAgreement: AjaxAgreement = {
        id: '',
        type: '',
        subscriptionId: '',
        agreementNumber: '',
        objectName: '',
        typeId: '',
        isSubscription: false,
        isActive: false,
        isUpcoming: false,
        insuranceTermsText: '',
        insuranceTermsId: '',
        validTo: {
            date: '',
            timezone: '',
        },
        validFrom: {
            date: '',
            timezone: '',
        },
        holder: {},
    };

    public fromAgreement(value: Agreement): this {
        Object.entries(value).forEach((entry: [string, LimitedVariant]): void => {
            const entryKey: string = entry[0];
            const entryValue: LimitedVariant = entry[1];
            if (useDefine().isSet(this.ajaxAgreement[entryKey])) {
                this.ajaxAgreement[entryKey] = entryValue;
            }
        });

        return this;
    }

    public build(): AjaxAgreement {
        return this.ajaxAgreement;
    }
}
