<script lang="ts">
import { computed, defineComponent, reactive, Ref, ref, nextTick, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import OneModule from '@/interfaces/OneModuleInterface';
import Popup from '@/services/popup.service';
import Error from '@/services/error.service';
import TimeRanges from '@/Enums/TimeRangesEnum';
import { InputOption } from '@/interfaces/InputOptionInterface';
import ErrorType from '@/Enums/ErrorTypeEnum';
import FormField from '@/assets/libraries/form/form-field';
import CssClass from '@/Enums/CssClassEnum';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import VueEvent from '@/Classes/VueEventClass';
import Sanitizer from '@/services/sanitizer.service';
import UserCredentials from '@/interfaces/user.credentials.interface';
import DataLayer from '@/services/data.layer.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useDefine } from '@/Composables/Define';
import DateRange from '@/interfaces/date.range.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import { useTranslate } from '@/Composables/Translate';
import { AxiosResponse } from 'axios';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import OneModuleService from '@/services/OneModuleService';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useScroll } from '@/Composables/Scroll';

export default defineComponent({
    setup() {
        const module: OneModule = OneModuleService.getInstance();

        const popup: Popup = Popup.getInstance();
        const error: Error = Error.getInstance();

        const request: AxiosParams = useAxios();

        const DisabledDays: number = 6;
        const CheckboxTypeNotRequired: string = 'not_required';
        const PhoneCodes: { [countryISO: string]: string } = {
            EE: '372',
            LT: '370',
            LV: '371',
        };

        const checkboxAgree: Ref<boolean> = ref(false);
        const saveToDb: Ref<boolean> = ref(false);
        const discardEmptyValues: Ref<boolean> = ref(false);
        const subject: Ref<string> = ref('');
        const checkboxType: Ref<string> = ref('');
        const phoneRequired: Ref<boolean> = ref(true);
        const showCompanyFields: Ref<boolean> = ref(false);
        const minutesStep: Ref<number> = ref(1);
        const getInTouchForm: Form = new Form();
        let minTime: UnwrapNestedRefs<object> = reactive({ hours: 0, minutes: 0 });
        let maxTime: UnwrapNestedRefs<object> = reactive({ hours: 23, minutes: 59 });
        const typeOptions: Ref<InputOption[]> = ref([]);
        const disabledDays: Ref<number[]> = ref([0, DisabledDays]);
        const minuteRange: Ref<number[]> = ref([0, TimeRanges.MaxMinutes]);
        const hourRange: Ref<number[]> = ref([0, TimeRanges.MaxHours]);

        const typeInputOptions: Ref<InputOption[]> = computed(() => {
            return typeOptions.value;
        });

        const timeInputMinTime: Ref<object> = computed(() => {
            return minTime;
        });

        const timeInputMaxTime: Ref<object> = computed(() => {
            return maxTime;
        });

        const timeInputMinutesStep: Ref<number> = computed(() => {
            return minutesStep.value;
        });

        const calendarInputDisabledDays: Ref<number[]> = computed(() => {
            return disabledDays.value;
        });

        const timeInputMinuteRange: Ref<number[]> = computed(() => {
            if (minuteRange.value.length <= TimeRanges.MaxMinuteDigits) {
                const result: number[] = [];
                for (let i: number = minuteRange.value[0]; i <= minuteRange.value[1]; i += minutesStep.value) {
                    result.push(i);
                }
                return result;
            }

            return minuteRange.value;
        });

        const timeInputHourRange: Ref<number[]> = computed(() => {
            if (hourRange.value.length <= TimeRanges.MaxHourDigits) {
                const result: number[] = [];
                for (let i: number = hourRange.value[0]; i <= hourRange.value[1]; i++) {
                    result.push(i);
                }
                return result;
            }

            return hourRange.value;
        });

        const fullName: Ref<string> = computed(() => {
            return module.localUser().current.firstname + ' ' + module.localUser().current.lastname;
        });

        const hasDataProcessingFieldOrIsLV: Ref<boolean> = computed(() => {
            return (
                (module.hasCmsFields() && module.cmsFieldIsEnabled('dataProcessingAgree')) ||
                (!module.hasCmsFields() && new AppCountry().isLV())
            );
        });

        const showDataProcessingCheckbox: Ref<boolean> = computed(() => {
            //INFO:
            //  Some GetInTouch modules (for example Default) doesn`t use cms fields
            //  In these cases data processing field will be visible for LV country
            return !module.localUser().isLogged() && hasDataProcessingFieldOrIsLV.value;
        });

        const create = (): void => {
            module.onModuleReady.subscribe((): void => {
                nextTick((): void => {
                    setupGetInTouchForm();
                    if (module.localUser().isLogged()) {
                        patchGetInTouchFields();
                    }
                    module.applyModuleType();
                });
            });
        };

        const contactsRequestCallback = (event: VueEvent): void => {
            if (event.params.callbackType) {
                getInTouchForm.field('callType').patch(event.params.callbackType);
            }
            useScroll().scrollTo(event);
        };

        const applyPhoneRequired = (isRequired: boolean): void => {
            phoneRequired.value = isRequired;
        };

        const applyShowCompanyFields = (isRequired: boolean): void => {
            showCompanyFields.value = isRequired;
        };

        const applySubject = (newSubject: string): void => {
            subject.value = newSubject;
        };

        const applyDiscardEmptyValues = (newDiscardEmptyValues: boolean): void => {
            discardEmptyValues.value = newDiscardEmptyValues;
        };

        const prepareAndSubmit = (): void => {
            module
                .captcha()
                .executeCaptcha(formSubmit)
                .then()
                .catch((reason: string): void => {
                    Error.log(ErrorType.Error, 'handleFormSubmit', reason, true);
                });
        };

        const formSubmit = (token: string): void => {
            const params: Record<string, unknown> = emailParams();
            params['g-recaptcha-response'] = token;
            submit(params);
        };

        const applyCheckboxType = (type: string): void => {
            checkboxType.value = type;
            if (checkboxType.value === CheckboxTypeNotRequired) {
                getInTouchForm.addField(new FormField('checkboxAgree', checkboxAgree.value));
            } else {
                getInTouchForm.addField(new FormField('checkboxAgree', checkboxAgree.value, 'checkboxChecked'));
            }
        };

        const applySaveToDb = (newSaveToDb: string): void => {
            saveToDb.value = JSON.parse(module.transformedVueSafeString(newSaveToDb));
        };

        const getInTouchPhoneClasses = (): string => {
            let result: string = '';
            if (module.hasCmsFields() && !module.cmsFieldIsEnabled('phone')) {
                result = CssClass.Hidden;
            }

            return result;
        };

        const makeTypeInputOptions = (options: string): void => {
            typeOptions.value = JSON.parse(options).map((option: DynamicDictionary) => {
                return new InputOptionBuilder().setValue(option.value).setName(option.name).build();
            });
        };

        const makeTimeInputOptions = (element: string): void => {
            const parsedElement: DynamicDictionary = JSON.parse(module.transformedVueSafeString(element));
            minTime = parsedElement.minTime;
            maxTime = parsedElement.maxTime;
            minutesStep.value = parsedElement.minutesStep;
            minuteRange.value = parsedElement.minuteRange;
            hourRange.value = parsedElement.hourRange;
        };

        const makeDateInputOptions = (element: string): void => {
            const parsedElement: DynamicDictionary = JSON.parse(module.transformedVueSafeString(element));
            disabledDays.value = parsedElement.disabledDays;
        };

        const setupGetInTouchForm = (): void => {
            if (module.hasCmsFields()) {
                if (module.cmsFieldIsEnabled('name')) {
                    getInTouchForm.addField(new FormField('name', '', '', Sanitizer.cleanName));
                    if (module.cmsFields.value.name.required) {
                        getInTouchForm.field('name').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('email')) {
                    getInTouchForm.addField(new FormField('email'));
                    if (module.cmsFields.value.email.required) {
                        getInTouchForm.field('email').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('phone')) {
                    getInTouchForm.addField(new FormField('phone', ''));
                    if (module.cmsFields.value.phone.required) {
                        getInTouchForm.field('phone').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('message')) {
                    getInTouchForm.addField(new FormField('message', ''));
                    if (module.cmsFields.value.message.required) {
                        getInTouchForm.field('message').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('policyNumber')) {
                    getInTouchForm.addField(new FormField('policyNumber', ''));
                    if (module.cmsFields.value.policyNumber.required) {
                        getInTouchForm.field('policyNumber').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('receiveMarketing')) {
                    getInTouchForm.addField(new FormField('receiveMarketing', false));
                    if (module.cmsFields.value.receiveMarketing.required) {
                        getInTouchForm.field('receiveMarketing').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('date')) {
                    getInTouchForm.addField(new FormField('date'));
                    if (module.cmsFields.value.date.required) {
                        getInTouchForm.field('date').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('time')) {
                    getInTouchForm.addField(new FormField('time'));
                    if (module.cmsFields.value.time.required) {
                        getInTouchForm.field('time').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('callType')) {
                    getInTouchForm.addField(new FormField('callType'));
                    if (module.cmsFields.value.callType.required) {
                        getInTouchForm.field('callType').addValidators('required');
                    }
                    if (module.cmsFields.value.callType.value !== '') {
                        getInTouchForm.field('callType').patch(module.cmsFields.value.callType.value);
                    }
                }
                if (module.cmsFieldIsEnabled('readRegulations')) {
                    getInTouchForm.addField(new FormField('readRegulations'));
                    if (module.cmsFields.value.readRegulations.required) {
                        getInTouchForm.field('readRegulations').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('companyName')) {
                    getInTouchForm.addField(new FormField('companyName'));
                    if (module.cmsFields.value.companyName.required) {
                        getInTouchForm.field('companyName').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('employeesCount')) {
                    getInTouchForm.addField(new FormField('employeesCount'));
                    if (module.cmsFields.value.employeesCount.required) {
                        getInTouchForm.field('employeesCount').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('dataProcessingAgree')) {
                    getInTouchForm.addField(new FormField('dataProcessingAgree'));
                    if (module.cmsFields.value.dataProcessingAgree.required && showDataProcessingCheckbox.value) {
                        getInTouchForm.field('dataProcessingAgree').addValidators('required');
                    }
                }
                if (module.cmsFieldIsEnabled('checkboxAgree')) {
                    getInTouchForm.addField(new FormField('checkboxAgree'));
                    if (module.cmsFields.value.checkboxAgree.required) {
                        getInTouchForm.field('checkboxAgree').addValidators('required');
                    }
                }
            }
            getInTouchForm.setReady();
        };

        const emailParams = (): Record<string, unknown> => {
            const body: { [param: string]: string } = {
                name: fieldValue('name'),
                email: fieldValue('email'),
                phone: phoneNumberFromForm(),
                type: fieldValue('callType'),
                date: fieldValue('date'),
                time: fieldValue('time'),
                message: fieldValue('message'),
                policyNumber: fieldValue('policyNumber'),
                companyName: fieldValue('companyName'),
                sumOfEmployees: fieldValue('employeesCount'),
            };
            if (checkboxType.value === CheckboxTypeNotRequired) {
                body.agree = getInTouchForm.field('checkboxAgree').value;
            }
            if (getInTouchForm.exists('receiveMarketing')) {
                body.receiveMarketing = getInTouchForm.field('receiveMarketing').value;
            }
            if (discardEmptyValues.value) {
                Object.keys(body)
                    .filter((key) => body[key] === null || body[key] === '')
                    .forEach((key) => delete body[key]);
            }

            return {
                body: body,
                subject: subject.value,
                pathname: location.pathname,
                saveToDb: saveToDb.value,
                moduleType: module.moduleType.value,
            };
        };

        const fieldValue = (name: string): string => {
            return getInTouchForm.exists(name) ? getInTouchForm.field(name).value : '';
        };

        const patchGetInTouchFields = (): void => {
            const countryISO: string = module.appCountry().iso;
            const userData: UserCredentials = module.localUser().current;
            if (module.localUser().isLogged()) {
                getInTouchForm.field('name').patch(fullName.value);
                getInTouchForm.field('email').patch(userData.email);
                getInTouchForm.field('phone').patch({
                    code: countryISO,
                    country: userData.phoneCode,
                    phone: userData.phone,
                });
            }
        };

        const pushDataLayer = () => {
            DataLayer.getInstance()
                .addRootParam('event', module.dataLayerEventType.value)
                .addRootParam('form', module.facility.value)
                .buildAndPush();
        };

        const phoneNumberFromForm = (): string => {
            let result: string = '';
            if ((module.hasCmsFields() && module.cmsFields.value.phone.enabled) || !module.hasCmsFields()) {
                const phoneFieldValue: DynamicDictionary = getInTouchForm.field('phone').value;
                const phoneCountry: LimitedVariant = phoneFieldValue.country;
                const appCountryISO: string = module.appCountry().iso;
                const isSet = useDefine().isSet;
                result =
                    '+' +
                    (isSet(phoneCountry)
                        ? phoneCountry
                        : isSet(PhoneCodes[appCountryISO])
                          ? PhoneCodes[appCountryISO]
                          : '') +
                    ' ' +
                    phoneFieldValue.phone;
            }

            return result;
        };

        const resetForm = (): void => {
            getInTouchForm.clear().then();
            patchGetInTouchFields();
            if (checkboxType.value) {
                getInTouchForm.field('checkboxAgree').value = checkboxAgree.value;
            }
            const emptyDate: DateRange = new (class implements DateRange {
                public startDate: string = '';
                public endDate: string = '';
            })();
            getInTouchForm.field('date').patch(emptyDate);
            getInTouchForm.field('time').clear();
        };

        const showFailure = (reason: DynamicDictionary): void => {
            popup.showPopup(PopupType.None);
            error.show(ErrorType.Error, 'Callback::' + module.facility, reason);
        };

        const showSuccess = (): void => {
            const popupInstance: Popup = Popup.getInstance();
            popupInstance.applyTextTitle(useTranslate().translate('btar_success'));
            popupInstance.applyTextDescription(useTranslate().translate('btar_message_send_successfully'));
            PopupService.getInstance().show(new OnePopup().withType().info);
        };

        const submit = (params: Record<string, unknown>): void => {
            nextTick(() => {
                PopupService.getInstance().show(new OnePopup().withType().loading);
            });
            request
                .post(Url.Ajax.submitGetInTouch, params)
                .then((value: AxiosResponse<DynamicDictionary>): void => {
                    PopupService.getInstance().hide();
                    resetForm();
                    if (module.validResponse(value) && value.data.data.status === 'OK') {
                        showSuccess();
                        pushDataLayer();
                    } else {
                        throw value;
                    }
                })
                .catch((reason: DynamicDictionary): void => {
                    showFailure(reason);
                });
        };

        return {
            ...module,
            ...{
                create,
                getInTouchForm,
                checkboxAgree,
                saveToDb,
                discardEmptyValues,
                subject,
                typeInputOptions,
                timeInputMinuteRange,
                timeInputHourRange,
                fullName,
                hasDataProcessingFieldOrIsLV,
                showDataProcessingCheckbox,
                timeInputMinTime,
                timeInputMaxTime,
                timeInputMinutesStep,
                calendarInputDisabledDays,
                contactsRequestCallback,
                applyPhoneRequired,
                applyShowCompanyFields,
                applySubject,
                applyDiscardEmptyValues,
                getInTouchPhoneClasses,
                applySaveToDb,
                prepareAndSubmit,
                applyCheckboxType,
                makeTypeInputOptions,
                makeDateInputOptions,
                makeTimeInputOptions,
            },
        };
    },

    mounted() {
        OneBaseService.getInstance().applyApp(this);
    },
});
</script>
