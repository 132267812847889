import { JSLoader } from '@/assets/libraries/jsloader/js-loader';
import Library from '@/Enums/LibraryEnum';
import { CSSLoader } from '@/assets/libraries/cssloader/css-loader';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';

export class ToastrPlugin {
    private static version: string = '2.1.4';

    public static init(): Promise<void> {
        return Promise.all([
            new CSSLoader(Library.Toastr.css.map((file: string) => Library.Path + file + '?' + this.version)).load(),
            new JSLoader(Library.Toastr.js.map((file: string) => Library.Path + file + '?' + this.version)).load(),
        ])
            .then(() => {})
            .catch((reason: DynamicDictionary) => {
                Error.log(ErrorType.Error, 'ToastrPlugin::init', reason);
            });
    }
}
