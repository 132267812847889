<script setup lang="ts">
import { computed, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import PolicyDetailsPerson from '@/Components/Policies/PolicyDetailsPersons/Interfaces/PolicyDetailsPersonInterface';
import AppInsuredRisks from '@/Components/Lists/InsuredRisks/InsuredRisks.vue';

const { translate } = useTranslate();

const props = defineProps({
    translationsType: { type: String, default: '' },
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

function personName(person: PolicyDetailsPerson): string {
    return person.name + ' ' + person.surname;
}

function onOpenerClick(id: string): void {
    $('.person-risks-opener[data-id="' + id + '"]').toggleClass('opened');
    $('.person-details-opener[data-id="' + id + '"]').toggleClass('opened');
}

const containerTitle: Ref<string> = computed(() => {
    let count: number = 0;
    if (props.content) {
        count = props.content.persons ? props.content.persons.length : 0;
    }
    const replacements: DynamicDictionary = {
        '%count%': count,
    };

    return translate('you_have_x_insured_persons', replacements);
});
</script>

<template>
    <div class="policy-details-persons">
        <div class="policy-details-persons-container">
            <h4>{{ containerTitle }}</h4>
            <template v-if="content">
                <div v-for="(person, index) in content.persons" :key="index" class="policy-details-person">
                    <div class="person-details">
                        <div class="details-left">
                            <img
                                class="icon"
                                width="52"
                                height="52"
                                src="images/one/components/drawers/accident-icon.svg"
                                alt="accident icon"
                            />
                            <div class="details">
                                <div v-mask-analytics class="name">{{ personName(person) }}</div>
                                <div class="program">{{ person.program }}</div>
                            </div>
                        </div>
                        <button :data-id="person.id" class="person-details-opener" @click="onOpenerClick(person.id)">
                            <img
                                width="14"
                                height="8"
                                src="images/one/components/drawers/opener.svg"
                                alt="opener icon"
                            />
                        </button>
                    </div>
                    <div
                        v-if="person.insuredRisks.length > 0"
                        class="person-risks person-risks-opener"
                        :data-id="person.id"
                    >
                        <app-insured-risks
                            class="with-indent"
                            :content="person.insuredRisks"
                            :hide-fraction="true"
                        ></app-insured-risks>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-persons {
    .policy-details-persons-container {
        padding: var(--size-normal);
        background-color: var(--white);
        border-radius: 16px;

        .policy-details-person {
            border-bottom: 1px solid var(--black-200);

            &:last-child {
                border-bottom: none;
            }

            .person-details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: var(--size-small);
                padding-bottom: var(--size-small);

                .details-left {
                    display: flex;
                    align-items: center;

                    .icon {
                        margin-right: var(--size-nano);
                    }

                    .details {
                        .name {
                            font-weight: 600;
                        }

                        .program {
                            font-size: var(--font-size-pico);
                            line-height: var(--line-height-accented);
                            color: var(--black-500);
                            margin-top: var(--size-femto);
                        }
                    }
                }

                .person-details-opener {
                    filter: var(--to-color-white-pale);
                    transition: transform 0.2s ease-in;
                    cursor: pointer;

                    &:focus-visible {
                        filter: var(--to-color-green);
                    }

                    &.opened {
                        filter: var(--to-color-green);
                        transform: rotate(180deg);
                    }
                }
            }

            .person-risks {
                overflow: hidden;
                height: 0;

                &.opened {
                    height: auto;
                }
            }
        }
    }

    .with-indent {
        margin-left: 13px;
    }
}
</style>
