export default {
    updated: (el: HTMLElement): void => {
        const field: HTMLInputElement | null = el instanceof HTMLInputElement ? el : el.querySelector('input');
        const updatedValue = field!.value.toLowerCase();
        if (field!.value !== updatedValue) {
            field!.value = updatedValue;
            field!.dispatchEvent(new Event('input', { bubbles: true }));
        }
    },
};
