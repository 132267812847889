import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class ButtonsWithCallbackBuilder {
    private static instance: ButtonsWithCallbackBuilder;
    private buttons: ButtonWithCallbackParams[] = [];
    private buttonIndex: number = -1;

    public constructor() {}

    public static getInstance(): ButtonsWithCallbackBuilder {
        if (!ButtonsWithCallbackBuilder.instance) {
            ButtonsWithCallbackBuilder.instance = new ButtonsWithCallbackBuilder();
        }
        ButtonsWithCallbackBuilder.instance.reset();

        return ButtonsWithCallbackBuilder.instance;
    }

    public reset(): this {
        this.buttons = [];
        this.buttonIndex = -1;

        return this;
    }

    public newButton(): this {
        this.buttonIndex++;
        this.buttons[this.buttonIndex] = new (class implements ButtonWithCallbackParams {
            [key: string]: string | number | boolean | DynamicDictionary | undefined;
        })();
        this.buttons[this.buttonIndex].backgroundColor = ButtonBackground.DarkGreen;
        this.buttons[this.buttonIndex].backgroundColorHover = ButtonBackground.Green;
        this.buttons[this.buttonIndex].textColor = ButtonTextColor.White;
        this.buttons[this.buttonIndex].iconColor = ButtonIconColor.White;

        return this;
    }

    public withCustomParams(params: ButtonWithCallbackParams): this {
        this.checkIndex();
        this.buttons[this.buttonIndex] = params;

        return this;
    }

    public withTitle(title: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].title = title;

        return this;
    }

    public withIcon(icon: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].icon = icon;

        return this;
    }

    public withTextColor(textColor: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].textColor = textColor;

        return this;
    }

    public withTextColorHover(textColorHover: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].textColorHover = textColorHover;

        return this;
    }

    public withIconColor(iconColor: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].iconColor = iconColor;

        return this;
    }

    public withBackgroundColor(backgroundColor: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].backgroundColor = backgroundColor;

        return this;
    }

    public withBackgroundColorHover(backgroundColorHover: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].backgroundColorHover = backgroundColorHover;

        return this;
    }

    public withMode(mode: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].mode = mode;

        return this;
    }

    public withIconPosition(iconPosition: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].iconPosition = iconPosition;

        return this;
    }

    public withBorderColor(borderColor: string): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].borderColor = borderColor;

        return this;
    }

    public withIconWidth(iconWidth: number): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].iconWidth = iconWidth;

        return this;
    }

    public withIconHeight(iconHeight: number): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].iconHeight = iconHeight;

        return this;
    }

    public withDisabled(disabled: boolean): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].disabled = disabled;

        return this;
    }

    public withCallbackParams(callbackParams: DynamicDictionary): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].callbackParams = callbackParams;

        return this;
    }

    public withCallback(callback: (params: DynamicDictionary) => void): this {
        this.checkIndex();
        this.buttons[this.buttonIndex].callback = callback;

        return this;
    }

    public build(): ButtonWithCallbackParams[] {
        return this.buttons;
    }

    private checkIndex(): void {
        if (this.buttonIndex < 0) {
            this.newButton();
        }
    }
}
