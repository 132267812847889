<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, ref, Ref, watch, onMounted, PropType, ComputedRef, nextTick } from 'vue';
import { useDefine } from '@/Composables/Define';
import * as uuid from 'uuid';
import Popup from '@/services/popup.service';
import AlignedTooltip from '@/services/aligned.tooltip.service';
import PopupType from '@/Enums/PopupTypeEnum';
import VueEvent from '@/Classes/VueEventClass';
import { useInputErrorMessage } from '@/Composables/InputErrorMessage';
import AppBrowser from '@/assets/libraries/app/app-browser';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';

const props = defineProps({
    label: { type: String, default: '' },
    labelWithTip: {
        type: Object as PropType<DynamicDictionary>,
        default: () => {
            return {
                title: '',
                tipDescription: '',
            };
        },
    },
    placeholder: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    formField: { type: FormField<any>, default: () => new FormField('') },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    loading: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    allowEmptySelection: { type: Boolean, default: false },
    supportTextMessage: { type: String, default: '' },
    feedbackMessage: { type: String, default: '' },
    type: { type: String, default: 'dropdown' },
    dropMode: { type: String, default: 'drop' },
    popupLabel: { type: String, default: '' },
    resetOnEmptyOptions: { type: Boolean, default: true },
    emptyOption: {
        type: Object as PropType<InputOption>,
        default: () => new InputOptionBuilder().setName('-----').build(),
    },
    tooltipAttachTarget: { type: String, default: '' },
    skipOptionsChangeFormReset: { type: Boolean, default: false },
    alwaysExpanded: { type: Boolean, default: false },
    disableErrorText: { type: Boolean, default: false },
});

const emit = defineEmits(['close', 'open', 'term-change', 'change', 'disabled-change']);

const { isSet } = useDefine();
const dropdownElement: Ref<HTMLDivElement | null> = ref(null);
const popup: Popup = Popup.getInstance();
const { infoMessageIsVisible, infoMessage } = useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: props.supportTextMessage,
    feedbackMessage: props.feedbackMessage,
});
const showLabel: ComputedRef<boolean> = computed(() => {
    return isSet(props.label) || isSet(props.popupLabel);
});
const labelText: ComputedRef<string> = computed(() => {
    return props.popupLabel ? props.popupLabel : props.label;
});
const dropUpwards: ComputedRef<boolean> = computed(() => {
    return props.dropMode === 'up';
});
const showOpenIcon: ComputedRef<boolean> = computed(() => {
    return props.type !== 'popup-wide' ? !props.loading : props.options.length > 1;
});

const isDisabled: Ref<boolean> = ref(false);
const isOpened: Ref<boolean> = ref(false);
const selectedOption: Ref<InputOption> = ref(new InputOptionBuilder().build());
const id: string = uuid.v4();
let popupContainerMaxHeight: string = '100%';
const showSingleTooltip: Ref<boolean> = ref(false);
const tipId: string = 'tip-id-' + String(Math.random()).replaceAll('.', '');

watch(
    () => props.disabled,
    () => {
        checkDisabledStatus();
    },
);

watch(
    () => props.formField.value,
    (newValue, oldValue) => {
        if (newValue !== oldValue) {
            selectedOption.value = optionByValue(String(newValue));
            emitChange();
        }
    },
);

watch(
    () => props.options,
    (newValue) => {
        if (
            props.formField.value &&
            !newValue.some((item: InputOption) => item.value === props.formField.value) &&
            props.resetOnEmptyOptions &&
            !props.skipOptionsChangeFormReset
        ) {
            props.formField.patch('');
        }
        checkDisabledStatus(newValue);
        applyHeightOnWide();
    },
    { deep: true },
);

onMounted((): void => {
    isDisabled.value = props.disabled;
    selectedOption.value = props.emptyOption;
    if (props.formField.value) {
        selectedOption.value = optionByValue(props.formField.value as string);
    }
    applyHeightOnWide();
    checkDisabledStatus();
    if (props.alwaysExpanded) {
        open();
    }
    AlignedTooltip.getInstance().init(tipId, props.tooltipAttachTarget);
});

function fieldId() {
    return props.formField.name + '-select';
}

function open(): void {
    if (props.type == 'popup-wide' && props.options.length === 1 && !props.formField.isEmpty()) {
        showSingleTooltip.value = !showSingleTooltip.value;
        if (showSingleTooltip.value) {
            AlignedTooltip.getInstance().show(props.options[0].name);
        }
    } else {
        if (isOpened.value) {
            close();
        } else {
            isOpened.value = true;
            if (props.type.startsWith('popup')) {
                popup.showPopup(PopupType.CustomPopup);
            }
            emit('open');
        }
    }
}

function close(): void {
    if (isOpened.value) {
        isOpened.value = false;
        props.formField.touch();
        if (props.type.startsWith('popup')) {
            popup.showPopup(PopupType.None);
        }
        emit('close');
    }
}

function closeFromOutside(): void {
    if (new AppBrowser().isDesktop()) {
        close();
    }
}

function select(option: InputOption): void {
    props.formField.patch(option.value);
    props.formField.validate();
    close();
}

function tooltipsterClicked(event: VueEvent): void {
    event.event.stopPropagation();
}

function mouseLeave(): void {
    showSingleTooltip.value = false;
    AlignedTooltip.getInstance().hide();
}

function checkDisabledStatus(value: InputOption[] | null = null): void {
    isDisabled.value = props.options.length === 0 || props.disabled || (value !== null && value.length === 0);
    if (!props.formField.isEmpty() && props.formField.value !== '' && selectedOption.value !== props.formField.value) {
        selectedOption.value = optionByValue(String(props.formField.value));
        emitDisabledChange(isDisabled.value);
    }
}

function applyHeightOnWide(): void {
    if (props.type === 'popup-wide') {
        nextTick(() => {
            const lineHeight: number = 32;
            const paddings: number = 20;
            const maxLinesBeforeScroll: number = 6;
            const maxHeight: number = maxLinesBeforeScroll * lineHeight + paddings;
            popupContainerMaxHeight = maxHeight + 'px';
        });
    }
}

function optionByValue(value: string): InputOption {
    return props.options.find((option: InputOption) => option.value.toString() === value) || props.emptyOption;
}

function scrollToSelectedElement() {
    const selectedOptionFromSelector: JQuery = $(dropdownElement.value!).find('.selected');
    if (selectedOptionFromSelector && selectedOptionFromSelector.length) {
        dropdownElement.value?.scroll({ top: selectedOptionFromSelector[0].offsetTop, behavior: 'smooth' });
    }
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();

    emit('change', props.formField.value);
}

function emitDisabledChange(currentValue: boolean, valueBefore?: boolean | null): void {
    if (currentValue !== valueBefore) {
        emit('disabled-change', currentValue);
    }
}

defineExpose({
    scrollToSelectedElement,
    close,
});
</script>

<template>
    <div
        :id="formField.name"
        class="input input-select"
        :class="{ ...formField.classes(), disabled: isDisabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" :id="fieldId()" class="label hide-on-mobile">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="labelWithTip.title" :id="fieldId()" class="label">
            <app-text-with-tip
                class="label-tooltip"
                :title="labelWithTip.title"
                :tip-description="labelWithTip.tipDescription"
            ></app-text-with-tip>
        </div>
        <div :id="tipId" class="wrapper" @mouseleave="mouseLeave()">
            <div :id="id" v-click-outside="closeFromOutside" class="select default">
                <button
                    v-if="!alwaysExpanded"
                    :id="formField.name + '-open'"
                    class="button"
                    :aria-labelledby="fieldId()"
                    @click="open()"
                >
                    <span
                        v-if="selectedOption === emptyOption"
                        class="text text-icon placeholder"
                        v-html="placeholder || selectedOption.name"
                    ></span>
                    <span
                        v-if="selectedOption !== emptyOption"
                        class="text text-icon"
                        v-html="selectedOption.name || selectedOption.value"
                    ></span>
                    <span v-if="showOpenIcon" class="icon">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 1L7 7L1 1"
                                stroke="#E30613"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </span>
                    <span v-if="loading" class="icon">
                        <app-content-loader class="loader" :icon-type="'spinner'"></app-content-loader>
                    </span>
                </button>
                <div v-if="infoMessageIsVisible()" class="feedback" v-html="infoMessage()"></div>
                <div
                    v-if="type === 'dropdown' && isOpened"
                    ref="dropdownElement"
                    class="dropdown"
                    :class="{ up: dropUpwards }"
                >
                    <button
                        v-if="allowEmptySelection"
                        :id="formField.name + '-dropdown-select-empty'"
                        class="item"
                        :class="{ selected: emptyOption === selectedOption }"
                        @click="select(emptyOption)"
                        v-html="emptyOption.name || emptyOption.value"
                    ></button>
                    <button
                        v-for="(option, index) in options"
                        :id="formField.name + '-dropdown-select-option-' + index"
                        :key="index"
                        class="item"
                        :class="{ selected: option === selectedOption }"
                        @click="select(option)"
                        v-html="option.name || option.value"
                    ></button>
                </div>
                <div v-if="type === 'popup' && isOpened" class="popups">
                    <app-popup class="simple list" @close="close()">
                        <div v-if="showLabel" class="title">{{ labelText }}</div>
                        <span class="list-details">
                            <button
                                v-if="allowEmptySelection"
                                :id="formField.name + '-popup-select-empty'"
                                class="item"
                                @click="select(emptyOption)"
                            >
                                <span class="label">{{ emptyOption.name }}</span>
                            </button>
                            <button
                                v-for="(option, index) in options"
                                :id="formField.name + '-popup-select-option-' + index"
                                :key="index"
                                class="item"
                                @click="select(option)"
                            >
                                <span class="label" v-html="option.name || option.value"></span>
                                <app-tooltipster
                                    v-if="option.tooltip"
                                    :open-on-hover="true"
                                    :title="option.tooltip.title"
                                    :description="option.tooltip.description"
                                    @icon-click="tooltipsterClicked(new VueEvent($event))"
                                >
                                </app-tooltipster>
                            </button>
                        </span>
                    </app-popup>
                </div>
                <div v-if="type === 'popup-wide' && isOpened" class="popups">
                    <app-popup
                        class="simple list-wide"
                        :styles="'max-height:' + popupContainerMaxHeight"
                        :close-on-overlay-click="true"
                        @close="close()"
                    >
                        <span class="list-details">
                            <button
                                v-if="allowEmptySelection"
                                :id="formField.name + '-popup-select-empty'"
                                class="item"
                                @click="select(emptyOption)"
                            >
                                <span class="label">{{ emptyOption.name }}</span>
                            </button>
                            <button
                                v-for="(option, index) in options"
                                :id="formField.name + '-popup-select-option-' + index"
                                :key="index"
                                class="item"
                                :class="{ selected: option.value === formField.value, hidden: option.hidden }"
                                @click="select(option)"
                            >
                                <span class="label">{{ option.name || option.value }}</span>
                                <img
                                    class="check-mark"
                                    src="images/one/components/select/checkmark.svg"
                                    alt="check mark"
                                />
                            </button>
                        </span>
                    </app-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-select {
    scroll-margin-top: 4em;

    .wrapper {
        .popups {
            .list {
                .list-details {
                    .item {
                        > .label:not(:only-child) {
                            margin-right: var(--size-big);
                        }

                        > .tooltipster {
                            right: 15px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    &.invalid {
        .wrapper {
            .select {
                .button {
                    border-color: var(--brand-red);
                }
            }
        }
    }
}

.input {
    .placeholder {
        opacity: 0.56;
        color: var(--black-500);
    }

    .dropdown {
        display: block;

        .item {
            height: auto;
            min-height: 52px;
            text-align: left;
        }
    }

    &.invalid.touched .select .button {
        border-color: var(--brand-red);
    }

    .loader {
        width: 16px;
        height: 16px;
    }
}

.disabled {
    .wrapper {
        > .select {
            pointer-events: none;

            > button {
                background-color: var(--component-color-background-disabled);

                .text {
                    color: var(--black-600);
                }
            }
        }
    }
}

.travel-insurance {
    .travelers-inputs {
        .input-select {
            .hide-on-mobile {
                display: none;

                @include respond-above('sm') {
                    display: flex;
                }
            }
        }
    }
}
</style>
