import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Validation from '@/services/validation.service';

export default class SummaryValidators {
    private form: Form = new Form();

    public init(form: Form): void {
        this.form = form;
    }

    public identityNumberValidator(): object {
        return {
            validIdentityNumber: (): boolean => {
                return this.form.field('policy-holder-code').isTouched ? this.isValidIdentityNumber() : true;
            },
        };
    }

    public captionValidator(assignedToField: string): object {
        const field: FormField = this.form.field(assignedToField);
        return {
            validCaption: (): boolean => (field.isTouched ? Validation.isValidCaption(field.value) : true),
        };
    }

    private isValidIdentityNumber(): boolean {
        const personCodeField: FormField = this.form.field('policy-holder-code');
        const countryField: FormField = this.form.field('policy-holder-country');
        const personIsResident: boolean = this.form.field('policy-holder-resident').value;
        const validationResult: boolean = personIsResident
            ? Validation.isValidNaturalPersonCode(personCodeField.value)
            : Validation.isValidNaturalPersonCode(personCodeField.value, countryField.value.iso);

        return validationResult && personCodeField.isNotEmpty();
    }
}
