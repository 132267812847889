import { LimitedVariant } from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import OneBaseService from '@/services/OneBaseService';

const { isSet, isCallable } = useDefine();

export const useModel = (): ModelParams => {
    const modelValueByName = (model: string | Function, context: DynamicDictionary | null = null): LimitedVariant => {
        let result: LimitedVariant = null;
        if (context && isSet(context[model as string])) {
            if (isSet(context[model as string].value) && !isSet(context[model as string].id)) {
                result = asString(context[model as string].value);
            } else {
                result = asString(context[model as string]);
            }
        } else if (isCallable(model)) {
            result = asString((model as Function)());
        }

        return result;
    };

    const callMethodByName = (
        methodName: string | Function,
        context: DynamicDictionary | null = null,
        params: LimitedVariant = null,
    ): LimitedVariant => {
        let result: LimitedVariant = null;
        if (isSet(context)) {
            const method: DynamicDictionary = context![methodName as string];
            if (isCallable(method)) {
                result = context![methodName as string](params);
            }
        } else if (isCallable(methodName as Function)) {
            (methodName as Function)(params);
        }

        return result;
    };

    const contextByMemberName = (modelName: string): DynamicDictionary | null => {
        let result: DynamicDictionary | null = null;
        if (isSet((OneBaseService.getInstance() as DynamicDictionary)[modelName])) {
            result = OneBaseService.getInstance();
        }
        if (
            isSet(OneBaseService.getInstance().currentApp()) &&
            isSet((OneBaseService.getInstance().currentApp() as DynamicDictionary)[modelName])
        ) {
            result = OneBaseService.getInstance().currentApp();
        }

        return result;
    };

    const setModelValue = (modelName: string, value: LimitedVariant): void => {
        const currentContext: DynamicDictionary | null = contextByMemberName(modelName);
        if (currentContext) {
            if (
                isSet((currentContext as DynamicDictionary)[modelName].value) &&
                isSet((currentContext as DynamicDictionary)[modelName].id)
            ) {
                (currentContext as DynamicDictionary)[modelName].value = value;
            } else {
                (currentContext as DynamicDictionary)[modelName] = value;
            }
        }
    };

    const asString = (value: string | boolean): string => {
        let result: string | boolean = value;
        if (typeof value === 'boolean') {
            result = value ? 'true' : 'false';
        }

        return result as string;
    };

    return {
        modelValueByName,
        callMethodByName,
        contextByMemberName,
        setModelValue,
    };
};

export interface ModelParams {
    modelValueByName: (model: string | Function, context: DynamicDictionary | null) => LimitedVariant;
    callMethodByName: (
        methodName: string | Function,
        context: DynamicDictionary | null,
        params: LimitedVariant,
    ) => LimitedVariant;
    contextByMemberName: (modelName: string) => DynamicDictionary | null;
    setModelValue: (modelName: string, value: LimitedVariant) => void;
}
