<script setup lang="ts">
import Form from '@/assets/libraries/form/form';
import { getCurrentInstance, onMounted, ref, Ref, watch } from 'vue';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import FormField from '@/assets/libraries/form/form-field';
import Translations from '@/services/translations.service';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import { SolarPanels } from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';
import { CoveragePlan } from '@/Apps/SolarPanels/Interfaces/CoveragePlanInterface';
import { CoveredPopupBuilder } from '@/Apps/SolarPanels/Builders/CoveredPopupBuilder';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupService from '@/services/custom.popup.service';
import { useFormatter } from '@/Composables/Formatter';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import { useDefine } from '@/Composables/Define';
import RequestService from '@/services/request.service';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Steps from '@/Apps/SolarPanels/Enums/Steps';
import OneBaseService from '@/services/OneBaseService';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppInputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import AppButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import { usePrice } from '@/Composables/Price';

const { translate, translateForType } = useTranslate();
const { sparsePrice } = useFormatter();
const form: Form = new Form();
const router: Router = useRouter();
const storage: Storage = Storage.getInstance();
const coverageOptions: Ref<InputOption[]> = ref([]);
const price: Ref<number> = ref(0);
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const popupService: PopupService = PopupService.getInstance();
const { isSet } = useDefine();

let products: SolarPanels[] = [];

watch(
    () => form.field('coverage-amount').value,
    (): void => {
        storage.fields.selectedProgram!.insuredSum = form.field('coverage-amount').value;
    },
);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    storage.updateRoute();
    StepsGuard.getInstance(storage).init();
    if (isSet(storage.fields.selectedProgram)) {
        fetchProducts().then(() => {
            Translations.getInstance().addType('solar_panels_coverage');
            setupForm();
            buildCoveredPopup();
            buildCoverageOptions();
        });
    }
});

defineExpose({
    coveredPopup,
});

function fetchProducts(): Promise<void> {
    return new Promise((resolve) => {
        RequestService.getInstance()
            .get({
                uri: Url.Ajax.solarPanelsProducts,
            })
            .then((response: AxiosResponse): void => {
                if (useDefine().validResponse(response)) {
                    products = response.data.data.body.products;
                    resolve();
                }
            })
            .catch((reason: DynamicDictionary): void => {
                Error.log(ErrorType.Error, 'Solar panels policy / Insurance page / Products from cache', reason);
            });
    });
}

function setupForm(): void {
    price.value = storage.fields.selectedProgram!.price;
    form.addField(
        new FormField('coverage-amount', storage.fields.selectedProgram!.insuredSum).addValidators('required'),
    );
    form.setReady();
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function proceedButton(): DynamicDictionary {
    return {
        title: translate('continue_btn'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconColor: ButtonIconColor.White,
        iconPosition: ButtonIconPosition.Right,
    };
}

function whatsCoveredButton(): DynamicDictionary {
    return {
        title: localized('see_what_covered'),
        textColor: ButtonTextColor.Black,
        backgroundColor: ButtonBackground.White,
        backgroundColorHover: ButtonBackground.White,
        icon: ButtonIcon.Covered,
        iconColor: ButtonIconColor.Green,
        borderColor: ButtonBorder.Pale,
    };
}

function onProceed(): void {
    storage.fields.selectedProgram!.insuredSum = form.field('coverage-amount').value;
    router.push({ name: Steps.Address });
}

function buildCoverageOptions(): void {
    const productFromStorage: SolarPanels | undefined = selectedProduct();
    if (productFromStorage) {
        productFromStorage.coveragePlans.forEach((plan: CoveragePlan): void => {
            coverageOptions.value.push(
                new InputOptionBuilder()
                    .setValue(plan.insuredSum)
                    .setName(sparsePrice(plan.insuredSum, true) + ' &euro;')
                    .build(),
            );
        });
    }
}

function selectedProduct(): SolarPanels | undefined {
    const storedProductId: string = selectedProductId();
    return products!.find((product: SolarPanels): boolean => product.id === storedProductId);
}

function selectedProductId(): string {
    return storage.fields.selectedProgram!.programIc;
}

function onCoverageChange(): void {
    storage.fields.selectedProgram!.insuredSum = form.field('coverage-amount').value;
    storage.fields.selectedProgram!.price = Number(
        selectedProduct()?.coveragePlans.find(
            (plan: CoveragePlan): boolean => plan.insuredSum === form.field('coverage-amount').value,
        )?.price,
    );
    price.value = storage.fields.selectedProgram!.price;
}

function buildCoveredPopup(): void {
    const product: SolarPanels[] = [];
    const storedProductId: string = selectedProductId();
    product.push(products!.filter((solarPanels: SolarPanels): boolean => solarPanels.id === storedProductId)[0]);
    coveredPopup
        .withCoveredType('AppCoveredPopupSolarPanelsMulti')
        .withContent(product)
        .withCoveragePlanKey(storage.fields.selectedProgram!.insuredSum)
        .withSingleMode();
}

function showCoveredPopup(): void {
    coveredPopup.withCoveragePlanKey(storage.fields.selectedProgram!.insuredSum);
    popupService.show(new OnePopup().withType().oneCovered);
}

function priceWithCents(value: number): string {
    return usePrice().formattedCurrency(value);
}
</script>

<template>
    <div class="container">
        <app-custom-form v-if="form.isReady()" :form="form" class="form">
            <a class="to-previous-step" @click="router.back()">
                <img src="images/one/arrow-left.svg" alt="back" />
                <span>{{ translate('back_button') }}</span>
            </a>
            <h1 class="page-title">{{ localized('title') }}</h1>
            <div id="whiteboard-0" class="whiteboard">
                <h3 class="whiteboard-subtitle">{{ localized('choose_amount') }}</h3>
                <p class="description">{{ localized('description') }}</p>
                <app-input-radio-overlayed
                    class="coverage-options"
                    :form-field="form.field('coverage-amount')"
                    :options="coverageOptions"
                    @change="onCoverageChange()"
                >
                </app-input-radio-overlayed>
                <div class="payment">
                    <div class="payment-text">{{ localized('your_monthly_payment') }}</div>
                    <div class="price">
                        <span class="amount">{{ priceWithCents(price) }}</span>
                        {{ translate('btar_policy_price_text') }}
                    </div>
                </div>
                <div class="buttons">
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        :disabled="!form.field('coverage-amount').isValid"
                        @button-callback-click="onProceed()"
                    >
                    </app-button-with-callback>
                    <app-button-with-callback
                        class="button"
                        v-bind="whatsCoveredButton()"
                        @button-callback-click="showCoveredPopup()"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.layout-container {
    .route-content {
        .container {
            .whiteboard {
                max-width: 580px !important;
                gap: var(--size-nano);

                .whiteboard-subtitle {
                    color: var(--black-950);
                    text-align: left;
                    font-size: var(--font-size-medium);
                    font-weight: 700;

                    @include respond-above('sm') {
                        text-align: center;
                        width: 100%;
                    }
                }

                .description {
                    color: var(--text-color-subtle);
                    font-size: var(--font-size-tiny);
                    font-weight: 500;
                    margin-bottom: var(--size-nano);

                    @include respond-above('sm') {
                        text-align: center;
                        width: 100%;
                    }
                }
            }

            .payment {
                display: flex;
                justify-content: space-between;
                margin-top: var(--size-tiny);
                margin-bottom: var(--size-tiny);
                width: 100%;

                @include respond-above('sm') {
                    align-items: flex-start;
                }

                .payment-text {
                    font-size: var(--font-size-tiny);
                    font-weight: 600;
                    margin-top: 2px;
                }

                .price {
                    margin-top: 8px;
                    color: var(--system-color-success-dark);

                    .amount {
                        font-size: var(--font-size-big);
                        font-weight: 700;
                    }
                }
            }

            .buttons {
                display: flex;
                flex-direction: column;
                gap: var(--size-nano);
                width: 100%;

                button {
                    min-height: 52px;
                }
            }

            :deep(.overlay-button) {
                padding: var(--size-tiny);
            }
        }
    }
}
</style>
