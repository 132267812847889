<script setup lang="ts">
import ObjectItem from '@/interfaces/ObjectItemInterface';
import { PropType } from 'vue';

const props = defineProps({
    items: { type: Array as PropType<ObjectItem[]>, default: () => [] },
});
const emit = defineEmits<{
    (event: 'edit', id: string): void;
    (event: 'remove', id: string): void;
}>();
</script>
<template>
    <div class="object-list">
        <div v-for="(item, index) in items" :key="index">
            <div>
                <div class="title">{{ item.title }}</div>
                <div class="value">{{ item.value }}</div>
            </div>
            <div class="actions">
                <div @click="emit('edit', item.id)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.1038 4.66848C15.3158 4.45654 15.5674 4.28843 15.8443 4.17373C16.1212 4.05903 16.418 4 16.7177 4C17.0174 4 17.3142 4.05903 17.5911 4.17373C17.868 4.28843 18.1196 4.45654 18.3315 4.66848C18.5435 4.88041 18.7116 5.13201 18.8263 5.40891C18.941 5.68582 19 5.9826 19 6.28232C19 6.58204 18.941 6.87882 18.8263 7.15573C18.7116 7.43263 18.5435 7.68423 18.3315 7.89617L7.43807 18.7896L3 20L4.21038 15.5619L15.1038 4.66848Z"
                            stroke="#1D1F23"
                            stroke-opacity="0.64"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <rect x="10" y="19" width="11" height="1.5" rx="0.75" fill="#1D1F23" fill-opacity="0.64" />
                        <path d="M13 6L17 10" stroke="#1D1F23" stroke-opacity="0.64" stroke-width="1.5" />
                    </svg>
                </div>
                <div @click="emit('remove', item.id)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M10 11V17M14 11V17"
                            stroke="#E30613"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.object-list {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: var(--size-nano);

    > div {
        border: 1px solid var(--component-color-border-default);
        border-radius: var(--size-pico);
        display: flex;
        padding: var(--size-small);
        gap: var(--size-small);
        justify-content: space-between;

        > div:not(.actions) {
            gap: var(--size-femto);
            display: flex;
            flex-direction: column;

            .title {
                font-weight: 600;
            }

            .value {
                color: var(--text-color-subtlest);
                font-size: 14px;
                font-weight: 500;
            }
        }

        .actions {
            align-self: center;
            display: flex;
            height: var(--size-normal);

            > div {
                cursor: pointer;
                width: var(--size-normal);

                &:not(:last-child) {
                    margin-right: 41px;
                    position: relative;

                    &::after {
                        cursor: default;
                        pointer-events: none;
                        left: 44px;
                        position: absolute;
                        content: '';
                        height: 24px;
                        width: 1px;
                        background: var(--black-100);
                    }
                }
            }
        }
    }
}
</style>
