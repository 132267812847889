<script lang="ts">
import { computed, defineComponent, reactive, ref, Ref, nextTick, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import { Subject, Subscription } from 'rxjs';
import BusinessModal from '@/pages/LegalPerson/Workbench/Common/BusinessModal';
import LegalPrograms from '@/pages/LegalPerson/Workbench/Common/LegalPrograms';
import ExcelTemplates from '@/pages/LegalPerson/Workbench/Common/ExcelTemplates';
import LegalPersons from '@/pages/LegalPerson/Workbench/Common/LegalPersons';
import { InputOption } from '@/interfaces/InputOptionInterface';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import AxiosCache from '@/services/axios.cache.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import LegalPersonTabState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonTabStateEnum';
import LegalInsuranceProgram from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuranceProgramInterface';
import LegalDocument from '@/pages/LegalPerson/Workbench/Interfaces/LegalDocumentInterface';
import BusinessTab from '@/pages/LegalPerson/Workbench/Common/Business.tab';
import Translations from '@/services/translations.service';
import Dimensions from '@/interfaces/dimensions.interface';
import PageContentSize from '@/interfaces/page.content.size.service';
import BusinessDashboard from '@/pages/LegalPerson/Workbench/Tabs/BusinessDashboard';
import BusinessInsuredPersons from '@/pages/LegalPerson/Workbench/Tabs/BusinessInsuredPersons';
import BusinessPrograms from '@/pages/LegalPerson/Workbench/Tabs/BusinessPrograms';
import BusinessDocuments from '@/pages/LegalPerson/Workbench/Tabs/BusinessDocuments';
import BusinessHelp from '@/pages/LegalPerson/Workbench/Tabs/BusinessHelp';
import moment from 'moment/moment';
import Value from '@/assets/libraries/form/value';
import { useTranslate } from '@/Composables/Translate';
import PopupBase from '@/assets/libraries/popups/popup.base';
import OnePopup from '@/assets/libraries/popups/one.popup';
import PopupIcon from '@/Enums/PopupIconEnum';
import PopupInput from '@/Enums/PopupInputEnum';
import PopupService from '@/services/custom.popup.service';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import LegalPersonModalState from '@/pages/LegalPerson/Workbench/Enums/LegalPersonModalStateEnum';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import LegalAgreement from '@/pages/LegalPerson/Workbench/Interfaces/LegalAgreementInterface';
import LegalProcessing from '@/pages/LegalPerson/Workbench/Services/LegalProcessingService';
import Url from '@/Enums/UrlEnum';
import FormField from '@/assets/libraries/form/form-field';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { DateTimeImmutable } from '@/interfaces/date.time.immutable.interface';
import FaqListModule from '@/Modules/Faq/FaqList.vue';
import User from '@/services/user.service';
import Paginator from '@/assets/libraries/paginator/paginator';
import VueModule from '@/Core/App/VueModule';
import AppCountry from '@/assets/libraries/app/app-country';
import RequestService from '@/services/request.service';
import { AxiosResponse } from 'axios';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const faqListModule = new VueModule(FaqListModule).mount();

        const CurrentStep: number = 1;
        const Facility: string = 'legal';
        const DateFormat: string = 'DD.MM.YYYY';
        const DateFormats: string[] = ['DD/MM/YYYY', 'DD.MM.YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'];
        const processing: LegalProcessing = new LegalProcessing();
        const onDetailsUpdated: Subject<void> = new Subject<void>();
        const excelTemplates: ExcelTemplates = new ExcelTemplates();
        const axiosCache: AxiosCache = AxiosCache.getInstance();
        const windowResizeCallback: Function = windowResizeCallbackReceiver.bind(this);

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const initialized: Ref<boolean> = ref(false);
        const documentsFetched: Ref<boolean> = ref(false);
        const currentActiveTab: Ref<string> = ref(LegalPersonTabState.None);
        const navigationButtons: Ref<InputOption[]> = ref([]);
        const navigationUrls: Ref<StringDictionary> = ref({});
        const policiesList: Ref<InputOption[]> = ref([]);
        const availablePrograms: Ref<LegalInsuranceProgram[]> = ref([]);
        const tabsWithNoPolicies: Ref<string[]> = ref([LegalPersonTabState.Help]);
        const tabsWithPoliciesDetailsFetch: Ref<string[]> = ref([
            LegalPersonTabState.InsuredPersons,
            LegalPersonTabState.Programs,
            LegalPersonTabState.Documents,
        ]);
        const styleObject: Ref<DynamicDictionary> = ref({});
        const addPersonSearch: Ref<HTMLDivElement | null> = ref(null);
        const inputSearch: Ref<HTMLDivElement | null> = ref(null);
        const dashboardIsReady: Ref<boolean> = ref(false);
        const legalPrograms: UnwrapNestedRefs<LegalPrograms> = reactive(new LegalPrograms());
        const insuredPersons: LegalPersons = new LegalPersons();

        const tab: Ref<BusinessTab> = ref(new BusinessTab() as any);
        const businessModal: Ref<BusinessModal> = ref(new BusinessModal() as any);
        const documentsPaginator: Paginator<LegalDocument> = new Paginator({ pageSize: 10 });

        const popupServices: Ref<PopupService> = computed(() => {
            return btaBase.popupService;
        });

        const currentTab: Ref<string> = computed(() => {
            return currentActiveTab.value;
        });

        const legalPolicies: Ref<InputOption[]> = computed(() => {
            return policiesList.value;
        });

        const legalPersons: Ref<LegalInsuredPerson[]> = computed(() => {
            return insuredPersons.persons;
        });

        const legalAvailablePrograms: Ref<LegalInsuranceProgram[]> = computed(() => {
            return availablePrograms.value;
        });

        const legalAvailableDocuments: Ref<LegalDocument[]> = computed(() => {
            return documentsPaginator.currentPageItems.value;
        });

        const modalPopupState: Ref<typeof LegalPersonModalState> = computed(() => {
            return LegalPersonModalState;
        });

        const legalPersonTabState: Ref<typeof LegalPersonTabState> = computed(() => {
            return LegalPersonTabState;
        });

        const mustShowPoliciesList: Ref<boolean> = computed(() => {
            return !tabsWithNoPolicies.value.includes(currentActiveTab.value);
        });

        const policyId: Ref<string> = computed(() => {
            return form.field('selectedPolicy').value;
        });

        const insuredPersonsCount: Ref<number> = computed(() => {
            return currentAgreement.value ? currentAgreement.value.objectsCount : 0;
        });

        const insuranceProgramsCount: Ref<number> = computed(() => {
            return currentAgreement.value ? currentAgreement.value.programsCount : 0;
        });

        const legalAvailableProgramInputOptions: Ref<InputOption[]> = computed(() => {
            return legalAvailablePrograms.value.map((program: LegalInsuranceProgram): InputOption => {
                return new InputOptionBuilder()
                    .setValue(program.id)
                    .setName(program.name)
                    .setHidden(!program.showInList)
                    .build();
            });
        });

        const currentAgreement: Ref<LegalAgreement | undefined> = computed(() => {
            return User.getInstance().current.selectedEntity!.agreements.find(
                (agreement: LegalAgreement): boolean => agreement.number === policyId.value,
            );
        });

        const contentBlockStyleObject: Ref<DynamicDictionary> = computed(() => {
            return styleObject.value;
        });

        const minPolicyEndDate: Ref<Date> = computed(() => {
            return new AppCountry().isLT() ? moment().subtract(1, 'day').toDate() : moment().add(1, 'day').toDate();
        });

        const showDocumentsAbsentBlock: Ref<boolean> = computed(() => {
            return legalAvailableDocuments.value.length === 0 && documentsFetched.value;
        });

        const currentPolicy: Ref<InputOption> = computed(() => {
            let result: InputOption = new InputOptionBuilder().setCustom({ modified: false }).build();
            policiesList.value.forEach((policy: InputOption): void => {
                if (policy.value === policyId.value) {
                    result = policy;
                }
            });

            return result;
        });

        const currentPolicyIsActive: Ref<boolean> = computed(() => {
            return currentAgreement.value ? currentAgreement.value.hasActivePolicy : false;
        });

        function setupForm(): void {
            form.addField(new FormField('selectedPolicy'));
        }

        function setFormReady(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function componentRef(refName: string): HTMLDivElement | null {
            let result: HTMLDivElement | null = null;
            switch (refName) {
                case 'addPersonSearch':
                    result = addPersonSearch.value;
                    break;

                case 'inputSearch':
                    result = inputSearch.value;
                    break;

                default:
            }

            return result;
        }

        function windowResizeCallbackReceiver(size: Dimensions): void {
            const minHeightLimit: number = 453;
            const newMinHeight: string = PageContentSize.getInstance()
                .applyMinHeightLimit(minHeightLimit)
                .minHeight(size);
            styleObject.value = {
                minHeight: newMinHeight,
            };
        }

        function setupTabInstance(context: DynamicDictionary): void {
            if (tab.value) {
                tab.value.destroy();
            }
            switch (currentActiveTab.value) {
                case LegalPersonTabState.Dashboard:
                    tab.value = BusinessDashboard.getInstance();
                    break;

                case LegalPersonTabState.InsuredPersons:
                    tab.value = BusinessInsuredPersons.getInstance();
                    break;

                case LegalPersonTabState.Programs:
                    tab.value = BusinessPrograms.getInstance();
                    break;

                case LegalPersonTabState.Documents:
                    tab.value = BusinessDocuments.getInstance();
                    break;

                case LegalPersonTabState.Help:
                    tab.value = BusinessHelp.getInstance();
                    break;

                default:
            }
            tab.value.addContext(context);
        }

        function applyTabUid(tabValue: string): void {
            currentActiveTab.value = tabValue;
            applyActiveTab(tabValue);
        }

        function applyExcelTemplates(content: DynamicDictionary, countryIso: string): void {
            excelTemplates.addContent(content, countryIso);
        }

        function currentTabIs(tabValue: string): boolean {
            return currentActiveTab.value === tabValue;
        }

        function applyNavigationUrls(urls: StringDictionary): void {
            navigationUrls.value = urls;
        }

        function onRemovePersonConfirmClick(): void {
            businessModal.value.personForEdit.endDate = moment(btaBase.popupService.inputField.value.startDate).format(
                DateFormat,
            );
            insuredPersons.deletePersons([businessModal.value.personForEdit]).finally((): void => {
                fetchPolicyDetailsWithoutCache().then((): void => {
                    businessModal.value.closeModalPopup();
                });
            });
        }

        function closeModalPopup(): void {
            businessModal.value.closeModalPopup();
        }

        function onPolicyChange(newValue: string): void {
            if (!new Value(newValue).isEmpty() && initialized.value) {
                onPolicyIdChange().then();
            }
        }

        function translated(stringId: string, replacements?: DynamicDictionary): string {
            return useTranslate().translateForType(stringId, useTranslate().type(), replacements);
        }

        function applyModifiedStatusOnCurrentPolicy(): void {
            (currentPolicy.value.custom as DynamicDictionary).modified = true;
        }

        function applyUnmodifiedStatusOnCurrentPolicy(): void {
            (currentPolicy.value.custom as DynamicDictionary).modified = false;
        }

        function showRemovePersonPopup(): void {
            const removePersonPopup: PopupBase = new OnePopup()
                .withType()
                .confirmPopup.withTitle(
                    translated('remove_person_question', {
                        insuredPerson:
                            businessModal.value.personForEdit.firstName +
                            ' ' +
                            businessModal.value.personForEdit.lastName,
                    }),
                )
                .withConfirmButtonText(translated('confirm_and_remove'))
                .withIcon(PopupIcon.AlertTriangle)
                .withInput(PopupInput.Calendar)
                .withLabelForCalendar(translated('policy_end_date'))
                .withCancelButtonText(translated('cancel'))
                .withStartDateForCalendar(minPolicyEndDate.value)
                .withMinDateForCalendarInput(minPolicyEndDate.value)
                .withMaxDateForCalendarInput(moment(currentAgreementEndDate()).subtract(1, 'day').toDate())
                .withConfirmCallback(onRemovePersonConfirmClick)
                .withCancelCallback('closePopup', btaBase.popup);
            btaBase.popupService.show(removePersonPopup);
        }

        function currentAgreementEndDate(): moment.Moment {
            return moment(currentAgreement.value!.endDate.date, 'YYYY-MM-DD');
        }

        function onPolicyIdChange(): Promise<void> {
            return fetchDetailsForCurrentPolicy()
                .then()
                .finally((): void => {
                    btaBase.userStorage.saveForms(true).then((): void => {
                        PopupService.getInstance().hide();
                        tab.value.init();
                        openModalByHash();
                    });
                });
        }

        function fetchDetailsForCurrentPolicy(): Promise<void> {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            let result: Promise<void> = Promise.resolve();
            const newCurrentAgreement: LegalAgreement | undefined = currentAgreement.value;
            if (newCurrentAgreement && tabsWithPoliciesDetailsFetch.value.includes(currentActiveTab.value)) {
                const params: DynamicDictionary = ajaxParams(newCurrentAgreement);
                result = RequestService.getInstance()
                    .get({ uri: Url.Ajax.legalPersonPolicyDetails, content: params })
                    .then((response: AxiosResponse): void => {
                        const axiosResponse: DynamicDictionary = response.data.data.body;
                        applyDetails(axiosResponse);
                        dashboardIsReady.value = true;
                        tab.value.onPolicyChanged(policyId.value);
                    });
            }
            buildNavigationBelt();

            return result;
        }

        function fetchPolicyDetailsWithoutCache(): Promise<void> {
            const newCurrentAgreement: LegalAgreement | undefined = currentAgreement.value;
            const params: DynamicDictionary = ajaxParams(newCurrentAgreement!);
            axiosCache.destroy();

            return RequestService.getInstance()
                .get({ uri: Url.Ajax.legalPersonPolicyDetails, content: params })
                .then((response: AxiosResponse): void => {
                    const axiosResponse: DynamicDictionary = response.data.data.body;
                    applyDetails(axiosResponse);
                    buildNavigationBelt();
                    tab.value.onPolicyChanged(policyId.value);
                });
        }

        function ajaxParams(currentLegalAgreement: LegalAgreement): DynamicDictionary {
            return {
                agreementId: currentLegalAgreement!.id,
                agreementNumber: currentLegalAgreement!.number,
                tab: currentActiveTab.value,
            };
        }

        function isDocumentsTab(): boolean {
            return currentActiveTab.value === LegalPersonTabState.Documents;
        }

        function isDocumentTabAndIsVisibleForCurrentPolicy(tabValue: string): boolean {
            return tabValue === LegalPersonTabState.Documents
                ? (currentPolicy.value.custom as DynamicDictionary).showDocumentSection
                : true;
        }

        function onPageChangeClick(page: number): void {
            documentsPaginator.changePage(page);
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        }

        function applyDetails(response: DynamicDictionary): void {
            const newInsuredPersons: LegalInsuredPerson[] = response.insuredPersons;
            insuredPersons.applyPersons(newInsuredPersons);
            processing.applyPersons(newInsuredPersons);
            availablePrograms.value = response.insurancePrograms;
            if (isDocumentsTab()) {
                documentsPaginator.setItems(response.documents);
            }
            documentsFetched.value = true;
            legalPrograms.rebuildGrid();
            insuredPersons.rebuildGrid();
            onDetailsUpdated.next();
            $('html, body').animate({ scrollTop: 0 }, 'slow');
        }

        function checkRestoredPolicyIdAndResetIfNeeded() {
            let hasValueInList: boolean = false;
            policiesList.value.forEach((policy: InputOption): void => {
                if (policy.value === policyId.value) {
                    hasValueInList = true;
                }
            });
            if (policiesList.value.length > 0 && !hasValueInList) {
                form.field('selectedPolicy').patch(policiesList.value[0].value);
            }
        }

        function policyHasChanged(): boolean {
            let result: boolean = false;
            policiesList.value.forEach((policy: InputOption): void => {
                if (policy.value === policyId.value) {
                    result = true;
                }
            });

            return result;
        }

        function buildPoliciesList(): void {
            const currentAgreements: LegalAgreement[] = btaBase.user.current.selectedEntity!.agreements;
            currentAgreements.forEach((agreement: LegalAgreement): void => {
                const startDate: string = moment(agreement.startDate.date).format('DD.MM.YYYY');
                const endDate: string = moment(agreement.endDate.date).format('DD.MM.YYYY');
                policiesList.value.push(
                    new (class implements InputOption {
                        public name: string = agreement.number;
                        public value: string = agreement.number;
                        public custom: LimitedVariant = {
                            postfix: startDate + ' — ' + endDate,
                            showDocumentSection: agreement.showDocumentSection,
                        };
                        public disabled: boolean = false;
                        public hidden: boolean = false;
                    })(),
                );
            });
        }

        function buildNavigationBelt(): void {
            navigationButtons.value = [];
            Object.keys(legalPersonTabState.value).forEach((key: string) => {
                const value: string = (legalPersonTabState.value as DynamicDictionary)[key];
                if (value !== '' && isDocumentTabAndIsVisibleForCurrentPolicy(value)) {
                    navigationButtons.value.push(
                        new InputOptionBuilder()
                            .setName(translated(value.replaceAll('-', '_')))
                            .setValue(navigationUrls.value[value])
                            .setCustom({
                                active: currentActiveTab.value === value,
                                counter: tabCounter(value),
                                tab: value,
                            })
                            .build(),
                    );
                }
            });
        }

        function tabCounter(tabValue: string): number {
            let result: number;
            switch (tabValue) {
                case LegalPersonTabState.InsuredPersons:
                    result = insuredPersonsCount.value;
                    break;
                case LegalPersonTabState.Programs:
                    result = insuranceProgramsCount.value;
                    break;
                default:
                    result = 0;
            }

            return result;
        }

        function applyActiveTab(tabValue: string): void {
            navigationButtons.value.forEach((item: InputOption) => {
                if (item.custom) {
                    (item.custom as DynamicDictionary).active = (item.custom as DynamicDictionary).tab === tabValue;
                }
            });
        }

        function prepare(): void {
            windowResizeCallbackReceiver({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setupForm();
            tab.value.setupForm();
            setFormReady();
            buildNavigationBelt();
            buildPoliciesList();
        }

        function onAfterFormRestored(): void {
            checkRestoredPolicyIdAndResetIfNeeded();
        }

        function openModalByHash(): void {
            const hash: string = String(window.location.hash).replaceAll('#', '');
            if (hash !== '') {
                const openTabs: LegalPersonModalState[] = [
                    LegalPersonModalState.AddPersonsManual,
                    LegalPersonModalState.DeletePersonsFromExcel,
                ];
                if (openTabs.includes(hash)) {
                    businessModal.value.applyModalState(hash).showModalPopup();
                    btaBase.clearHash();
                }
            }
        }

        function formattedDate(dateTime: DateTimeImmutable): string {
            return moment(dateTime.date).format(DateFormat);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                DateFormat,
                DateFormats,
                processing,
                businessModal,
                onDetailsUpdated,
                legalPrograms,
                excelTemplates,
                insuredPersons,
                initialized,
                documentsFetched,
                currentActiveTab,
                navigationButtons,
                navigationUrls,
                policiesList,
                availablePrograms,
                documentsPaginator,
                tabsWithNoPolicies,
                tabsWithPoliciesDetailsFetch,
                tab,
                axiosCache,
                styleObject,
                dashboardIsReady,
                onAfterFormRestored,
                windowResizeCallback,
                isDocumentsTab,
                componentRef,
                setupTabInstance,
                onPageChangeClick,
                applyTabUid,
                applyExcelTemplates,
                currentTabIs,
                applyNavigationUrls,
                ajaxParams,
                onRemovePersonConfirmClick,
                closeModalPopup,
                applyDetails,
                onPolicyChange,
                policyHasChanged,
                translated,
                applyModifiedStatusOnCurrentPolicy,
                applyUnmodifiedStatusOnCurrentPolicy,
                showRemovePersonPopup,
                currentAgreementEndDate,
                fetchPolicyDetailsWithoutCache,
                buildPoliciesList,
                buildNavigationBelt,
                formattedDate,
                openModalByHash,
                prepare,
                onPolicyIdChange,
                faqListModule,
                popupServices,
                currentTab,
                legalPolicies,
                legalPersons,
                legalAvailablePrograms,
                legalAvailableDocuments,
                modalPopupState,
                legalPersonTabState,
                mustShowPoliciesList,
                policyId,
                insuredPersonsCount,
                insuranceProgramsCount,
                legalAvailableProgramInputOptions,
                currentAgreement,
                contentBlockStyleObject,
                minPolicyEndDate,
                showDocumentsAbsentBlock,
                currentPolicy,
                currentPolicyIsActive,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.faqListModule.create();
        this.faqListModule.beforeModuleMounted();
        this.faqListModule.moduleMounted();
        Translations.getInstance().addType('legal');
        this.processing.addContext(this);
        this.businessModal.addContext(this);
        this.legalPrograms.addContext(this);
        this.insuredPersons.addContext(this);
        this.insuredPersons.init();
        this.setupTabInstance(this);
        this.prepare();

        const formRestoreSubscription: Subscription = this.userStorage.onFormStorageDataIsReady.subscribe((): void => {
            nextTick((): void => {
                this.initialized = true;
                this.onPolicyIdChange().then();
            });
            formRestoreSubscription.unsubscribe();
        });
        this.onWindowResize.subscribe((size: Dimensions): void => {
            this.windowResizeCallback(size);
        });
        const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady.subscribe(() => {
            this.onAfterFormRestored();
            onAfterFormRestoredSubscription.unsubscribe();
        });
    },
});
</script>
