<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useScroll } from '@/Composables/Scroll';
import VueEvent from '@/Classes/VueEventClass';
import CssClass from '@/Enums/CssClassEnum';
import RecurringPaymentBanklink from '@/Enums/RecurringPaymentBanklinkEnum';
import AppCountry from '@/assets/libraries/app/app-country';
import FormField from '@/assets/libraries/form/form-field';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useAxios } from '@/Composables/Axios';
import Url from '@/Enums/UrlEnum';
import { AxiosResponse } from 'axios';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { useDefine } from '@/Composables/Define';
import CardDetails from '@/Components/CreditCards/CreditCardDetails/CardDetails';
import { useTransforms } from '@/Composables/Transforms';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import { useRouter, Router } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import GuardsService from '@/Apps/PayByLink/Pay/Services/GuardsService';
import { Subscription } from 'rxjs';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const router: Router = useRouter();
        const { validResponse } = useDefine();

        const CurrentStep: number = 1;
        const Facility: string = 'pay-by-link-pay';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const submitEnabled: Ref<boolean> = ref(false);
        const paymentTerm: Ref<number> = ref(0);
        const documentNumber: Ref<string> = ref('');
        const paymentAmount: Ref<number> = ref(0);
        const bankLinkId: Ref<number> = ref(0);
        const informationText: Ref<string | null> = ref(null);
        const bankLinkIdError: Ref<boolean> = ref(false);
        const showPaymentMethods: Ref<boolean> = ref(false);
        const cardNumberPart: Ref<string> = ref('');
        const paymentData: Ref<DynamicDictionary> = ref({});
        const viewIsReady: Ref<boolean> = ref(false);

        const cardDetailsPbl: Ref<HTMLDivElement | null> = ref(null);
        const steps: RouteStepperStep[] = (router.options.routes || []).map((routeConfig): RouteStepperStep => {
            return {
                routeConfig: routeConfig,
                label: routeConfig.meta?.title as string,
            };
        });

        const useCardDetails: Ref<boolean> = computed(() => {
            return paymentData.value.useCard;
        });

        function setupForm(): void {
            form.addField(new FormField('creditCardDetails'));
            form.addField(new FormField('creditCardAgree', '', 'checkboxChecked'));
            form.setReady();
            formIsReady.value = true;
        }

        function init(): void {
            applyData();
            if (useCardDetails.value) {
                setupForm();
            }
        }

        function applyCardNumberPart(value: string): void {
            cardNumberPart.value = value;
        }

        function openPaymentMethods(): void {
            showPaymentMethods.value = true;
            useScroll().scrollToTop();
        }

        function choosePayment(event: VueEvent): void {
            const newBankLinkId: number = event.params.bankLinkId;
            const newInformationText: string = event.params.informationText;
            if (newBankLinkId > 0) {
                event.sender.siblings('.' + CssClass.Active).removeClass(CssClass.Active);
                event.sender.toggleClass(CssClass.Active);
                bankLinkId.value = newBankLinkId === bankLinkId.value ? 0 : newBankLinkId;
                informationText.value = newInformationText
                    ? btaBase.localized(String(newInformationText).toLowerCase(), '')
                    : null;
                bankLinkIdError.value = bankLinkId.value === 0;
                submitEnabled.value = !bankLinkIdError.value;
            }
        }

        function submitButton(): void {
            if (bankLinkIdError.value) {
                onSubmitShowBankPaymentError();
            } else {
                submitPayment();
            }
        }

        function isVisibleInformationalText(): boolean {
            return !bankLinkIdError.value && !!informationText.value;
        }

        function hasReference(): boolean {
            return String(window.location.search).split('?ref=').length === 2;
        }

        function onCardDetailsSubmit(): void {
            Object(cardDetailsPbl.value)
                .touchAndValidate()
                .then(() => {
                    bankLinkId.value = RecurringPaymentBanklink.ByCountry[new AppCountry().iso()];
                    submitPayment();
                });
        }

        function applyData(): void {
            paymentTerm.value = paymentData.value.paymentTerm;
            documentNumber.value = paymentData.value.documentNumber;
            paymentAmount.value = paymentData.value.paymentAmount;
        }

        function submitPayment(): void {
            const params: Record<string, Record<string, string | unknown>> = assemblePaymentData();
            form.validate().then((): void => {
                if (form.isValid()) {
                    nextTick((): void => {
                        btaBase.lockInput(true);
                        PopupService.getInstance().show(new OnePopup().withType().loadingWait);
                        useAxios()
                            .post(Url.Ajax.payByLink, params)
                            .then((value: AxiosResponse<DynamicDictionary>): void => {
                                if (validResponse(value) && value.data.data.status === 'OK') {
                                    btaBase.navigate(value.data.data.body.redirectUrl);
                                } else {
                                    PopupService.getInstance().hide();
                                    btaBase.error.show(ErrorType.Error, 'submitPayment', value);
                                }
                            })
                            .catch((reason: LimitedVariant): void => {
                                btaBase.lockInput(false);
                                PopupService.getInstance().hide();
                                btaBase.error.show(ErrorType.Error, 'submitPayment', reason as DynamicDictionary);
                            });
                    });
                } else {
                    scrollToInvalidElement();
                }
            });
        }

        function scrollToInvalidElement(): void {
            const invalidElements: JQuery = $('.invalid').not('[style*="display: none"]');
            if (invalidElements.length > 0) {
                invalidElements[0].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'center',
                });
            }
        }

        function assemblePaymentData(): Record<string, Record<string, string | unknown>> {
            const paymentCardDetails: CardDetails = form.field('creditCardDetails').value;
            const json: Record<string, Record<string, number | string>> = {
                data: {},
            };
            json.data.id = paymentData.value.id;
            json.data.bankLinkId = bankLinkId.value;
            let cardDetails: string = '';
            if (useCardDetails.value) {
                cardDetails = transformedCardDetails(paymentCardDetails);
            }

            return {
                data: {
                    json: JSON.stringify(json),
                    facility: Facility,
                    extra: cardDetails,
                    numberPart: cardNumberPart.value,
                },
            };
        }

        function transformedCardDetails(details: CardDetails): string {
            return JSON.stringify({
                holder: details.holderName,
                number: details.number,
                expiryYear: details.validThru.year,
                expiryMonth: details.validThru.month,
                cvc: details.cvc,
            });
        }

        function onSubmitShowBankPaymentError(): void {
            const errorTitle: string = btaBase.localized('btar_error_payment_method_not_selected_title', '');
            const errorDescription: string = btaBase.localized(
                'btar_error_payment_method_not_selected_description',
                '',
            );
            const errorElement: HTMLElement = $('#banks-list').get(0) as HTMLElement;
            btaBase.popup
                .applyErrorTitle(errorTitle)
                .applyErrorDetails(errorDescription)
                .applyErrorElement(errorElement);
            btaBase.showPopup('error');
        }

        function addPaymentData(paymentDataSafeJson: string): void {
            paymentData.value = JSON.parse(useTransforms().transformedVueSafeString(paymentDataSafeJson));
            TransferStateService.getInstance().set('payByLink', paymentData.value);
        }

        function applyMtplOffer(json: string): void {
            TransferStateService.getInstance().set(
                'mtplOffer',
                JSON.parse(useTransforms().transformedVueSafeString(json)),
            );
        }

        function applyOfferId(value: string): void {
            TransferStateService.getInstance().set('renewedOfferId', value);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                submitEnabled,
                paymentTerm,
                documentNumber,
                paymentAmount,
                bankLinkId,
                informationText,
                bankLinkIdError,
                paymentData,
                showPaymentMethods,
                cardNumberPart,
                cardDetailsPbl,
                setupForm,
                init,
                applyCardNumberPart,
                openPaymentMethods,
                choosePayment,
                submitButton,
                isVisibleInformationalText,
                hasReference,
                onCardDetailsSubmit,
                applyData,
                assemblePaymentData,
                transformedCardDetails,
                onSubmitShowBankPaymentError,
                addPaymentData,
                applyMtplOffer,
                applyOfferId,
                steps,
                viewIsReady,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStorageUsage(false);
        GuardsService.getInstance().init();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.init();

        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.viewIsReady = true;
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
