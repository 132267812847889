<script setup lang="ts">
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { CancelTokenSource } from 'axios';
import { useTranslate } from '@/Composables/Translate';
import { computed, onMounted, ref, Ref } from 'vue';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import { useDefine } from '@/Composables/Define';
import OneDate from '@/assets/libraries/Date/OneDate';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import OneBaseService from '@/services/OneBaseService';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/Services/ProcessInnerToasterService';
import InnerToasterState from '@/Components/Tooltips/ProcessInnerToaster/Enums/InnerToasterStateEnum';

const request: AxiosParams = useAxios();
const { translateForType, translate } = useTranslate();
const { imgTag } = useHtml();
const { price } = usePrice();
const { isSet } = useDefine();

const props = defineProps({
    translationsType: { type: String, default: 'dashboard' },
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

const fetchIsInProgress: Ref<boolean> = ref(false);
const cancelToken: Ref<CancelTokenSource | null> = ref(null);
const compact: Ref<HTMLDivElement | null> = ref(null);
const decisionIsOpened: Ref<boolean> = ref(false);

const isCompensationPaid: Ref<boolean> = computed(() => {
    return (
        isSet(props.content.detailsBlock.compensationSum) &&
        props.content.detailsBlock.compensationSum !== '0' &&
        props.content.detailsBlock.compensationSum !== ''
    );
});

const addFilesButtonIsDisabled: Ref<boolean> = computed(() => {
    let filesIsEmpty: boolean = true;
    let uploadInProgress: boolean = true;
    const compactFileUploader: DynamicDictionary = compact.value as DynamicDictionary;
    if (compactFileUploader && compactFileUploader.filesUploader) {
        filesIsEmpty = compactFileUploader.filesUploader.filesCount === 0;
        uploadInProgress = compactFileUploader.filesUploader.uploadInProgress;
    }

    return filesIsEmpty || uploadInProgress;
});

function addAttachmentsToClaimCase(): void {
    const params: DynamicDictionary = {
        policyId: props.content.uploadBlock.policyId,
        policyNumber: props.content.uploadBlock.policyNumber,
        policyType: props.content.uploadBlock.policyType,
        claimNr: props.content.uploadBlock.claimNumber,
        claimId: props.content.uploadBlock.claimId,
        uploader: props.content.uploadBlock.claimNumber,
    };
    const headers: DynamicDictionary = {};
    OneBaseService.getInstance().lockInput(true);
    const compactFileUploader: DynamicDictionary = compact.value as DynamicDictionary;
    compactFileUploader.applyToasterState(InnerToasterState.Process);
    useAxios()
        .post(Url.Ajax.addOneAttachmentsToClaimCase, params, {
            headers: headers,
        })
        .then((): void => {
            if (compact.value) {
                compactFileUploader.filesUploader.clearUploads();
            }
        })
        .catch((reason: DynamicDictionary): void => {
            OneBaseService.getInstance().error.show(ErrorType.Error, 'addAttachmentsToClaimCase', reason);
        })
        .finally((): void => {
            if (compact.value) {
                compactFileUploader.applyToasterState(InnerToasterState.Complete);
            }
            OneBaseService.getInstance().lockInput(false);
            OneBaseService.getInstance().changeBodyVerticalScrollState();
        });
}

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationsType, replacements);
}

function attachmentExtension(attachment: Attachment): string {
    return attachment.type.toUpperCase();
}

function toggleDecisionBlock(): void {
    decisionIsOpened.value = !decisionIsOpened.value;
}

onMounted(() => {
    ProcessInnerToaster.getInstance()
        .applyProcessTitle(translated('uploading_documents'))
        .applyCompleteTitle(translated('documents_uploaded'));
});
</script>

<template>
    <div class="policy-details-overview">
        <div v-if="content.statusBlock.enabled" class="overview-block">
            <div class="header header-with-badges">
                <div class="title">{{ translated('claim_status') }}</div>
            </div>
            <app-progress-steps :step-names="content.statusBlock.progressSteps" :step="content.statusBlock.currentStep">
            </app-progress-steps>
        </div>
        <div class="overview-block">
            <div class="header header-with-badges">
                <div class="title">{{ translated('claim_details') }}</div>
            </div>
            <div class="details-block">
                <div v-if="content.detailsBlock.claimType" class="details-line">
                    <span class="left-part">{{ translated('claim_type') }}</span>
                    <span class="right-part">{{ content.detailsBlock.claimType }}</span>
                </div>
                <div class="details-line">
                    <span class="left-part">{{ translated('claim_case_number') }}</span>
                    <span class="right-part">{{ content.detailsBlock.claimNumber }}</span>
                </div>
                <div class="details-line">
                    <span class="left-part">{{ translated('submitted_at') }}</span>
                    <span class="right-part">{{ content.detailsBlock.submitDate }}</span>
                </div>
                <div v-if="content.detailsBlock.policyNumber" class="details-line">
                    <span class="left-part">{{ translated('policy_number') }}</span>
                    <span class="right-part">{{ content.detailsBlock.policyNumber }}</span>
                </div>
                <div v-if="isCompensationPaid" class="details-line">
                    <span class="left-part">{{ translated('claim_compensation_sum') }}</span>
                    <span class="right-part">{{ price(content.detailsBlock.compensationSum, false, 'EUR') }}</span>
                </div>
                <div v-if="isCompensationPaid" class="details-line">
                    <span class="left-part">{{ translated('claim_compensation_date') }}</span>
                    <span class="right-part">{{ OneDate.short(content.detailsBlock.compensationDate) }}</span>
                </div>
                <div v-if="content.detailsBlock.closeDate && !isCompensationPaid" class="details-line">
                    <span class="left-part">{{ translated('claim_close_date') }}</span>
                    <span class="right-part">{{ content.detailsBlock.closeDate }}</span>
                </div>
            </div>
        </div>
        <div v-if="content.decisionBlock.enabled" class="overview-block">
            <div class="decision-title" :class="{ opened: decisionIsOpened }" @click="toggleDecisionBlock()">
                {{ content.decisionBlock.title }}
                <img src="images/one/components/claims/dropdown-toggle.svg" alt="toggle" width="24" height="24" />
            </div>
            <div class="decision-opener" :class="{ opened: decisionIsOpened }">
                <div class="decision-description" v-html="content.decisionBlock.description"></div>
                <div v-if="content.decisionBlock.documents.length > 0" class="decision-documents">
                    <div v-if="content.decisionBlock.documentsTitle !== ''" class="title">
                        {{ content.decisionBlock.documentsTitle }}
                    </div>
                    <ul class="attachments">
                        <li v-for="(attachment, key) in content.decisionBlock.documents" :key="key" class="attachment">
                            <a :href="attachment.downloadLink" download>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                        stroke="#1d1f23a3"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M7 10L12 15L17 10"
                                        stroke="#1d1f23a3"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12 15V3"
                                        stroke="#1d1f23a3"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <span class="attachment-name">{{ attachment.name }}</span>
                            </a>
                            <span class="additional-text">{{ attachmentExtension(attachment) }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div v-if="content.uploadBlock.enabled" class="overview-block no-extra-padding">
            <app-compact-file-uploader
                ref="compact"
                :title="translated('upload_documents')"
                :subtitle="translated('submit_additional_documents')"
                :uploader="content.uploadBlock.claimNumber"
                :with-document-list="false"
                :upload-files-callback="addAttachmentsToClaimCase"
                :translation-type="'dashboard'"
            >
            </app-compact-file-uploader>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details-overview {
    .overview-block {
        width: 100%;
        padding: var(--size-small);
        border-radius: 16px;
        background-color: var(--white);
        box-shadow: none;
        margin-bottom: 20px;

        @include respond-above('sm') {
            padding: var(--size-medium);
        }

        .header {
            display: flex;
            align-items: center;
            margin-bottom: var(--size-small);

            &.header-with-border {
                padding-bottom: 30px;
                border-bottom: 1px solid var(--black-300);
            }

            &.small-margin {
                margin-bottom: 20px;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
                max-width: 50%;
            }
        }

        .details-block {
            .details-line {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
                font-size: var(--font-size-nano);

                &.column {
                    flex-direction: column;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .left-part {
                    color: var(--text-color-subtle);
                }

                .right-part {
                    font-weight: 500;
                    text-align: right;
                    color: var(--text-color-default);
                }

                .red {
                    color: var(--brand-red);
                    font-size: var(--font-size-small);
                    align-self: baseline;
                }

                .tiny {
                    font-size: var(--font-size-tiny);
                    align-self: baseline;
                }
            }

            .grey-line {
                border-top: solid 1px var(--black-50);
                margin-bottom: 20px;

                &.extra-margins {
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-big);
                }
            }
        }

        .decision-title {
            font-size: var(--font-size-small);
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            img {
                transform: rotate(180deg);
                transition: transform 0.3s ease-in-out;
            }

            &.opened {
                margin-bottom: var(--size-medium);

                img {
                    transform: rotate(0deg);
                }
            }
        }

        .decision-opener {
            display: none;

            &.opened {
                display: block;
                @include respond-above('sm') {
                    padding-bottom: var(--size-nano);
                }
            }

            .decision-description {
                display: block;

                :deep(h1),
                :deep(h2),
                :deep(h3),
                :deep(h4),
                :deep(h5) {
                    margin-bottom: var(--size-medium);
                }

                :deep(p) {
                    margin-bottom: var(--size-tiny);
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                }

                :deep(p:last-of-type) {
                    margin-bottom: var(--size-small);
                }

                :deep(span) {
                    display: block;
                    font-size: var(--font-size-nano);
                    color: var(--text-color-default);
                }

                :deep(ul) {
                    font-size: var(--font-size-nano);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-small);
                    padding-left: var(--size-normal);
                    color: var(--text-color-default);

                    li {
                        margin-bottom: 10px;

                        &::before {
                            content: '\2022';
                            color: var(--brand-red);
                            font-weight: bold;
                            display: inline-block;
                            width: 1em;
                            margin-left: -1em;
                        }
                    }
                }

                :deep(table) {
                    display: block;
                    overflow-x: scroll;
                    font-size: var(--font-size-femto);
                    margin-top: var(--size-small);
                    margin-bottom: var(--size-normal);

                    tbody {
                        tr {
                            border: 1px solid var(--black-100);

                            th,
                            td {
                                padding: 8px;
                            }

                            th {
                                min-height: 38px;
                                background-color: var(--background-light);
                                color: var(--text-color-subtle);
                                line-height: 11px;
                            }

                            th:nth-child(1),
                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(1),
                            td:nth-child(2),
                            td:nth-child(3) {
                                width: 80px;
                            }

                            th:nth-child(4),
                            td:nth-child(4) {
                                min-width: 280px;
                            }

                            th:nth-child(2),
                            th:nth-child(3),
                            td:nth-child(2),
                            td:nth-child(3) {
                                text-align: right;
                            }

                            td {
                                line-height: 14.4px;
                                color: var(--text-color-default);
                            }
                        }
                    }
                }
            }

            .decision-documents {
                .title {
                    font-size: var(--font-size-small);
                    font-weight: 700;
                    padding-top: var(--size-normal);
                    margin-top: var(--size-small);
                    border-top: 1px solid var(--black-100);
                }

                .attachments {
                    padding: var(--size-tiny) 0 0 0;

                    .attachment {
                        display: flex;
                        justify-content: space-between;
                        border: 1px solid var(--black-100);
                        padding: var(--size-nano) var(--size-small);
                        border-radius: var(--size-pico);
                        margin-bottom: var(--size-pico);

                        a,
                        span {
                            display: flex;
                            align-items: center;

                            .attachment-name {
                                padding-left: var(--size-tiny);
                                font-size: var(--font-size-tiny);
                                font-weight: 600;
                            }
                        }

                        .additional-text {
                            display: flex;
                            align-items: center;
                            font-size: var(--font-size-pico);
                            color: var(--text-color-subtlest);
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }

                    &.not-downloadable {
                        padding-bottom: 0;

                        .attachment {
                            &:last-of-type {
                                border-bottom: none;
                            }

                            .attachment-name {
                                font-weight: 600;
                            }

                            .additional-text {
                                font-size: var(--font-size-nano);
                            }
                        }
                    }
                }
            }
        }
    }

    .no-extra-padding {
        padding: 0;
    }
}
</style>
