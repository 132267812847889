<script setup lang="ts">
import { watch, onMounted, PropType, Ref, computed, ref } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useHtml } from '@/Composables/Html';
import CardDetails from '@/Components/CreditCards/CreditCardDetails/CardDetails';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import Value from '@/assets/libraries/form/value';
import { useScroll } from '@/Composables/Scroll';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppCreditCardDetails from '@/Components/CreditCards/CreditCardDetails/CreditCardDetails.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';

const props = defineProps({
    formField: { type: Object as PropType<FormField<DynamicDictionary>>, default: () => new FormField('') },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    externalMessage: {
        type: Object as PropType<DynamicDictionary>,
        default: () => {
            return {};
        },
    },
    cardNumber: { type: String, default: '' },
    label: { type: String, default: '' },
    mode: { type: String as PropType<'add' | 'update'>, default: '' },
});

const emit = defineEmits(['card-add', 'card-update']);

const { translate } = useTranslate();
const { imgTag } = useHtml();
const { scrollToId } = useScroll();

const form: Form = new Form();
const visibleNumbersOffset: number = -4;
const segmentMask: string = '&nbsp;&bull;&bull;&bull;&bull;&nbsp;';
const nonBreakingSpace: string = '&nbsp;';
const cardDetailsId: string = 'cardDetails-cardDetails';
const cardDetailsVisible: Ref<boolean> = ref(false);

const isExternalMessageEmpty: Ref<boolean> = computed((): boolean => {
    return props.externalMessage.type === '';
});

const isUpdateModeEnabled: Ref<boolean> = computed((): boolean => {
    return props.mode === 'update';
});

const isAddModeEnabled: Ref<boolean> = computed((): boolean => {
    return props.mode === 'add';
});

const cardDetailsAreValid: Ref<boolean> = computed((): boolean => {
    return form.field('cardDetails').isValid;
});

const cardDetailsAvailable: Ref<boolean> = computed((): boolean => {
    return new Value(cardDetails.value).isNotEmpty();
});

const isErrorMessage: Ref<boolean> = computed((): boolean => {
    return props.externalMessage.type === 'error';
});

const isSuccessMessage: Ref<boolean> = computed((): boolean => {
    return props.externalMessage.type === 'success';
});

const hasAgreedToCardUsage: Ref<boolean> = computed((): boolean => {
    return form.field('creditCardAgree').value;
});

const cardDetails: Ref<CardDetails> = computed((): CardDetails => {
    return form.field('cardDetails').value;
});

const cardDetailsTitle: Ref<string> = computed((): string => {
    return isUpdateModeEnabled.value ? 'btar_recurring_change_card' : 'btar_recurring_add_payment_card';
});

const cardMask: Ref<string> = computed((): string => {
    return (
        '<div class="card-mask">' +
        defaultCardIcon.value +
        '<div class="masked-numbers">' +
        '<span class="mask">' +
        numberMask.value +
        '</span>' +
        visibleNumbers.value +
        '</div>' +
        statusSign.value +
        '</div>'
    );
});

const submitDetailsButtonText: Ref<string> = computed((): string => {
    return isUpdateModeEnabled.value ? 'btar_recurring_change_card' : 'btar_recurring_add_card';
});

const visibleNumbers: Ref<string> = computed((): string => {
    return props.cardNumber.slice(visibleNumbersOffset);
});

const numberMask: Ref<string> = computed((): string => {
    return [segmentMask, segmentMask, segmentMask, nonBreakingSpace].join('');
});

const defaultCardIcon: Ref<string> = computed((): string => {
    return imgTag(cardIconAttributes());
});

const statusSign: Ref<string> = computed((): string => {
    let result: string = '';
    if (isErrorMessage.value) {
        result = imgTag(warningIconAttributes());
    } else {
        if (isSuccessMessage.value) {
            result = imgTag(successIconAttributes());
        }
    }

    return result;
});

watch(
    () => cardDetails.value,
    () => {
        if (cardDetailsAvailable.value) {
            patchFormFieldValue();
        }
    },
    { deep: true },
);

watch(
    () => props.cardNumber,
    () => {
        patchFormFieldValue();
    },
);

onMounted((): void => {
    setupForm();
    init();
});

function onCardDetailsSave() {
    if (canSubmitCardDetails()) {
        toggleCardDetails().then((): void => {
            scrollToId(props.formField.name).then((): void => {
                emitModeEvent();
            });
        });
    }
}

function onCardDetailsAdd() {
    toggleCardDetails().then((): void => {
        form.clear().then((): void => {
            form.markAsUntouched().then((): void => {
                scrollToId(cardDetailsId).then();
            });
        });
    });
}

function emitModeEvent(): void {
    if (isUpdateModeEnabled.value) {
        emit('card-update');
    } else {
        emit('card-add');
    }
}

function toggleCardDetails(): Promise<void> {
    return new Promise((resolve) => {
        cardDetailsVisible.value = !cardDetailsVisible.value;
        resolve();
    });
}

function canSubmitCardDetails(): boolean {
    return cardDetailsAvailable.value && cardDetailsAreValid.value && hasAgreedToCardUsage.value;
}

function cardIconAttributes(): ImageTagAttributes {
    return {
        class: isErrorMessage.value ? 'filter-red' : 'filter-green',
        src: 'images/one/bank/generic_card.svg',
        width: 28,
        height: 20,
    };
}

function addCardIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/bank/add_card.svg',
        width: 94,
        height: 84,
    };
}

function warningIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/exclamation-circle.svg',
        width: 24,
        height: 24,
    };
}

function successIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/checkmark.svg',
        width: 24,
        height: 24,
    };
}

function setupForm(): void {
    form.addField(new FormField('registeredCard'));
    form.addField(new FormField('cardDetails'));
    form.addField(new FormField('creditCardAgree', '', 'checkboxChecked'));
}

function init(): void {
    if (props.cardNumber !== '') {
        patchFormFieldValue();
    }
}

function patchFormFieldValue(): void {
    props.formField.patch({
        visibleNumbers: visibleNumbers.value,
        cardDetails: cardDetails.value,
    });
}
</script>

<template>
    <div
        :id="formField.name"
        :key="formField.name"
        class="credit-card-update"
        :data-type="formField.name"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label informative">
            <p>{{ label }}<span v-if="required" class="asterisk">&#42;</span></p>
            <slot name="app-tooltipster"></slot>
        </div>
        <div v-if="isUpdateModeEnabled" class="update-card" :class="{ 'extra-margin-bottom': !isExternalMessageEmpty }">
            <app-input-text
                class="registered-card"
                :class="{ warning: isErrorMessage, success: isSuccessMessage }"
                :form-field="form.field('registeredCard')"
                :data-store-disabled="true"
                :inside-label="cardMask"
                :disabled="true"
            >
            </app-input-text>
            <button
                :id="formField.name + '-showCardDetails'"
                class="button margin-top-mobile"
                :class="{
                    'extra-margin-top': !isExternalMessageEmpty,
                    'outside': !isErrorMessage,
                    'red': isErrorMessage,
                }"
                :disabled="cardDetailsVisible"
                @click="onCardDetailsAdd()"
            >
                {{ translate('btar_recurring_change_card') }}
            </button>
            <div
                v-if="!isExternalMessageEmpty"
                :id="formField.name + '-externalMessage'"
                class="external-message"
                :class="{ warning: isErrorMessage, success: isSuccessMessage }"
                v-html="externalMessage.message"
            ></div>
        </div>
        <div v-if="isAddModeEnabled" class="add-card with-shadow">
            <div v-html="imgTag(addCardIconAttributes())"></div>
            <div class="add-card-header">
                <h3 class="title" v-html="translate('btar_recurring_add_card_title')"></h3>
                <p class="subtitle" v-html="translate('btar_recurring_add_card_subtitle')"></p>
            </div>
            <button
                :id="formField.name + '-addCardDetails'"
                class="button red"
                :class="{ hidden: cardDetailsVisible }"
                @click="onCardDetailsAdd()"
            >
                {{ translate('btar_recurring_connect_payments') }}
            </button>
        </div>
        <div v-if="cardDetailsVisible" class="update-details">
            <app-credit-card-details
                :title="cardDetailsTitle"
                :data-store-disabled="true"
                :form-field="form.field('cardDetails')"
            >
            </app-credit-card-details>
            <app-input-checkbox
                class="margin-top"
                :form-field="form.field('creditCardAgree')"
                :data-store-disabled="true"
            >
                <span v-html="translate('btar_credit_card_agree')"></span>
            </app-input-checkbox>
            <button
                :id="formField.name + '-saveCardDetails'"
                class="button red margin-top"
                :disabled="!canSubmitCardDetails()"
                @click="onCardDetailsSave"
            >
                {{ translate(submitDetailsButtonText) }}
            </button>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.credit-card-update {
    width: 100%;
    scroll-margin-top: 4em;

    > .label {
        &.informative {
            margin-bottom: var(--size-nano);
        }
    }

    .update-details {
        margin-top: var(--size-small);
        background-color: var(--white);
        padding: var(--size-normal);

        @include respond-above('sm') {
            padding: var(--size-big);
        }

        .update-title {
            margin-bottom: var(--size-small);
        }
    }

    .update-card {
        display: flex;
        width: 100%;
        flex-direction: column;
        position: relative;

        @include respond-above('sm') {
            flex-direction: row;
            justify-content: space-between;
        }

        .external-message {
            position: absolute;
            width: 100%;
            top: 32%;
            padding: var(--size-small);
            display: flex;
            align-items: center;

            @include respond-above('sm') {
                width: 60%;
                top: 105%;
            }

            &.warning {
                background-color: var(--system-color-error-light);
            }

            &.success {
                background-color: var(--system-color-success-default);
            }
        }
    }

    .add-card {
        padding: var(--size-normal);

        @include respond-above('sm') {
            padding: var(--size-big) 42px;
        }

        .add-card-header {
            .title {
                margin-bottom: var(--size-small);
            }

            .subtitle {
                line-height: var(--line-height-accented);
                margin-bottom: var(--size-small);
            }
        }
    }

    .with-shadow {
        box-shadow:
            0 10px 20px rgb(0 0 0 / 0.19),
            0 6px 6px rgb(0 0 0 / 0.23);
        border-radius: 6px;
    }

    :deep(.input-text) {
        &.registered-card {
            width: 100%;

            &.disabled {
                .wrapper {
                    input {
                        background: none;
                    }
                }
            }

            @include respond-above('sm') {
                width: 60%;
            }

            .wrapper {
                .inside-label {
                    left: 0;
                    width: 100%;

                    .card-mask {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: space-evenly;

                        .filter-green {
                            filter: var(--to-color-green);
                        }

                        .filter-red {
                            filter: var(--to-color-red);
                        }

                        .masked-numbers {
                            width: 60%;

                            .mask {
                                font-size: var(--font-size-medium);
                                vertical-align: sub;
                            }
                        }
                    }

                    &::before {
                        width: 0;
                    }

                    &:hover {
                        cursor: default;
                    }
                }
            }
        }

        &.warning {
            .wrapper {
                input[name='registeredCard'] {
                    border-color: var(--system-color-error-default);
                    background-color: var(--white);
                    border-width: 2px;
                }
            }
        }

        &.success {
            .wrapper {
                input[name='registeredCard'] {
                    border-color: var(--system-color-success-default);
                    background-color: var(--white);
                    border-width: 2px;
                }
            }
        }
    }

    .margin-top-desktop {
        margin-top: 0;

        @include respond-above('sm') {
            margin-top: var(--size-small);
        }
    }

    .margin-top {
        margin-top: var(--size-small);
    }

    .margin-left-desktop {
        margin-left: 0;

        @include respond-above('sm') {
            margin-left: var(--size-small);
        }
    }

    .margin-top-mobile {
        margin-top: var(--size-small);

        @include respond-above('sm') {
            margin-top: 0;
        }
    }

    .extra-margin-bottom {
        margin-bottom: 0;

        @include respond-above('sm') {
            margin-bottom: 90px;
        }
    }

    .extra-margin-top {
        margin-top: 100px;

        @include respond-above('sm') {
            margin-top: 0;
        }
    }
}
</style>
