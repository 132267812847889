import { Subject } from 'rxjs';
import { Vehicle } from '@/interfaces/resources/vehicle.interface';

export default class Vehicles {
    private static instance: Vehicles;
    private vehicles: Vehicle[] = [];
    public ready: boolean = false;
    public onExternalDataIsReady = new Subject<void>();

    private constructor() {}

    public static getInstance(): Vehicles {
        if (!Vehicles.instance) {
            Vehicles.instance = new Vehicles();
        }
        return Vehicles.instance;
    }

    public addVehicles(vehicles: Vehicle[]): void {
        this.vehicles = vehicles;
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public vehicleModels(): Vehicle[] {
        return this.vehicles;
    }
}
