<script setup lang="ts">
import { onMounted, PropType, ref, Ref, computed, markRaw } from 'vue';
import { useTranslate } from '@/Composables/Translate';
import { HealthCard } from '@/interfaces/resources/health.card.interface';
import FormField from '@/assets/libraries/form/form-field';
import OneDate from '@/assets/libraries/Date/OneDate';
import OptionsList from '@/Components/Options/OptionsList/OptionsList.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';

const props = defineProps({
    healthCards: { type: Array as PropType<HealthCard[]>, default: () => [] },
    defaultCardCallback: { type: Function, default: null },
    mode: { type: String as PropType<'list' | 'radio'>, default: 'list' },
});

const emit = defineEmits(['card-change', 'radio-card-change']);
const { translate } = useTranslate();

const localCards: Ref<LocalHealthCard[]> = ref([]);
const radioCards: Ref<InputOption[]> = ref([]);

const currentCard: Ref<LocalHealthCard | undefined> = computed((): LocalHealthCard | undefined => {
    return localCards.value.find((card: LocalHealthCard): boolean => card.selected.value);
});

const isRadioMode: Ref<boolean> = computed((): boolean => {
    return props.mode === 'radio';
});
const isCardsListMode: Ref<boolean> = computed((): boolean => {
    return props.mode === 'list';
});

const radioCardField: FormField<DynamicDictionary> = new FormField('radioCard', {
    selected: '',
    customText: '',
} as DynamicDictionary);

onMounted((): void => {
    init();
});

function init(): void {
    storeLocalCards().then((): void => {
        if (isRadioMode.value) {
            prepareRadioCards().then((): void => {
                patchDefaultCard();
            });
        }
        emit('card-change', JSON.parse(JSON.stringify(currentCard.value)));
    });
}

function storeLocalCards(): Promise<void> {
    return new Promise((resolve) => {
        props.healthCards.forEach((card: HealthCard, index: number): void => {
            const tempCard: LocalHealthCard = card as LocalHealthCard;
            tempCard.selected = markRaw(new FormField('selected', index === 0));
            localCards.value.push(tempCard);
        });
        resolve();
    });
}

function selectCurrentCard(cardNumber: string): void {
    unselectAllCards(cardNumber).then((): void => {
        emit('card-change', JSON.parse(JSON.stringify(currentCard.value)));
    });
}

function unselectAllCards(cardNumber: string): Promise<void> {
    return new Promise((resolve) => {
        localCards.value.forEach((card: LocalHealthCard) => {
            if (card.cardNumber !== cardNumber) {
                card.selected.patch(false);
            }
        });
        resolve();
    });
}

function prepareRadioCards(): Promise<void> {
    return new Promise((resolve) => {
        localCards.value.forEach((card: LocalHealthCard): void => {
            const radioCard: InputOption = new InputOptionBuilder()
                .setName(card.cardNumber)
                .setValue(card.cardNumber)
                .build();
            radioCards.value.push(radioCard);
        });
        resolve();
    });
}

function patchDefaultCard(): void {
    if ((radioCardField.value as { selected: string }).selected === '') {
        radioCardField.patch({
            selected: defaultCard(),
            customText: '',
        });
    }
}

function defaultCard(): string {
    return props.defaultCardCallback
        ? props.defaultCardCallback(localCards.value).cardNumber
        : localCards.value[0].cardNumber;
}

function onRadioCardChange(value: DynamicDictionary) {
    const selectedCard: HealthCard | undefined =
        value.selected === ''
            ? localCards.value[0]
            : localCards.value.find((card: HealthCard): boolean => card.cardNumber === value.selected);
    emit('radio-card-change', selectedCard);
}

interface LocalHealthCard extends HealthCard {
    selected: FormField<boolean>;
}
</script>

<template>
    <div class="health-cards-list" :class="{ radio: isRadioMode }">
        <ul v-if="isCardsListMode" class="health-cards">
            <li v-for="(item, index) in localCards" :key="index" :class="{ active: item.selected.value }">
                <div class="health-card">
                    <div class="checkbox-and-tooltip">
                        <app-input-checkbox
                            :key="item.cardNumber"
                            :data-store-disabled="true"
                            :form-field="item.selected"
                            :class="{ 'radio-clickable': isRadioMode }"
                            @click="selectCurrentCard(item.cardNumber)"
                        ></app-input-checkbox>
                        <app-tooltipster></app-tooltipster>
                    </div>
                    <p :id="'cardNumber-' + index">{{ translate('health.health_claim_card') }} {{ item.cardNumber }}</p>
                    <span
                        >{{ OneDate.short(item.dateFrom) }} - {{ OneDate.short(item.excludeDate || item.dateTo) }}</span
                    >
                </div>
            </li>
        </ul>
        <options-list
            v-if="isRadioMode"
            :options="radioCards"
            :type="'radio'"
            :form-field="radioCardField"
            @change="onRadioCardChange($event)"
        >
        </options-list>
    </div>
</template>

<style lang="scss" scoped>
.health-cards-list {
    overflow: auto hidden;

    &.radio {
        width: 100%;
    }

    .health-cards {
        display: flex;
        margin-top: var(--size-small);
        flex-direction: column;

        @include respond-above('lg') {
            flex-direction: row;
        }
    }

    :deep(.options-list) {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
        gap: 12px;

        @include respond-above('sm') {
            grid-template-columns: auto auto;
        }

        .options-list-option {
            margin-bottom: var(--size-nano) !important;

            &.selected {
                background-color: var(--system-color-success-light) !important;
                border-color: var(--teal-700) !important;

                .options-list-panel {
                    &::before {
                        background-color: var(--teal-700) !important;
                        border-color: var(--teal-700) !important;
                    }
                }

                .radio-icon {
                    width: 8px;
                    height: 8px;
                    left: 22px;
                    margin-top: -4px;
                    background-color: var(--white) !important;
                }
            }
        }
    }

    .health-cards li {
        border: 2px solid grey;
        padding: var(--size-small);
        border-radius: 9px;
        width: 240px;
        flex-shrink: 0;
    }

    .health-cards li:nth-child(n + 2) {
        margin-top: 10px;

        @include respond-above('lg') {
            margin-top: 0;
            margin-left: 10px;
        }
    }

    .health-card {
        display: flex;
        flex-direction: column;
    }

    .health-card p {
        padding-top: var(--size-small);
        padding-bottom: 10px;
    }

    .active {
        border-color: var(--brand-teal) !important;
    }

    .checkbox-and-tooltip {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>
