import CreditCardDetails from '@/Components/CreditCards/CreditCardDetails/CreditCardDetails.vue';
import CreditCardUpdate from '@/Components/CreditCards/CreditCardUpdate/CreditCardUpdate.vue';
import CreditCardWidget from '@/Components/CreditCards/CreditCardWidget/CreditCardWidget.vue';
import AppCreditCardStatusWidget from '@/Components/CreditCards/CreditCardStatusWidget/CreditCardStatusWidget.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppCreditCardDetails: CreditCardDetails,
    AppCreditCardUpdate: CreditCardUpdate,
    AppCreditCardWidget: CreditCardWidget,
    AppCreditCardStatusWidget: AppCreditCardStatusWidget,
} as Components;
