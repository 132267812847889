<script setup lang="ts">
import { computed, PropType, Ref, ref } from 'vue';
import { useRouter, Router, RouteRecordRaw } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import StepperLink from './StepperLink.vue';

const props = defineProps({
    steps: { type: Array as PropType<RouteStepperStep[]>, default: () => [] },
    type: { type: String, default: '' },
});
const router: Router = useRouter();
const currentStepIndex: Ref<number> = ref(0);

router.afterEach(() => updateCurrentStepIndex());
updateCurrentStepIndex();

const isDynamic: Ref<boolean> = computed(() => {
    return props.type === 'dynamic';
});

function isCurrentStep(stepIndex: number): boolean {
    return stepIndex === currentStepIndex.value;
}

function isNextStep(stepIndex: number): boolean {
    return stepIndex > currentStepIndex.value;
}

function isPrevStep(stepIndex: number): boolean {
    return stepIndex < currentStepIndex.value;
}

function isVisible(stepIndex: number): boolean {
    return props.steps[stepIndex].visible ?? true;
}

function stepNumber(stepIndex: number): number {
    return stepIndex + 1;
}

function updateCurrentStepIndex(): void {
    const currentRouteConfig: RouteRecordRaw | undefined = (router.options.routes || []).find(
        (route: RouteRecordRaw) => route.name === router.currentRoute.value.name,
    );
    currentStepIndex.value =
        props.steps.findIndex((step: RouteStepperStep): boolean => {
            return step.routeConfig.path === currentRouteConfig?.path;
        }) || 0;
}
</script>

<template>
    <div v-if="currentStepIndex > 0" class="application-steps">
        <template v-for="(step, index) in steps" :key="step.routeConfig.name ?? step.rowIndex">
            <stepper-link
                class="application-step"
                :data-id="'step-' + index"
                :to="step.routeConfig"
                :class="{
                    done: isPrevStep(index),
                    current: isCurrentStep(index),
                    next: isNextStep(index),
                    hidden: !isVisible(index),
                }"
            >
                <div v-if="isPrevStep(index)" class="preview icon">
                    <svg width="26" height="26" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="20" height="20" rx="10" fill="#00B4AD" fill-opacity="0.08" />
                        <path
                            d="M14 7L8.5 13L6 10.2727"
                            stroke="#00B4AD"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div v-if="!isPrevStep(index)" class="preview">
                    {{ isDynamic ? steps[index].rowIndex : stepNumber(index) }}
                </div>
                <div class="title">{{ (step.routeConfig.meta as any).stepLabel }}</div>
            </stepper-link>
            <aside
                v-if="index < steps.length - 1"
                :key="index"
                class="line"
                :class="{
                    hidden: !isVisible(index),
                }"
            ></aside>
        </template>
    </div>
</template>
