<script setup lang="ts">
import { watch, onMounted, PropType, Ref, computed, onUnmounted } from 'vue';
import { Subscription } from 'rxjs';
import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import { useTranslate } from '@/Composables/Translate';
import Form from '@/assets/libraries/form/form';
import FormField from '@/assets/libraries/form/form-field';
import Sanitizer from '@/services/sanitizer.service';
import Value from '@/assets/libraries/form/value';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Validation from '@/services/validation.service';
import AppEnvironment from '@/assets/libraries/app/app-environment';
import SettingsService from '@/services/settings.service';
import { useDefine } from '@/Composables/Define';
import { useHtml } from '@/Composables/Html';
import CardDetails from '@/Components/CreditCards/CreditCardDetails/CardDetails';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import AppInputCreditCardNumber from '@/Components/Inputs/InputCreditCardNumber/InputCreditCardNumber.vue';
import AppInputCreditCardDate from '@/Components/Inputs/InputCreditCardDate/InputCreditCardDate.vue';

const props = defineProps({
    formField: { type: Object as PropType<FormField<CardDetails>>, default: () => new FormField('') },
    disabled: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    title: { type: String, default: '' },
});

const emit = defineEmits(['blur']);

const { translate } = useTranslate();
const { isSet } = useDefine();
const { imgTag } = useHtml();

const cardCvcMaxLength: number = 3;
const nameMaxLength: number = 52;
const form: Form = new Form();
const subscriptions: Subscription[] = [];

const allowCardDetailsPrefill: Ref<boolean> = computed((): boolean => {
    return !new AppEnvironment().isProduction();
});

watch(
    () => props.formField.value,
    () => {
        if (!new Value(props.formField.value).isEmpty()) {
            props.formField.touch();
        }
    },
    { deep: true },
);

onMounted((): void => {
    init();
});

onUnmounted((): void => {
    subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
});

defineExpose({
    touch,
    touchAndValidate,
});

function patchDummyCardDetails(): void {
    const settings: SettingsService = SettingsService.getInstance();
    props.formField.patch(settings.dummyBankCard());
}

function touch(): Promise<void> {
    return new Promise((resolve) => {
        props.formField.touch();
        resolve();
    });
}

function touchAndValidate(): Promise<void> {
    props.formField.touch();
    return form
        .touch()
        .then((): Promise<void> => form.validate())
        .then((): Promise<void> => props.formField.validate());
}

function onInput(field: string = ''): void {
    const currentValue: DynamicDictionary = props.formField.value;
    currentValue[field] = form.field(field).value;
    props.formField.setValue(currentValue as CardDetails);
}

function onHolderNameBlur(): void {
    const formValue: CardDetails = cardDetails();
    while (formValue.holderName !== formValue.holderName.replace(/[-.'`~ ]$/gm, '')) {
        formValue.holderName = formValue.holderName.replace(/[-.'`~ ]$/gm, '');
    }
    props.formField.patch(formValue);
    emit('blur', props.formField.value);
}

function init(): void {
    setupForm();
    props.formField.setValue(cardDetails());
    applyFormFieldValidators();
    subscriptions.push(
        props.formField.onClear.subscribe((): void => {
            form.fields().forEach((field: FormField) => {
                field.clear();
            });
        }),
        props.formField.onPatch.subscribe((): void => {
            applyValuesToChildFields();
        }),
        props.formField.onTouch.subscribe((): void => {
            form.validate().then((): void => {
                props.formField.validate().then();
            });
        }),
    );
}

function setupForm(): void {
    form.addField(new FormField('holderName', '', holderNameValidators(), Sanitizer.cleanCardHolderName));
    form.addField(new FormField('number'));
    form.addField(new FormField('validThru'));
    form.addField(new FormField('cvc', '', cvcValidators(), Sanitizer.cleanInputInteger));
    form.setReady();
}

function cvcValidators(): object {
    return {
        isValidCaption: () => {
            return form.field('cvc').isTouched ? String(form.field('cvc').value).length === cardCvcMaxLength : true;
        },
    };
}

function holderNameValidators(): object {
    return {
        isValidCaption: () => {
            return form.field('holderName').isTouched
                ? Validation.isValidCardHolderName(form.field('holderName').value)
                : true;
        },
    };
}

function applyFormFieldValidators(): void {
    props.formField.addValidators(cardDetailsValidators());
}

function cardDetailsValidators(): object {
    return {
        hasValidCardDetails: () => {
            return props.formField.isTouched ? allChildFieldsAreValid() && allChildFieldsAreTouched() : true;
        },
    };
}

function allChildFieldsAreValid(): boolean {
    return form.fields().filter((field: FormField) => !field.isValid).length === 0;
}

function allChildFieldsAreTouched(): boolean {
    return form.fields().filter((field: FormField) => !field.isTouched).length === 0;
}

function applyValuesToChildFields(): void {
    const formFieldValue: DynamicDictionary = props.formField.value;
    Object.keys(formFieldValue)
        .filter((key: string) => isSetAndNotEmpty(formFieldValue[key]))
        .forEach((key: string) => form.field(key).patch(formFieldValue[key]));
    form.validate().then();
}

function cardDetails(): CardDetails {
    const result: CardDetails = new CardDetails();
    result.holderName = form.field('holderName').value;
    result.number = form.field('number').value;
    result.validThru = form.field('validThru').value;
    result.cvc = form.field('cvc').value;

    return result;
}

function isSetAndNotEmpty(value: DynamicDictionary): boolean {
    return isSet(value) && !new Value(value).isEmpty();
}

function cardIcon(name: string): string {
    return imgTag(cardIconAttributes(name));
}

function cardIconAttributes(name: string): ImageTagAttributes {
    const source: string = 'images/one/bank/' + name + '_title.svg';
    return {
        class: '',
        src: source,
        width: 36,
        height: 24,
    };
}
</script>

<template>
    <div
        v-if="form.isReady()"
        :id="formField.name + '-cardDetails'"
        class="input credit-card-details"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="title" class="title margin-bottom-small">
            <div class="title-text">
                <div>{{ translate(title) }}</div>
            </div>
            <div class="title-logos">
                <div class="title-logos-logo">
                    <div v-html="cardIcon('visa')"></div>
                </div>
                <div class="title-logos-logo">
                    <div v-html="cardIcon('master_card')"></div>
                </div>
            </div>
        </div>
        <app-input-text
            :class="'card-holder-name margin-bottom-small'"
            :label="translate('btar_credit_card_holder_name')"
            :form-field="form.field('holderName')"
            :data-store-disabled="true"
            :max-length="nameMaxLength"
            :autocomplete="'cc-name'"
            @keyup="onInput('holderName')"
            @blur="onHolderNameBlur"
        >
        </app-input-text>
        <div class="card-details">
            <app-input-credit-card-number
                :form-field="form.field('number')"
                :data-store-disabled="true"
                @keyup="onInput('number')"
            >
            </app-input-credit-card-number>
            <div class="row">
                <app-input-credit-card-date
                    :class="'card-date'"
                    :form-field="form.field('validThru')"
                    :data-store-disabled="true"
                    @keyup="onInput('validThru')"
                >
                </app-input-credit-card-date>
                <app-input-text
                    :class="'card-cvc'"
                    :label="translate('btar_credit_card_cvc')"
                    :form-field="form.field('cvc')"
                    :data-store-disabled="true"
                    :max-length="cardCvcMaxLength"
                    :autocomplete="'cc-csc'"
                    @keyup="onInput('cvc')"
                >
                </app-input-text>
            </div>
        </div>
        <button
            v-if="allowCardDetailsPrefill"
            class="button outside prefill"
            data-type="prefill"
            @click="patchDummyCardDetails()"
        >
            Prefill card details
        </button>
    </div>
</template>

<style lang="scss" scoped>
.credit-card-details {
    width: 100%;
    scroll-margin-top: 4em;

    .prefill {
        margin-top: var(--size-nano);
        width: 100%;

        @include respond-above('sm') {
            width: 300px;
        }
    }

    .card-details {
        display: flex;
        flex-direction: column;
        gap: var(--size-small);

        @include respond-above('sm') {
            flex-direction: row;
        }

        .input-credit-card-number {
            width: 100%;

            @include respond-above('sm') {
                width: 60%;
            }
        }

        .row {
            width: 100%;
            display: flex;
            gap: var(--size-small);

            @include respond-above('sm') {
                width: 40%;
            }
        }
    }

    .margin-bottom-small {
        margin-bottom: var(--size-small);
    }

    .title {
        display: flex;
        justify-content: space-between;
        font-size: var(--font-size-small);
        font-weight: 600;
    }

    .title-logos {
        text-align: end;
        display: flex;
    }

    .title-logos-logo {
        padding: 0 0 0 var(--size-pico);
    }

    &.input {
        &.error {
            :deep(#holderName, #number, #validThru, #cvc) {
                &.valid {
                    input {
                        border-color: var(--component-color-border-default);
                        background-color: var(--white);

                        &:focus {
                            @include input-focus;
                        }
                    }
                }
            }
        }
    }
}
</style>
