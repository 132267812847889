<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { Ref, ref, onMounted, watch, computed, ComputedRef, nextTick } from 'vue';
import Translations from '@/services/translations.service';
import Form from '@/assets/libraries/form/form';
import RegionsConditions from '@/interfaces/regions.conditions.interface';
import Territories from '@/interfaces/territories.interface';
import { AxiosResponse } from 'axios';
import Url from '@/Enums/UrlEnum';
import OptionValue from '@/interfaces/option.value.interface';
import CountriesWithRegion from '@/interfaces/countries.with.region.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useDefine } from '@/Composables/Define';
import RequestService from '@/services/request.service';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import Popup from '@/services/popup.service';
import PopupType from '@/Enums/PopupTypeEnum';
import AppInputSelectionPanels from '@/Components/Inputs/InputSelectionPanels/InputSelectionPanels.vue';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import AppMultiCountry from '@/Components/Inputs/MultiCountry/MultiCountry.vue';

const props = defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
    searchCountryShowCount: { type: Number, default: 5 },
    message: { type: String, default: '' },
    locked: { type: Boolean, default: false },
    customUrl: { type: String, default: '' },
    urlParams: { type: Object, default: () => ({}) },
    customElementIsVisible: { type: Boolean, default: true },
});

const emit = defineEmits(['change', 'input', 'close-message', 'country-selection', 'options-updated']);

const translations: Translations = Translations.getInstance();
const requestService: RequestService = RequestService.getInstance();
const RegionWorldwide: string = 'WORLDWIDE';
const RegionWorldwideInclude: string = 'WORLDWIDE_INCL';
const selectionPanelsComponent: Ref = ref(null);
const form: Form = new Form();
const { isSet } = useDefine();
const territoriesIcs: ComputedRef<string[]> = computed(() => {
    const result: string[] = [];
    for (let i: number = 0; i < territories.value.length; i++) {
        result.push(territories.value[i].regionIc as string);
    }

    return result;
});

const isDisabled: Ref<boolean> = ref(false);
const isLoading: Ref<boolean> = ref(false);
const regions: Ref<RegionsConditions[]> = ref([]);
const territories: Ref<Territories[]> = ref([]);
const url: Ref<string> = ref('');
const selectionPopupOptions: Ref<InputOption[]> = ref([]);

onMounted((): void => {
    checkDisabledStatus();
    nextTick(() => {
        setupForm();
        url.value = props.customUrl ? props.customUrl : Url.Ajax.regions;
        if (props.urlParams) {
            fetchRegions();
        }
    });
});

watch(
    () => props.formField.value,
    (newValue, oldValue) => {
        if (newValue !== '' || (oldValue !== '' && newValue !== oldValue)) {
            const valueToPatch: any = newValue !== '' ? newValue : oldValue;
            form.field('regionalSelectorRegion').patch(valueToPatch);
        }
    },
);

watch(
    () => props.urlParams,
    (newValue) => {
        if (clearUrlParams(newValue) !== '') {
            fetchRegions(newValue);
        }
    },
);

function emitCountrySelection(value: OptionValue[]): void {
    const regionIc: string = calculatedRegion();
    const countriesSelected = new (class implements CountriesWithRegion {
        public countries: OptionValue[] = value;
        public region: string = regionIc;
    })();

    emit('country-selection', countriesSelected);
}

function emitOptionsUpdated(options: InputOption[]): void {
    emit('options-updated', options);
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();

    emit('change', props.formField.value);
}

function closeMessage(): void {
    emit('close-message');
}

function onRegionChange(currentValue: OptionValue): void {
    props.formField.patch(currentValue);
    emitChange();
}

function onCountryClose(): void {
    Popup.getInstance().showPopup(PopupType.CustomPopup);
    const regionIc: string = calculatedRegion();
    if (isSet(regionIc) && isValidIc(regionIc)) {
        form.field('regionalSelectorRegion').patch(regionIc);
        props.formField.patch(regionIc);
        form.field('regionalSelectorCountry').patch([]);
        selectionPanelsComponent.value?.close();
        emitChange();
    }
}

function onCountryChange(value: OptionValue[]): void {
    emitCountrySelection(value);
}

function translatedStrings(id: string): string {
    return translations.ready() ? translations.localized(id) : '';
}

function setupForm(): void {
    form.addField(new FormField('regionalSelectorRegion', '', 'required'));
    form.addField(new FormField('regionalSelectorCountry', [], ''));
}

function calculatedRegion(): string {
    const regionIcOffset: number = 4;
    const countryIsoOffset: number = 0;
    let result: string = '';
    const selectedRegions: OptionValue[] = form.field('regionalSelectorCountry').value;
    const regionsIcs: string[] = [];
    const regionsIso: string[] = [];
    selectedRegions.forEach((value: OptionValue) => {
        const valueParts: string[] = String(value.id).split(':');
        if (!regionsIcs.includes(valueParts[regionIcOffset])) {
            regionsIcs.push(valueParts[regionIcOffset]);
            regionsIso.push(valueParts[countryIsoOffset]);
        }
    });
    if (regionsIcs.length === 1) {
        result = regionsIcs[0];
    } else if (regionsIcs.length > 3) {
        result = RegionWorldwide;
    } else {
        regions.value.forEach((value: RegionsConditions) => {
            const intersection: string[] = regionsIcs.filter((x: string) => value.conditions.includes(x));
            if (
                regionsIcs.length === intersection.length &&
                regionsIcs.length === value.conditions.length &&
                result === ''
            ) {
                result = value.region;
            }
        });
    }
    result = appliedExceptions(result, regionsIso);

    return result;
}

function appliedExceptions(region: string, regionsIso: any): any {
    let result: string = region;
    if (translations.countryIso === 'EE') {
        if (regionsIso.includes('AUS') || regionsIso.includes('USA')) {
            result = RegionWorldwideInclude;
        }
    }

    return result;
}

function checkDisabledStatus(): void {
    isDisabled.value = props.disabled || selectionPopupOptions.value.length === 0;
}

function fetchRegions(urlParams: DynamicDictionary = {}): void {
    form.lockInput();
    isLoading.value = true;
    requestService
        .get({ uri: url.value, content: urlParams })
        .then((response: AxiosResponse) => {
            if (validResponse(response)) {
                const body: DynamicDictionary = response.data.data.body;
                regions.value = body.regions || body.mapping;
                territories.value = body.territories;
                buildRegionsAndTerritories();
            } else {
                Error.log(ErrorType.Error, 'fetchRegions::validResponse(invalid structure received)', null);
            }
        })
        .catch((reason: any) => {
            Error.log(ErrorType.Error, 'fetchRegions', reason);
        })
        .finally(() => {
            form.unlockInput();
            isLoading.value = false;
        });
}

function buildRegionsAndTerritories(): void {
    let defaultTerritory: Territories | null = null;
    selectionPopupOptions.value = [];
    for (let i: number = 0; i < territories.value.length; i++) {
        const tipTitle: string = 'region_' + territories.value[i].regionIc!.toLowerCase() + '_title';
        const tipDescription: string = 'region_' + territories.value[i].regionIc!.toLowerCase() + '_description';
        selectionPopupOptions.value.push(
            new InputOptionBuilder()
                .setValue(territories.value[i].regionIc as string)
                .setName(territories.value[i].regionName as string)
                .setTipster(translatedStrings(tipTitle), translatedStrings(tipDescription))
                .build(),
        );
        if (isDefault(territories.value[i].isDefault as string)) {
            defaultTerritory = territories.value[i];
        }
    }
    emitOptionsUpdated(selectionPopupOptions.value);
    checkDisabledStatus();
    if (defaultTerritory) {
        applyDefaultTerritory(defaultTerritory);
    } else if (territories.value.length > 0) {
        defaultTerritory = territories.value[0];
        applyDefaultTerritory(defaultTerritory);
    }
}

function applyDefaultTerritory(defaultTerritory: Territories): void {
    props.formField.patch(defaultTerritory.regionIc as string);
}

function validResponse(response: any): boolean {
    return isSet(response.status) && response.status === 200 && isSet(response.data) && isSet(response.data.data);
}

function isValidIc(ic: string): boolean {
    let found: boolean = false;
    for (let i: number = 0; i < territories.value.length; i++) {
        if (territories.value[i].regionIc === ic) {
            found = true;
        }
    }

    return found;
}

function isDefault(param: string): boolean {
    return param === 'Y' || param === '1';
}

function clearUrlParams(params: DynamicDictionary): string {
    let result: string = '';
    if (params && params.target !== undefined && params.target !== '') {
        result = params.target;
    }

    return result;
}
</script>

<template>
    <div
        :id="formField.name"
        class="country-regions"
        :class="{ ...formField.classes(), disabled: isDisabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <app-input-selection-panels
            ref="selectionPanelsComponent"
            class="country-region-opener"
            :form-field="form.field('regionalSelectorRegion')"
            :label="translatedStrings('where_you_go')"
            :options="selectionPopupOptions"
            :allow-empty-selection="false"
            :loading="isLoading"
            :data-store-disabled="true"
            :show-custom-element="customElementIsVisible"
            @change="onRegionChange"
        >
            <template #panel-opener-label>
                <app-tooltipster
                    :title="translatedStrings('regions_opener_tip_title')"
                    :description="translatedStrings('regions_opener_tip_description')"
                >
                </app-tooltipster>
            </template>
            <template #custom-element>
                <app-multi-country
                    v-if="customElementIsVisible"
                    class="item"
                    :form-field="form.field('regionalSelectorCountry')"
                    :popup-label="translatedStrings('select_destination_country')"
                    :custom-class="'panel'"
                    :static-text="translatedStrings('select_country')"
                    :message="message"
                    :locked="locked"
                    :valid-regions="territoriesIcs"
                    :max-default-elements="searchCountryShowCount"
                    :data-store-disabled="true"
                    @close="onCountryClose"
                    @change="onCountryChange"
                    @close-message="closeMessage"
                >
                    <template #tooltipster-opener>
                        <app-tooltipster
                            :title="translatedStrings('regions_country_opener_tip_title')"
                            :description="translatedStrings('regions_country_opener_tip_description')"
                        >
                        </app-tooltipster>
                    </template>
                    <template #static-text-tooltip>
                        <app-tooltipster
                            :title="translatedStrings('regions_select_country_title')"
                            :description="translatedStrings('regions_select_country_description')"
                            :open-on-hover="true"
                        >
                        </app-tooltipster>
                    </template>
                </app-multi-country>
            </template>
        </app-input-selection-panels>
    </div>
</template>

<style lang="scss" scoped>
.country-regions {
    .country-region-opener {
        width: 100%;
    }
}
</style>
