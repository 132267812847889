<script lang="ts">
import { computed, defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import { Router, RouteRecordRaw, useRouter } from 'vue-router';
import RouteStepperStep from '@/Components/Routing/RouteStepper/RouteStepperStepInterface';
import { Subscription } from 'rxjs';
import { CoveredPopupBuilder } from '@/Apps/Renters/Builders/CoveredPopupBuilder';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();
        const router: Router = useRouter();
        const CurrentStep: number = 1;
        const Facility: string = 'solar-panels-insurance';
        const appPreparedAndReady: Ref<boolean> = ref(false);
        const initialStepUrl: Ref<string> = ref('');
        const steps: RouteStepperStep[] = (router.options.routes || []).map(
            (routeConfig: RouteRecordRaw): RouteStepperStep => {
                return {
                    routeConfig: routeConfig,
                };
            },
        );
        const coveredPopup: Ref<CoveredPopupBuilder> = computed(() => {
            return btaBase.spa().coveredPopup;
        });

        function applyInitialStepUrl(url: string): void {
            initialStepUrl.value = url;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                steps,
                applyInitialStepUrl,
                appPreparedAndReady,
                coveredPopup,
            },
        };
    },
    mounted() {
        this.applyApp(this);
        this.initBtaBase();
        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.appPreparedAndReady = true;
            nextTick(() => {
                this.sticky.init();
            });
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
