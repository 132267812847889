export default class CoveredPopupAutomationIterator {
    private static blockIndexIterator: number = 0;

    public static init(): void {
        this.blockIndexIterator = 0;
    }

    public static blockIndexIterate(): number {
        return this.blockIndexIterator++;
    }

    public static blockIndex(): number {
        return this.blockIndexIterator - 1;
    }
}
