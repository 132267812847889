import GridContent from '@/Components/Lists/SmartGrid/Interfaces/GridContentInterface';
import ColumnsDefinitions from '@/Components/Lists/SmartGrid/Interfaces/ColumnsDefinitionsInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import CellRendererTemplate from '@/Components/Lists/SmartGrid/Interfaces/CellRendererTemplateInterface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import moment from 'moment';
import ProcessingRecordAction from '@/Enums/ProcessingRecordActionEnum';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import LegalInsuredPerson from '@/pages/LegalPerson/Workbench/Interfaces/LegalInsuredPersonInterface';
import ProcessingRecord from '@/pages/LegalPerson/Workbench/Interfaces/ProcessingRecordInterface';
import LegalProcessing from '@/pages/LegalPerson/Workbench/Services/LegalProcessingService';

export default class ProcessingDetailsTab extends ModalTab {
    public gridIdName: string = 'processing-grid';
    public personsForProcessing: LegalInsuredPerson[] = [];
    private gridContent: GridContent = new (class implements GridContent {
        public columnDefs: ColumnsDefinitions[] = [];
        public rowData: DynamicDictionary[] = [];
    })();

    private static instance: ProcessingDetailsTab;

    public static getInstance(): ProcessingDetailsTab {
        if (!ProcessingDetailsTab.instance) {
            ProcessingDetailsTab.instance = new ProcessingDetailsTab();
        }

        return ProcessingDetailsTab.instance;
    }

    public init(): void {
        this.gridContent.columnDefs = [
            {
                field: 'id',
                headerName: '',
                hide: true,
            },
            {
                field: 'status',
                headerName: this.translated('status'),
                cellRendererParams: this.cellRendererTemplateBadge.bind(this),
                minWidth: 120,
                maxWidth: 240,
            },
            {
                field: 'firstName',
                headerName: this.translated('name'),
                cellRendererParams: this.cellRendererTemplateFirstName.bind(this),
            },
            {
                field: 'lastName',
                headerName: this.translated('surname'),
                cellRendererParams: this.cellRendererTemplateLastName.bind(this),
            },
            {
                field: 'email',
                headerName: this.translated('email'),
                cellRendererParams: this.cellRendererTemplateEmail.bind(this),
            },
            {
                field: 'resident',
                headerName: this.translated('resident'),
                maxWidth: 100,
                cellRendererParams: this.cellRendererTemplateResident.bind(this),
            },
            {
                field: 'personCode',
                maxWidth: 200,
                headerName: this.translated('identity_number'),
            },
            {
                field: 'startDate',
                maxWidth: 140,
                headerName: this.translated('start_date'),
            },
            {
                field: 'endDate',
                maxWidth: 140,
                headerName: this.translated('end_date'),
                cellRendererParams: this.cellRendererTemplateEndDate.bind(this),
            },
            {
                field: 'programName',
                cellClass: 'program-wrapper',
                headerName: this.translated('program'),
                cellRendererParams: this.cellRendererTemplateProgramName.bind(this),
                customTooltipRendererParams: this.cellTopPanelTooltipRenderer.bind(this),
            },
        ];
        this.rebuildGrid();
    }

    public rebuildGrid(): void {
        this.gridContent.rowData = [];
        this.personsForProcessing.forEach((person: LegalInsuredPerson, index: number): void => {
            person.id = index;
            this.gridContent.rowData.push({
                id: person.id,
                status: String(person.status).toLowerCase(),
                firstName: person.processingRecords[0].firstName ?? person.firstName,
                lastName: person.processingRecords[0].lastName ?? person.lastName,
                email: person.processingRecords[0].email ?? person.email,
                resident: person.resident,
                personCode: person.personCode,
                startDate: moment(person.startDate, 'YYYY-MM-DD').format(this.modal.app.DateFormat),
                endDate: person.processingRecords[0].endDate
                    ? moment(person.processingRecords[0].endDate, 'YYYY-MM-DD').format(this.modal.app.DateFormat)
                    : moment(person.endDate, 'YYYY-MM-DD').format(this.modal.app.DateFormat),
                programName: person.processingRecords[0].insuranceProgram
                    ? person.processingRecords[0].insuranceProgram.name
                    : person.insuranceProgram.name,
            });
        });
    }

    public destroy(): void {
        this.gridContent.rowData = [];
    }

    public get legalProcessing(): LegalProcessing {
        return this.modal.app.processing;
    }

    public get processingCountText(): string {
        return this.modal.app.localized('processing_title', '', { '%count%': this.processingPersonCount() });
    }

    public get gridContentData(): GridContent {
        return this.gridContent;
    }

    private translated(stringId: string): string {
        return this.modal.app.translated(stringId);
    }

    private localized(stringId: string): string {
        return this.modal.app.localized(stringId);
    }

    private cellRendererTemplateBadge(params: DynamicDictionary): CellRendererTemplate {
        const recordActions: ProcessingRecord[] = this.personsForProcessing[params.rowIndex].processingRecords ?? [];
        const status: string = String(recordActions[0].action).toLowerCase();
        const badgeClass: string = 'grid-status-badge-' + status.replaceAll('_', '-');
        const templateHtml: string = '<span class="grid-status-badge ' + badgeClass + '">@</span>';
        const formattedValue: string = this.translated(status);

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
            public formattedValue: LimitedVariant = formattedValue;
        })();
    }

    private cellRendererTemplateResident(params: DynamicDictionary, data: DynamicDictionary): CellRendererTemplate {
        const iconPath: string = '/images/one/components/status-icon/' + (params.value ? 'valid.svg' : 'cross.svg');
        const templateHtml: string =
            '<img class="grid-resident-icon" src="' + iconPath + '" alt="' + iconPath + '" width="20" height="20">';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private cellRendererTemplateProgramName(params: DynamicDictionary): CellRendererTemplate {
        const person: LegalInsuredPerson = this.personsForProcessing[params.rowIndex] ?? [];
        const textColor: string = person.processingRecords[0].insuranceProgram ? '-orange' : '';
        const templateHtml: string = '<span class="program-hover-area text-color' + textColor + '">@</span>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private cellTopPanelTooltipRenderer(): CellRendererTemplate {
        const templateHtml: string = '<div class="ag-one-tooltip-top-blue">@</div>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private processingPersonCount(): string {
        return String(this.personsForProcessing.length);
    }

    private cellRendererTemplateEndDate(params: DynamicDictionary): CellRendererTemplate {
        const person: LegalInsuredPerson = this.personsForProcessing[params.rowIndex] ?? [];
        const textColor: string = person.processingRecords[0].endDate ? '-red' : '';
        const templateHtml: string = '<span class="text-color' + textColor + '">@</span>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private cellRendererTemplateFirstName(params: DynamicDictionary): CellRendererTemplate {
        const person: LegalInsuredPerson = this.personsForProcessing[params.rowIndex] ?? [];
        const textColor: string =
            !this.matchPreviousName(person.firstName, person.processingRecords[0].firstName) &&
            this.isStatusChanging(person)
                ? '-orange'
                : '';
        const templateHtml: string = '<span class="text-color' + textColor + '">@</span>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private cellRendererTemplateLastName(params: DynamicDictionary): CellRendererTemplate {
        const person: LegalInsuredPerson = this.personsForProcessing[params.rowIndex] ?? [];
        const textColor: string =
            !this.matchPreviousName(person.lastName, person.processingRecords[0].lastName) &&
            this.isStatusChanging(person)
                ? '-orange'
                : '';
        const templateHtml: string = '<span class="text-color' + textColor + '">@</span>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private cellRendererTemplateEmail(params: DynamicDictionary): CellRendererTemplate {
        const person: LegalInsuredPerson = this.personsForProcessing[params.rowIndex] ?? [];
        const textColor: string =
            !this.matchPreviousName(person.email, person.processingRecords[0].email) && this.isStatusChanging(person)
                ? '-orange'
                : '';
        const templateHtml: string = '<span class="text-color' + textColor + '">@</span>';

        return new (class implements CellRendererTemplate {
            public template: string = templateHtml;
        })();
    }

    private matchPreviousName(nameOne: string, nameTwo: string): boolean {
        return nameOne === nameTwo;
    }

    private isStatusChanging(person: LegalInsuredPerson): boolean {
        return ProcessingRecordAction.ChangeObjectData === String(person.processingRecords[0].action);
    }
}
