<script lang="ts">
import { computed, defineComponent, markRaw, reactive, ref, Ref, UnwrapNestedRefs } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import { useNavigate } from '@/Composables/Navigate';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import { useTranslate } from '@/Composables/Translate';
import { Subscription } from 'rxjs';
import FormField from '@/assets/libraries/form/form-field';
import UserStorage from '@/services/user.storage.service';
import AdditionalOption from '@/Components/Lists/AdditionalOptionsList/Interfaces/MovablePropertyOptionInterface';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import InsurancePlan from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanInterface';
import InsurancePlanBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanBuilder';
import InsurancePlanItem from '@/Components/Widgets/InsurancePlanWidget/Interfaces/InsurancePlanItemInterface';
import InsurancePlanItemBuilder from '@/Components/Widgets/InsurancePlanWidget/Builders/InsurancePlanItemBuilder';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useNumbers } from '@/Composables/Numbers';
import TravelInsuranceOptionBuilder from '@/Components/Lists/AdditionalOptionsList/Builders/TravelInsuranceOptionBuilder';
import SettingsService from '@/services/settings.service';
import DateRange from '@/interfaces/date.range.interface';
import moment from 'moment/moment';
import Url from '@/Enums/UrlEnum';
import RequestService from '@/services/request.service';
import OneDate from '@/assets/libraries/Date/OneDate';
import { AxiosResponse } from 'axios';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { usePrice } from '@/Composables/Price';
import TravelDataLayer from '@/pages/Travel/TravelDataLayer';
import { useDefine } from '@/Composables/Define';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translate, translateForType } = useTranslate();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 3;
        const Facility: string = 'one-travel';
        const TranslationType: string = 'travel';
        const dataLayer: TravelDataLayer = new TravelDataLayer();

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const additionalOptions: Ref<AdditionalOption[]> = ref([]);
        const insurancePlan: UnwrapNestedRefs<InsurancePlan> = reactive({
            title: '',
            priceTitle: '',
            price: 0,
            discount: 0,
            items: [],
            paymentFrequency: '',
        });
        const insurancePlanPrice: Ref<number> = ref(0);
        const insurancePlanItems: Ref<InsurancePlanItem[]> = ref([]);
        const optionsReady: Ref<boolean> = ref(false);

        const hasActiveDbDiscount: Ref<boolean> = computed(() => {
            return dbDiscount.value > 0;
        });

        const nextStep: Ref<number> = ref(4);
        const dbDiscount: Ref<number> = ref(0);
        const initialValues: DynamicDictionary = {};

        function setupForm(): void {
            form.addField(new FormField('additional-options'));
            form.addField(new FormField('car-count'));
            form.addField(new FormField('deductible'));
            form.addField(new FormField('rental-period'));
            form.setReady();
            formIsReady.value = true;
            stepsSubmitter.addForm(form);
        }

        function patchDefaultValues(): void {
            const deductibleValues: string[] =
                SettingsService.getInstance().travelAdditionalSettings().rentalCarSecurityDeductibles;
            if (form.field('car-count').isEmpty()) {
                form.field('car-count').setValue(1);
            }
            if (form.field('deductible').isEmpty()) {
                form.field('deductible').setValue(deductibleValues[0]);
            }
            if (form.field('rental-period').isEmpty()) {
                form.field('rental-period').setValue(defaultDateRange());
            }
        }

        function defaultDateRange(): DateRange {
            const storage: DynamicDictionary = UserStorage.getInstance().stepStorageData;
            const dateFrom: moment.Moment = moment(storage.travelStartDate, 'YYYY-MM-DD');
            const dateTo: moment.Moment = moment(storage.travelEndDate, 'YYYY-MM-DD');

            return new (class implements DateRange {
                public startDate: string = dateFrom.format();
                public endDate: string = dateTo.format();
            })();
        }

        function onAppReady(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            btaBase.dynamicStepper.enableAll();
            recalculate().then((): void => {
                PopupService.getInstance().hide();
                optionsReady.value = true;
            });
        }

        function stashInitialValues(): void {
            ['car-count', 'deductible', 'rental-period'].forEach((key: string): void => {
                initialValues[key] = JSON.parse(JSON.stringify(form.field(key).value));
            });
        }

        function restoreFromStash(): void {
            Object.keys(initialValues).forEach((key: string): void => {
                form.field(key).setValue(initialValues[key]);
            });
        }

        function recalculate(): Promise<void> {
            return new Promise((resolve, reject) => {
                fetchRiskPrices().then((response: AxiosResponse): void => {
                    if (response.data.data) {
                        stepsSubmitter.addSubmitCustomParam(
                            'policyPricesAdditional',
                            JSON.stringify({
                                priceAuthenticated: response.data.data.body.prices.priceAuthenticated,
                                priceGuest: response.data.data.body.prices.priceGuest,
                            }),
                        );
                        buildAdditionalOptions(response.data.data.body.risks).then((): void => {
                            buildInsurancePlanItems();
                            calculatePrice();
                            buildInsurancePlan();
                            resolve();
                        });
                    } else {
                        Error.getInstance().show(ErrorType.Error, 'onAppReady', response);
                        reject();
                    }
                });
            });
        }

        function onBeforeFormRestored(): void {
            patchDefaultValues();
        }

        function fetchRiskPrices(): Promise<AxiosResponse> {
            return RequestService.getInstance()
                .get({
                    uri: Url.Ajax.travelAdditional,
                    content: {
                        ['customParam[risks]']: [rentalCarRisk(), customRisks()].flat(),
                        ['customParam[product]']: selectedProductId(),
                        ['customParam[destinationType]']: destinationType(),
                        ...UserStorage.getInstance().stepStorageData,
                    },
                })
                .then();
        }

        function rentalCarRisk(): DynamicDictionary {
            const riskOptions: string[] = UserStorage.getInstance().stepStorageData.additionalOptions;
            return {
                ic: riskOptions[0],
                carCount: form.field('car-count').value,
                deductibleIc: form.field('deductible').value,
                startDate: OneDate.iris(moment(form.field('rental-period').value.startDate).toDate()),
                endDate: OneDate.iris(moment(form.field('rental-period').value.endDate).toDate()),
            };
        }

        function customRisks(): DynamicDictionary[] {
            const storage: UserStorage = UserStorage.getInstance();
            const result: DynamicDictionary[] = [];
            if (
                useDefine().isSet(storage.stepStorageData.customSums) &&
                useDefine().isSet(storage.stepStorageData.product) &&
                selectedProductId() === storage.stepStorageData.product
            ) {
                const parsedSums: DynamicDictionary = JSON.parse(storage.stepStorageData.customSums);
                Object.keys(parsedSums).forEach((key: string): void => {
                    result.push({
                        ic: key,
                        deductibleIc: 'EUR' + parsedSums[key],
                    });
                });
            }

            return result;
        }

        function applyNextStep(value: string) {
            nextStep.value = Number(value);
        }

        function applyDbDiscount(discountValue: number): void {
            dbDiscount.value = discountValue;
        }

        function activeDiscount(): number {
            const coveragePlan: DynamicDictionary | undefined = selectedProductCoverage();
            let result: number = 0;
            if (hasActiveDbDiscount.value) {
                result = dbDiscount.value;
            } else {
                if (coveragePlan) {
                    result = btaBase.user.isLogged()
                        ? coveragePlan.authenticatedDiscountPercent
                        : coveragePlan.guestDiscountPercent;
                }
            }

            return result;
        }

        function calculatePrice(): void {
            const coveragePlan: DynamicDictionary | undefined = selectedProductCoverage();
            const prices: number[] = [];
            if (coveragePlan) {
                prices.push(premium());
                activeRisks().forEach((risk: CoverageRisk): void => {
                    prices.push(risk.price);
                });
            }
            insurancePlanPrice.value = useNumbers().arraySum(prices);
        }

        function discount(): string {
            const percentage: number = 100;
            const coveragePlan: DynamicDictionary | undefined = selectedProductCoverage();
            let coveragePrice: number = 0;
            if (coveragePlan) {
                coveragePrice = premium();
            }

            const price: number = (coveragePrice * percentage) / (percentage - activeDiscount());

            return usePrice().sparse(price - coveragePrice, false);
        }

        function buildInsurancePlan(): void {
            Object.assign(
                insurancePlan,
                new InsurancePlanBuilder()
                    .withTitle(translateForType('insurance_widget_title', TranslationType))
                    .withPrice(insurancePlanPrice.value)
                    .withPriceTitle(translateForType('insurance_widget_price_title', TranslationType))
                    .withItems(insurancePlanItems.value)
                    .withPaymentFrequency('&nbsp;&euro;')
                    .withDiscount(Number(discount()))
                    .build(),
            );
        }

        function buildInsurancePlanItems(): void {
            insurancePlanItems.value = [];
            addSelectedProductToItems();
            activeRisks().forEach((risk: CoverageRisk): void => {
                insurancePlanItems.value.push(
                    new InsurancePlanItemBuilder()
                        .withTitle(translateForType(risk.id + '_MAIN', TranslationType))
                        .withPrice(risk.price)
                        .build(),
                );
            });
        }

        function addSelectedProductToItems(): void {
            insurancePlanItems.value.push(
                new InsurancePlanItemBuilder()
                    .withTitle(translate(translateForType(selectedProductId(), TranslationType)))
                    .withPrice(Number(premium()))
                    .build(),
            );
        }

        function buildAdditionalOptions(risks: DynamicDictionary[]): Promise<void> {
            return new Promise((resolve) => {
                additionalOptions.value = [];
                risks.forEach((risk: DynamicDictionary): void => {
                    const storedOptions: DynamicDictionary = form.field('additional-options').value;
                    const coveredRisk: CoverageRisk = new (class implements CoverageRisk {
                        public id: string = risk.ic;
                        public insuredSum: number = 0;
                        public isAdditional: boolean = true;
                        public isFeatured: boolean = false;
                        public price: number = Number(risk.price);
                        public withoutInsuredSum: boolean = true;
                    })();
                    const optionState: boolean = storedOptions[risk.ic];
                    additionalOptions.value.push(
                        new TravelInsuranceOptionBuilder()
                            .withName(translateForType(risk.ic + '_MAIN', TranslationType))
                            .withCoverageRisk(coveredRisk)
                            .withState(optionState)
                            .withPaymentFrequency('&nbsp;&euro;')
                            .withCarCount(markRaw(form.field('car-count')))
                            .withDeductible(markRaw(form.field('deductible')))
                            .withRentalPeriod(markRaw(form.field('rental-period')))
                            .build(),
                    );
                });
                resolve();
            });
        }

        function onBackClick(): void {
            useNavigate().navigate(SubmitterUrls.getInstance().previousStep());
        }

        function onSubmitStep(): void {
            stepsSubmitter.proceedStep('', btaBase.nextStep());
        }

        function selectedProductCoverage(): DynamicDictionary | undefined {
            let result: DynamicDictionary | undefined;
            const storedProduct: DynamicDictionary | undefined = selectedProduct();
            if (storedProduct) {
                result = storedProduct;
            }

            return result;
        }

        function premium(): number {
            let result: number = 0;
            const coveragePlan: DynamicDictionary | undefined = selectedProductCoverage();
            if (coveragePlan) {
                if (hasCustomSumForProduct()) {
                    result = productCustomSum()[selectedProductId()];
                } else {
                    result = btaBase.user.isLogged() ? coveragePlan.authenticatedPrice : coveragePlan.guestPrice;
                }
            }

            return Number(result);
        }

        function productCustomSum(): DynamicDictionary {
            const storage: UserStorage = UserStorage.getInstance();
            let result: DynamicDictionary = {};
            if (useDefine().isSet(storage.stepStorageData.productCustomSum)) {
                result = JSON.parse(storage.stepStorageData.productCustomSum);
            }

            return result;
        }

        function hasCustomSumForProduct(): boolean {
            return selectedProductId() in productCustomSum();
        }

        function selectedProduct(): DynamicDictionary | undefined {
            const storage: UserStorage = UserStorage.getInstance();
            const storedProductId: string = selectedProductId();
            return (storage.storageData as DynamicDictionary[]).find(
                (product: DynamicDictionary): boolean => product.id === storedProductId,
            );
        }

        function selectedProductId(): string {
            return UserStorage.getInstance().stepStorageData.policyPlanId ?? '';
        }

        function destinationType(): string {
            return UserStorage.getInstance().stepStorageData.destinationType ?? '';
        }

        function onAdditionalOptionToggle(optionEmit: DynamicDictionary): void {
            Object.keys(optionEmit).forEach((key: string): void => {
                const tempValue: DynamicDictionary = form.field('additional-options').value;
                if (new Value(tempValue).isNotEmpty()) {
                    tempValue[key] = optionEmit[key];
                    form.field('additional-options').patch(tempValue);
                } else {
                    form.field('additional-options').patch(optionEmit);
                }
            });
            calculatePrice();
            buildInsurancePlanItems();
            buildInsurancePlan();
        }

        function onAdditionalOptionConfirm(): void {
            PopupService.getInstance().show(new OnePopup().withType().loading);
            recalculate().then((): void => {
                PopupService.getInstance().hide();
            });
        }

        function onAdditionalOptionCancel(): void {
            restoreFromStash();
        }

        function onAdditionalOptionEdit(): void {
            stashInitialValues();
        }

        function onInsuranceWidgetContinue(): void {
            PopupService.getInstance().show(new OnePopup().withType().loadingWait);
            dataLayer.pushDataLayer(selectedProductId());
            prepareSubmit();
            stepsSubmitter.proceedStep(SubmitterUrls.getInstance().nextStep(), nextStep.value);
        }

        function activeRisks(): CoverageRisk[] {
            const result: CoverageRisk[] = [];
            const storedOptions: DynamicDictionary = form.field('additional-options').value;
            Object.keys(storedOptions).forEach((riskId: string): void => {
                if (storedOptions[riskId]) {
                    const additionalOption: AdditionalOption = additionalOptions.value.filter(
                        (option: AdditionalOption): boolean => option.risk.id === riskId,
                    )[0];
                    const risk: CoverageRisk = new (class implements CoverageRisk {
                        public id: string = riskId;
                        public insuredSum: number = 0;
                        public isAdditional: boolean = true;
                        public isFeatured: boolean = false;
                        public price: number = additionalOption.risk.price;
                        public withoutInsuredSum: boolean = true;
                    })();
                    result.push(risk);
                }
            });

            return result;
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', nextStep.value);
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('additionalOptions', additionalRisksForSubmit());
        }

        function additionalRisksForSubmit(): DynamicDictionary[] {
            const result: DynamicDictionary[] = [];
            const storedOptions: DynamicDictionary = form.field('additional-options').value;
            Object.keys(storedOptions).forEach((riskId: string): void => {
                if (storedOptions[riskId]) {
                    result.push(rentalCarRisk());
                }
            });

            return result;
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                onSubmitStep,
                onBackClick,
                setupForm,
                onAppReady,
                onBeforeFormRestored,
                additionalOptions,
                onAdditionalOptionToggle,
                onAdditionalOptionConfirm,
                onAdditionalOptionCancel,
                onAdditionalOptionEdit,
                insurancePlan,
                onInsuranceWidgetContinue,
                applyNextStep,
                optionsReady,
                applyDbDiscount,
                fetchRiskPrices,
                selectedProductCoverage,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();

        const onBeforeFormRestoredSubscription = this.userStorage.onBeforeFormStorageDataIsRestored.subscribe(() => {
            this.onBeforeFormRestored();
            onBeforeFormRestoredSubscription.unsubscribe();
        });

        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
