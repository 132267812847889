import UrlBuilder from '@/assets/libraries/url/url-builder';
import { useTranslate } from '@/Composables/Translate';
import BusinessTab from '@/pages/LegalPerson/Workbench/Common/Business.tab';
import LegalDocument from '@/pages/LegalPerson/Workbench/Interfaces/LegalDocumentInterface';
import LegalPersonInvoicePrintout from '@/pages/LegalPerson/Workbench/Interfaces/LegalPersonInvoicePrintoutInterface';
import LegalPersonAdjustmentPrintout from '@/pages/LegalPerson/Workbench/Interfaces/LegalPersonAdjustmentPrintoutInterface';

export default class BusinessDocuments extends BusinessTab {
    private static instance: BusinessDocuments;

    public static getInstance(): BusinessDocuments {
        if (!BusinessDocuments.instance) {
            BusinessDocuments.instance = new BusinessDocuments();
        }

        return BusinessDocuments.instance;
    }

    public documentUrl(
        annex: LegalDocument,
        printout: LegalPersonAdjustmentPrintout | LegalPersonInvoicePrintout,
    ): string {
        return new UrlBuilder()
            .withBaseUrl($('head base').attr('href') ?? '')
            .withLanguage(useTranslate().language())
            .withUri('Printouts/legalDocument')
            .withGetParams({
                agreementId: this.app.currentAgreement!.id,
                agreementNumber: this.app.currentAgreement!.number,
                isGroup: annex.isGroup,
                annexGroupId: annex.isGroup ? annex.id : null,
                printout: printout,
            })
            .build();
    }
}
