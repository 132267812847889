import CreditCardType from '@/interfaces/credit.card.type.interface';
import CreditCards from '@/Enums/CreditCardsEnum';

export default class Visa implements CreditCardType {
    public static readonly VisaAllowedDigitsMin: number = 16;
    public static readonly VisaAllowedDigitsMax: number = 19;
    public readonly icon: string = '<img src="images/one/bank/visa.svg" width="52px" height="52px">';
    public readonly maxDigitsFormatted: number = 22;
    public readonly name: string = CreditCards.Visa;
    public readonly numberDigits: number[] = [Visa.VisaAllowedDigitsMin, Visa.VisaAllowedDigitsMax];
    public readonly pattern: RegExp = /^4\d{12}(\d{3}|\d{6})?$/;
    public readonly startDigit: number = 4;
    public readonly whitelistedNumbers: string[] = [];
}
