import Validation from '@/services/validation.service';
import Form from '@/assets/libraries/form/form';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { ref, Ref } from 'vue';

export default class TravellerValidators {
    private form: Form = new Form();
    private travelers: Ref<DynamicDictionary[]> = ref([]);

    public init(form: Form, travelers: DynamicDictionary[]): void {
        this.form = form;
        this.travelers.value = travelers;
    }

    public birthDateValidator(assignedToField: string, fieldIndex: number): object {
        return {
            isRequired: (): boolean => {
                return this.travelers.value[fieldIndex].isRequiredBirthDate
                    ? this.form.field(assignedToField).value !== ''
                    : true;
            },
        };
    }

    public countryValidator(assignedToField: string, dependsOnField: string): object {
        return {
            isRequired: (): boolean => {
                return this.form.field(dependsOnField).value ? true : this.form.field(assignedToField).value !== '';
            },
        };
    }

    public ehicNumberValidator(assignedToField: string): object {
        const ehicNumber: number = 10;
        const ehicDoubleNumber: number = 20;
        return {
            isCorrectLength: (): boolean => {
                return this.form.field(assignedToField).value !== ''
                    ? this.form.field(assignedToField).value.length === ehicNumber ||
                          this.form.field(assignedToField).value.length === ehicDoubleNumber
                    : true;
            },
        };
    }

    public personCodeValidator(assignedToField: string, residenceCountry: string): object {
        return {
            validPersonCode: (): boolean =>
                Validation.isValidNaturalPersonCode(this.form.field(assignedToField).value, residenceCountry),
        };
    }

    public isValidPersonCodeWithoutDate(value: string, residenceCountry: string): boolean {
        return value ? Validation.isValidPersonCodeWithoutDate(value, residenceCountry) : false;
    }
}
