<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import ContractualInstitutions from '@/Modules/ContractualInstitutions/ContractualInstitutions.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const contractualInstitutionsModule = new VueModule(ContractualInstitutions).mount();

        return {
            ...btaBase,
            ...{
                contractualInstitutionsModule,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.contractualInstitutionsModule.create();
        this.contractualInstitutionsModule.beforeModuleMounted();
        this.contractualInstitutionsModule.moduleMounted();
    },
});
</script>
