export default class InsurancesContentTab {
    public static readonly None: string = 'none';
    public static readonly Active: string = 'active';
    public static readonly Inactive: string = 'inactive';
    public static readonly Transport: string = 'transport';
    public static readonly Travel: string = 'travel';
    public static readonly Property: string = 'property';
    public static readonly Health: string = 'health';
    public static readonly Accident: string = 'accident';
    public static readonly Other: string = 'other';
    public static readonly Terminated: string = 'terminated';
    public static readonly SpecialOffers: string = 'specialOffers';
}
