import AppCountry from '@/assets/libraries/app/app-country';
import DateFormat from '@/Enums/DateFormatEnum';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import moment from 'moment';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PersonalCodeValidator from '@/Validators/PersonCodeValidator';

const centuryAndYearHundredsDifference: number = 1;
const yearsInCentury: number = 100;
type SexAndCentury = Record<number, { century: number; gender: string }>;

export default class ExtractDataService {
    public birthDateFromPersonCode(personCode: string, localeIso: string = new AppCountry().iso()): Date | undefined {
        switch (localeIso) {
            case 'EE':
            case 'LT':
                return this.birthDateFromEeAndLtIdentityNumber(personCode.toString(), localeIso);
            case 'LV':
                return this.birthDateFromPersonCodeLV(personCode.toString());
            default:
                Error.log(ErrorType.Error, 'extractData::birthDateFromPersonCode', 'Unknown app country ISO');
                break;
        }
    }

    private birthDateFromPersonCodeLV(personCode: string): Date | undefined {
        if (personCode === '') {
            return;
        }
        const tempCode: string = String(personCode).replace(/[^0-9]/g, '');
        const minLength: number = 7;
        const endOfMonthDay: number = 4;
        const century: number = 6;
        let result;
        if (tempCode.length >= minLength) {
            const centuryNumber: number = Number(tempCode.slice(century, century + 1));
            const dayMonth: string = tempCode.slice(0, endOfMonthDay);
            const year: string =
                ExtractDataService.millenium(centuryNumber) + tempCode.slice(endOfMonthDay, endOfMonthDay + 2);
            const dateString: string = [dayMonth, year].join('');
            result = moment(dateString, 'DDMMYYYY').toDate();
        }

        return result;
    }

    public formatPersonCode(personCode: string, localeIso: string = new AppCountry().iso()): string {
        let result: string = personCode.toString();
        if (localeIso === 'LV') {
            result = this.formatPersonCodeLV(result);
        }

        return result;
    }

    private formatPersonCodeLV(personCode: string): string {
        const result: string = personCode.replace('-', '');
        const personCodeCut: number = 6;

        return result.slice(0, personCodeCut) + '-' + result.slice(personCodeCut);
    }

    public ageFromBirthDate(birthDate: string): number {
        return moment().diff(moment(birthDate, DateFormat.Default.Short), 'years');
    }

    public dateWithCustomFormat(date: Date, format: string = 'YYYY-MM-DD'): string {
        return moment(date).format(format);
    }

    private birthDateFromEeAndLtIdentityNumber(personCode: string, locale: string): Date | undefined {
        if (!new PersonalCodeValidator().isValidPersonCode(personCode, locale)) {
            return;
        }
        const monthAndDateSliceIndices: [number, number] = [3, 7];

        return moment(
            String(this.eeAndLtBirthYear(personCode)) + personCode.slice(...monthAndDateSliceIndices),
            'YYYYMMDD',
        ).toDate();
    }

    private eeAndLtBirthYear(personalCode: string): number {
        const yearSliceIndices: [number, number] = [1, 3];

        return (
            (this.sexAndCenturyDigitMappingForEeAndLtIdentityNo()[Number(personalCode[0])].century -
                centuryAndYearHundredsDifference) *
                yearsInCentury +
            Number(personalCode.slice(...yearSliceIndices))
        );
    }

    /**
     * Sex and century digit mapping for EE and LT identity numbers.
     *
     * See:
     * - https://learn.e-resident.gov.ee/hc/en-us/articles/360000624498-How-to-use-your-digital-ID
     * - https://en.wikipedia.org/wiki/National_identification_number#Lithuania
     */
    private sexAndCenturyDigitMappingForEeAndLtIdentityNo(): SexAndCentury {
        const addendForGenderIndex: number = 1;
        const centuries: number[] = [];
        const currentCentury: number = Math.floor(moment().year() / yearsInCentury) + centuryAndYearHundredsDifference;
        const multiplierForCenturyIndex: number = 2;
        const sexAndCenturyMapping: SexAndCentury = {};
        const startCentury: number = 19;
        for (let century = startCentury; century <= currentCentury; century++) {
            centuries.push(century);
        }
        centuries.forEach((century: number, century_index: number): void => {
            ['male', 'female'].forEach((gender: string, index: number): void => {
                sexAndCenturyMapping[century_index * multiplierForCenturyIndex + index + addendForGenderIndex] = {
                    century: century,
                    gender: gender,
                };
            });
        });

        return sexAndCenturyMapping;
    }

    private static millenium(centuryNumber: number): string {
        const centuries: DynamicDictionary = {
            1: '19',
            2: '20',
        };

        return centuries[centuryNumber];
    }
}
