import Dimensions from '@/interfaces/dimensions.interface';
import BreakPoints from '@/Enums/BreakPointsEnum';

export default class PageContentSize {
    private static instance: PageContentSize;
    private minHeightLimit: number = 0;

    private constructor() {}

    public static getInstance(): PageContentSize {
        if (!PageContentSize.instance) {
            PageContentSize.instance = new PageContentSize();
        }

        return PageContentSize.instance;
    }

    public applyMinHeightLimit(limit: number): PageContentSize {
        this.minHeightLimit = limit;

        return this;
    }

    public minHeight(size: Dimensions): string {
        let result: string = 'auto';
        const maxHeight: number = 622;
        const footerDefaultPartHeight: number = 154;
        if (size.width > BreakPoints.Sm) {
            let headerHeight: number = this.heightFromElement('navigation-belt');
            headerHeight += this.heightFromElement('header-main');
            const outerPartsHeight: number = headerHeight + footerDefaultPartHeight;
            if (size.height > outerPartsHeight) {
                let newSize: number = Math.min(size.height - outerPartsHeight, maxHeight);
                if (newSize < this.minHeightLimit) {
                    newSize = this.minHeightLimit;
                }
                result = newSize + 'px';
            }
        }

        return result;
    }

    private heightFromElement(className: string): number {
        let result: number = 0;
        const navigationBelt: JQuery = $('.' + className);
        if (navigationBelt.length > 0) {
            const navigationBeltHeight: number | undefined = navigationBelt.outerHeight(true);
            if (navigationBeltHeight) {
                result = navigationBeltHeight;
            }
        }

        return result;
    }
}
