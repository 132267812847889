export default class AppBrowser {
    private iOsUserAgentPattern: RegExp = /ipad|iphone|safari/i;
    private httpsProtocolPattern: RegExp = /https:/i;
    private baseZero: number = 0xff;

    public userAgent(): string {
        return navigator.userAgent;
    }

    public isIos(): boolean {
        return !!this.userAgent().match(this.iOsUserAgentPattern);
    }

    public isDevice(): boolean {
        return (navigator.maxTouchPoints & this.baseZero) > 0;
    }

    public isDesktop(): boolean {
        return !this.isDevice();
    }

    public isHttps(): boolean {
        return !!location.protocol.match(this.httpsProtocolPattern);
    }
}
