import CommunicationNotificator from '@/Components/TwoWayCommunication/CommunicationNotificator/CommunicationNotificator.vue';
import UserMessageCard from '@/Components/TwoWayCommunication/MessageCard/UserMessageCard.vue';
import Message from '@/Components/TwoWayCommunication/Message/Message.vue';
import NewMessage from '@/Components/TwoWayCommunication/NewMessage/NewMessage.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppCommunicationNotificator: CommunicationNotificator,
    AppUserMessageCard: UserMessageCard,
    AppMessage: Message,
    AppNewMessage: NewMessage,
} as Components;
