export default class PaginatorPage {
    private page: number;
    private size: number;
    private offset: number;

    public constructor(page: number, size: number, offset: number) {
        this.page = page;
        this.size = size;
        this.offset = offset;
    }

    public startIndex(): number {
        return (this.page - 1) * this.size + this.offset + 1;
    }

    public endIndex(): number {
        return this.startIndex() + this.size - 1;
    }
}
