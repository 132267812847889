import { AxiosPromise, AxiosRequestConfig } from 'axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import RequestService from '@/services/request.service';

export const useAxios = (): AxiosParams => {
    const get = (uri: string, config?: AxiosRequestConfig): AxiosPromise<DynamicDictionary> => {
        return RequestService.getInstance().get({
            uri: uri,
            content: config?.params,
            headers: config?.headers,
            withCache: false,
            cancelToken: config?.cancelToken,
        });
    };

    const post = (
        uri: string,
        requestContent: DynamicDictionary,
        config?: AxiosRequestConfig,
    ): AxiosPromise<DynamicDictionary> => {
        return RequestService.getInstance().post({
            uri: uri,
            content: requestContent,
            headers: config?.headers,
            cancelToken: config?.cancelToken,
        });
    };

    const isCancel = (reason: DynamicDictionary): boolean => {
        return RequestService.getInstance().isCanceled(reason);
    };

    return {
        get,
        post,
        isCancel,
    };
};

export interface AxiosParams {
    get: (uri: string, config?: AxiosRequestConfig) => AxiosPromise<DynamicDictionary>;
    post: (
        uri: string,
        requestContent: DynamicDictionary,
        config?: AxiosRequestConfig,
    ) => AxiosPromise<DynamicDictionary>;
    isCancel: (reason: DynamicDictionary) => boolean;
}
