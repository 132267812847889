import PolicyPlan from '@/interfaces/policy.plan.interface';
import OneRisk from '@/interfaces/one.risk.interface';
import Tooltipster from '@/interfaces/tooltipster.interface';
import User from '@/services/user.service';
import StringDictionary from '@/interfaces/string.dictionary.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import Form from '@/assets/libraries/form/form';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import AppCountry from '@/assets/libraries/app/app-country';
import { useFormatter } from '@/Composables/Formatter';

const btaBase: OneBase = OneBaseService.getInstance();
const { isSet } = useDefine();
const { translate, hasLocalization, translateForType } = useTranslate();
const { sparsePrice } = useFormatter();

export default class CoveredPopupTravel {
    private readonly TranslationType: string = 'travel';
    private form: Form = new Form();
    private excludedCoverageShowingRisks: string[] = [
        'EE_TRAVEL_V2_R_COVID19_TRIP_CANCELLATION',
        'EE_TRAVEL_V2_R_TRIP_INTERRUPTION_EXTENDED_COVERAGE',
    ];
    public contentID: string = '';
    public title: string = '';
    public description: string = '';
    public policyPlans: PolicyPlan[] = [];
    public travelRisks: OneRisk[] = [];
    private sums: StringDictionary = new (class implements StringDictionary {
        [key: string]: string;
    })();

    public init(form: Form): void {
        this.form = form;
    }

    public build(id: string, badges: DynamicDictionary[]): void {
        this.contentID = id;
        this.title = translate('btar_what_covered');
        this.policyPlans = [];
        const userIsLogged: boolean = User.getInstance().isLogged();

        btaBase.userStorage.storageData.forEach((product: DynamicDictionary, index: number): void => {
            product.featuredRisks.forEach((risk: DynamicDictionary): void => {
                if (isSet(risk.sums)) {
                    const fieldName: string = String(product.id + '_' + risk.id);
                    this.sums[fieldName] = this.form.field(fieldName).value;
                }
            });
            const name: string = translateForType(product.id, this.TranslationType);
            this.policyPlans.push(
                new (class implements PolicyPlan {
                    public id: string = product.id;
                    public price: number = userIsLogged ? product.authenticatedPrice : product.guestPrice;
                    public title: string = name;
                    public badge: string = index === 1 ? badges[index].label : '';
                })(),
            );
        });
        this.buildTravelRisks();
    }

    public isSelectedItem(id: string): boolean {
        return id === this.contentID;
    }

    private multiSum(product: DynamicDictionary, riskId: string): number {
        let result: string = '';
        const id: string = product.id + '_' + riskId;
        const productRisk: DynamicDictionary | undefined = (product.risks as DynamicDictionary[]).find(
            (risk) => risk.id === riskId,
        );
        if (isSet(this.sums[id])) {
            result = this.sums[id];
        } else if (productRisk) {
            result = productRisk.sum;
        }

        return parseInt(result, 10);
    }

    private additional(product: DynamicDictionary, riskId: string): boolean {
        let result: boolean = false;
        const productRisk: DynamicDictionary | undefined = (product.risks as DynamicDictionary[]).find(
            (risk) => risk.id === riskId,
        );
        if (productRisk && isSet(productRisk.additional)) {
            result = productRisk.additional === true;
        }

        return result;
    }

    public coverageWithExcludedRisks(price: number, riskIc: string): string {
        let result: string = '';
        if (!this.excludedCoverageShowingRisks.includes(riskIc)) {
            result = sparsePrice(price, true) + ' &euro;';
        }

        return result;
    }

    private buildTravelRisks(): void {
        this.travelRisks = [];
        const coverageIndex: number = new AppCountry().isEE() ? 2 : 0;
        const risksForCoverage: DynamicDictionary[] = btaBase.userStorage.storageData[coverageIndex].risks;
        const storage: DynamicDictionary = btaBase.userStorage.storageData;
        risksForCoverage.forEach((risk: DynamicDictionary): void => {
            const riskTitle: string = translateForType(risk.travelRisk.id + '_title', this.TranslationType);
            const travelRisk: OneRisk = new (class implements OneRisk {
                public prices: number[] = [];
                public title: string = riskTitle;
                public additional: boolean[] = [];
            })();
            travelRisk.prices = [];
            travelRisk.additional = [];
            const maxProducts: number = 3;
            for (let i: number = 0; i < maxProducts; i++) {
                if (isSet(storage[i])) {
                    travelRisk.prices.push(this.multiSum(storage[i], risk.id));
                    travelRisk.additional.push(this.additional(storage[i], risk.id));
                }
            }
            const uid: string = risk.travelRisk.id;
            const tooltipTitleId: string = uid + '_tip_title';
            const tooltipDescriptionId: string = uid + '_tip_description';
            const tooltipDescriptionOnly: string = 'travel_risk_' + uid + '_tip_description';
            const descriptionOnly: boolean = hasLocalization(tooltipDescriptionOnly, this.TranslationType);
            travelRisk.id = uid;
            if (hasLocalization(tooltipTitleId, this.TranslationType) || descriptionOnly) {
                const tooltipTitle: string = descriptionOnly
                    ? ''
                    : translateForType(tooltipTitleId, this.TranslationType);
                const tooltipDescription: string = descriptionOnly
                    ? translateForType(tooltipDescriptionOnly, this.TranslationType)
                    : translateForType(tooltipDescriptionId, this.TranslationType);
                travelRisk.tooltip = new (class implements Tooltipster {
                    public title: string = tooltipTitle;
                    public description: string = tooltipDescription;
                })();
            }
            this.travelRisks.push(travelRisk);
        });
    }
}
