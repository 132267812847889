import { reactive, UnwrapNestedRefs } from 'vue';
import InnerToasterContent from '@/Components/Tooltips/ProcessInnerToaster/Interfaces/InnerToasterContentInterface';
import InnerToasterState from '@/Components/Tooltips/ProcessInnerToaster/Enums/InnerToasterStateEnum';

export default class ProcessInnerToaster {
    private static instance: ProcessInnerToaster;
    private toasterContent: UnwrapNestedRefs<InnerToasterContent> = reactive(
        new (class implements InnerToasterContent {
            public processTitle: string = '';
            public completeTitle: string = '';
            public state: string = InnerToasterState.None;
        })(),
    );
    private SlotDataName: string = 'toaster-slot-content';

    private constructor() {}

    public static getInstance(): ProcessInnerToaster {
        if (!ProcessInnerToaster.instance) {
            ProcessInnerToaster.instance = new ProcessInnerToaster();
            (window as any).t = ProcessInnerToaster.instance;
        }

        return ProcessInnerToaster.instance;
    }

    public applyProcessTitle(title: string): ProcessInnerToaster {
        this.toasterContent.processTitle = title;

        return this;
    }

    public applyCompleteTitle(title: string): ProcessInnerToaster {
        this.toasterContent.completeTitle = title;

        return this;
    }

    public applyState(state: string): ProcessInnerToaster {
        this.toasterContent.state = state;

        return this;
    }

    public content(): InnerToasterContent {
        return this.toasterContent;
    }

    public slotDataName(): string {
        return this.SlotDataName;
    }

    public applyResize(minMaxHeight: number = 0): void {
        const slotElement: JQuery = $('[data-slot="' + this.SlotDataName + '"]');
        let newSize: number | string = minMaxHeight > 0 ? minMaxHeight : 'unset';
        if (slotElement.length > 0) {
            const outerHeight: number | undefined = slotElement.outerHeight();
            const height: number | undefined = slotElement.height();
            if (outerHeight && height) {
                const maxHeight: number | undefined = Math.max(outerHeight, height);
                if (maxHeight) {
                    newSize = Math.max(maxHeight, minMaxHeight);
                }
            }
        }
        slotElement.css('max-height', newSize);
    }
}
