import InsuredRisk from '@/interfaces/one_policy/insured.risk.interface';
import PolicyDetailsPersons from '@/Components/Policies/PolicyDetailsPersons/Interfaces/PolicyDetailsPersonsInterface';
import PolicyDetailsPersonsContent from '@/Components/Policies/PolicyDetailsPersons/Interfaces/PolicyDetailsPersonsContentInterface';
import PolicyDetailsPerson from '@/Components/Policies/PolicyDetailsPersons/Interfaces/PolicyDetailsPersonInterface';

export default class PolicyDetailsPersonsBuilder {
    private policyDetailsPersons: PolicyDetailsPersons = new (class implements PolicyDetailsPersons {
        public component: string = 'AppPolicyDetailsPersons';
        public content: PolicyDetailsPersonsContent = new (class implements PolicyDetailsPersonsContent {
            public persons: PolicyDetailsPerson[] = [];
        })();
    })();
    private currentPersonIndex: number = -1;

    public reset(): PolicyDetailsPersonsBuilder {
        this.currentPersonIndex = -1;
        this.policyDetailsPersons.content.persons = [];

        return this;
    }

    public startNewPerson(personId: string = ''): PolicyDetailsPersonsBuilder {
        this.currentPersonIndex++;
        const personIndex: string = personId === '' ? String(this.currentPersonIndex) : personId;
        this.policyDetailsPersons.content.persons.push(
            new (class implements PolicyDetailsPerson {
                public id: string = personIndex;
                public name: string = '';
                public surname: string = '';
                public program: string = '';
                public insuredRisks: InsuredRisk[] = [];
            })(),
        );

        return this;
    }

    public withPersonName(name: string): PolicyDetailsPersonsBuilder {
        this.checkCurrentPerson();
        this.policyDetailsPersons.content.persons[this.currentPersonIndex].name = name;

        return this;
    }

    public withPersonSurname(surname: string): PolicyDetailsPersonsBuilder {
        this.checkCurrentPerson();
        this.policyDetailsPersons.content.persons[this.currentPersonIndex].surname = surname;

        return this;
    }

    public withPersonProgram(program: string): PolicyDetailsPersonsBuilder {
        this.checkCurrentPerson();
        this.policyDetailsPersons.content.persons[this.currentPersonIndex].program = program;

        return this;
    }

    public withPersonRisks(insuredRisks: InsuredRisk[]): PolicyDetailsPersonsBuilder {
        this.checkCurrentPerson();
        this.policyDetailsPersons.content.persons[this.currentPersonIndex].insuredRisks = insuredRisks;

        return this;
    }

    public elementsCount(): number {
        return this.policyDetailsPersons.content.persons.length;
    }

    public build(): PolicyDetailsPersons {
        return this.policyDetailsPersons;
    }

    private checkCurrentPerson(): void {
        if (this.currentPersonIndex === -1) {
            this.startNewPerson();
        }
    }
}
