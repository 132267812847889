export default class FilesUploader {
    public static readonly Settings = new (class {
        public ResizableMimeTypes: string = 'uploadResizableMimeTypes';
        public FilesMax: string = 'uploadFilesMax';
        public FileAllowedTypes: string = 'uploadFileAllowedTypes';
        public FileSizeMax: string = 'uploadFileSizeMax';
        public RestrictionDays: string = 'uploadRestrictionDays';
        public WsUrl: string = 'uploadWsUrl';
        public FilesCumulativeSizeMaxBytes = 'uploadFilesCumulativeSizeMaxBytes';
    })();

    public static readonly Url = new (class {
        public UploadReference: string = '/ajax/FilesServer/uploadReference';
        public SubmitFile: string = '/ajax/FilesServer/submitFile';
        public RemoveFile: string = '/ajax/FilesServer/removeFile';
        public RemoveFiles: string = '/ajax/FilesServer/removeFiles';
        public SharedFiles: string = '/ajax/FilesServer/sharedFiles';
    })();

    public static readonly Field = new (class {
        public Id: string = 'id';
        public CategoryId: string = 'categoryId';
        public Categories: string = 'categories';
        public Uploader: string = 'uploader';
        public Reference: string = 'reference';
        public Renew: string = 'renew';
        public File: string = 'file';
        public Files: string = 'files';
        public FileBase64: string = 'fileBase64';
        public MimeType: string = 'mimeType';
        public Event: string = 'event';
        public Form: string = 'form';
        public UploadCategories: string = 'uploadCategories';
    })();

    public static readonly SocketCommand = new (class {
        public Connect: string = 'connect';
        public JoinRoom: string = 'join-room';
        public FilesAdded: string = 'file-added';
        public FilesFetch: string = 'fetch-files';
        public FileRemoved: string = 'file-removed';
        public CloseRoom: string = 'close-room';
        public RoomClosed: string = 'room-closed';
    })();

    public static readonly Error = new (class {
        public UploadErrorWrongReference: string = 'error_wrong_upload_reference';
        public UploadFilesMax: string = 'error_upload_files_max';
        public UploadBatchSize: string = 'error_upload_batch_size';
        public UploadFileDuplicate: string = 'error_upload_duplicate';
        public UploadWrongFileType: string = 'error_upload_type';
        public UploadWrongFileSize: string = 'error_upload_size';
        public UploadFileInvalidDate: string = 'error_invalid_date';
        public UploadFilesCumulativeSize: string = 'error_files_cumulative_size';
        public UploadErrorRemoveFile: string = 'error_remove_file';
        public UploadFetchInvalid: string = 'error_invalid_data_fetch';
    })();

    public static readonly MobileUploadUrl: string = 'mobile';
    public static readonly WrongReferenceRedirect: string = 'wrong-reference';
}
