import { VNode, DirectiveBinding } from 'vue';
import ClickOutsideElement from '@/interfaces/click.outside.element.interface';
import ClickOutsideNode from '@/interfaces/click.outside.node.interface';
import VueEvent from '@/Classes/VueEventClass';
import TriggeredEvent = JQuery.TriggeredEvent;
import { LimitedVariant } from '@/Types/LimitedVariantType';

export default {
    beforeMount: (el: HTMLElement, binding: DirectiveBinding, node: VNode): void => {
        const elementId: string = ClickOutsideEmitter.elementId(el, binding);
        if (elementId === '') {
            console.debug('Directive[click-outside] binding element has no unique id!');
        }
        ClickOutsideEmitter.create(el, elementId, binding, node);
    },

    unmounted: (el: HTMLElement, binding: DirectiveBinding): void => {
        ClickOutsideEmitter.release(el, ClickOutsideEmitter.elementId(el, binding));
    },
};

class ClickOutsideEmitter {
    public static emitters: ClickOutsideElement = new (class implements ClickOutsideElement {
        [key: string]: ClickOutsideNode;
    })();

    public static elementId(el: HTMLElement, binding: DirectiveBinding): string {
        return el.id || (binding.value as Function).name || '';
    }

    public static create(el: HTMLElement, emitterName: string, binding: DirectiveBinding, node: VNode): void {
        this.emitters[emitterName] = new (class implements ClickOutsideNode {
            public el: HTMLElement = el;
            public binding: DirectiveBinding = binding;
            public node: VNode = node;
            public onElementClick: LimitedVariant = (e: MouseEvent): void => {
                e.stopPropagation();
            };
            public onBodyClick: LimitedVariant = (e: TriggeredEvent): void => {
                const method: Function | undefined = ClickOutsideEmitter.emitters[emitterName].binding.value;
                if (method) {
                    method(new VueEvent(e));
                }
            };
        })();
        el.addEventListener('click', this.emitters[emitterName].onElementClick);
        document.body.addEventListener('click', this.emitters[emitterName].onBodyClick);
    }

    public static release(el: HTMLElement, emitterName: string): void {
        el.removeEventListener('click', this.emitters[emitterName].onElementClick);
        document.body.removeEventListener('click', this.emitters[emitterName].onBodyClick);
        delete this.emitters[emitterName];
    }
}
