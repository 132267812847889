import FormField from './form-field';
import { merge, Subject, Subscription } from 'rxjs';
import { computed, ComputedRef, markRaw, ref, Ref } from 'vue';

export default class Form {
    public onValidation: Subject<void> = new Subject();
    public onTouch: Subject<void> = new Subject();
    public onTouchChange: Subject<FormField> = new Subject();
    private localFields: Ref<FormField[]> = ref([]);
    private localIsInputLocked: Ref<boolean> = ref(false);
    private localIsReady: Ref<boolean> = ref(false);
    private formName: Ref<string> = ref('');
    private fieldSubscriptions: { [fieldName: string]: Subscription } = {};
    private localIsValid: ComputedRef<boolean> = computed((): boolean => {
        return !this.fields().some((field: FormField) => !field.isValid);
    });
    private localIsTouched: ComputedRef<boolean> = computed((): boolean => {
        return this.fields().some((field: FormField) => field.isTouched);
    });

    public addField(formField: FormField): void {
        if (!this.exists(formField.name)) {
            this.localFields.value.push(markRaw(formField));
            this.fieldSubscriptions[formField.name] = merge(
                formField.onTouch,
                formField.onUntouched,
                formField.onClear,
            ).subscribe((): void => {
                this.onTouchChange.next(formField as FormField);
            });
        }
    }

    public fields(): FormField[] {
        return this.localFields.value;
    }

    public destroy(): void {
        this.localIsReady.value = false;
        Object.keys(this.fieldSubscriptions).forEach((fieldName: string): void => {
            this.fieldSubscriptions[fieldName]?.unsubscribe();
        });
        this.fieldSubscriptions = {};
        this.localFields.value = [];
    }

    public removeRow(rowIndex: number, indexDelimiter: string = '_'): void {
        this.removeFieldsByIndex(rowIndex, indexDelimiter).then((): void => {
            this.remapIndexedFieldNames(indexDelimiter);
        });
    }

    public invalidRows(indexDelimiter: string = '_'): number[] {
        const invalidRows: number[] = this.localFields.value
            .filter(
                (field: FormField): boolean => !field.isValid && field.isTouched && field.name.includes(indexDelimiter),
            )
            .map((field: FormField): number => Number(field.name.substring(field.name.indexOf(indexDelimiter) + 1)));

        return [...new Set(invalidRows)];
    }

    public create(formName: string = ''): Form {
        this.formName.value = formName !== '' ? formName : 'form-' + String(Math.random()).replace('.', '');

        return this;
    }

    public field(fieldName: string): FormField {
        const field: FormField | undefined = this.fields().find((value: FormField) => value.isThisField(fieldName));
        return field || new FormField('');
    }

    public exists(fieldName: string): boolean {
        return this.fields().some((field: FormField): boolean => field.name === fieldName);
    }

    public isReady(): boolean {
        return this.localIsReady.value;
    }

    public get ready(): boolean {
        return this.localIsReady.value;
    }

    public isValid(): boolean {
        return this.localIsValid.value;
    }

    public get valid(): boolean {
        return this.localIsValid.value;
    }

    public isTouched(): boolean {
        return this.localIsTouched.value;
    }

    public isInputLocked(): boolean {
        return this.localIsInputLocked.value;
    }

    public async markAsUntouched(): Promise<void[]> {
        return Promise.all(this.fields().map((field) => field.markAsUntouched()));
    }

    public async markAsFresh(): Promise<void[]> {
        return Promise.all(this.fields().map((field) => field.markAsFresh()));
    }

    public sanitize(): void {
        this.fields().forEach((field) => field.sanitize());
    }

    public async clear(): Promise<void> {
        return Promise.all(this.fields().map((field) => field.clear()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {});
    }

    public setReady(): void {
        this.localIsReady.value = true;
    }

    public lockInput(): void {
        this.localIsInputLocked.value = true;
    }

    public unlockInput(): void {
        this.localIsInputLocked.value = false;
    }

    public async validate(): Promise<void> {
        return Promise.all(this.fields().map((field) => field.validate()))
            .then(() => {
                this.onValidation.next();
            })
            .catch(() => {});
    }

    public async touch(): Promise<void> {
        return new Promise((resolve) => {
            this.fields().forEach((field) => field.touch());
            resolve();
        });
    }

    public get name(): string {
        return this.formName.value;
    }

    public applyName(name: string): void {
        this.formName.value = name;
    }

    private async removeFieldsByIndex(rowIndex: number, indexDelimiter: string): Promise<void> {
        return new Promise((resolve) => {
            const fieldsToRemove: FormField[] = this.localFields.value.filter(
                (field: FormField): boolean =>
                    field.name.substring(field.name.indexOf(indexDelimiter)) === indexDelimiter + rowIndex,
            );
            fieldsToRemove.forEach((targetField: FormField): void => {
                this.fieldSubscriptions[targetField.name]?.unsubscribe();
                const indexToRemove: number = this.localFields.value.findIndex(
                    (field: FormField): boolean => field.name === targetField.name,
                );
                if (indexToRemove >= 0) {
                    this.localFields.value.splice(indexToRemove, 1);
                }
            });
            resolve();
        });
    }

    private remapIndexedFieldNames(indexDelimiter: string): void {
        const currentIndexes: string[] = this.localFields.value
            .filter((field: FormField): boolean => field.name.includes(indexDelimiter))
            .map((field: FormField) => field.name.substring(field.name.indexOf(indexDelimiter)));
        const uniqueIndexes: string[] = [...new Set(currentIndexes)];
        uniqueIndexes.forEach((uniqueIndex: string, index: number): void => {
            this.localFields.value
                .filter(
                    (field: FormField): boolean =>
                        field.name.substring(field.name.indexOf(indexDelimiter)) === uniqueIndex,
                )
                .forEach((field: FormField): void => {
                    field.name = field.name.replace(uniqueIndex, indexDelimiter + index);
                });
        });
    }
}
