import OneBaseService from '@/services/OneBaseService';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class ModuleActions {
    private app: DynamicDictionary | null = null;
    private moduleActions: string[] = ['data-scroll-alias', 'data-scroll-hash', 'data-submit-step'];

    public init(): void {
        this.app = OneBaseService.getInstance().currentApp();

        this.moduleActions.forEach((action: string): void => {
            const actionElements: NodeListOf<Element> = document.querySelectorAll(`.module a[${action}]`);
            actionElements.forEach((element: Element): void => {
                if (element instanceof HTMLElement) {
                    element.addEventListener('click', () => {
                        this.clickHandler(action, element);
                    });
                }
            });
        });
    }

    private clickHandler(action: string, element: HTMLElement): void {
        switch (action) {
            case 'data-scroll-alias':
                this.app?.scrollToAliasByName(element.dataset.scrollAlias as string);
                break;
            case 'data-scroll-hash':
                this.app?.scrollToHash(element.dataset.scrollHash as string);
                break;
            case 'data-submit-step':
                this.app?.onSubmitStep();
                break;
        }
    }
}
