import { computed, reactive, Ref, UnwrapNestedRefs } from 'vue';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import TwoWaySupportType from '@/Apps/TwoWayCommunication/Enums/TwoWaySupportTypeEnum';
import TwoWayCommunicationsBlock from '@/Apps/TwoWayCommunication/Interfaces/TwoWayCommunicationsBlockInterface';
import TwoWayStorageFields from '@/Apps/TwoWayCommunication/Interfaces/TwoWayStorageFieldsInterface';
import SpaUserStorage from '@/services/SpaUserStorageService';
import Agreement from '@/Components/Policies/PolicyBlock/Interfaces/AgreementInterface';
import RequestService from '@/services/request.service';
import TwoWayAjaxCalls from '@/Apps/TwoWayCommunication/Enums/TwoWayAjaxCallsEnum';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { AxiosResponse } from 'axios';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import UserCredentials from '@/interfaces/user.credentials.interface';
import User from '@/services/user.service';
import { useTranslate } from '@/Composables/Translate';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';

export default class TwoWayCommunication {
    public storageFields: UnwrapNestedRefs<TwoWayStorageFields> = reactive(
        new (class implements TwoWayStorageFields {
            public supportType: string = TwoWaySupportType.None;
            public caseSupportType: string = TwoWaySupportType.None;
            public selectedClaim: string = '';
            public claimsList: Claim[] = [];
            public selectedPolicy: string = '';
            public policyList: Agreement[] = [];
            public communicationId: string = '';
            public isReply: boolean = false;
            public isAddNewMessage: boolean = false;
            public allCommunications: TwoWayCommunicationsBlock[] = [];
        })(),
    );
    private static instance: TwoWayCommunication;
    private communicationsInitialUrl: string = '';
    private transferStateService: TransferStateService = TransferStateService.getInstance();
    private requestService: RequestService = RequestService.getInstance();

    private constructor() {}

    public static getInstance(): TwoWayCommunication {
        if (!TwoWayCommunication.instance) {
            TwoWayCommunication.instance = new TwoWayCommunication();
            TwoWayCommunication.instance.buildState();
        }

        return TwoWayCommunication.instance;
    }

    public fetchCommunicationsList(): void {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        this.requestService
            .get({
                uri: TwoWayAjaxCalls.List,
                content: {},
                withCache: false,
            })
            .then((response: AxiosResponse<DynamicDictionary>): void => {
                if (useDefine().validResponse(response)) {
                    this.storageFields.allCommunications = response.data.data.body.list;
                }
            })
            .finally((): void => {
                PopupService.getInstance().hide().then();
            });
    }

    public communications(): TwoWayCommunicationsBlock[] {
        return this.storageFields.allCommunications;
    }

    public communicationsById(id: string): TwoWayCommunicationsBlock[] {
        let result: TwoWayCommunicationsBlock[] = [];
        const block: TwoWayCommunicationsBlock[] = this.storageFields.allCommunications.filter(
            (value: TwoWayCommunicationsBlock): boolean => {
                return value.id === id;
            },
        );
        if (block.length > 0) {
            const selectedBlock: TwoWayCommunicationsBlock = block[0];
            if (selectedBlock.sourceId) {
                const thread: TwoWayCommunicationsBlock[] = this.storageFields.allCommunications.filter(
                    (value: TwoWayCommunicationsBlock): boolean => {
                        return value.sourceId === selectedBlock.sourceId;
                    },
                );
                result = result.concat(thread);
            } else {
                result.push(selectedBlock);
            }
        }

        return result;
    }

    public communicationShortBody(block: TwoWayCommunicationsBlock): string {
        const withNewLineTokens: string = block.body.replace(/<\/(p)*?>/g, '@#@');
        const withNoOtherTags: string = withNewLineTokens.replace(/<(.)*?>/g, '');
        const withNewLines: string = withNoOtherTags.replace(/@#@/, '</br>');
        const maxLength: number = 120;
        const length: number = withNewLines.length;
        const ellipsis: string = length > maxLength ? withNewLines.slice(0, maxLength) + '...' : withNewLines;

        return '<p>' + ellipsis + '</p>';
    }

    public communicationIsBta(block: TwoWayCommunicationsBlock): boolean {
        return !block.isAuthenticatedPersonAuthor;
    }

    public communicationAuthor(block: TwoWayCommunicationsBlock): string {
        let result: string = useTranslate().translate('bta_team');
        if (block.isAuthenticatedPersonAuthor) {
            const currentUsr: UserCredentials = User.getInstance().current;
            result = currentUsr.name;
        }

        return result;
    }

    public unreadCommunications: Ref<number> = computed(() => {
        return this.storageFields.allCommunications.filter((communication: TwoWayCommunicationsBlock) => {
            return !communication.read;
        }).length;
    });

    public hasUnreadCommunications: Ref<boolean> = computed((): boolean => {
        return (
            this.storageFields.allCommunications.filter((communication: TwoWayCommunicationsBlock) => {
                return !communication.read;
            }).length > 0
        );
    });

    public markAsRead(): void {
        const communicationId: string = this.storageFields.communicationId;
        if (this.messageIsUnread(communicationId)) {
            this.requestService
                .get({
                    uri: TwoWayAjaxCalls.Read,
                    content: {
                        id: communicationId,
                    },
                    withCache: false,
                })
                .then((response: AxiosResponse<DynamicDictionary>): void => {
                    if (useDefine().validResponse(response)) {
                        this.markAsReadById(communicationId);
                        SpaUserStorage.getInstance().saveUpdatedStorage();
                    }
                })
                .catch((reason: DynamicDictionary) => {
                    Error.log(
                        ErrorType.Error,
                        'TwoWayCommunicationSingle::markAsRead(' + communicationId + ')',
                        reason,
                        true,
                    );
                });
        }
    }

    public currentSelectedClaim(): Claim | null {
        let result: Claim | null = null;
        if (String(this.storageFields.claimsList) !== '') {
            this.storageFields.claimsList.forEach((claim: Claim): void => {
                if (claim.claimNumber === this.storageFields.selectedClaim) {
                    result = claim;
                }
            });
        }

        return result;
    }

    public currentSelectedPolicy(): Agreement | null {
        let result: Agreement | null = null;
        if (String(this.storageFields.policyList) !== '') {
            this.storageFields.policyList.forEach((policy: Agreement): void => {
                if (policy.agreementNumber === this.storageFields.selectedPolicy) {
                    result = policy;
                }
            });
        }

        return result;
    }

    public communicationsUrl(): string {
        return this.communicationsInitialUrl;
    }

    public actionsAfterRouteChanged(from: string, to: string): void {
        if (from === 'communication-new' && to === 'communication-single') {
            this.storageFields.supportType = TwoWaySupportType.None;
            this.storageFields.caseSupportType = TwoWaySupportType.None;
            this.storageFields.isReply = false;
            this.storageFields.isAddNewMessage = false;
        }
        if (from === 'communication-new' && to === 'communication-single') {
            this.storageFields.supportType = TwoWaySupportType.None;
        }
        SpaUserStorage.getInstance().saveUpdatedStorage();
    }

    private markAsReadById(id: string): void {
        this.storageFields.allCommunications.map((communication: TwoWayCommunicationsBlock): void => {
            if (communication.id === id) {
                communication.read = true;
            }
        });
    }

    private messageIsUnread(id: string): boolean {
        let result: boolean = false;
        this.storageFields.allCommunications.map((communication: TwoWayCommunicationsBlock): void => {
            if (communication.id === id && !communication.read) {
                result = true;
            }
        });

        return result;
    }

    private buildState(): void {
        this.communicationsInitialUrl = this.transferStateService.get('communicationsInitialUrl') || '';
    }
}
