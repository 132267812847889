import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import { CellRenderer } from '@/Components/Lists/SmartGrid/Renderers/CellRenderer';

export class TemplateCellRenderer extends CellRenderer {
    public htmlTemplate(params: DynamicDictionary, data: DynamicDictionary): string {
        let value: LimitedVariant = '';
        if (params.valueObjectFieldName) {
            value = params.value[params.valueObjectFieldName];
        } else {
            value = params.formattedValue ? params.formattedValue : params.value;
        }

        return String(params.template).replace('@', String(value));
    }
}
