<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { PropType } from 'vue';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';

const props = defineProps({
    data: { type: Array as PropType<CoverageRisk[]>, default: () => [] },
});
const { translate } = useTranslate();

function policyPlanPrice(targetPrice: number, currency: string): string {
    let result: string = '<span class="zero-price"></span>';
    if (targetPrice > 0) {
        result = '<span>' + targetPrice + '</span><span>' + currency + '</span>';
    }

    return result;
}
</script>

<template>
    <div class="inner-one-day-policy">
        <div v-for="(risk, index) in props.data" :key="index" class="item">
            <div class="title">{{ translate(risk.id) }}</div>
            <div class="price" v-html="policyPlanPrice(risk.insuredSum, '&nbsp;&euro;')"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.inner-one-day-policy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 180px;
    margin-bottom: 20px;

    .item {
        position: relative;
        min-height: 46px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-above('sm') {
            justify-content: center;
            display: inherit;
            white-space: nowrap;
        }

        .title {
            color: var(--text-color-default);
            font-size: var(--font-size-nano);
            text-align: center;
            padding: var(--size-nano) 0;
            width: 50%;
        }

        .price {
            position: relative;
            font-weight: 600;
            padding-left: 30px;
            display: flex;
            justify-content: right;
            font-size: var(--font-size-tiny);
            min-width: 32px;
            width: 50%;

            .zero-price {
                position: absolute;
                background-color: var(--brand-red);
                width: 32px;
                height: 2px;
                right: 0;

                @include respond-above('sm') {
                    left: 50%;
                    margin-left: -16px;
                    margin-top: 10px;
                }
            }

            span {
                &:last-child {
                    padding-left: 4px;
                }
            }
        }

        .title,
        .price {
            text-align: left;

            @include respond-above('sm') {
                text-align: left;
            }
        }

        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            border-top: thin solid var(--black-500);
            bottom: 0;
            opacity: 0.24;
        }

        &:first-child {
            &::before {
                position: absolute;
                content: '';
                height: 1px;
                width: 100%;
                border-top: thin solid var(--black-500);
                top: 0;
                opacity: 0.24;
            }
        }
    }
}
</style>
