<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { CoverageRisk } from '@/interfaces/resources/MovableProperties/CoverageRiskInterface';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { usePrice } from '@/Composables/Price';
import { PropType } from 'vue';

const props = defineProps({
    data: { type: Array as PropType<CoverageRisk[]>, default: () => [] },
});
const { translate, translateForType } = useTranslate();
const TranslationType: string = 'items_and_belongings';

function riskPriceInfo(risk: CoverageRisk): string {
    const additionalRiskText: string = translateForType('risk_is_additional', TranslationType);
    const limitText: string = translateForType('limit_from', TranslationType) + '&nbsp;';
    let result: string = `<span>${limitText + usePrice().sparse(risk.insuredSum, true)}</span><span>&nbsp;&euro;</span>`;
    if (risk.withoutInsuredSum) {
        result = risk.isAdditional
            ? `<span>${additionalRiskText}</span>`
            : `<span>${useHtml().imgTag(successIconAttributes())}</span>`;
    }

    return result;
}

function successIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/checkmark-thin.svg',
        width: 24,
        height: 24,
    };
}
</script>

<template>
    <div class="inner-movable-property">
        <div v-for="(risk, index) in props.data" :key="index" class="item">
            <div class="title">{{ translateForType(risk.id + '_MAIN', 'items_and_belongings') }}</div>
            <div class="limit" v-html="riskPriceInfo(risk)"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.inner-movable-property {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 180px;
    margin-bottom: var(--size-small);

    .item {
        position: relative;
        min-height: 46px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: var(--font-size-tiny);

        @include respond-above('sm') {
            justify-content: center;
            display: inherit;
            white-space: nowrap;
        }

        .title {
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            text-align: center;
            padding: var(--size-nano) 0;
            width: 50%;
        }

        .limit {
            position: relative;
            padding-left: 30px;
            display: flex;
            justify-content: right;
            font-size: var(--font-size-nano);
            line-height: var(--line-height-accented);
            min-width: 32px;
            width: 50%;
        }

        .title,
        .price {
            text-align: left;

            @include respond-above('sm') {
                text-align: left;
            }
        }

        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            border-top: thin solid var(--component-color-border-default);
            bottom: 0;
            opacity: 0.24;
        }

        &:first-child {
            &::before {
                position: absolute;
                content: '';
                height: 1px;
                width: 100%;
                border-top: thin solid var(--component-color-border-default);
                top: 0;
                opacity: 0.24;
            }
        }
    }
}
</style>
