import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DynamicStep from '@/interfaces/dynamic.step.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import OneBaseService from '@/services/OneBaseService';

export default class DynamicStepper {
    private stepsContents: DynamicStep[] = [];
    private currentStep: number = 0;
    private static instance: DynamicStepper;
    private showStepper: boolean = true;

    private constructor() {}

    public static getInstance(): DynamicStepper {
        if (!DynamicStepper.instance) {
            DynamicStepper.instance = new DynamicStepper();
        }

        return DynamicStepper.instance;
    }

    public applyDynamicSteps(steps: string): void {
        try {
            this.stepsContents = JSON.parse(steps);
        } catch (reason) {
            Error.log(ErrorType.Error, 'DynamicStepper::applyDynamicSteps', reason as LimitedVariant);
        }
    }

    public applyCurrent(current: number): void {
        this.currentStep = current;
        this.stepsContents.forEach((step: DynamicStep) => {
            step.current = step.index === current;
        });
    }

    public applyEnabled(enabled: number[]): void {
        if (enabled.length > 0) {
            let index: number = 1;
            this.disableAll();
            this.stepsContents.forEach((step: DynamicStep) => {
                step.visible = enabled.includes(step.index);
                if (step.visible) {
                    step.rowIndex = index++;
                }
            });
        }
    }

    public enableAll(): void {
        let index: number = 1;
        this.stepsContents.forEach((step: DynamicStep) => {
            step.visible = true;
            step.rowIndex = index++;
        });
    }

    public disableAll(): void {
        this.stepsContents.forEach((step: DynamicStep) => {
            step.visible = false;
            step.rowIndex = 0;
        });
    }

    public itemClasses(item: DynamicStep): string {
        let result: string = 'application-step ';
        if (item.index < this.currentStep) {
            result += 'done enabled';
        } else if (item.index === this.currentStep) {
            result += 'current enabled';
        } else {
            result += 'next';
        }

        return result;
    }

    public hasIcon(item: DynamicStep): boolean {
        return item.index < this.currentStep;
    }

    public hasAside(item: DynamicStep): boolean {
        let lastIndex: number = 0;
        this.stepsContents.forEach((step: DynamicStep) => {
            if (step.visible) {
                lastIndex = step.index;
            }
        });

        return item.index !== lastIndex;
    }

    public onClick(item: DynamicStep): void {
        OneBaseService.getInstance().navigate(item.url);
    }

    public get steps(): DynamicStep[] {
        return this.stepsContents;
    }

    public get rowIndex(): number {
        let result: number = 1;
        let index: number = 1;
        this.stepsContents.forEach((step: DynamicStep) => {
            if (step.current) {
                result = index;
            }
            if (step.visible) {
                index++;
            }
        });

        return result;
    }

    public get enableStates(): number[] {
        const result: number[] = [];
        this.stepsContents.forEach((step: DynamicStep) => {
            if (step.visible) {
                result.push(step.index);
            }
        });

        return result;
    }

    public get shouldShowStepper(): boolean {
        return this.showStepper;
    }

    public show(show: boolean): boolean {
        return (this.showStepper = show);
    }
}
