<script setup lang="ts">
import { PropType } from 'vue';
import { CoveragePlan } from '@/Apps/SolarPanels/Interfaces/CoveragePlanInterface';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useHtml } from '@/Composables/Html';
import { usePrice } from '@/Composables/Price';
import { useTranslate } from '@/Composables/Translate';

const { translateForType } = useTranslate();
const TranslationType: string = 'solar_panels_insurance';

const props = defineProps({
    data: { type: Object as PropType<CoveragePlan>, default: () => ({}) as DynamicDictionary },
});

function option(selected: boolean): string {
    const iconSrc: string = selected ? 'images/one/checkmark-thin.svg' : 'images/one/x-thin.svg';

    return useHtml().imgTag(iconAttributes(iconSrc));
}

function iconAttributes(iconSrc: string): ImageTagAttributes {
    return {
        class: '',
        src: iconSrc,
        width: 24,
        height: 24,
    };
}
</script>

<template>
    <div class="solar-panels-policy">
        <div class="item">
            <div class="title">{{ translateForType('include_all_risks', TranslationType) }}</div>
            <div class="limit-from-value">
                <span class="subvalue"
                    >{{ translateForType('limit_from', TranslationType) }}&nbsp;
                    <span class="currency">{{ usePrice().sparse(data.insuredSum, true) }} &nbsp;&euro;</span></span
                >
            </div>
        </div>
        <div v-for="(risk, index) in data.risks" :key="index" class="item">
            <div class="title">{{ translateForType(risk.id + '_MAIN', TranslationType) }}</div>
            <div class="value" v-html="option(risk.isIncluded)"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.solar-panels-policy {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-width: 180px;
    margin-bottom: 20px;

    .item {
        position: relative;
        min-height: 46px;
        margin-top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include respond-above('sm') {
            justify-content: center;
            display: inherit;
        }

        .title {
            color: var(--text-color-default);
            font-size: var(--font-size-tiny);
            text-align: left;
            padding: 12px 0;
            width: 100%;
        }

        .value {
            padding-left: 30px;
            justify-content: right;
            font-size: var(--font-size-nano);
            line-height: var(--line-height-accented);
            min-width: 32px;

            span {
                &:last-child {
                    padding-left: 4px;
                }
            }
        }

        .limit-from-value {
            color: var(--text-color-subtle);
            font-size: var(--font-size-nano);
            font-weight: 500;
            justify-content: right;
            white-space: nowrap;

            .currency {
                color: var(--text-color-subtle);
                font-size: var(--font-size-tiny);
                font-weight: 600;
            }
        }

        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            border-top: thin solid var(--component-color-border-default);
            bottom: 0;
            opacity: 0.24;
        }

        &:first-child {
            &::before {
                position: absolute;
                content: '';
                height: 1px;
                width: 100%;
                border-top: thin solid var(--component-color-border-default);
                top: 0;
                opacity: 0.24;
            }
        }
    }
}
</style>
