<script setup lang="ts">
import { getCurrentInstance, onMounted, ref, Ref } from 'vue';
import { AxiosResponse } from 'axios';
import { SolarPanels } from '@/Apps/SolarPanels/Interfaces/SolarPanelsInterface';
import { CoverageRisk } from '@/Apps/SolarPanels/Interfaces/CoverageRiskInterface';
import { CoveredPopupBuilder } from '@/Apps/SolarPanels/Builders/CoveredPopupBuilder';
import { useTranslate } from '@/Composables/Translate';
import { Router, useRouter } from 'vue-router';
import PopupService from '@/services/custom.popup.service';
import Form from '@/assets/libraries/form/form';
import Url from '@/Enums/UrlEnum';
import OnePopup from '@/assets/libraries/popups/one.popup';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Storage from '@/Apps/SolarPanels/Services/Storage';
import { useDefine } from '@/Composables/Define';
import { Program } from '@/Apps/SolarPanels/Interfaces/ProductProgram';
import StepsGuard from '@/Apps/SolarPanels/Services/StepsGuard';
import RequestService from '@/services/request.service';
import Steps from '@/Apps/SolarPanels/Enums/Steps';
import OneBaseService from '@/services/OneBaseService';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import AppProductBlock from '@/Components/Other/ProductBlock/ProductBlock.vue';
import AppCustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import ProductBlockItemBuilder from '@/Components/Other/ProductBlock/Builders/ProductBlockItemBuilder';
import ProductBlockItem from '@/Components/Other/ProductBlock/Interfaces/ProductBlockItemInterface';

const router: Router = useRouter();
const { translateForType } = useTranslate();
const { isSet, isLinkAnchor } = useDefine();

const form: Form = new Form();
const popupService: PopupService = PopupService.getInstance();
const TranslationType: string = 'solar_panels_insurance';
const coveredPopup: CoveredPopupBuilder = new CoveredPopupBuilder();
const productBlocks: Ref<ProductBlockItem[]> = ref([]);
const BadgeTextSuffix: string = '_BADGE_TEXT';
const storage: Storage = Storage.getInstance();

let products: SolarPanels[] = [];

defineExpose({
    coveredPopup,
});

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    StepsGuard.getInstance(storage).init();
    fetchProducts().then(() => {
        if (shouldReroute()) {
            reRoute();
        } else {
            setupForm();
            buildProductBlocks();
            buildCoveredPopup();
        }
    });
});

function fetchProducts(): Promise<void> {
    return new Promise((resolve) => {
        RequestService.getInstance()
            .get({
                uri: Url.Ajax.solarPanelsProducts,
            })
            .then((response: AxiosResponse): void => {
                if (useDefine().validResponse(response)) {
                    products = response.data.data.body.products;
                    resolve();
                }
            })
            .catch((reason: DynamicDictionary): void => {
                Error.log(ErrorType.Error, 'Solar panels policy / Insurance page / fetchProducts', reason);
            });
    });
}

function setupForm(): void {
    clearStorage();
    form.setReady();
}

function clearStorage(): void {
    storage.fields.selectedProgram = undefined;
}

function buildCoveredPopup(): void {
    coveredPopup
        .withCoveredType('AppCoveredPopupSolarPanelsMulti')
        .withOnSelectClick(onSelectProductClick as Function)
        .withContent(products);
}

function onSelectProductClick(programIc: string[]): void {
    const product: DynamicDictionary = products.find(
        (value: DynamicDictionary) => value.id === programIc[0],
    ) as DynamicDictionary;
    storage.fields.selectedProgram = new (class implements Program {
        public programIc: string = product.id;
        public price: number = product.price;
        public insuredSum: number = product.insuredSum;
    })();
    router.push({ name: Steps.Coverage });
    popupService.hide();
}

function onShowCoveredPopupClick(): void {
    popupService.show(new OnePopup().withType().oneCovered);
}

function buildProductBlocks(): void {
    products.forEach((policy: SolarPanels): void => {
        const programIc: string = policy.id.toLowerCase();
        const productBlockItemBuilder: ProductBlockItemBuilder = new ProductBlockItemBuilder();
        const productName: string = translateForType(programIc, TranslationType);
        const price: number = policy.price;
        const badgeText: string = translateForType(policy.id + BadgeTextSuffix, TranslationType);
        const customModule: string = 'AppProductBlockInnerSolarPanelsPolicy';
        const selectButtonText: string = translateForType('button_select_' + programIc, TranslationType);
        const customButtonText: string = translateForType('what_covered_solar_panels', TranslationType);
        const dynamicContent: DynamicDictionary = {
            insuredSum: policy.insuredSum,
            risks: featuredRisks(policy.risks),
        };
        productBlockItemBuilder
            .withProductId(policy.id)
            .withProductName(productName)
            .withDiscountSum(price)
            .withDiscountTooltip({
                title: translateForType('price_from', TranslationType),
                description: translateForType('tooltip_product_block_description', TranslationType),
            })
            .withDynamicContent(customModule, dynamicContent)
            .withButtonSelectTitle(selectButtonText)
            .withButtonCustomTitle(customButtonText);
        if (policy.params.withBadge) {
            productBlockItemBuilder.withBadgeText(badgeText);
        }
        if (policy.params.defaultOnMobile) {
            productBlockItemBuilder.asDefaultOnMobile();
        }
        productBlocks.value.push(productBlockItemBuilder.build());
    });
}

function featuredRisks(risks: CoverageRisk[]): CoverageRisk[] {
    return risks.filter((risk: CoverageRisk) => risk.isFeatured);
}

function reRoute(): void {
    router.push(storage.store().currentRoute);
}

function shouldReroute(): boolean {
    const isCurrentRoute: boolean = storage.store().currentRoute !== '';
    const isStorageSelectedProgram: boolean = isSet(storage.fields.selectedProgram);

    return isStorageSelectedProgram && isCurrentRoute && !isLinkAnchor(location.href);
}
</script>

<template>
    <div class="container">
        <h1 class="page-title">{{ translateForType('title_solar_panels_insurance', TranslationType) }}</h1>
        <h2 class="page-subtitle">{{ translateForType('subtitle_solar_panels_insurance', TranslationType) }}</h2>
        <div v-if="!form.isReady()" class="loading">
            <app-content-loader></app-content-loader>
        </div>
        <app-custom-form else :form="form" class="form">
            <app-product-block
                :id="'solarPanelProducts'"
                :form-field="form.field('product-panel-blocks')"
                :products="productBlocks"
                :product-sum-type="'mo'"
                :selected-frame-hidden="true"
                :mini-plans-component="'AppSolarPanelsMini'"
                @selected="onSelectProductClick"
                @custom="onShowCoveredPopupClick"
            >
            </app-product-block>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.container {
    margin-top: var(--size-big) !important;
}
</style>
