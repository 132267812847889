import TriggeredEvent = JQuery.TriggeredEvent;
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { LimitedVariant } from '@/Types/LimitedVariantType';

export default class VueDom {
    private readonly originalEvent: TriggeredEvent | MouseEvent | FocusEvent;
    private readonly target: JQuery;
    private readonly destinationTarget: JQuery;

    public constructor(event: TriggeredEvent | MouseEvent | FocusEvent) {
        this.originalEvent = event;
        this.target = $(Object(event.currentTarget));
        this.destinationTarget = $(Object(event.target));
    }

    public data(value: string): LimitedVariant {
        return this.target.data(value);
    }

    public get params(): DynamicDictionary {
        return this.target.data('param');
    }

    public get sender(): JQuery {
        return this.target;
    }

    public get event(): TriggeredEvent | MouseEvent | FocusEvent {
        return this.originalEvent;
    }

    public get destinationSender(): JQuery {
        return this.destinationTarget;
    }
}
