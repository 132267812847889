<script setup lang="ts">
import MapControlsOptions from '@/Components/Maps/MapControls/MapControlsOptions';

const props = defineProps({
    options: { type: MapControlsOptions, default: () => new MapControlsOptions() },
});
const emit = defineEmits(['map-zoom-in', 'map-zoom-out', 'map-home', 'map-geolocate', 'map-basemap']);

function onZoomInClick(): void {
    emit('map-zoom-in');
}

function onZoomOutClick(): void {
    emit('map-zoom-out');
}

function onMapHomeClick(): void {
    emit('map-home');
}

function onGeolocateClick(): void {
    emit('map-geolocate');
}

function onBasemapClick(): void {
    emit('map-basemap');
}
</script>
<template>
    <div class="map-controls">
        <button v-if="options.zoomControl" class="map-button" @click="onZoomInClick()">
            <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    class="fill"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 0H4V4H0V6H4V10H6V6H10V4H6V0Z"
                    fill="#9297A0"
                />
            </svg>
        </button>
        <button v-if="options.zoomControl" class="map-button" @click="onZoomOutClick()">
            <svg width="10" height="10" viewBox="0 0 10 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill" d="M0 2V0H10V2H0Z" fill="#9297A0" />
            </svg>
        </button>
        <button v-if="options.homeControl" class="map-button" @click="onMapHomeClick()">
            <svg width="21" height="21" viewBox="-2 -3 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    class="stroke"
                    d="M1.00129 5.2L7.00065 1L13 5.2C13 7.8 13 10.4 13 13H1.00233C0.997781 10.4 1.00129 7.8 1.00129 5.2Z"
                    stroke="#9297A0"
                    stroke-width="1.5"
                    stroke-linecap="square"
                />
                <path
                    class="stroke"
                    d="M5.00195 13V8.19995H9.00152V13"
                    stroke="#9297A0"
                    stroke-width="1.5"
                    stroke-linecap="square"
                />
            </svg>
        </button>
        <button v-if="options.geolocateControl" class="map-button" @click="onGeolocateClick()">
            <svg width="20" height="21" viewBox="-1 -2 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle class="stroke" cx="8.99858" cy="8.99988" r="5.79545" stroke="#9297A0" stroke-width="1.5" />
                <circle class="fill" cx="9.0016" cy="8.99997" r="3.63636" fill="#9297A0" />
                <path class="stroke" d="M9 1V17" stroke="#9297A0" stroke-width="1.5" stroke-linecap="square" />
                <path class="stroke" d="M17 9L1 9" stroke="#9297A0" stroke-width="1.5" stroke-linecap="square" />
            </svg>
        </button>
        <button v-if="options.basemapControl" class="map-button" @click="onBasemapClick()">
            <svg width="21" height="21" viewBox="-1.5 -3 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    class="stroke"
                    d="M7.00028 1L1.85742 5.28571L7.00028 9.57143L12.1431 5.28571L7.00028 1Z"
                    stroke="#9297A0"
                    stroke-width="1.5"
                />
                <path class="stroke" d="M1 7.85714L7 13L13 7.85714" stroke="#9297A0" stroke-width="1.5" />
            </svg>
        </button>
    </div>
</template>
<style lang="scss" scoped>
.map-controls {
    display: flex;
    flex-direction: column;
    width: 32px;
    margin-right: var(--size-pico);

    .map-button {
        background-color: var(--white);
        box-shadow: 0 1px 3px 0 var(--black-600);
        border-radius: 2px;
        width: 29px;
        height: 29px;
        align-items: center;
        margin-top: 3px;
        margin-bottom: var(--size-pico);

        &:hover {
            .fill {
                fill: var(--brand-teal);
            }

            .stroke {
                stroke: var(--brand-teal);
            }

            .icon {
                path {
                    stroke: var(--brand-teal);
                }
            }
        }
    }
}
</style>
