<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import FilesByLinkGenerateFormModule from '@/Modules/FilesByLinkGenerateForm/FilesByLinkGenerateFormModule.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const filesByLinkGenerateFormModule = new VueModule(FilesByLinkGenerateFormModule).mount();

        return {
            ...btaBase,
            ...{
                filesByLinkGenerateFormModule,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.filesByLinkGenerateFormModule.create();
        this.filesByLinkGenerateFormModule.beforeModuleMounted();
        this.filesByLinkGenerateFormModule.moduleMounted();
    },
});
</script>
