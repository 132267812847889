import UserCredentials from '@/interfaces/user.credentials.interface';
import { Subject } from 'rxjs';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { reactive, UnwrapNestedRefs } from 'vue';

export default class User {
    public static LoginStatusNoChanges: string = 'no-changes';

    private static instance: User;
    private user: UnwrapNestedRefs<UserCredentials> = reactive({
        logged: false,
        firstTimeStep: 0,
        address: '',
        bank: '',
        bankCurrency: '',
        bankFirstName: '',
        bankFullName: '',
        bankLastName: '',
        bankName: '',
        bankSwift: '',
        bankTemp: '',
        birthDate: '',
        email: '',
        firstname: '',
        agreeVersion: '',
        gender: '',
        lastname: '',
        name: '',
        personCode: '',
        personId: '',
        clientNumber: '',
        phone: '',
        phoneCode: '',
        code: '',
        phoneDefault: '',
        receiveMarketing: false,
        marketingConsents: [],
        resident: true,
        csrf_name: '',
        csrf_hash: '',
        addressCode: '',
        addressCountry: '',
        addressExtra: '',
        postCode: '',
        changeName: '',
        isFixedPhone: false,
        agreedPersonalProcessing: false,
        personalProcessingExpirationDate: '',
        hasActiveHealthPolicy: false,
        legalEntities: [],
        selectedEntity: null,
        correctContactData: false,
        contactLanguage: '',
        firstTime: 0,
        contactsConsentId: '',
        dataProcessingConsentId: '',
        marketingConsentId: '',
    });
    private guestOnly: boolean = false;
    private userLoginStatus: string;
    public ready: boolean = false;
    public onExternalDataIsReady: Subject<void> = new Subject<void>();

    public constructor() {
        this.userLoginStatus = User.LoginStatusNoChanges;
    }

    public static getInstance(): User {
        if (!User.instance) {
            User.instance = new User();
        }

        return User.instance;
    }

    public addUser(json: DynamicDictionary): void {
        if (json && Object.keys(json).length > 0) {
            Object.assign(this.user, json);
            this.user.logged = true;
        }
        this.ready = true;
        this.onExternalDataIsReady.next();
    }

    public isLogged(): boolean {
        return this.user.logged;
    }

    public isGuest(): boolean {
        return !this.isLogged();
    }

    public isLoggedAsBooleanString(): string {
        return this.isLogged() ? 'true' : 'false';
    }

    public applyGuestOnly(): void {
        this.guestOnly = true;
    }

    public applyLoginStatus(status: string): void {
        this.userLoginStatus = status;
    }

    public get isGuestOnly(): boolean {
        return this.guestOnly;
    }

    public get loginStatus(): string {
        return this.userLoginStatus;
    }

    public get current(): UserCredentials {
        return this.user;
    }

    public userAgreedPersonalProcessing(): boolean {
        return this.user.agreedPersonalProcessing;
    }

    public set agreedPersonalProcessing(value: boolean) {
        this.user.agreedPersonalProcessing = value;
    }

    public userPersonalProcessingExpirationDate(): string {
        return this.user.personalProcessingExpirationDate;
    }

    public get hasActiveHealthPolicy(): boolean {
        return this.user.hasActiveHealthPolicy;
    }

    public userHasCorrectContactData(): boolean {
        return this.user.correctContactData;
    }

    public userAgreedReceiveMarketing(): boolean {
        return this.user.receiveMarketing;
    }

    public assembleUser(): DynamicDictionary {
        const params: DynamicDictionary = {};
        params[this.user.csrf_name] = this.user.csrf_hash;
        params['params'] = JSON.parse(JSON.stringify(this.user));

        return params;
    }

    public hasLegalPersons(): boolean {
        return typeof this.user.legalEntities !== 'undefined' && this.user.legalEntities.length > 0;
    }
}
