<script setup lang="ts">
import { computed, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, Ref, watch } from 'vue';
import { Subject, Subscription } from 'rxjs';
import * as uuid from 'uuid';
import Suggestion from '@/interfaces/suggestion.interface';
import VueEvent from '@/Classes/VueEventClass';
import AppCountry from '@/assets/libraries/app/app-country';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Sanitizer from '@/services/sanitizer.service';
import FormField from '@/assets/libraries/form/form-field';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';
import { useDefine } from '@/Composables/Define';
import { useInputErrorMessage } from '@/Composables/InputErrorMessage';
import { debounceTime } from 'rxjs/operators';
import RequestService from '@/services/request.service';
import { useAxios } from '@/Composables/Axios';

const props = defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    locked: { type: Boolean, default: true },
    minCharacters: { type: Number, default: 3 },
    type: { type: String, default: 'basic' },
    url: { type: String, default: '' },
    receiverCallback: { type: Function, default: undefined },
    disablePredictiveText: { type: Boolean, default: false },
    additionalParams: {
        type: Object,
        default: () => {},
    },
    placeholder: { type: String, default: '' },
    label: { type: String, default: '' },
    supportTextMessage: { type: String, default: '' },
    feedbackMessage: { type: String, default: '' },
    disableErrorText: { type: Boolean, default: false },
});
const emit = defineEmits(['lock-change', 'click', 'change']);
const { isSet } = useDefine();
const { infoMessageIsVisible, infoMessage } = useInputErrorMessage(props.formField, props.disableErrorText, {
    supportTextMessage: props.supportTextMessage,
    feedbackMessage: props.feedbackMessage,
});
const isEmptyValue: Ref<boolean> = computed((): boolean => {
    return (
        !(props.formField.value as { label: string }).label || (props.formField.value as { label: string }).label === ''
    );
});
const isLocked: Ref<boolean> = ref(true);
const requestInProgress: Ref<boolean> = ref(false);
const inputLabelModel: Ref<string> = ref('');
const suggestions: Ref<Array<Suggestion>> = ref([]);

let onInputSubjectSubscribe: Subscription | null = null;
let patchSubscribe: Subscription | null = null;
let clearSubscribe: Subscription | null = null;
const onInputSubject: Subject<VueEvent | void> = new Subject();

watch(
    () => props.locked,
    () => {
        isLocked.value = props.locked;
    },
);

watch(
    () => isLocked.value,
    () => {
        emitLockChange();
    },
);

onBeforeMount((): void => {
    isLocked.value = props.locked;
});

onMounted((): void => {
    enableIfEmpty();
    updateInputLabelModel();
    patchSubscribe = props.formField.onPatch.subscribe((value: any) => {
        updateInputLabelModel();
        value !== '' ? disableIfHasValue() : enableIfEmpty();
    });
    clearSubscribe = props.formField.onClear.subscribe(() => {
        updateInputLabelModel();
        enableIfEmpty();
    });
    onInputSubjectSubscribe = onInputSubject.pipe(debounceTime(1000)).subscribe((event) => {
        if (event && isFetchKeyCodeFromEvent(event) && getQuery().length >= props.minCharacters) {
            fetchSuggestions();
        }
    });
    nextTick(() => {
        emitLockChange();
    });
});

onBeforeUnmount((): void => {
    if (isSet(patchSubscribe)) {
        patchSubscribe!.unsubscribe();
    }
    if (isSet(clearSubscribe)) {
        clearSubscribe!.unsubscribe();
    }
    if (isSet(onInputSubjectSubscribe)) {
        onInputSubjectSubscribe!.unsubscribe();
    }
});

function emitLockChange() {
    emit('lock-change', isLocked.value);
}

function emitClick() {
    emit('click', isLocked.value);
}

function emitChange() {
    emit('change', props.formField.value);
}

function fieldId(): string {
    return props.formField.name + '-text';
}

function uid(): string {
    return '-' + uuid.v4();
}

function onKeyUp(event: VueEvent): void {
    props.formField.value = '';
    if (props.disablePredictiveText) {
        const eventSender: DynamicDictionary = event.sender;
        if (eventSender.length) {
            const currentText: string = eventSender[0].value;
            if (isSet(currentText) && currentText.length > 2) {
                inputLabelModel.value = currentText;
            }
        }
    }
    props.formField.touch();
    props.formField.validate();
    onInputSubject.next(event);
    if (inputLabelModel.value === '') {
        suggestions.value = [];
    }
}

function select(item: Suggestion): void {
    suggestions.value = [];
    isLocked.value = true;
    props.formField.value = item;
    updateInputLabelModel();
    nextTick(() => {
        emitChange();
    });
}

function enable(): void {
    isLocked.value = false;
    props.formField.clear();
    props.formField.touch();
    props.formField.validate();
    onInputSubject.next();
    emitClick();
}

function isSelected(item: Suggestion): boolean {
    return !isEmptyValue.value && item.label === (props.formField.value as { label: string }).label;
}

function isFetchKeyCodeFromEvent(event: VueEvent): boolean {
    const keyCode: number | null = event ? (event.event.which as number) : null;

    return (
        !!keyCode &&
        (keyCode === 111 ||
            keyCode === 109 ||
            (keyCode >= 96 && keyCode <= 105) ||
            (keyCode >= 65 && keyCode <= 90) ||
            (keyCode >= 48 && keyCode <= 57) ||
            keyCode === 32 ||
            keyCode === 46 ||
            keyCode === 8 ||
            keyCode === 229)
    );
}

function fetchSuggestions(): void {
    requestInProgress.value = true;
    suggestions.value = [];
    RequestService.getInstance()
        .get({
            uri: props.url + getQuery(),
            returnRaw: true,
        })
        .then((value) => {
            suggestions.value = value.data.map((item: any) => {
                return props.receiverCallback ? props.receiverCallback(item) : item;
            });
        })
        .catch((reason) => {
            if (!useAxios().isCancel(reason)) {
                Error.log(ErrorType.Error, 'fetchSuggestions', reason);
            }
        })
        .finally(() => {
            attractSuggestion();
            requestInProgress.value = false;
        });
}

function attractSuggestion(): void {
    if (suggestions.value.length === 1 && new AppCountry().isLT()) {
        select(suggestions.value[0]);
    }
}

function getQuery(): string {
    const query: string = queryWithAdditionalParams(String(inputLabelModel.value));

    return Sanitizer.cleanAddressRequest(query);
}

function queryWithAdditionalParams(query: string): string {
    return props.additionalParams && Object.keys(props.additionalParams).length > 0
        ? `${query}&${new URLSearchParams(props.additionalParams).toString()}`
        : query;
}

function enableIfEmpty(): void {
    if (isEmptyValue.value) {
        isLocked.value = false;
    }
}

function disableIfHasValue(): void {
    if (!isEmptyValue.value) {
        isLocked.value = true;
    }
}

function updateInputLabelModel(): void {
    if (isEmptyValue.value) {
        inputLabelModel.value = '';
        enableIfEmpty();
    } else {
        inputLabelModel.value = (props.formField.value as { label: string }).label;
    }
}

function vueEvent(event: any): VueEvent {
    return new VueEvent(event);
}
</script>
<template>
    <div
        :id="formField.name"
        class="input switchable-input input-text-ajax"
        :class="{ ...formField.classes(), disabled: isLocked }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label informative">
            <label :for="fieldId()">{{ label }}</label>
            <slot name="app-tooltipster"></slot>
        </div>
        <div class="wrapper">
            <input
                :id="fieldId()"
                v-model="inputLabelModel"
                type="text"
                :autocomplete="'disabled'"
                :name="formField.name + uid"
                :disabled="isLocked"
                :placeholder="placeholder"
                @keyup="onKeyUp(vueEvent($event))"
            />
            <div v-if="infoMessageIsVisible()" class="feedback" v-html="infoMessage()"></div>
            <div v-if="suggestions.length > 0" class="autocomplete">
                <div
                    v-for="(item, index) in suggestions"
                    :id="formField.name + '-suggestion-' + index"
                    :key="index"
                    class="item"
                    :class="{ selected: isSelected(item) }"
                    @click="select(item)"
                >
                    {{ item.label }}
                </div>
            </div>
            <div v-if="isLocked" class="enabler">
                <button :id="formField.name + '-click'" @click="enable">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.48">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M14.7071 1.29289C14.3166 0.902369 13.6834 0.902369 13.2929 1.29289L2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V17C2 17.5523 2.44772 18 3 18H7C7.26522 18 7.51957 17.8946 7.70711 17.7071L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289L14.7071 1.29289ZM4 16V13.4142L14 3.41421L16.5858 6L6.58579 16H4Z"
                                fill="#9297A0"
                            />
                            <path
                                d="M3 21C2.44772 21 2 21.4477 2 22C2 22.5523 2.44772 23 3 23H21C21.5523 23 22 22.5523 22 22C22 21.4477 21.5523 21 21 21H3Z"
                                fill="#9297A0"
                            />
                        </g>
                    </svg>
                </button>
            </div>
            <div v-if="requestInProgress" class="loading">
                <app-content-loader :icon-type="'spinner'"></app-content-loader>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.switchable-input {
    > .wrapper {
        .enabler {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;

                &:hover {
                    path {
                        fill: var(--text-color-default);
                    }
                }
            }
        }

        .loading {
            position: absolute;
            top: 15px;
            right: 15px;

            img {
                width: 20px;
                height: 20px;
            }
        }

        input {
            padding-right: 50px;
        }
    }
}

.autocomplete {
    position: absolute;
    height: auto;
    max-height: 262px;
    min-width: 220px;
    left: 0;
    top: 52px;
    width: 100%;
    background: var(--component-color-background-base);
    border: 1px solid rgb(146 151 160 / 0.48);
    border-radius: 0 0 3px 3px;
    box-shadow: 0 16px 32px rgb(146 151 160 / 0.32);
    z-index: 2;
    overflow-y: auto;

    > div {
        width: 100%;
        position: relative;
        min-height: 52px;
        padding: 6px var(--size-small) 6px var(--size-small);
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 500;
        font-size: var(--font-size-tiny);
        color: var(--text-subtlest);
        font-family: 'Gilroy', sans-serif;
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 1px;
            background: var(--black-500);
            opacity: 0.24;
        }

        &:first-of-type {
            &::before {
                display: none;
            }
        }

        &.selected,
        &:hover {
            color: var(--brand-red);
        }
    }
}

.feedback {
    position: absolute;
    top: 105%;
    width: 100%;
    color: var(--brand-red);
    font-size: var(--font-size-pico);
}
</style>
