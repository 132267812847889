import * as Sentry from '@sentry/vue';
import { App } from 'vue';

export default class SentryLibrary {
    public init(app: App, dsn: string, targets: (string | RegExp)[], cid: string): void {
        Sentry.setTag('x-correlation-id', cid);
        Sentry.init({
            app,
            dsn: dsn,
            integrations: [Sentry.browserTracingIntegration()],
            tracesSampleRate: 1.0,
            tracePropagationTargets: this.processedTargets(targets).concat(/^\//),
        });
    }

    private processedTargets(targets: (string | RegExp)[]): (string | RegExp)[] {
        return targets.map((target: string | RegExp): string | RegExp => {
            let result: string | RegExp = target;
            if (typeof target === 'string' && !target.match(/^(https?):\/\/.*/i)) {
                result = new RegExp('^https?://' + target, 'i');
            }
            return result;
        });
    }
}
