<script setup lang="ts">
import ClaimsMtplService from '@/Apps/ClaimsMtpl/Services/ClaimsMtplService';
import Form from '@/assets/libraries/form/form';
import { onMounted, reactive, UnwrapNestedRefs } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import { useTranslate } from '@/Composables/Translate';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import { useStrings } from '@/Composables/Strings';
import PersonDamageNames from '@/Apps/ClaimsMtpl/Enums/Damage/PersonDamageNames';
import DamageTypes from '@/Apps/ClaimsMtpl/Enums/DamageTypesEnum';
import { SubFlowEvent } from '@/Apps/ClaimsMtpl/Interfaces/SubFlowEvent';
import Translations from '@/services/translations.service';
import ClaimsMtplFormFields from '@/Apps/ClaimsMtpl/Classes/ClaimsMtplFormFields';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import Value from '@/assets/libraries/form/value';
import { useDefine } from '@/Composables/Define';

const props = defineProps({
    dataScroll: { type: String, default: () => '' },
});
const emit = defineEmits<{
    (event: 'completed', subFlowEvent: SubFlowEvent): void;
    (event: 'change'): void;
}>();

const { translateForType } = useTranslate();
const { isSet } = useDefine();

const btaBase: OneBase = OneBaseService.getInstance();
const claimsMtplService: ClaimsMtplService = ClaimsMtplService.getInstance();

const form: Form = new Form();

function proceedButton(): ButtonWithCallbackParams {
    return {
        title: localized('proceed'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: ButtonIcon.LongArrowRight,
        iconPosition: ButtonIconPosition.Right,
    };
}

function setupForm(): void {
    form.addField(new FormField(PersonDamageNames.Details));
    addFormValidators();
    form.setReady();
}

function restoreValues(): void {
    const storedValues: DynamicDictionary = claimsMtplService.fields;
    Object.keys(PersonDamageNames).forEach((field: string) => {
        const formKey: string = PersonDamageNames[field as keyof PersonDamageNames];
        const serviceKey: string = 'person' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        if (isSet(storedValues[serviceKey]) && new Value(storedValues[serviceKey]).isNotEmpty()) {
            form.field(formKey).setValue(storedValues[serviceKey]);
        }
    });
}

function addFormValidators(): void {
    form.field(PersonDamageNames.Details).addValidators({
        describeEventValid: (value: string) => !(stringOutOfBounds(value) || stringHasInvalidText(value)),
    });
}

function stringOutOfBounds(text: string): boolean {
    const multibyteLength: number = useStrings().multibyteLength(text);
    const minLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMinLength;
    const maxLength: number = btaBase.settings.claimsSettings().mtpl.descriptionFieldMaxLength;

    return multibyteLength < minLength || multibyteLength > maxLength;
}

function stringHasInvalidText(text: string): boolean {
    return !useStrings().isValidWordString(text);
}

function storeFormToService(): void {
    Object.keys(PersonDamageNames).forEach((field: string) => {
        const formKey: string = PersonDamageNames[field as keyof PersonDamageNames];
        const serviceKey: string = 'person' + formKey.charAt(0).toUpperCase() + formKey.slice(1);
        claimsMtplService.fields[serviceKey as keyof ClaimsMtplFormFields] = form.field(formKey).value;
    });
}

function localized(stringUid: string): string {
    return translateForType(stringUid, Translations.getInstance().type);
}

function completedEvent(): SubFlowEvent {
    return {
        [DamageTypes.Person]: {
            description: form.field(PersonDamageNames.Details).value,
        },
    };
}

function onChange(): void {
    emit('change');
    storeFormToService();
}

onMounted(() => {
    setupForm();
    restoreValues();
});
defineExpose({
    claimsMtplService,
});
</script>

<template>
    <div class="container">
        <app-custom-form v-if="form.isReady()" :form="form" :data-scroll="dataScroll" class="form" @change="onChange()">
            <div class="whiteboard-panel whiteboard-panel-margin">
                <label>{{ localized('injured_person_title') }}</label>
                <div class="whiteboard">
                    <h4 class="title">{{ localized('person_details_title') }}</h4>
                    <app-input-textarea
                        :form-field="form.field(PersonDamageNames.Details)"
                        :placeholder="localized('person_details_placeholder')"
                    >
                    </app-input-textarea>
                    <app-button-with-callback
                        class="button"
                        v-bind="proceedButton()"
                        :disabled="!form.field(PersonDamageNames.Details).isValid"
                        @button-callback-click="emit('completed', completedEvent())"
                    >
                    </app-button-with-callback>
                </div>
            </div>
        </app-custom-form>
    </div>
</template>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;

    .button {
        padding: 0 var(--size-medium);

        .icon-right {
            margin-left: var(--size-nano);
        }
    }

    .whiteboard.whiteboard-margin h4:first-of-type {
        margin-bottom: var(--size-big);
    }

    :deep(#details) {
        margin-bottom: 0;
    }

    .button-with-callback {
        height: 52px;
    }
}
</style>
