<script setup lang="ts">
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { useTranslate } from '@/Composables/Translate';
import { useHtml } from '@/Composables/Html';

const props = defineProps({
    title: { type: String, default: '' },
    description: { type: String, default: '' },
    myAccountLink: { type: String, default: '' },
});
const { translate, translateForType } = useTranslate();
const { imgTag } = useHtml();

function printUrl(): string {
    return props.myAccountLink;
}

function printPhoneIcon(): string {
    return imgTag(phoneIconAttributes());
}

function phoneIconAttributes(): ImageTagAttributes {
    return {
        class: '',
        src: 'images/one/phone-black.svg',
        width: 40,
        height: 24,
    };
}
</script>
<template>
    <section class="modal-overlay-message">
        <div class="stolen-car flex centered no-margin">
            <div class="circle red-icon">
                <div v-html="printPhoneIcon()"></div>
            </div>
            <h3 class="title space-top-bottom" v-html="title"></h3>
            <section class="call-us default-margin-top" v-html="description"></section>
            <a class="button red with-white-icon" :href="translate('tel:btar_one_header_contacts_phone_num')">
                <div class="icon icon-inside-button" v-html="printPhoneIcon()"></div>
                {{ translateForType('claims_casco_call_now', 'claims') }}
            </a>
            <a class="button outside huge-margin-top" :href="printUrl()"
                >{{ translateForType('claims_thank_you_my_bta', 'claims') }}
            </a>
        </div>
    </section>
</template>
<style lang="scss" scoped>
.modal-overlay-message {
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: var(--size-huge);
    min-height: 550px;

    .stolen-car {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        width: 100%;
        text-align: center;
        margin: auto;

        .call-us {
            position: relative;
            padding: 0 0 30px;
            border-radius: 16px;
            max-width: 260px;
            width: 100%;
            text-align: center;
            margin: auto;
        }

        .circle {
            vertical-align: middle;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            border: solid 2px var(--brand-red);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }

        .red-icon {
            filter: var(--to-color-red);
        }

        .button {
            max-width: 260px;
            width: auto;
            font-size: var(--font-size-tiny);
            cursor: pointer;
            text-align: center;
            padding: 25px;
            margin: auto auto 42px;

            &.with-white-icon {
                .icon {
                    filter: var(--to-color-white);
                }
            }

            .icon-inside-button {
                margin-right: 15px;
            }

            &:hover,
            &:focus,
            &:active {
                .icon {
                    filter: var(--to-color-white);
                }
            }
        }

        .outside {
            color: var(--text-color-default);
            background-color: var(--component-color-background-disabled);
        }
    }
}

.space-top-bottom {
    padding: 30px 0 var(--size-small);
}

.huge-margin-top {
    margin-top: 200px !important;
}
</style>
