import Validation from '@/services/validation.service';
import moment, { Moment } from 'moment';
import ExtractDataService from '@/services/extract.data.service';
import ModalTab from '@/pages/LegalPerson/Workbench/Common/Tabs/ModalTab';
import AddPersonTab from '@/pages/LegalPerson/Workbench/Common/Tabs/AddPersonTab';
import AppCountry from '@/assets/libraries/app/app-country';

export default class AddPersonValidators {
    private context!: ModalTab;

    public addContext(context: ModalTab): void {
        this.context = context;
    }

    public identityNumberValidator(fieldName: string): object {
        return {
            validIdentityNumber: (): boolean => {
                return this.modalTab().form.field(fieldName).isTouched ? this.isValidIdentityNumber(fieldName) : true;
            },
        };
    }

    public policyStartDateValidator(fieldName: string): object {
        return {
            validPolicyStartDate: (): boolean => {
                return this.modalTab().form.field(fieldName).isTouched ? this.isValidPolicyStartDate(fieldName) : true;
            },
        };
    }

    public birthDateValidator(fieldName: string): object {
        return {
            validBirthDate: (): boolean => {
                return this.modalTab().form.field(fieldName).isTouched ? this.isValidBirthDate(fieldName) : true;
            },
        };
    }

    public countryValidator(fieldName: string): object {
        return {
            validCountryIc: (): boolean => {
                return this.modalTab().form.field(fieldName).isTouched ? this.hasValidCountryIc(fieldName) : true;
            },
        };
    }

    public emailValidator(fieldName: string): object {
        return {
            validEmail: (): boolean => {
                return this.modalTab().form.field(fieldName).isTouched ? this.hasValidEmail(fieldName) : true;
            },
        };
    }

    private isValidIdentityNumber(fieldName: string): boolean {
        const fieldIndex: string = this.fieldIndex(fieldName);
        const personIsResident: boolean = !this.modalTab().form.field('nonResident_' + fieldIndex).value;
        const fieldIsEmpty: boolean = this.modalTab().form.field(fieldName).isEmpty();
        const validationResult: boolean = personIsResident
            ? Validation.isValidNaturalPersonCode(this.modalTab().form.field(fieldName).value)
            : true;

        return validationResult && !fieldIsEmpty;
    }

    private isValidBirthDate(fieldName: string): boolean {
        const fieldIndex: string = this.fieldIndex(fieldName);
        const personIsResident: boolean = !this.modalTab().form.field('nonResident_' + fieldIndex).value;
        const identityNumber: string = this.modalTab().form.field('personCode_' + fieldIndex).value;
        const applyValidation: boolean = !Validation.isValidPersonCodeWithoutDate(identityNumber);
        const dateFromIdentityNumber: Date | undefined = new ExtractDataService().birthDateFromPersonCode(
            identityNumber,
        );
        const dateFromForm: Date = this.modalTab().form.field(fieldName).value;

        return personIsResident && applyValidation
            ? moment(dateFromIdentityNumber).isSame(moment(dateFromForm), 'day')
            : true;
    }

    private hasValidCountryIc(fieldName: string): boolean {
        const fieldIndex: string = this.fieldIndex(fieldName);
        const personIsResident: boolean = !this.modalTab().form.field('nonResident_' + fieldIndex).value;
        const countryIc: string | undefined = this.modalTab().form.field('countryIc_' + fieldIndex).value.ic;
        const hasValidCountryIc: boolean = typeof countryIc !== 'undefined' && countryIc !== '';

        return personIsResident ? true : hasValidCountryIc;
    }

    private hasValidEmail(fieldName: string): boolean {
        const fieldIndex: string = this.fieldIndex(fieldName);
        const personIsResident: boolean = !this.modalTab().form.field('nonResident_' + fieldIndex).value;
        const email: string = this.modalTab().form.field('email_' + fieldIndex).value;

        return personIsResident ? true : email !== '';
    }

    private isValidPolicyStartDate(fieldName: string): boolean {
        const dateFromForm: Date = this.modalTab().form.field(fieldName).value.startDate;
        const startDate: Moment = new AppCountry().isLT() ? moment() : this.modalTab().agreementStartDate();

        return moment(dateFromForm).isBetween(startDate, this.modalTab().agreementEndDate(), 'day', '[]');
    }

    private fieldIndex(fieldName: string): string {
        return fieldName.substring(fieldName.indexOf('_') + 1);
    }

    private modalTab(): AddPersonTab {
        return this.context as AddPersonTab;
    }
}
