<script setup lang="ts">
import { computed, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import DrawerPolicyDetailsContentTab from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentTabInterface';
import { useDefine } from '@/Composables/Define';

const { isSet } = useDefine();

const props = defineProps({
    translationsType: { type: String, default: '' },
    content: {
        type: Object,
        default: () => {
            return {};
        },
    },
});

function onTabClick(index: number): void {
    (props as DynamicDictionary).content.tabs.active = index;
}

function tabBadgeValue(badgeValue: number): string {
    return badgeValue > 0 ? String(badgeValue) : '';
}

function isActiveTab(tabIndex: number): boolean {
    return tabIndex === (props.content ? props.content.tabs.active : 0);
}

const titleIconSource: Ref<string> = computed(() => {
    const path: string = '/images/one/policy-icons/';

    return props.content ? path + props.content.type + '.svg' : '';
});

const titleText: Ref<string> = computed(() => {
    return props.content ? props.content.title : '';
});

const descriptionTitle: Ref<string> = computed(() => {
    return props.content ? props.content.description : '';
});

const tabs: Ref<DynamicDictionary[]> = computed(() => {
    let result: DynamicDictionary[] = [];
    if (props.content) {
        result = props.content.tabs.elements;
    }

    return result;
});

const componentName: Ref<string> = computed(() => {
    let result: string = '';
    if (
        isSet(props.content) &&
        isSet(props.content.tabs) &&
        isSet(props.content.tabs.elements) &&
        props.content.tabs.elements.length > 0
    ) {
        result = (props.content.tabs.elements[props.content.tabs.active] as DrawerPolicyDetailsContentTab).module;
    }

    return result;
});

const componentContent: Ref<DynamicDictionary> = computed(() => {
    let result: DynamicDictionary = {};
    if (
        isSet(props.content) &&
        isSet(props.content.tabs) &&
        isSet(props.content.tabs.elements) &&
        props.content.tabs.elements.length > 0
    ) {
        result = (props.content.tabs.elements[props.content.tabs.active] as DrawerPolicyDetailsContentTab).content;
    }

    return result;
});
</script>

<template>
    <div class="policy-details">
        <div class="policy-header">
            <div class="title-container">
                <div class="title-icon">
                    <img v-if="titleIconSource !== ''" :src="titleIconSource" :alt="titleIconSource" />
                </div>
                <div class="title-text">{{ titleText }}</div>
            </div>
            <div class="description-container">
                <div v-mask-analytics class="description">{{ descriptionTitle }}</div>
            </div>
            <div class="details-tabs">
                <button
                    v-for="(tab, index) in tabs"
                    :key="tab.id"
                    :class="{ active: isActiveTab(index) }"
                    class="details-tab"
                    :data-id="tab.id"
                    @click="onTabClick(index)"
                >
                    {{ tab.title }}
                    <span v-if="tabBadgeValue(tab.badge) !== ''" class="badge">{{ tabBadgeValue(tab.badge) }}</span>
                </button>
            </div>
        </div>
        <div id="scrollable" class="policy-content content-scrollable">
            <component
                :is="componentName"
                v-if="componentName !== ''"
                class="policy-component"
                :content="componentContent"
                :translations-type="translationsType"
            ></component>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.policy-details {
    .policy-header {
        min-height: auto;
        padding: var(--size-big) var(--size-big) 0;
        box-shadow: var(--box-shadow-zenith);
        background-color: var(--white);
        z-index: 1;

        .title-container {
            display: flex;
            align-items: center;

            .title-icon {
                display: flex;
                width: 52px;
                height: 52px;
                background-color: var(--blue-100);
                border-radius: 26px;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 25px;
                    max-height: 25px;
                }
            }

            .title-text {
                margin-left: var(--size-small);
                font-weight: 700;
            }
        }

        .description-container {
            margin-top: var(--size-normal);

            .description {
                font-size: var(--font-size-medium);
                font-weight: 700;
                line-height: var(--line-height-basic);
            }

            .sub-description {
                margin-top: 8px;
                font-size: var(--font-size-nano);
                color: var(--black-500);
            }
        }

        .details-tabs {
            display: flex;
            align-items: flex-end;
            min-height: 72px;

            .details-tab {
                display: flex;
                position: relative;
                font-size: var(--font-size-nano);
                margin-right: 30px;
                min-height: 46px;
                align-items: center;

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    color: var(--brand-red);

                    &::after {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        height: 2px;
                        background-color: var(--brand-red);
                    }
                }

                .badge {
                    color: var(--brand-red);
                    margin-left: var(--size-pico);
                }
            }
        }
    }

    .policy-content {
        padding: var(--size-normal);
    }
}
</style>
