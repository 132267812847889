<script lang="ts">
import { defineComponent, ref, Ref, nextTick } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import { Subscription } from 'rxjs';
import ErrorType from '@/Enums/ErrorTypeEnum';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import FormField from '@/assets/libraries/form/form-field';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';
import InsuredPerson from '@/interfaces/insured.person.interface';
import AppCountry from '@/assets/libraries/app/app-country';
import CascoClaimsPanelsNames from '@/Enums/CascoClaimsPanelsNamesEnum';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const stepsSubmitter = useStepsSubmitter();
        const { isSet } = useDefine();

        const CurrentStep: number = 7;
        const NextStep: number = CurrentStep + 1;
        const Facility: string = 'one-claims-casco';
        const ThankYouStepFacility: string = 'claims-thank-you';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);

        function setupForm(): void {
            Object.keys(btaBase.cmsFields).forEach((field: string) => {
                form.addField(new FormField(btaBase.cmsFields[field].name));
            });
            form.setReady();
            formIsReady.value = true;
        }

        function resetForm(): void {
            form.fields().forEach((field: FormField) => {
                field.clearValidators();
                field.markAsUntouched();
            });
        }

        function onSubmitStep(): void {
            resetForm();
            touchAndRequireNecessaryFields();
            form.validate().then(() => {
                if (form.isValid()) {
                    btaBase.captcha
                        .executeCaptcha(submit)
                        .then()
                        .catch((reason: string) => {
                            btaBase.error.show(ErrorType.Error, 'onSubmitStep', reason);
                        });
                }
            });
        }

        function submit(token: string): void {
            prepareSubmit(token);
            const thankYouStepStorageNumber: number = 8;
            stepsSubmitter.saveParamsToStorageWithStep(thankYouStepStorageNumber, ThankYouStepFacility).then(() => {
                stepsSubmitter.submitMethod(Method.Post);
                stepsSubmitter.submitStep(Url.Ajax.claimsCascoSubmitClaim);
            });
        }

        function buildInitialOptions(params: DynamicDictionary): void {
            if (isSet(params.options.enabled)) {
                nextTick(() => {
                    calculateStepperVisibility();
                });
            }
        }

        function calculateStepperVisibility(): void {
            const accidentDataStepNumber: number = 2;
            const vehicleStepNumber: number = 3;
            const extraDetailsStepNumber: number = 4;
            const uploadFilesStepNumber: number = 5;
            const yourContactDetailsStepNumber: number = 7;
            const steps: number[] = [
                1,
                accidentDataStepNumber,
                vehicleStepNumber,
                extraDetailsStepNumber,
                isSelectedRepairService() ? uploadFilesStepNumber : null,
                yourContactDetailsStepNumber,
            ].filter((value: number | null): value is number => value !== null);
            btaBase.dynamicStepper.applyEnabled(steps);
        }

        function touchAndRequireNecessaryFields(): void {
            form.fields().forEach((field: FormField) => {
                field.addValidators('required');
                field.touch();
            });
        }

        function onAppReady(): void {
            btaBase.dynamicStepper.applyEnabled(btaBase.userStorage.stepStorageData.dynamicStepper);
            patchApplicantFields();
            touchAndRequireNecessaryFields();
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitCustomParam('nextStep', NextStep);
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitParam('claimType', 'transport', false);
            stepsSubmitter.addSubmitParam('uploader', 'claims-casco', false);
            stepsSubmitter.addSubmitParams(btaBase.userStorage.stepStorageData, false);
            if (needToIncludeApplicant()) {
                stepsSubmitter.addSubmitParam('applicant', insuredPerson(), false);
            }
        }

        function insuredPerson(): InsuredPerson {
            return {
                identityNumber: btaBase.user.current.personCode,
                firstName: btaBase.user.current.firstname,
                lastName: btaBase.user.current.lastname,
                companyName: '',
                companyType: '',
                registrationNumber: '',
                isLegal: false,
                isResident: true,
                residenceCountryIso: new AppCountry().iso(),
                birthDate: btaBase.user.current.birthDate,
                email: form.field('applicantEmail').value,
                phoneCode: form.field('applicantPhone').value.country,
                phone: form.field('applicantPhone').value.phone,
                bankAccount: btaBase.user.current.bank,
            };
        }

        function needToIncludeApplicant(): boolean {
            return !isSet(btaBase.userStorage.stepStorageData.applicant);
        }

        function patchApplicantFields(): void {
            if (form.field('applicantEmail').isEmpty()) {
                form.field('applicantEmail').patch(btaBase.user.current.email);
            }
            if (form.field('applicantPhone').isEmpty()) {
                form.field('applicantPhone').patch({
                    code: btaBase.user.current.code,
                    iso: btaBase.user.current.code,
                    country: btaBase.user.current.phoneCode,
                    phone: btaBase.user.current.phone,
                });
            }
        }

        function isSelectedRepairService(): boolean {
            return !!btaBase.userStorage.stepStorageData[CascoClaimsPanelsNames.PleaseChooseRepairService];
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                buildInitialOptions,
                calculateStepperVisibility,
                setupForm,
                resetForm,
                onSubmitStep,
                onAppReady,
                needToIncludeApplicant,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        this.setupForm();
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe(() => {
            this.onAppReady();
            this.calculateStepperVisibility();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
