<script setup lang="ts">
import PayByLinkService from '@/Apps/PayByLink/Pay/Services/PayByLinkService';
import OneDate from '@/assets/libraries/Date/OneDate';
import AgreementPeriodMap from '@/Apps/PayByLink/Classes/AgreementPeriodMap';
import OfferRisk from '@/Apps/PayByLink/Pay/Interfaces/OfferRiskInterface';
import { useTranslate } from '@/Composables/Translate';
import { useHtml } from '@/Composables/Html';
import ImageTagAttributes from '@/interfaces/image.tag.attributes';
import { computed, getCurrentInstance, onMounted, ref, Ref } from 'vue';
import { AxiosResponse } from 'axios';
import { useDefine } from '@/Composables/Define';
import Url from '@/Enums/UrlEnum';
import TransferStateService from '@/Core/ServerState/TransferStateService';
import RequestService from '@/services/request.service';
import { useScroll } from '@/Composables/Scroll';
import { useRouter, Router } from 'vue-router';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import EventBus from '@/services/event.bus.service';
import AppTextWithTip from '@/Components/Tooltips/TextWithTip/TextWithTip.vue';
import OneBaseService from '@/services/OneBaseService';
import { usePrice } from '@/Composables/Price';
import SettingsService from '@/services/settings.service';
import { usePayByLink } from '@/Apps/PayByLink/Composables/PayByLink';

const { translateForType } = useTranslate();
const { sparse } = usePrice();
const { acceptCalculationResponse } = usePayByLink();

const router: Router = useRouter();
const payByLinkService: PayByLinkService = PayByLinkService.getInstance();
const TranslationType: string = 'mtpl_renewal';
const canEditOffer: Ref<boolean> = computed((): boolean => {
    return !payByLinkService.hasCalculationError.value;
});
const editRenewalOfferMandatory: Ref<boolean> = computed((): boolean => {
    return SettingsService.getInstance().editRenewalOfferMandatory();
});
const viewIsReady: Ref<boolean> = ref(false);

onMounted(() => {
    OneBaseService.getInstance().applySpa(getCurrentInstance());
    EventBus.getInstance().subscribe('offer-failed', showFetchOfferError);
    useScroll().scrollToTop();
    fetchOffer()
        .then((): void => {})
        .catch((): void => {
            payByLinkService.hasCalculationError.value = true;
            viewIsReady.value = true;
        });
});

async function fetchOffer(): Promise<void> {
    return RequestService.getInstance()
        .get({
            uri: Url.Ajax.mtplRenewalFetchOffer,
            content: {
                hash: payByLinkService.payByLink.value.id,
            },
        })
        .then((response: AxiosResponse): void => {
            if (useDefine().isSet(response)) {
                if (acceptCalculationResponse(response)) {
                    TransferStateService.getInstance().set('mtplOffer', response.data.data.body.offer);
                    payByLinkService.init();
                    viewIsReady.value = true;
                } else {
                    throw response;
                }
            }
        });
}

function offerValidity(): string {
    return OneDate.short(payByLinkService.payByLink.value.offerValidity.date);
}

function offerPeriodLabel(): string {
    const offerPeriod: string[] = payByLinkService.payByLink.value.insurancePeriod.split(' - ');

    return [monthsLabel(), [OneDate.short(offerPeriod[0]), OneDate.short(offerPeriod[1])].join(' - ')].join(' / ');
}

function monthsLabel(): string {
    const map: AgreementPeriodMap = new AgreementPeriodMap();
    const periodIc: string = payByLinkService.payByLink.value.periodIc;
    const months: number = map.monthsByIc(periodIc);
    const label: string = map.monthsLabelByIc(periodIc);

    return `${String(months)} ${label}`;
}

function showFetchOfferError(): void {
    PopupService.getInstance().show(
        new OnePopup()
            .withType()
            .simpleError.withDescription(useTranslate().translateForType('fetch_offer_error', TranslationType)),
    );
}

function showRisksSection(): boolean {
    return includedRisks().length > 0 || (allowedRisks().length > 0 && !payByLinkService.hasCalculationError.value);
}

function showAllowedRiskSection(): boolean {
    return allowedRisks().length > 0 && viewIsReady.value && canEditOffer.value;
}

function showLineBreak(): boolean {
    return includedRisks().length > 0 && allowedRisks().length > 0 && viewIsReady.value && canEditOffer.value;
}

function includedRisks(): OfferRisk[] {
    return payByLinkService.payByLink.value.offerRisks.filter((risk: OfferRisk): boolean => risk.included);
}

function allowedRisks(): OfferRisk[] {
    return payByLinkService.payByLink.value.offerRisks.filter(
        (risk: OfferRisk): boolean => !risk.included && risk.canInclude,
    );
}

function riskIcon(riskIc: string, status: string): string {
    const iconName: string = [riskIc, status].join('-');

    return useHtml().imgTag(iconAttributes(iconName));
}

function iconAttributes(iconName: string): ImageTagAttributes {
    return {
        class: '',
        src: `images/one/mtpl-renewal/policy-options-round/${iconName}.svg`,
        width: 52,
        height: 52,
    };
}

function onMakePaymentClick(): void {
    payByLinkService.fields.offerPeriod = '';
    router.push({ name: 'pay-by-link-pay-summary' });
}

function onEditOfferClick(): void {
    if (payByLinkService.hasCalculationError.value) {
        showFetchOfferError();
    } else {
        router.push({ name: 'pay-by-link-pay-edit' });
    }
}

function formattedPrice(): string {
    return sparse(payByLinkService.payByLink.value.paymentAmount, false);
}
</script>

<template>
    <div class="step-container">
        <img src="images/one/mtpl-renewal/auto-renewal-desktop.png" class="item-desktop hero-banner" alt="" />
        <img src="images/one/mtpl-renewal/auto-renewal-mobile.png" class="item-mobile hero-banner-mobile" alt="" />
        <div class="agreement-details">
            <div class="header">{{ translateForType('details_view_title', TranslationType) }}</div>
            <section id="whiteboard-0" class="whiteboard">
                <div class="whiteboard-title">{{ translateForType('mtpl_price', TranslationType) }}</div>
                <div class="summary">
                    <app-text-with-tip
                        :title="translateForType('total_price', TranslationType)"
                        :tip-description="translateForType('total_price_description', TranslationType)"
                    >
                    </app-text-with-tip>
                    <span class="total"
                        >{{ formattedPrice() }}
                        <span class="currency">&euro;</span>
                    </span>
                </div>
                <hr class="line-break" />
                <div class="summary">
                    <div class="period">{{ translateForType('valid_till', TranslationType) }}</div>
                    <div>{{ offerValidity() }}</div>
                </div>
                <app-content-loader v-if="!viewIsReady"></app-content-loader>
                <div v-if="viewIsReady" class="buttons">
                    <a v-if="canEditOffer && editRenewalOfferMandatory" class="button red" @click="onEditOfferClick()">
                        <span>{{ translateForType('edit_and_pay', TranslationType) }}</span>
                        <img src="images/one/arrow-right.svg" alt="arrow-right" />
                    </a>
                    <a
                        v-if="!canEditOffer || !editRenewalOfferMandatory"
                        class="button outside"
                        :class="{ disabled: !canEditOffer }"
                        @click="onEditOfferClick()"
                    >
                        <img src="images/one/edit-item.svg" alt="edit-item" />
                        <span>{{ translateForType('edit_policy', TranslationType) }}</span>
                    </a>
                    <a
                        v-if="!canEditOffer || !editRenewalOfferMandatory"
                        class="button red"
                        @click="onMakePaymentClick()"
                    >
                        <span>{{ translateForType('pay', TranslationType) }}</span>
                        <img src="images/one/arrow-right.svg" alt="arrow-right" />
                    </a>
                </div>
            </section>
            <section id="whiteboard-1" class="whiteboard">
                <div class="whiteboard-title">{{ translateForType('mtpl_details', TranslationType) }}</div>
                <ul class="list list-border">
                    <li id="whiteboard-item-1-0" class="item">
                        <span>{{ translateForType('insured', TranslationType) }}</span>
                        <span class="item-value">{{ payByLinkService.payByLink.value.objectName }}</span>
                    </li>
                    <li id="whiteboard-item-1-1" class="item">
                        <span>{{ translateForType('mtpl_period', TranslationType) }}</span>
                        <span class="item-value">{{ offerPeriodLabel() }}</span>
                    </li>
                    <li id="whiteboard-item-1-2" class="item">
                        <span>{{ translateForType('policy_holder', TranslationType) }}</span>
                        <span class="item-value">{{ payByLinkService.payByLink.value.holderName }}</span>
                    </li>
                </ul>
            </section>
            <section v-if="showRisksSection()" id="whiteboard-2" class="whiteboard">
                <div class="whiteboard-title">{{ translateForType('options', TranslationType) }}</div>
                <div v-if="includedRisks().length > 0" id="included-risks" class="options">
                    <div class="title">{{ translateForType('included_risks', TranslationType) }}</div>
                    <div
                        v-for="(item, index) in includedRisks()"
                        :id="'whiteboard-included-item-2-' + index"
                        :key="index"
                        class="item"
                    >
                        <div class="icon" v-html="riskIcon(item.ic, 'included')"></div>
                        {{ translateForType(item.ic, TranslationType) }}
                    </div>
                    <hr v-if="showLineBreak()" class="line-break" />
                </div>
                <app-content-loader v-if="!viewIsReady"></app-content-loader>
                <div v-if="showAllowedRiskSection()" id="allowed-risks" class="options">
                    <div class="title">{{ translateForType('can_add_at_next_step', TranslationType) }}</div>
                    <div
                        v-for="(item, index) in allowedRisks()"
                        :id="'whiteboard-allowed-item-2-' + index"
                        :key="index"
                        class="item"
                    >
                        <div class="icon" v-html="riskIcon(item.ic, 'grey')"></div>
                        {{ translateForType(item.ic, TranslationType) }}
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.step-container {
    margin-bottom: var(--size-big);

    .hero-banner {
        margin-top: -30px;
        margin-bottom: var(--size-small);
        height: 420px;
        width: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 56px;
    }

    .hero-banner-mobile {
        width: 100%;
    }

    .agreement-details {
        margin-top: var(--size-small);
        padding: var(--size-small);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--size-small);

        @include respond-above('sm') {
            margin-top: 220px;
            gap: var(--size-big);
            align-items: center;
        }

        .header {
            width: 100%;
            position: relative;
            color: var(--text-color-default);
            text-align: center;
            font-size: var(--font-size-big);
            font-weight: 700;
            line-height: 120%;

            @include respond-above('sm') {
                display: flex;
                height: 115px;
                align-items: flex-end;
                color: var(--text-color-inverse);
                letter-spacing: -0.48px;
                font-size: var(--font-size-xl);
                max-width: 800px;
            }
        }

        .whiteboard {
            display: flex;
            padding: var(--size-small);
            flex-direction: column;
            align-items: flex-start;
            border-radius: var(--size-tiny);
            background: var(--component-color-background-base);
            gap: var(--size-small);
            width: 100%;
            max-width: 800px;

            @include respond-above('sm') {
                padding: var(--size-big);
            }

            .whiteboard-title {
                color: var(--text-color-default);
                font-size: var(--font-size-medium);
                font-weight: 700;
            }

            .title {
                font-size: var(--font-size-small);
                font-weight: 700;
            }

            .summary {
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;

                .total {
                    white-space: nowrap;
                    color: var(--system-color-success-dark);
                    font-size: var(--font-size-big);
                    font-weight: 700;
                    letter-spacing: -0.32px;
                }

                .currency {
                    font-size: var(--font-size-tiny);
                    font-weight: 600;
                }

                .period {
                    color: var(--text-color-subtle);
                    font-size: var(--font-size-nano);
                    font-weight: 500;
                }
            }

            .line-break {
                width: 100%;
                height: 1px;
                background-color: var(--component-color-border-default);
            }

            .buttons {
                display: flex;
                align-items: flex-start;
                gap: var(--size-nano);
                align-self: stretch;
                flex-direction: column-reverse;

                @include respond-above('sm') {
                    flex-direction: row;
                    gap: var(--size-small);
                }

                .button {
                    display: flex;
                    height: 52px;
                    min-height: 52px;
                    padding: 0 var(--button-horizontal-padding);
                    justify-content: center;
                    align-items: center;
                    gap: var(--size-nano, 12px);
                    flex: 1 0 0;
                }

                .border {
                    border-radius: var(--button-corner-radius);
                    border: 1px solid var(--button-color-border-secondary-default);
                }
            }

            .list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;

                .item {
                    display: flex;
                    padding: var(--size-small);
                    flex-direction: column;
                    align-items: flex-start;
                    gap: var(--size-pico);
                    align-self: stretch;
                    font-size: var(--font-size-nano);

                    @include respond-above('sm') {
                        flex-direction: row;
                        justify-content: space-between;
                        padding: var(--size-small);
                    }

                    .item-value {
                        font-weight: 600;

                        @include respond-above('sm') {
                            width: 50%;
                            text-align: right;
                        }
                    }
                }
            }

            .list-border {
                border-radius: var(--size-pico);
                border: 1px solid var(--black-100);

                .item {
                    &:not(:last-child) {
                        border-bottom: 1px solid var(--black-100);
                    }
                }
            }

            .options {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                align-self: stretch;
                gap: 12px;

                .item {
                    display: flex;
                    align-items: center;
                    gap: var(--size-tiny);
                    align-self: stretch;
                    font-size: var(--size-tiny);
                    font-weight: 600;
                }
            }
        }
    }

    @include respond-above('sm') {
        margin-bottom: var(--size-huge);
    }

    a {
        cursor: pointer;

        &.disabled {
            img {
                filter: var(--to-color-grey);
            }
        }
    }
}
</style>
