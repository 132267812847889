import AppHumanBodyParts from '@/Components/Widgets/HumanBodyParts/HumanBodyParts.vue';
import InfoWithActions from '@/Components/Widgets/InfoWithActions/InfoWithActions.vue';
import LanguageSwitcher from '@/Components/Widgets/LanguageSwitcher/LanguageSwitcher.vue';
import InsurancePlanWidget from '@/Components/Widgets/InsurancePlanWidget/InsurancePlanWidget.vue';
import NextBestAction from '@/Components/Widgets/NextBestAction/NextBestAction.vue';
import PaymentMethods from '@/Components/Widgets/PaymentMethods/PaymentMethods.vue';
import PaymentWidget from '@/Components/Widgets/PaymentWidget/PaymentWidget.vue';
import SelectAccountDropdown from '@/Components/Widgets/SelectAccountDropdown/SelectAccountDropdown.vue';
import Notification from '@/Components/Widgets/Notification/Notification.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppHumanBodyParts: AppHumanBodyParts,
    AppInfoWithActions: InfoWithActions,
    AppInsurancePlanWidget: InsurancePlanWidget,
    AppLanguageSwitcher: LanguageSwitcher,
    AppNextBestAction: NextBestAction,
    AppPaymentMethods: PaymentMethods,
    AppPaymentWidget: PaymentWidget,
    AppSelectAccountDropdown: SelectAccountDropdown,
    AppNotification: Notification,
} as Components;
