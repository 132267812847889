import { nextTick } from 'vue';

export default class LoadingWaitService {
    private loaderStep: number = 0;
    private timer: NodeJS.Timeout | number = 0;
    private delay: number = 3500;
    private static fadeSpeed: number = 600;
    private static instance: LoadingWaitService;

    public static getInstance(): LoadingWaitService {
        if (!LoadingWaitService.instance) {
            LoadingWaitService.instance = new LoadingWaitService();
        }
        return LoadingWaitService.instance;
    }

    public init(): void {
        this.loaderStep = 0;
        nextTick((): void => {
            $('.loader-step-0')
                .css('opacity', 0)
                .show()
                .queue(function () {
                    $(this).dequeue().animate({ opacity: 1 }, LoadingWaitService.fadeSpeed);
                });
            this.start();
        });
    }

    public start(): void {
        this.timer = setInterval((): void => {
            this.loaderStep++;
            const lastStep: number = 3;
            if (this.loaderStep > lastStep) {
                this.loaderStep = 0;
            }
            $.when(
                $('.loader-step')
                    .not('.loader-step-skip-' + this.loaderStep)
                    .animate({ opacity: 0 }, LoadingWaitService.fadeSpeed),
            ).done((): void => {
                $('.loader-step')
                    .not('.loader-step-skip-' + this.loaderStep)
                    .hide();
                $('.loader-step-' + this.loaderStep)
                    .css('opacity', 0)
                    .show()
                    .animate({ opacity: 1 }, LoadingWaitService.fadeSpeed);
            });
        }, this.delay);
    }

    public stop(): void {
        clearInterval(this.timer);
        $('.loader-step-0').css('opacity', 0).hide();
    }
}
