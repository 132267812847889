import moment from 'moment';
import NumericArrayBuilder from '@/Builders/NumericArrayBuilder';

export default class PersonCodeValidator {
    private readonly Ee: string = 'EE';
    private readonly Lt: string = 'LT';
    private readonly Lv: string = 'LV';
    private readonly codeModularDivisor: number = 11;
    private readonly codeBaseLength: number = 10;

    /**
     * Unified identity number validation
     *
     * In case of Latvian identity number both types - date based and the new
     * format - are checked.
     */
    public isIdentityNumberValid(value: string, localeIso: string): boolean {
        return this.isLv(localeIso) && this.hasValidStartingDigits(value)
            ? this.isValidPersonCodeWithoutDateLV(value)
            : this.isValidPersonCode(value, localeIso);
    }

    public isValidPersonCode(value: string, localeIso: string): boolean {
        let isValid: boolean = value.length > 2;
        const personCodePatternEE: RegExp = /^(3|4|5|6)([0-9]{2})(0|1)([0-9]{1})(0|1|2|3)[0-9]{5}$/;
        const personCodePatternLT: RegExp = /^(3|4|5|6)([0-9]{2})(0|1)([0-9]{1})(0|1|2|3)[0-9]{5}$/;
        const personCodePatternLV: RegExp =
            /^(3[2-9][0-9]{9})|(3[2-9][0-9]{4}-[0-9]{5})|(0|1|2|3)[0-9]{1}(0|1)[0-9]{3}(-|)(1|2)[0-9]{4}$/;
        switch (localeIso) {
            case this.Ee:
                isValid = personCodePatternEE.test(value) && this.isValidPersonCodeEE(value);
                break;
            case this.Lt:
                isValid = personCodePatternLT.test(value) && this.isValidPersonCodeLT(value);
                break;
            case this.Lv:
                isValid = personCodePatternLV.test(value) && this.isValidPersonCodeLV(value);
                break;
            default:
        }

        return isValid;
    }

    public isValidPersonCodeWithoutDate(value: string, localeIso: string): boolean {
        return this.isLv(localeIso) ? this.isValidPersonCodeWithoutDateLV(value) : false;
    }

    private isEe(localeIso: string): boolean {
        return localeIso === this.Ee;
    }

    private isLt(localeIso: string): boolean {
        return localeIso === this.Lt;
    }

    private isLv(localeIso: string): boolean {
        return localeIso === this.Lv;
    }

    private isValidPersonCodeEE(value: string): boolean {
        const result: string = String(value);
        let isValid: boolean = false;
        if (result.length === this.codeModularDivisor) {
            const multiplier1: number[] = new NumericArrayBuilder()
                .withFrom(NumericArrayBuilder.One)
                .withTo(NumericArrayBuilder.Nine)
                .withConcat(NumericArrayBuilder.One)
                .build();
            const multiplier2: number[] = new NumericArrayBuilder()
                .withFrom(NumericArrayBuilder.Three)
                .withTo(NumericArrayBuilder.Nine)
                .withConcat(
                    new NumericArrayBuilder()
                        .withFrom(NumericArrayBuilder.One)
                        .withTo(NumericArrayBuilder.Three)
                        .build(),
                )
                .build();
            let mod: number;
            let total: number = 0;
            for (let i: number = 0; i < this.codeBaseLength; i++) {
                const currentNumber: number = parseInt(result.charAt(i), 10);
                total += currentNumber * multiplier1[i];
            }
            mod = total % this.codeModularDivisor;
            total = 0;
            if (this.codeBaseLength === mod) {
                for (let i: number = 0; i < this.codeBaseLength; i++) {
                    const currentNumber: number = parseInt(result.charAt(i), 10);
                    total += currentNumber * multiplier2[i];
                }
                mod = total % this.codeModularDivisor;
                if (this.codeBaseLength === mod) {
                    mod = 0;
                }
            }
            isValid = mod === Number(result.charAt(this.codeBaseLength));
        }

        return isValid;
    }

    private isValidPersonCodeLT(value: string): boolean {
        let isValid: boolean = false;
        if (value.length === this.codeModularDivisor) {
            let b: number = 1;
            let c: number = 3;
            let d: number = 0;
            let e: number = 0;
            let i: number;
            let digit: number;
            for (i = 0; i < this.codeBaseLength; i++) {
                digit = parseInt(value[i], 10);
                d += digit * b;
                e += digit * c;
                b++;
                if (b === this.codeBaseLength) {
                    b = 1;
                }
                c++;
                if (c === this.codeBaseLength) {
                    c = 1;
                }
            }
            d = d % this.codeModularDivisor;
            e = e % this.codeModularDivisor;
            const checksum = d < this.codeBaseLength ? d : e < this.codeBaseLength ? e : 0;
            isValid = checksum === Number(value.slice(-1));
        }

        return isValid;
    }

    private isValidPersonCodeLV(value: string): boolean {
        const formattedValue: string = String(value).replace('-', '');
        let isValid: boolean = false;
        if (formattedValue.length === this.codeModularDivisor) {
            if (this.hasValidStartingDigits(formattedValue)) {
                isValid = true;
            } else if (moment(formattedValue, 'DDMMYY').isValid()) {
                const personCodeSplit = formattedValue.split('').map((item) => Number(item));
                const calc: number = new NumericArrayBuilder()
                    .withConcat([
                        NumericArrayBuilder.One,
                        NumericArrayBuilder.Six,
                        NumericArrayBuilder.Three,
                        NumericArrayBuilder.Seven,
                        NumericArrayBuilder.Nine,
                        NumericArrayBuilder.Ten,
                        NumericArrayBuilder.Five,
                        NumericArrayBuilder.Eight,
                        NumericArrayBuilder.Four,
                        NumericArrayBuilder.Two,
                    ])
                    .build()
                    .map((digit, index) => digit * personCodeSplit[index])
                    .reduce((a, b) => a + b, 0);
                const checkNumber: number = 1101;
                isValid = (checkNumber - calc) % this.codeModularDivisor === personCodeSplit[this.codeBaseLength];
            }
        }
        return isValid;
    }

    private hasValidStartingDigits(value: string): boolean {
        const firstDigit: number = Number(String(value).slice(0, 1));
        const secondDigit: number = Number(String(value).slice(1, 2));
        const allowedSecondDigits: number[] = new NumericArrayBuilder()
            .withFrom(NumericArrayBuilder.Two)
            .withTo(NumericArrayBuilder.Nine)
            .build();

        return firstDigit === NumericArrayBuilder.Three && allowedSecondDigits.includes(secondDigit);
    }

    private isValidPersonCodeWithoutDateLV(value: string): boolean {
        return this.hasValidStartingDigits(value) && this.isValidPersonCodeLV(value);
    }
}
