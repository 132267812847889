import PropertyType from '@/interfaces/property/type.interface';
import AdditionalObjectType from '@/interfaces/property/additional.object.type.interface';
import BuildingMaterial from '@/interfaces/property/building.material.interface';
import BuildingYear from '@/interfaces/property/building.year.interface';
import Limits from '@/interfaces/property/limits.interface';

export default class PropertyEnum {
    public static Type: PropertyType = new (class implements PropertyType {
        public House: string = 'PD21_O_BLDF';
        public Apartment: string = 'PD21_O_APAR';
        public AdditionalObject: string = 'PD21_O_BLDA';
    })();
    public static AdditionalObjectType: AdditionalObjectType = new (class implements AdditionalObjectType {
        public Sauna: string = 'BLDA01';
        public Garage: string = 'BLDF10';
        public Outhouse: string = 'BLDF11';
    })();
    public static BuildingMaterial: BuildingMaterial = new (class implements BuildingMaterial {
        public Brick: string = 'BRICK';
        public Mixed: string = 'MIX';
        public Wood: string = 'WOOD';
    })();
    public static BuildingYear: BuildingYear = new (class implements BuildingYear {
        public Current: string = 'current';
        public Older: string = 'older';
        public OffsetCurrent: number = 40;
        public OffsetOlder: number = 41;
        public DashSeparator: string = ' - ';
        public SpaceSeparator: string = ' ';
    })();
    public static Limits: Limits = new (class implements Limits {
        public HouseAreaMinLimit: number = 15;
        public ApartmentAreaMinLimit: number = 10;
        public SaunaAreaMinLimit: number = 5;
        public GarageAreaMinLimit: number = 5;
        public OuthouseAreaMinLimit: number = 5;
        public YearMinLimit: number = 100;
    })();
    public static AdditionalObjectsLimit: number = 10;
    public static TranslationType: string = 'property';
}
