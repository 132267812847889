<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import VehicleProduct from '@/Enums/VehicleProductEnum';

const props = defineProps({
    type: { type: String, default: () => '' },
    translationType: { type: String, default: 'components' },
});
const { translateForType } = useTranslate();
const isCasco: boolean = props.type === VehicleProduct.Casco.toLowerCase();
</script>
<template>
    <div class="image-info">
        <div v-if="isCasco" class="examples-container">
            <h4 class="title">{{ translateForType('image_info_title', translationType) }}</h4>
            <div class="examples">
                <div class="example">
                    <img src="/images/casco_picture_2.jpg" alt="" />
                    <p class="description">{{ translateForType('image_info_front_view', translationType) }}</p>
                </div>
                <div class="example">
                    <img src="/images/casco_picture_1.jpg" alt="" />
                    <p class="description">{{ translateForType('image_info_back_view', translationType) }}</p>
                </div>
                <div class="example">
                    <img src="/images/casco_picture_3.jpg" alt="" />
                    <p class="description">{{ translateForType('image_info_dashboard', translationType) }}</p>
                </div>
            </div>
            <div class="examples second-row">
                <div class="example">
                    <img src="/images/casco_picture_4.jpg" alt="" />
                    <p class="description">{{ translateForType('image_info_vin_number', translationType) }}</p>
                </div>
                <div class="example">
                    <img src="/images/casco_picture_5.jpg" alt="" />
                    <p class="description">{{ translateForType('image_info_accessories', translationType) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.image-info {
    width: 100%;
    margin-top: var(--size-small);

    .examples-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            font-weight: 500;
            margin-bottom: var(--size-normal);
        }

        .examples {
            width: 100%;
            max-width: 420px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include respond-above('lg') {
                flex-direction: row;
                max-width: 100%;
            }

            &.second-row {
                justify-content: space-around;

                @include respond-above('lg') {
                    margin-top: var(--size-small);
                }
            }

            .example {
                margin-bottom: var(--size-normal);

                @include respond-above('lg') {
                    max-width: 225px;
                    margin-bottom: 0;
                }

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 3px 3px 0 0;
                }

                .description {
                    min-height: 46px;
                    width: 100%;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 400;
                    color: var(--black-500);
                    background: var(--white);
                    border-radius: 0 0 3px 3px;
                }
            }
        }
    }
}
</style>
