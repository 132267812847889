<script setup lang="ts">
import { computed, nextTick, ref, Ref } from 'vue';
import { TranslateReplaceParts, useTranslate } from '@/Composables/Translate';
import ButtonWithCallback from '@/Components/Buttons/ButtonWithCallback/ButtonWithCallback.vue';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonTextColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.text.color.enum';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconPosition from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.position.enum';
import DataLayerType from '@/Enums/DataLayerTypeEnum';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ProcessInnerToaster from '@/Components/Tooltips/ProcessInnerToaster/Services/ProcessInnerToasterService';
import InnerToasterState from '@/Components/Tooltips/ProcessInnerToaster/Enums/InnerToasterStateEnum';

const props = defineProps({
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    translationType: { type: String, default: '' },
    uploader: { type: String, default: '' },
    fileFormats: { type: String, default: '' },
    alwaysOpened: { type: Boolean, default: false },
    uploadFilesCallback: { type: Function, default: null },
});
const { translate, translateForType } = useTranslate();

const uploaderIsVisible: Ref<boolean> = ref(false);
const uploaderIsOpened: Ref<boolean> = ref(false);
const filesUploader: Ref<DynamicDictionary | null> = ref(null);
const minHeightIsCalculated: Ref<boolean> = ref(false);

const isUploadRestricted: boolean = false;

const uploadDocumentsMode: Ref<boolean> = computed(() => {
    return uploaderIsOpened.value;
});

const button: Ref<ButtonWithCallbackParams> = computed(() => {
    return {
        title: uploadDocumentsMode.value ? localized('upload_documents') : localized('add_documents'),
        textColor: ButtonTextColor.White,
        backgroundColor: ButtonBackground.Red,
        icon: uploadDocumentsMode.value ? ButtonIcon.LongArrowRight : ButtonIcon.AddFile,
        iconPosition: uploadDocumentsMode.value ? ButtonIconPosition.Right : ButtonIconPosition.Left,
        callback: uploadDocumentsMode.value ? onSubmitDocumentsClick : onAddDocumentsClick,
    };
});

const addFilesButtonIsDisabled: Ref<boolean> = computed(() => {
    let filesIsEmpty: boolean = true;
    let uploadInProgress: boolean = true;
    if (filesUploader.value) {
        filesIsEmpty = filesUploader.value.filesCount === 0;
        uploadInProgress = filesUploader.value.uploadInProgress;
    }
    let result: boolean = false;
    if (uploadDocumentsMode.value) {
        result =
            filesIsEmpty ||
            uploadInProgress ||
            ProcessInnerToaster.getInstance().content().state === InnerToasterState.Process;
    }

    return result;
});

const addFilesButtonClasses: Ref<string> = computed(() => {
    const result: string[] = ['action-button'];
    if (filesUploader.value) {
        if (filesUploader.value.filesCount > 0) {
            result.push('action-button-gap');
        }
    }

    return result.join(' ');
});

function onAddDocumentsClick(): void {
    uploaderIsVisible.value = true;
    uploaderIsOpened.value = true;
    ProcessInnerToaster.getInstance().applyState(InnerToasterState.None);
    nextTick(() => {
        if (!minHeightIsCalculated.value) {
            minHeightIsCalculated.value = true;
            const minHeight: number = 398;
            ProcessInnerToaster.getInstance().applyResize(minHeight);
        }
    });
}

function onSubmitDocumentsClick(): void {
    props.uploadFilesCallback();
}

function applyToasterState(state: string): void {
    switch (state) {
        case InnerToasterState.Process:
            ProcessInnerToaster.getInstance().applyState(InnerToasterState.Process);
            break;

        case InnerToasterState.Complete:
            ProcessInnerToaster.getInstance().applyState(InnerToasterState.Complete);
            uploaderIsOpened.value = false;
            break;

        default:
            ProcessInnerToaster.getInstance().applyState(InnerToasterState.None);
    }
}

function localized(key: string, replaceParts?: TranslateReplaceParts): string {
    return translateForType(key, props.translationType, replaceParts);
}

defineExpose({
    filesUploader,
    applyToasterState,
});
</script>

<template>
    <div class="compact-file-uploader">
        <div class="content">
            <span class="title">{{ title }}</span>
            <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
            <app-process-inner-toaster>
                <app-files-uploader
                    v-if="uploaderIsVisible || props.alwaysOpened"
                    ref="filesUploader"
                    :uploader="uploader"
                    :is-upload-restricted="isUploadRestricted"
                    :data-layer-event="DataLayerType.ClaimUploadFiles"
                >
                </app-files-uploader>
            </app-process-inner-toaster>
            <button-with-callback
                v-if="!props.alwaysOpened"
                class="buttons"
                :class="addFilesButtonClasses"
                v-bind="button"
                :disabled="addFilesButtonIsDisabled"
            ></button-with-callback>
            <div v-if="props.fileFormats" class="file-formats">{{ props.fileFormats }}</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.compact-file-uploader {
    .content {
        display: flex;
        flex-direction: column;
        padding: var(--size-medium);
        border-radius: var(--size-tiny);
        width: 100%;

        .document-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: var(--size-nano);
            margin-bottom: var(--size-small);

            .button-with-callback {
                width: 100%;
                height: 52px;
                justify-content: flex-start;
            }
        }

        .buttons {
            height: 52px;
            margin-top: var(--size-small);
        }

        .title {
            font-weight: 700;
            font-size: var(--font-size-small);
            margin-bottom: var(--size-nano);
            width: 100%;
            text-align: left;
        }

        .subtitle {
            font-weight: 500;
            font-size: var(--font-size-tiny);
            color: var(--text-color-default);
            margin-bottom: var(--size-small);
            width: 100%;
            text-align: left;
        }

        .toaster-content {
            width: 100%;

            .files-uploader {
                margin-top: var(--size-nano);

                :deep() .files-uploader-area {
                    gap: var(--size-pico);
                    height: 216px;

                    .item {
                        min-height: auto;

                        &.upload-container {
                            width: 100%;
                            margin-right: 0;

                            @include respond-below('xs') {
                                width: 100%;
                            }
                        }

                        &.qr {
                            max-width: 192px;
                            display: flex;

                            @include respond-below('xs') {
                                display: none;
                            }
                        }
                    }
                }

                :deep() .files-area:not(:empty) {
                    margin-top: var(--size-small);

                    .file {
                        width: 68px;
                        height: 68px;
                        margin-top: 0;
                        background-size: cover;

                        &.fail {
                            outline: none;
                            border: solid 2px var(--system-color-error-default);

                            .fileType,
                            .fileName {
                                padding-left: 2px;
                            }

                            .fileInfo {
                                padding-left: 2px;

                                .deleteButton {
                                    top: 4px;
                                    right: -37px;
                                }
                            }
                        }

                        .fileInfo {
                            padding-left: var(--size-femto);

                            .deleteButton {
                                right: -39px;
                                width: 20px;
                                height: 20px;
                                margin-bottom: 4px;
                            }
                        }

                        .fileName,
                        .fileType {
                            width: auto;
                            padding-left: var(--size-femto);
                        }
                    }
                }

                :deep() .button {
                    width: 100%;
                    margin-top: var(--size-small);
                }
            }
        }

        .action-button {
            &.action-button-gap {
                margin-top: var(--size-small);
            }
        }

        .file-formats {
            margin-top: var(--size-small);
            color: var(--text-color-subtlest);
            font-size: var(--font-size-nano);
        }
    }
}
</style>
