<script setup lang="ts">
import { useTranslate } from '@/Composables/Translate';
import { computed, ref, Ref } from 'vue';

const props = defineProps({
    showMoreTextId: { type: String, default: '' },
    showLessTextId: { type: String, default: '' },
    showOpened: { type: Boolean, default: false },
    showArrows: { type: Boolean, default: true },
    svgWidth: { type: String, default: '24' },
    svgHeight: { type: String, default: '24' },
    strokeColor: { type: String, default: '#007A76' },
});

const { translate } = useTranslate();

const opened: Ref<boolean> = ref(props.showOpened);

const buttonText: Ref<string> = computed(() => {
    let result: string;
    if (opened.value) {
        result = props.showLessTextId !== '' ? translate(props.showLessTextId) : translate('show_less');
    } else {
        result = props.showMoreTextId !== '' ? translate(props.showMoreTextId) : translate('show_more');
    }

    return result;
});

const expanderClass: Ref<string> = computed(() => {
    return opened.value ? 'expander-expanded' : '';
});

function onExpanderButtonClick(): void {
    opened.value = !opened.value;
}
</script>

<template>
    <div class="expandable">
        <div class="header">
            <slot name="header"></slot>
        </div>
        <div class="expander" :class="expanderClass">
            <slot name="expander"></slot>
        </div>
        <button class="expand-button" @click="onExpanderButtonClick">
            {{ buttonText }}
            <template v-if="props.showArrows">
                <svg
                    v-if="opened"
                    :width="svgWidth"
                    :height="svgHeight"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M18 15L12 9L6 15"
                        :stroke="strokeColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <svg
                    v-else
                    :width="svgWidth"
                    :height="svgHeight"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M6 9L12 15L18 9"
                        :stroke="strokeColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </template>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.expandable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .header {
        height: auto;
    }

    .expander {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: auto;
        max-height: 0;
        overflow: hidden;
        font-size: var(--font-size-nano);

        &-expanded {
            max-height: 10000px;
        }

        > ul {
            li {
                position: relative;
                padding-left: var(--size-small);
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    content: '';
                    position: absolute;
                    width: 4px;
                    height: 4px;
                    left: 0;
                    top: 50%;
                    margin-top: -2px;
                    border-radius: 50%;
                    background-color: var(--brand-red);
                }
            }
        }
    }

    .expand-button {
        display: flex;
        align-items: center;
        gap: var(--size-pico);
        color: var(--brand-red);

        &:hover {
            text-decoration: underline;
        }

        svg {
            transition: transform 0.3s ease;
        }
    }
}
</style>
