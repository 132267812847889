import OverviewBlock from '@/Components/Policies/PolicyDetailsOverview/Interfaces/OverviewBlockInterface';
import ClaimOverviewContent from '@/Components/Claims/ClaimOverview/Interfaces/ClaimOverviewContent';
import StatusBlock from '@/Components/Claims/ClaimOverview/Interfaces/StatusBlock';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import UploadBlock from '@/Components/Claims/ClaimOverview/Interfaces/UploadBlock';
import CommunicationsBlock from '@/Components/Claims/ClaimOverview/Interfaces/CommunicationsBlock';
import ClaimOverview from '@/Components/Claims/ClaimOverview/Interfaces/ClaimOverview';
import DetailsBlock from '@/Components/Claims/ClaimOverview/Interfaces/DetailsBlock';
import DecisionBlock from '@/Components/Claims/ClaimOverview/Interfaces/DecisionBlock';
import Attachment from '@/Components/TwoWayCommunication/Message/Interfaces/Attachment';

export default class ClaimOverviewBuilder {
    private claimDetailsOverview: ClaimOverview = this.cleanValues();

    public reset(): this {
        this.claimDetailsOverview = this.cleanValues();

        return this;
    }

    public withStatusBlockEnabled(): this {
        this.claimDetailsOverview.content.statusBlock.enabled = true;

        return this;
    }

    public withStatusBlockProgressSteps(value: string[]): this {
        this.claimDetailsOverview.content.statusBlock.progressSteps = value;

        return this;
    }

    public withStatusBlockCurrentStep(value: number): this {
        this.claimDetailsOverview.content.statusBlock.currentStep = value;

        return this;
    }

    public withDetailsBlockEnabled(): this {
        this.claimDetailsOverview.content.detailsBlock.enabled = true;

        return this;
    }

    public withDetailsBlockClaimType(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.claimType = value;

        return this;
    }

    public withDetailsBlockClaimNumber(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.claimNumber = value;

        return this;
    }

    public withDetailsBlockSubmitDate(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.submitDate = value;

        return this;
    }

    public withDetailsBlockCompensationSum(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.compensationSum = value;

        return this;
    }

    public withDetailsBlockCompensationDate(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.compensationDate = value;

        return this;
    }

    public withDetailsBlockCloseDate(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.closeDate = value;

        return this;
    }

    public withDetailsBlockPolicyNumber(value: string): this {
        this.claimDetailsOverview.content.detailsBlock.policyNumber = value;

        return this;
    }

    public withUploadBlockEnabled(): this {
        this.claimDetailsOverview.content.uploadBlock.enabled = true;

        return this;
    }

    public withUploadBlockPolicyId(value: string): this {
        this.claimDetailsOverview.content.uploadBlock.policyId = value;

        return this;
    }

    public withUploadBlockPolicyNumber(value: string): this {
        this.claimDetailsOverview.content.uploadBlock.policyNumber = value;

        return this;
    }

    public withUploadBlockUploaderPolicyType(value: string): this {
        this.claimDetailsOverview.content.uploadBlock.policyType = value;

        return this;
    }

    public withUploadBlockUploaderClaimNumber(value: string): this {
        this.claimDetailsOverview.content.uploadBlock.claimNumber = value;

        return this;
    }

    public withUploadBlockUploaderClaimId(value: string): this {
        this.claimDetailsOverview.content.uploadBlock.claimId = value;

        return this;
    }

    public withUploadBlockDocuments(value: DynamicDictionary[]): this {
        this.claimDetailsOverview.content.uploadBlock.documents = value;

        return this;
    }

    public withCommunicationsBlockEnabled(): this {
        this.claimDetailsOverview.content.communicationsBlock.enabled = true;

        return this;
    }

    public withCommunicationsBlockMessages(value: DynamicDictionary[]): this {
        this.claimDetailsOverview.content.communicationsBlock.messages = value;

        return this;
    }

    public withActionsBlockEnabled(): this {
        this.claimDetailsOverview.content.actionsBlock.enabled = true;

        return this;
    }

    public withDecisionBlockEnabled(): this {
        this.claimDetailsOverview.content.decisionBlock.enabled = true;

        return this;
    }

    public withDecisionBlockTitle(title: string): this {
        this.claimDetailsOverview.content.decisionBlock.title = title;

        return this;
    }

    public withDecisionBlockContent(description: string): this {
        this.claimDetailsOverview.content.decisionBlock.description = description;

        return this;
    }

    public withDecisionBlockDocumentsTitle(title: string): this {
        this.claimDetailsOverview.content.decisionBlock.documentsTitle = title;

        return this;
    }

    public withDecisionBlockDocuments(documents: Attachment[]): this {
        this.claimDetailsOverview.content.decisionBlock.documents = documents;

        return this;
    }

    private cleanValues(): ClaimOverview {
        return new (class implements ClaimOverview {
            public component: string = 'AppClaimOverview';
            public content: ClaimOverviewContent = new (class implements ClaimOverviewContent {
                public statusBlock: StatusBlock = new (class implements StatusBlock {
                    public enabled: boolean = false;
                    public progressSteps: string[] = [];
                    public currentStep: number = 0;
                })();
                public detailsBlock: DetailsBlock = new (class implements DetailsBlock {
                    public enabled: boolean = false;
                    public claimType: string = '';
                    public claimNumber: string = '';
                    public submitDate: string = '';
                    public policyNumber: string = '';
                    public compensationSum: string = '';
                    public compensationDate: string = '';
                    public closeDate: string = '';
                })();
                public decisionBlock: DecisionBlock = new (class implements DecisionBlock {
                    public enabled: boolean = false;
                    public title: string = '';
                    public description: string = '';
                    public documentsTitle: string = '';
                    public documents: Attachment[] = [];
                })();
                public uploadBlock: UploadBlock = new (class implements UploadBlock {
                    public enabled: boolean = false;
                    public documents: DynamicDictionary[] = [];
                    public policyId: string = '';
                    public policyNumber: string = '';
                    public claimNumber: string = '';
                    public policyType: string = '';
                    public claimId: string = '';
                })();
                public communicationsBlock: CommunicationsBlock = new (class implements CommunicationsBlock {
                    public enabled: boolean = false;
                    public messages: DynamicDictionary[] = [];
                })();
                public actionsBlock: OverviewBlock = new (class implements OverviewBlock {
                    public enabled: boolean = false;
                })();
            })();
        })();
    }

    public build(): ClaimOverview {
        return this.claimDetailsOverview;
    }
}
