import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PolicyDetailsContentTab from '@/Components/Policies/PolicyDetails/Interfaces/PolicyDetailsContentTabInterface';

export default class PolicyDetailsContentTabsBuilder {
    private currentTab: number = -1;
    private tabs: PolicyDetailsContentTab[] = [];

    public reset(): PolicyDetailsContentTabsBuilder {
        this.tabs = [];

        return this;
    }

    public startNewTab(tabId: string = ''): PolicyDetailsContentTabsBuilder {
        this.currentTab++;
        const id: string = tabId === '' ? String(this.currentTab) : tabId;
        this.tabs.push(
            new (class implements PolicyDetailsContentTab {
                public id: string = id;
                public title: string = '';
                public badge: number = 0;
                public module: string = '';
                public content: DynamicDictionary = {};
            })(),
        );

        return this;
    }

    public withTitle(title: string): PolicyDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].title = title;

        return this;
    }

    public withBadgeValue(badgeValue: number): PolicyDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].badge = badgeValue;

        return this;
    }

    public withModule(module: DynamicDictionary): PolicyDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].module = module.component;
        this.tabs[this.currentTab].content = module.content;

        return this;
    }

    public build(): PolicyDetailsContentTab[] {
        return this.tabs;
    }

    private checkCurrentTab(): void {
        if (this.currentTab === -1) {
            this.startNewTab();
        }
    }
}
