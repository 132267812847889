import { useBase } from '@/Composables/Base';
import OneBase from '@/interfaces/OneBaseInterface';

export default class OneBaseService {
    private static instance: OneBase;

    private constructor() {}

    public static getInstance(): OneBase {
        if (!OneBaseService.instance) {
            OneBaseService.instance = useBase();
        }

        return OneBaseService.instance;
    }
}
