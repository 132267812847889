import { defineStore, StoreDefinition } from 'pinia';
import { ref, Ref } from 'vue';
import { RouteLocationNormalizedLoaded, useRoute } from 'vue-router';

export const useRenters = (): RentersStoreParams => {
    const store = (): StoreDefinition<string, RentersStore> => {
        return defineStore(
            'renters',
            (): {
                currentRoute: Ref<string>;
                updateRoute: Function;
                destroyRoute: Function;
            } => {
                const currentRoute: Ref<string> = ref(storedItem('renters-route'));

                const updateRoute = (): void => {
                    const route: RouteLocationNormalizedLoaded = useRoute();
                    currentRoute.value = route.fullPath;
                    localStorage.setItem('renters-route', currentRoute.value);
                };

                const destroyRoute = (): void => {
                    currentRoute.value = '';
                    localStorage.removeItem('renters-route');
                };

                return { currentRoute, updateRoute, destroyRoute };
            },
        );
    };

    const storedItem = (name: string): string => {
        const stored: string | null = localStorage.getItem(name);

        return stored ? stored : '';
    };

    return {
        store,
    };
};

export interface RentersStore {
    currentRoute: string;
    updateRoute: Function;
    destroyRoute: Function;
}

export interface RentersStoreParams {
    store: () => StoreDefinition<string, RentersStore>;
}
