import Confirm from '@/assets/libraries/popups/types/confirm';
import Popups from '@/assets/libraries/popups/types/popups.interface';
import SelectAccount from '@/assets/libraries/popups/types/select.account';
import SimpleError from '@/assets/libraries/popups/types/simple.error';
import CoveredProperty from '@/assets/libraries/popups/types/CoveredProperty';
import CoveredTravel from '@/assets/libraries/popups/types/CoveredTravel';
import Covered from '@/assets/libraries/popups/types/Covered';
import Login from '@/assets/libraries/popups/types/Login';
import LoadingWait from '@/assets/libraries/popups/types/LoadingWait';
import Loading from '@/assets/libraries/popups/types/Loading';
import ClaimSuccess from '@/assets/libraries/popups/types/ClaimSuccess';
import Error from '@/assets/libraries/popups/types/Error';
import VideoPopup from '@/assets/libraries/popups/types/VideoPopup';
import CoveredProduct from '@/assets/libraries/popups/types/CoveredProduct';
import SessionEnd from '@/assets/libraries/popups/types/SessionEnd';
import OneCovered from '@/assets/libraries/popups/types/OneCovered';
import TravelRentalCarSecurity from '@/assets/libraries/popups/types/TravelRentalCarSecurity';
import Info from '@/assets/libraries/popups/types/Info';
import OneConfirm from '@/assets/libraries/popups/types/OneConfirm';
import NextBestAction from '@/assets/libraries/popups/types/NextBestAction';
import YoungestDriverAge from '@/assets/libraries/popups/types/YoungestDriverAge';

export default class OnePopup {
    public withType(): Popups {
        return this.availablePopups();
    }

    private availablePopups(): Popups {
        return {
            confirmPopup: new Confirm(),
            selectAccountPopup: new SelectAccount(),
            simpleError: new SimpleError(),
            coveredProperty: new CoveredProperty(),
            coveredTravel: new CoveredTravel(),
            covered: new Covered(),
            login: new Login(),
            loadingWait: new LoadingWait(),
            loading: new Loading(),
            claimSuccess: new ClaimSuccess(),
            error: new Error(),
            videoPopup: new VideoPopup(),
            coveredProduct: new CoveredProduct(),
            sessionEnd: new SessionEnd(),
            oneCovered: new OneCovered(),
            travelRentalCarSecurity: new TravelRentalCarSecurity(),
            youngestDriverAge: new YoungestDriverAge(),
            info: new Info(),
            oneConfirm: new OneConfirm(),
            nextBestAction: new NextBestAction(),
        } as Popups;
    }
}
