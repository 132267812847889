<script lang="ts">
import { computed, defineComponent, ref, Ref } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import Form from '@/assets/libraries/form/form';
import Translations from '@/services/translations.service';
import { useTranslate } from '@/Composables/Translate';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useDefine } from '@/Composables/Define';
import Error from '@/services/error.service';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { useStepsSubmitter } from '@/Composables/StepsSubmitter';

export default defineComponent({
    setup() {
        const btaBase = OneBaseService.getInstance();

        const { translateForType } = useTranslate();
        const { isSet } = useDefine();
        const stepsSubmitter = useStepsSubmitter();

        const CurrentStep: number = 4;
        const Facility: string = 'one-claims-property';
        const uploader: string = 'property-claims';

        const form: Form = new Form();
        const formIsReady: Ref<boolean> = ref(false);
        const filesUploader: Ref<DynamicDictionary | null> = ref(null);
        const renew: Ref<boolean> = ref(false);

        const fileUploadMessage: Ref<string> = computed(() => {
            return isSet(btaBase.userStorage.stepStorageData.fileUploadMessage)
                ? translateForType(
                      btaBase.userStorage.stepStorageData.fileUploadMessage,
                      Translations.getInstance().type,
                  )
                : '';
        });

        const isUploaded: Ref<boolean> = computed(() => {
            return filesUploader.value
                ? !(filesUploader.value as DynamicDictionary).filesNotUploaded &&
                      (filesUploader.value as DynamicDictionary).uploadsSuccessful
                : false;
        });

        function setupForm(): void {
            form.setReady();
            formIsReady.value = true;
        }

        function onSubmitStep(): void {
            btaBase.captcha
                .executeCaptcha(submit)
                .then()
                .catch((reason: string) => {
                    Error.log(ErrorType.Error, 'onSubmitStep', reason, true);
                });
        }

        function submit(token: string): void {
            (filesUploader.value as DynamicDictionary).touch();
            prepareSubmit(token);
            stepsSubmitter.proceedStep('', 0);
        }

        function prepareSubmit(token: string): void {
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            stepsSubmitter.addSubmitCustomParam('nextStep', btaBase.nextStep());
            stepsSubmitter.addSubmitCustomParam('facility', Facility);
            stepsSubmitter.addSubmitCustomParam('filesUploaded', isUploaded.value);
            stepsSubmitter.addSubmitCustomParam('g-recaptcha-response', token);
        }

        return {
            ...btaBase,
            ...{
                CurrentStep,
                Facility,
                form,
                formIsReady,
                filesUploader,
                renew,
                uploader,
                isUploaded,
                setupForm,
                onSubmitStep,
                submit,
                prepareSubmit,
                fileUploadMessage,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility(this.Facility);
        this.setStorageUsage(true);
        Translations.getInstance().addType('claims');
        this.setupForm();
    },
});
</script>
