import { computed, ref, Ref } from 'vue';

export default class Paginator<T = any> {
    public currentPageNumber: Ref<number> = ref(1);
    public currentPageItems: Ref<T[]> = computed((): T[] => {
        const startIndex: number = (this.currentPageNumber.value - 1) * this.pageSize.value;
        return this.items.value.slice(startIndex, startIndex + this.pageSize.value);
    });
    public totalPagesCount: Ref<number> = computed((): number => {
        return Math.ceil(this.totalItemsCount.value / this.pageSize.value);
    });
    private readonly pageSize: Ref<number> = ref(10);
    private readonly items: Ref<T[]> = ref([]);
    private totalItemsCount: Ref<number> = ref(1);

    public constructor(config?: { pageSize?: number }) {
        this.pageSize.value = config?.pageSize || this.pageSize.value;
    }

    public setItems(items: T[]): this {
        this.items.value = items;
        this.totalItemsCount.value = this.items.value.length;
        this.changePage(1);
        return this;
    }

    public changePage(page: number): this {
        this.currentPageNumber.value = page;
        return this;
    }
}
