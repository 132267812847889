import SettingsService from '../../../../services/settings.service';
import DynamicDictionary from '../../../../interfaces/dynamic.dictionary.interface';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';

export default class AgreementTypeMap {
    private readonly mapping: Map<string, string[]> = new Map();

    public constructor() {
        const agreements: DynamicDictionary = SettingsService.getInstance().agreementsMapping();
        this.mapping = new Map(Object.entries(agreements));
    }

    public byTypeId(agreementTypeId: string): string {
        let result: string = '';
        const filtered: [string, string[]] | undefined = this.filteredByTypeId(agreementTypeId);
        if (filtered) {
            result = filtered[0];
        }

        return result;
    }

    public isTravelAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Travel);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isAccidentAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Accident);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isHealthAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Health);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isPropertyAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Property);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isCascoAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Casco);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isMtplAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Mtpl);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isTransportAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const mtplAgreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Mtpl);
        const cascoAgreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Casco);
        if (mtplAgreementCodes) {
            result = mtplAgreementCodes.includes(agreementTypeId);
        }
        if (!result && cascoAgreementCodes) {
            result = cascoAgreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    public isLiabilityAgreement(agreementTypeId: string): boolean {
        let result: boolean = false;
        const agreementCodes: string[] | undefined = this.mapping.get(AgreementTypes.Liability);
        if (agreementCodes) {
            result = agreementCodes.includes(agreementTypeId);
        }

        return result;
    }

    private filteredByTypeId(agreementTypeId: string): [string, string[]] | undefined {
        return [...this.mapping.entries()].find((entry: [string, string[]]): boolean =>
            entry[1].includes(agreementTypeId),
        );
    }
}
