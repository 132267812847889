<script setup lang="ts">
const emit = defineEmits(['close-attempt']);

function onCloseClick(): void {
    emit('close-attempt');
}
</script>

<template>
    <aside class="legal-modal">
        <aside class="overlay"></aside>
        <div class="wrapper">
            <div class="container">
                <button class="close" @click="onCloseClick()">
                    <svg width="32" height="32" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 1L13 13M13 1L1 13"
                            stroke="#9297A0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        ></path>
                    </svg>
                </button>
                <div class="content">
                    <slot></slot>
                </div>
            </div>
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.legal-modal {
    position: fixed;
    overflow-y: auto;

    @include fullscreen;

    z-index: 400;

    > .overlay {
        position: absolute;

        @include fullscreen;

        z-index: 1;
        background: var(--brand-black);
        opacity: 0.24;
    }

    .close {
        position: absolute;
        top: 31px;
        right: 33px;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    > .wrapper {
        position: relative;
        overflow-y: auto;
        background-color: var(--background-light);
        z-index: 1;
        height: auto;
        width: 100%;
        padding: 32px;
        min-height: 140px;
        max-height: 100%;
    }
}

.narrowed-centered > .wrapper > .container > .close {
    position: relative;
    top: 20px;
    right: 12px;
    float: right;

    @include respond-below('md') {
        flex-direction: column;
    }

    svg {
        width: 12px;
        height: 12px;
    }
}

.narrowed-centered > .wrapper > .container {
    max-width: 1440px;
    margin: auto;
}
</style>
