<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';
import PaymentsWaitingModule from '@/Modules/PaymentsWaiting/PaymentsWaitingModule.vue';
import VueModule from '@/Core/App/VueModule';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const paymentsWaitingModule = new VueModule(PaymentsWaitingModule).mount();

        return {
            ...btaBase,
            ...{
                paymentsWaitingModule,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.paymentsWaitingModule.create();
        this.paymentsWaitingModule.beforeModuleMounted();
        this.paymentsWaitingModule.moduleMounted();
    },
});
</script>
