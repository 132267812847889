import { useCurrency } from '@/Composables/Currency';

export const usePrice = (): PriceParams => {
    const CurrencyPeriod: number = 100;
    const EndZerosPattern: RegExp = /\.00$/;

    const price = (
        targetPrice: string | number,
        isCents: boolean = true,
        currency?: string,
        hideEndZeros: boolean = false,
        useSparse: boolean = false,
    ): string => {
        if (!targetPrice && targetPrice !== 0) {
            return '';
        }
        const priceArray: string[] = [];
        let formattedPrice: string = priceInEuros(Number(targetPrice), isCents);
        if (hideEndZeros) {
            formattedPrice = formattedPrice.replace(EndZerosPattern, '');
        }
        if (useSparse) {
            const sparsePricePattern: RegExp = /(\d)()(?=(\d{3})+(?!\d))/g;
            const sparsePriceSubstitute: string = '$1 $2';
            formattedPrice = formattedPrice.replace(sparsePricePattern, sparsePriceSubstitute);
        }
        priceArray.push(formattedPrice);
        if (currency) {
            priceArray.push(useCurrency().currency(currency));
        }
        return priceArray.join(' ');
    };

    const sparse = (targetPrice: number, noCents: boolean): string => {
        let result: string = targetPrice.toFixed(2);
        if (targetPrice > 0) {
            const sparsePricePattern: RegExp = /\d(?=(\d{3})+\.)/g;
            const sparsePriceSubstitute: string = '$& ';
            result = result.replace(sparsePricePattern, sparsePriceSubstitute);
            if (noCents) {
                result = result.split('.')[0];
            }
        }

        return result;
    };

    const priceInEuros = (cents: number, isCents: boolean): string => {
        let result: number = cents;
        if (!isCents) {
            result = result * CurrencyPeriod;
        }
        const eur: number = result > 0 ? Math.floor(result / CurrencyPeriod) : Math.ceil(result / CurrencyPeriod);
        result -= eur * CurrencyPeriod;

        return String((eur + result / 100).toFixed(2));
    };

    const formattedCurrency = (currentPrice: number): string => {
        return Number(currentPrice).toFixed(2);
    };

    const currencyEuroOrPercentPostfix = (value: string): string => {
        return value
            .split('_')
            .map((part: string) => parseFloat(part) + ' ' + (part.endsWith('P') ? '%' : '€'))
            .join(' + ');
    };

    return {
        price,
        sparse,
        formattedCurrency,
        currencyEuroOrPercentPostfix,
    };
};

export interface PriceParams {
    price: (
        targetPrice: string | number,
        isCents: boolean,
        currency?: string,
        hideEndZeros?: boolean,
        sparsePrice?: boolean,
    ) => string;
    sparse: (targetPrice: number, noCents: boolean) => string;
    formattedCurrency: (currentPrice: number) => string;
    currencyEuroOrPercentPostfix: (value: string) => string;
}
