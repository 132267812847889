<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { computed, ComputedRef, onMounted, PropType, ref, Ref, watch, nextTick } from 'vue';
import * as uuid from 'uuid';
import Popup from '@/services/popup.service';
import PopupType from '@/Enums/PopupTypeEnum';
import VueEvent from '@/Classes/VueEventClass';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import AppContentLoader from '@/Components/Loaders/ContentLoader/ContentLoader.vue';

const props = defineProps({
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    options: { type: Array as PropType<InputOption[]>, default: () => [] },
    hiddenOptions: { type: Array as PropType<InputOption[]>, default: () => [] },
    allowEmptySelection: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    useDirectFormValue: { type: Boolean, default: false },
    isVisiblePopup: { type: Boolean, default: true },
    showCustomElement: { type: Boolean, default: true },
    itemsPerRow: { type: Number, default: 3 },
    popupLabel: { type: String, default: '' },
    skipOptionsChangeFormReset: { type: Boolean, default: false },
});

const emit = defineEmits(['close', 'change']);

const popup: Popup = Popup.getInstance();
const popupStyles: ComputedRef<{ maxWidth: string }> = computed(() => ({
    maxWidth: popupClasses.value + 'px',
}));
const popupClasses: ComputedRef<number> = computed(() => {
    const panelWidthGapWidth: number = 224;
    const popupHorizontalPaddingWidth: number = 84;
    let result: number = panelWidthGapWidth * props.itemsPerRow;
    if (props.options.length < props.itemsPerRow) {
        result = panelWidthGapWidth * (props.options.length + (props.showCustomElement ? 1 : 0));
    }
    if (specificRegionsCaseForFourElements()) {
        result = panelWidthGapWidth * 2;
    }

    return result + popupHorizontalPaddingWidth;
});
const showOpener: ComputedRef<boolean> = computed(() => {
    return props.options.length > 1;
});
const isDisabled: ComputedRef<boolean> = computed(() => {
    return props.options.length === 0 || props.disabled || props.options.length === 0;
});

const isOpened: Ref<boolean> = ref(false);
const emptyOption: Ref<InputOption> = ref(new InputOptionBuilder().setName('-----').build());
const selectedOption: Ref<InputOption> = ref(emptyOption);
const id: string = uuid.v4();

watch(
    () => props.formField.value,
    (newValue) => {
        selectedOption.value = optionByValue(String(newValue));
        emitChange();
    },
);

watch(
    () => props.options,
    (newValue: InputOption[]) => {
        if (
            props.formField.value &&
            !props.hiddenOptions.some((item: InputOption) => item.value === props.formField.value) &&
            !newValue.some((item) => item.value === props.formField.value) &&
            !props.skipOptionsChangeFormReset
        ) {
            props.formField.patch('');
        }
        selectedOption.value = optionByValue(props.formField.value as string);
        emitChange();
    },
);

onMounted((): void => {
    nextTick(() => {
        if (props.formField.value) {
            selectedOption.value = optionByValue(props.formField.value as string);
        }
    });
});

function open(): void {
    isOpened.value = true;
    popup.showPopup(PopupType.CustomPopup);
}

function close(): void {
    if (isOpened.value) {
        isOpened.value = false;
        props.formField.touch();
        popup.showPopup(PopupType.None);
        emit('close');
    }
}

function select(option: InputOption, event: VueEvent): void {
    if (!tooltipWasClicked(event)) {
        props.formField.patch(option.value);
        props.formField.validate();
        close();
    }
}

function isActive(option?: InputOption): boolean {
    let result: boolean = false;
    if (option && String(props.formField.value) === option.value) {
        result = true;
    }

    return result;
}

function optionByValue(value: string): InputOption {
    let result: InputOption = new InputOptionBuilder().setName('-----').build();
    const option: InputOption | undefined = props.options.find(
        (inputOption: InputOption) => inputOption.value === value,
    );
    if (option) {
        result = option;
    }
    const hiddenOption: InputOption | undefined = props.hiddenOptions.find(
        (hiddenOptionValue) => hiddenOptionValue.value === value,
    );
    if (hiddenOption) {
        result = hiddenOption;
    }

    return result;
}

function tooltipWasClicked(event: VueEvent): boolean {
    const tooltip: JQuery = $(event.event.currentTarget).find('.tooltipster');
    let result: boolean = false;
    if (tooltip.length > 0) {
        if (event.event.target !== event.event.currentTarget && tooltip.has(event.event.target).length > 0) {
            result = true;
        }
    }

    return result;
}

function specificRegionsCaseForFourElements(): boolean {
    return props.options.length + (props.showCustomElement ? 1 : 0) === 4;
}

function emitChange(): void {
    props.formField.touch();
    props.formField.sanitize();
    props.formField.validate();
    emit('change', props.formField.value);
}

defineExpose({
    close,
});
</script>

<template>
    <div
        :id="formField.name"
        class="input input-select input-selection-panels"
        :class="{ ...formField.classes(), disabled: isDisabled || !showOpener }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : formField.value"
    >
        <div v-if="label" class="label hide-on-mobile">
            <p>{{ label }}<span v-if="required">*</span></p>
            <slot name="panel-label"></slot>
        </div>
        <div class="wrapper">
            <div :id="id" class="select default">
                <button :id="formField.name + '-open'" class="button" @click="open()">
                    <span
                        v-if="selectedOption === emptyOption"
                        class="text text-icon"
                        v-html="placeholder || selectedOption.name"
                    ></span>
                    <span
                        v-if="selectedOption !== emptyOption"
                        class="text text-icon"
                        v-html="selectedOption.name || selectedOption.value"
                    ></span>
                    <span v-if="showOpener" class="icon">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 1L7 7L1 1"
                                stroke="#E30613"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </span>
                </button>
                <div v-if="isOpened && isVisiblePopup" class="popups">
                    <app-popup :styles="popupStyles" class="simple list-panels" @close="close()">
                        <div
                            v-if="popupLabel || label"
                            :class="{
                                description: popupLabel,
                                custom: popupLabel,
                                title: !popupLabel,
                            }"
                        >
                            {{ popupLabel || label }}
                            <slot name="panel-opener-label"></slot>
                        </div>
                        <span class="list-details">
                            <button
                                v-if="allowEmptySelection"
                                :id="formField.name + '-select-active'"
                                class="button item"
                                :class="{ active: isActive() }"
                                @click="select(emptyOption, new VueEvent($event))"
                            >
                                <span class="label" v-html="emptyOption.name"></span>
                            </button>
                            <button
                                v-for="(option, index) in options"
                                :id="formField.name + '-select-' + index"
                                :key="index"
                                class="button item"
                                :class="{ active: isActive(option) }"
                                @click="select(option, new VueEvent($event))"
                            >
                                <span class="label" v-html="option.name || option.value"></span>
                                <app-tooltipster
                                    v-if="option.tooltip"
                                    :title="option.tooltip.title"
                                    :description="option.tooltip.description"
                                    :open-on-hover="true"
                                ></app-tooltipster>
                            </button>
                            <slot name="custom-element"></slot>
                        </span>
                    </app-popup>
                </div>
            </div>
            <div v-if="loading" class="loading">
                <app-content-loader :icon-type="'spinner'"></app-content-loader>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wrapper {
    position: relative;

    .loading {
        position: absolute;
        bottom: 16px;
        right: 50px;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.disabled {
    .wrapper {
        > .select {
            pointer-events: none;

            > button {
                background-color: var(--component-color-background-disabled);

                .text {
                    color: var(--black-600);
                }
            }
        }
    }
}

.travel-insurance {
    .input-selection-panels {
        .hide-on-mobile {
            display: none;

            @include respond-above('sm') {
                display: block;
            }
        }
    }
}

.input-selection-panels {
    .popups {
        .list-panels {
            .wrapper {
                .title {
                    color: var(--text-color-default);
                }
            }
        }
    }

    &.invalid {
        .wrapper {
            .select {
                .button:not(.item) {
                    border-color: var(--brand-red);
                }
            }
        }
    }
}

.invalid.touched {
    .input-selection-panels {
        .wrapper {
            > .select {
                > .button {
                    border-color: var(--brand-red);
                }
            }
        }
    }
}

.popups {
    .single-popup {
        &.list-panels {
            > .wrapper {
                .list-details {
                    .button.item {
                        @include respond-below('sm') {
                            padding-right: 35px;
                            padding-left: 35px;
                        }
                    }
                }
            }
        }
    }
}
</style>
