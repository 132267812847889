<script setup lang="ts">
import { computed, ComputedRef, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from 'vue';
import FormField from '@/assets/libraries/form/form-field';
import Counters from '@/interfaces/counters.interface';
import * as uuid from 'uuid';
import Form from '@/assets/libraries/form/form';
import MultiCountersValue from '@/interfaces/multi.counters.value.interface';
import { Subject, Subscription } from 'rxjs';
import Popup from '@/services/popup.service';
import { debounceTime } from 'rxjs/operators';
import Counter from '@/interfaces/counter.interface';
import CountersValue from '@/interfaces/counters.value.interface';
import CounterValue from '@/interfaces/counter.value.interface';
import PopupType from '@/Enums/PopupTypeEnum';
import { useDefine } from '@/Composables/Define';
import { useTranslate } from '@/Composables/Translate';
import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import AppTooltipster from '@/Components/Tooltips/Tooltipster/Tooltipster.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import AppInputCounter from '@/Components/Inputs/InputCounter/InputCounter.vue';

const props = defineProps({
    countersData: { type: Array as PropType<Counters[]>, default: () => [] },
    openerLabel: { type: String, default: '' },
    message: { type: String, default: '' },
    isAdditionalPanelAvailable: { type: Boolean, default: false },
    formField: {
        type: Object as PropType<FormField<MultiCountersValue>>,
        default: () => new FormField(''),
    },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change', 'close', 'close-message']);

const { isSet } = useDefine();
const { translate } = useTranslate();

const popup: Popup = Popup.getInstance();
const id: string = uuid.v4();
const onInputSubject: Subject<void> = new Subject();
const onInputSubjectSubscribe: Subscription = onInputSubject.pipe(debounceTime(10)).subscribe(() => {
    updateAndEmitChanges();
});

const isOpened: Ref<boolean> = ref(false);
const form: Form = new Form();
const counters: Ref<Counters[]> = ref(JSON.parse(JSON.stringify(props.countersData)));
const hasRestriction: Ref<boolean> = ref(false);

const isVisibleMessage: ComputedRef<boolean> = computed(() => props.message !== '');
const fieldId: ComputedRef<string> = computed(() => props.formField.name + '-open');
const hasErrors: ComputedRef<boolean> = computed(() => props.formField.errors().length > 0 || hasRestriction.value);
const messageIsExpanded: ComputedRef<boolean> = computed(() => form.field('enabler-checkbox-0').value);

const showMaxCountMessage: ComputedRef<boolean> = computed((): boolean => {
    return counters.value[0].counters.some((item: Counter): boolean => item.maxCountMessage !== '');
});
const maxCountMessage: ComputedRef<string> = computed((): string => {
    const counterWithMessage: Counter | undefined = counters.value[0].counters.find(
        (item: Counter): boolean => item.maxCountMessage !== '',
    );

    return counterWithMessage ? counterWithMessage.maxCountMessage : '';
});

const showRestrictedMessage: ComputedRef<boolean> = computed((): boolean => {
    const hasOtherCounters: boolean = counters.value[0].counters.some(
        (item: Counter): boolean => !item.isRestricted && item.value > 0,
    );
    const hasRestrictedItemWithMessage: boolean = counters.value[0].counters.some(
        (item: Counter): boolean => item.isRestricted && item.restrictedMessage !== '',
    );

    return !hasOtherCounters && hasRestrictedItemWithMessage;
});
const restrictedMessage: ComputedRef<string> = computed((): string => {
    const counterWithMessage: Counter | undefined = counters.value[0].counters.find(
        (item: Counter): boolean => item.isRestricted && item.restrictedMessage !== '',
    );

    return counterWithMessage ? counterWithMessage.restrictedMessage : '';
});

const formValue: ComputedRef<MultiCountersValue> = computed(() => {
    const result: MultiCountersValue = { counters: [], total: 0 };
    counters.value
        .filter((value: Counters) => value.enabled)
        .forEach((value: Counters) => {
            value.counters.forEach((counter: Counter) => {
                result.total += counter.value;
            });
        });
    counters.value.forEach((countersValue: Counters, countersIndex: number) => {
        result.counters[countersIndex] = { counters: [], selected: countersValue.enabled, total: 0 };
        countersValue.counters.forEach((counterValue: Counter, counterIndex) => {
            result.counters[countersIndex].counters[counterIndex] = {
                name: counterValue.name,
                value: counterValue.value,
            };
            result.counters[countersIndex].total += counterValue.value;
        });
    });
    return result;
});
const lastApprovedFormValue: Ref<MultiCountersValue> = ref(formValue.value);
const ageRangeLabel: Ref<string> = ref('');
const totalCount: ComputedRef<number> = computed(() =>
    lastApprovedFormValue.value.counters.length > 0
        ? lastApprovedFormValue.value.counters[0].total
        : lastApprovedFormValue.value.total,
);
const hasSubtitle: Ref<boolean> = computed(() => {
    return isSet(props.countersData[0].subtitle);
});
const subtitle: Ref<string> = computed(() => {
    return props.countersData[0].subtitle ? props.countersData[0].subtitle : '';
});

watch(
    () => props.formField.value,
    (value: any, oldValue: any) => {
        computeRangeLabel();
        if (value !== '' && JSON.stringify(value) !== JSON.stringify(oldValue) && isSet(value.counters)) {
            restoreForm(value.counters);
            updateAndEmitChanges();
        }
    },
);

watch(
    () => props.isAdditionalPanelAvailable,
    () => {
        resetPanelCountersAndStatus();
        updateAndEmitChanges();
    },
);

watch(
    () => props.formField.isRestored,
    () => {
        lastApprovedFormValue.value = formValue.value;
    },
);

onMounted((): void => {
    setupForm();
});

onBeforeUnmount((): void => {
    onInputSubjectSubscribe.unsubscribe();
});

function computeRangeLabel(): void {
    ageRangeLabel.value = '';
    if (isSet(counters.value) && counters.value.length > 0) {
        let selectedIndex: number = -1;
        let selectedRangesCount: number = 0;
        counters.value[0].counters.forEach((counter: CounterValue, index: number): void => {
            if (counter.value > 0) {
                selectedIndex = index;
                selectedRangesCount++;
            }
        });
        if (selectedRangesCount === 1) {
            ageRangeLabel.value = translate(counters.value[0].counters[selectedIndex].label);
        }
    }
}

function open(): void {
    isOpened.value = true;
    popup.showPopup(PopupType.CustomPopup);
}

function close(): void {
    if (isOpened.value) {
        closePopup();
        setOldValues();
        computeRangeLabel();
        emit('close');
    }
}

function closeMessage(): void {
    resetMaxCountMessages();
    emit('close-message');
}

function closeRestrictedMessage(): void {
    resetRestrictedMessages();
}

function resetMaxCountMessages(): void {
    counters.value[0].counters.forEach((item: Counter): void => {
        item.maxCountMessage = '';
    });
}

function resetRestrictedMessages(): void {
    counters.value[0].counters.forEach((item: Counter): void => {
        item.restrictedMessage = '';
    });
}

function apply(): void {
    lastApprovedFormValue.value = formValue.value;
    closePopup();
    computeRangeLabel();
}

function nextPanelExists(index: number): boolean {
    return counters.value.length > 0 && isSet(counters.value[index + 1]);
}

function nextPanelAvailable(index: number): boolean {
    return nextPanelExists(index) && props.countersData[index + 1].available;
}

function panelOpenerText(index: number): string {
    let result: string = '';
    if (nextPanelExists(index)) {
        result = counters.value[index + 1].openerTitle;
    }

    return result;
}

function nextPanelIsEnabled(index: number): boolean {
    let result: boolean = false;
    if (nextPanelExists(index)) {
        result = counters.value[index + 1].enabled;
    }

    return result;
}

function nextPanelToggle(index: number): void {
    if (nextPanelExists(index)) {
        const item: Counters = counters.value[index + 1];
        if (item.enabled) {
            item.enabled = false;
            for (let i = index + 1; i < counters.value.length; i++) {
                counters.value[i].enabled = false;
            }
        } else {
            item.enabled = true;
        }
        form.field('enabler-checkbox-' + index).patch(item.enabled);
    }
    onInputSubject.next();
}

function maxValueForCounter(index: number, counterIndex: number): number {
    let result: number = counters.value[index].counters[counterIndex].maxCount;
    if (index === 1) {
        result = counters.value[0].counters[counterIndex].value;
    }

    return result;
}

function unrestrictedMaxAge(): number {
    const defaultMaxAge: number = 75;

    return (
        counters.value
            .filter((item: Counters): boolean => item.enabled)[0]
            .counters.filter((item: Counter): boolean => !item.isRestricted)
            .toSorted((a: Counter, b: Counter): number => a.maxAge - b.maxAge)
            .at(-1)?.maxAge ?? defaultMaxAge
    );
}

function onCounterItemChange(item: Counter, index: number, counterIndex: number): void {
    patchCounterByName(item.name);
    props.formField.sanitize().validate();
    onInputSubject.next();
    if (index === 0) {
        const counterHasMaxValue: boolean = item.value >= item.maxCount;
        item.maxCountMessage =
            counterHasMaxValue && !item.isRestricted
                ? translate('multi_counter_max_count_message', {
                      '%count%': maxValueForCounter(index, counterIndex),
                  })
                : '';
        if (item.isRestricted) {
            const hasOwnCount: boolean = counters.value[0].counters.some(
                (counter: Counter): boolean => counter.isRestricted && counter.value > 0,
            );
            item.restrictedMessage = hasOwnCount
                ? translate('multi_counter_restricted_message', { '%max_age%': unrestrictedMaxAge() })
                : '';
            hasRestriction.value = hasOwnCount;
        }
    }
    computeRangeLabel();
}

function showErrorForCounter(value: number): boolean {
    return hasErrors.value && value > 0;
}

function patchCounterByName(name: string, value: any = null): void {
    for (let i = 0; i < counters.value.length; i++) {
        for (let j = 0; j < counters.value[i].counters.length; j++) {
            if (counters.value[i].counters[j].name === name) {
                counters.value[i].counters[j].value = value !== null ? value : form.field(name).value;
            }
        }
    }
}

function enableCounterByIndex(index: number, enabled: boolean): void {
    if (isSet(counters.value[index])) {
        counters.value[index].enabled = enabled;
    }
}

function updateAndEmitChanges(): void {
    props.formField.patch(formValue.value);
    props.formField.validate();
    emit('change', formValue.value);
}

function setupForm(): void {
    props.formField.patch(formValue.value);
    props.formField.markAsUntouched();
    counters.value.forEach((value: Counters, index: number) => {
        value.counters.forEach((counter: Counter) => {
            form.addField(new FormField(counter.name, counter.value));
        });
        form.addField(new FormField('enabler-checkbox-' + index, false));
    });
    onInputSubject.next();
}

function setOldValues(): void {
    restoreForm(lastApprovedFormValue.value.counters);
    updateAndEmitChanges();
}

function resetPanelCountersAndStatus(): void {
    counters.value[1].enabled = false;
    for (let i: number = 0; i < counters.value[1].counters.length; i++) {
        counters.value[1].counters[i].value = 0;
    }
}

function closePopup(): void {
    isOpened.value = false;
    props.formField.touch();
    popup.showPopup(PopupType.None);
}

function restoreForm(restoreValue: CountersValue[]): void {
    restoreValue.forEach((countersValue: CountersValue, countersIndex: number) => {
        enableCounterByIndex(countersIndex, countersValue.selected);
        countersValue.counters.forEach((counterValue: CounterValue) => {
            patchCounterByName(counterValue.name, counterValue.value);
        });
    });
}
</script>

<template>
    <div
        :id="formField.name"
        class="input multi-counter"
        :class="{ ...formField.classes(), disabled: disabled }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div v-if="label" class="label hide-on-mobile">
            <label>{{ label }}<span v-if="required">*</span></label>
        </div>
        <div class="wrapper">
            <div :id="id" class="select default">
                <button :id="fieldId" class="button" @click="open()">
                    <span class="text text-icon"
                        >{{ totalCount + ' ' + openerLabel }}<span class="range">{{ ageRangeLabel }}</span></span
                    >
                    <span class="age-group-slot"><slot name="age-group-slot"></slot></span>
                    <span class="icon">
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13 1L7 7L1 1"
                                stroke="#E30613"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            ></path>
                        </svg>
                    </span>
                </button>
                <div v-if="isOpened" class="popups">
                    <app-popup class="simple multi-counter" @close="close()">
                        <div class="counters">
                            <template v-for="(countersPanel, index) in counters">
                                <div
                                    v-if="index === 0 || (countersPanel.enabled && isAdditionalPanelAvailable)"
                                    :key="index"
                                    class="counter-panel"
                                    :class="{ 'counter-panel-additional': index > 0 }"
                                >
                                    <div v-if="countersPanel.title !== ''" class="title">
                                        {{ translate(countersPanel.title) }}
                                    </div>
                                    <div v-if="hasSubtitle" class="counters-subtitle">
                                        <span v-if="index === 0">{{ translate(subtitle) }}</span>
                                    </div>
                                    <div
                                        v-for="(item, counterIndex) in countersPanel.counters"
                                        :key="counterIndex"
                                        class="counters"
                                    >
                                        <div class="range-text">{{ translate(item.label) }}</div>
                                        <app-input-counter
                                            :label="translate(item.label)"
                                            :form-field="form.field(item.name)"
                                            :min-value="item.minCount"
                                            :max-value="maxValueForCounter(index, counterIndex)"
                                            :value="item.value"
                                            :class="{ invalid: showErrorForCounter(item.value) }"
                                            @change="onCounterItemChange(item, index, counterIndex)"
                                        >
                                        </app-input-counter>
                                    </div>
                                    <div
                                        v-if="
                                            nextPanelExists(index) &&
                                            nextPanelAvailable(index) &&
                                            isAdditionalPanelAvailable
                                        "
                                        class="opener-container"
                                    >
                                        <div
                                            :id="formField.name + '-panel-opener'"
                                            class="panel-opener"
                                            @click="nextPanelToggle(index)"
                                        >
                                            <app-input-checkbox
                                                :form-field="form.field('enabler-checkbox-' + index)"
                                                :value="nextPanelIsEnabled(index)"
                                            >
                                                {{ translate(panelOpenerText(index)) }}
                                            </app-input-checkbox>
                                        </div>
                                        <app-tooltipster
                                            v-if="countersPanel.tooltipster"
                                            :title="translate(countersPanel.tooltipster.title)"
                                            :description="translate(countersPanel.tooltipster.description)"
                                        >
                                        </app-tooltipster>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div v-if="isVisibleMessage" class="message" :class="{ expanded: messageIsExpanded }">
                            <span class="message-text" v-html="message"></span>
                            <button
                                :id="formField.name + '-message-close'"
                                class="message-close"
                                @click="closeMessage()"
                            >
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L13 13M13 1L1 13"
                                        stroke="#FFFFFF"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div v-if="showRestrictedMessage" class="message">
                            <span class="message-text" v-html="restrictedMessage"></span>
                            <button
                                :id="formField.name + '-restricted-message-close'"
                                class="message-close"
                                @click="closeRestrictedMessage()"
                            >
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L13 13M13 1L1 13"
                                        stroke="#FFFFFF"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div
                            v-if="showMaxCountMessage"
                            class="message mobile-expanded"
                            :class="{ expanded: messageIsExpanded }"
                        >
                            <span class="message-text" v-html="maxCountMessage"></span>
                            <button
                                :id="formField.name + '-max-count-message-close'"
                                class="message-close"
                                @click="closeMessage()"
                            >
                                <svg
                                    width="10"
                                    height="10"
                                    viewBox="0 0 14 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L13 13M13 1L1 13"
                                        stroke="#FFFFFF"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div class="apply-button-container">
                            <button
                                :id="formField.name + '-close'"
                                class="button red"
                                :disabled="hasErrors"
                                @click="apply()"
                            >
                                <span>{{ translate('btar_apply') }}</span>
                            </button>
                        </div>
                    </app-popup>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.multi-counter {
    &.invalid {
        .wrapper {
            .select {
                .button {
                    border-color: var(--component-color-border-error);
                }
            }
        }

        &.error {
            :deep(input) {
                border-color: var(--black-200);
            }
        }
    }

    .button {
        .age-group-slot {
            font-size: var(--font-size-nano);
            color: var(--black-600);
            width: 70%;

            @include respond-above('sm') {
                font-size: var(--font-size-pico);
            }
        }

        .text {
            overflow: visible;
            text-align: left;

            .range {
                margin-left: var(--size-tiny);
                color: var(--text-color-subtlest);
            }
        }
    }
}

.disabled {
    .wrapper {
        > .select {
            pointer-events: none;

            > button {
                background-color: var(--component-color-background-disabled);

                .text {
                    color: var(--component-color-text-value);
                }
            }
        }
    }
}

.travel-insurance {
    .multi-counter {
        .hide-on-mobile {
            display: none;

            @include respond-above('sm') {
                display: block;
            }
        }
    }
}
</style>
