export class CSSLoader {
    private filesUrlList: string[] = [];

    public constructor(filesUrlList: string[]) {
        this.filesUrlList = filesUrlList;
    }

    public load(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.loadAllFilesSequentially()
                .then(() => {
                    resolve();
                })
                .catch((reason) => {
                    reject(reason);
                });
        });
    }

    private loadAllFilesSequentially(): Promise<void> {
        const linkElements: HTMLLinkElement[] = [].slice.call(document.getElementsByTagName('link'));
        const filesUrls = this.filesUrlList.filter((fileUrl) => {
            return !linkElements.some((tag) => tag.href.indexOf(fileUrl) !== -1);
        });
        return filesUrls.reduce(async (previousPromise, fileUrl) => {
            await previousPromise;
            return this.loadFile(fileUrl);
        }, Promise.resolve());
    }

    private loadFile(fileUrl: string): Promise<void> {
        const headElements = document.head;
        return new Promise((resolve, reject) => {
            const linkElements = document.createElement('link');
            linkElements.href = fileUrl;
            linkElements.rel = 'stylesheet';
            linkElements.type = 'text/css';
            linkElements.onload = () => {
                resolve();
            };
            linkElements.onerror = () => {
                reject("Can't load style: " + fileUrl);
            };
            headElements.appendChild(linkElements);
        });
    }
}
