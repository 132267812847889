import AppPopup from '@/Components/Popups/Popup/Popup.vue';
import AppPopupFilter from '@/Components/Popups/PopupFilter/PopupFilter.vue';
import AppPopupSelector from '@/Components/Popups/PopupSelector/PopupSelector.vue';
import AppPopupOpener from '@/Components/Popups/PopupOpener/PopupOpener.vue';
import AppCoveredPopupSingle from '@/Components/Popups/CoveredPopup/CoveredPopupSingle.vue';
import AppCoveredPopupMultiple from '@/Components/Popups/CoveredPopup/CoveredPopupMultiple.vue';
import AppCoveredPopupBicycleSingle from '@/Components/Popups/CoveredPopup/CoveredPopupBicycleSingle.vue';
import AppCoveredPopupBicycleMulti from '@/Components/Popups/CoveredPopup/CoveredPopupBicycleMulti.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppPopup: AppPopup,
    AppPopupFilter: AppPopupFilter,
    AppPopupSelector: AppPopupSelector,
    AppPopupOpener: AppPopupOpener,
    AppCoveredPopupSingle: AppCoveredPopupSingle,
    AppCoveredPopupMultiple: AppCoveredPopupMultiple,
    AppCoveredPopupBicycleSingle: AppCoveredPopupBicycleSingle,
    AppCoveredPopupBicycleMulti: AppCoveredPopupBicycleMulti,
} as Components;
