import { AutoForms, useAutoForms } from '@/pages/Auto/Composables/AutoForms';

export default class AutoFormsService {
    private static instance: AutoForms;

    private constructor() {}

    public static getInstance(): AutoForms {
        if (!AutoFormsService.instance) {
            AutoFormsService.instance = useAutoForms();
        }

        return AutoFormsService.instance;
    }
}
