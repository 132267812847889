<script lang="ts">
import { computed, defineComponent, Ref, ref, nextTick } from 'vue';
import { StepsSubmitterParams, useStepsSubmitter } from '@/Composables/StepsSubmitter';
import TravelCustomPolicy from '@/pages/Travel/Policy/TravelCustomPolicy';
import CoveredPopupTravel from '@/pages/Travel/Policy/CoveredPopupTravel';
import TravelDataLayer from '@/pages/Travel/TravelDataLayer';
import Form from '@/assets/libraries/form/form';
import CssClass from '@/Enums/CssClassEnum';
import VueEvent from '@/Classes/VueEventClass';
import { PriceParams, usePrice } from '@/Composables/Price';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { TranslateParams, useTranslate } from '@/Composables/Translate';
import { DefineParams, useDefine } from '@/Composables/Define';
import FormField from '@/assets/libraries/form/form-field';
import Method from '@/Enums/MethodEnum';
import Url from '@/Enums/UrlEnum';
import ErrorType from '@/Enums/ErrorTypeEnum';
import { AxiosParams, useAxios } from '@/Composables/Axios';
import OneBase from '@/interfaces/OneBaseInterface';
import OneBaseService from '@/services/OneBaseService';
import { InputOption } from '@/interfaces/InputOptionInterface';
import SubmitterUrls from '@/services/SubmitterUrls.service';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import { useTransforms } from '@/Composables/Transforms';
import { Subscription } from 'rxjs';
import TravelSteps from '@/pages/Travel/Classes/TravelSteps';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();

        const stepsSubmitter: StepsSubmitterParams = useStepsSubmitter();
        const { price, sparse }: PriceParams = usePrice();
        const { translate, translateForType, hasLocalization }: TranslateParams = useTranslate();
        const { isSet }: DefineParams = useDefine();
        const request: AxiosParams = useAxios();

        const CurrentStep: number = 2;
        const OptionsStep: number = 3;
        const LastProduct: number = 2;
        const TranslationType: string = 'travel';
        const customPolicy: TravelCustomPolicy = new TravelCustomPolicy();
        const coveredPopup: CoveredPopupTravel = new CoveredPopupTravel();
        const dataLayer: TravelDataLayer = new TravelDataLayer();

        const form: Form = new Form();
        const policyPlan: Ref<string> = ref('');
        const visibleMobilePlan: Ref<string> = ref('');
        const useCustomPolicy: Ref<boolean> = ref(false);
        const customPolicyEnabled: Ref<boolean> = ref(false);
        const badges: Ref<DynamicDictionary[]> = ref([]);
        const policyPlanIsSelected: Ref<boolean> = ref(false);
        const dbDiscount: Ref<number> = ref(0);

        const nextStep: Ref<number> = ref(4);
        const nextStepUrl: Ref<string> = ref('');
        const activeRisks: Ref<DynamicDictionary> = ref({});
        const v2PoliciesEnabled: Ref<boolean> = ref(false);
        const isAppReady: Ref<boolean> = ref(false);

        const travelersCount: Ref<number> = computed(() => {
            let result: number = 0;
            if (isSet(btaBase.userStorage.stepStorageData.travelers)) {
                const travelers: DynamicDictionary[] = JSON.parse(btaBase.userStorage.stepStorageData.travelers);
                result = travelers.reduce(
                    (total: number, current: DynamicDictionary): number => total + current.count,
                    0,
                );
            }

            return result;
        });

        const hasActiveDbDiscount: Ref<boolean> = computed(() => {
            return dbDiscount.value > 0;
        });

        const policyPlanLocalized: Ref<string> = computed(() => {
            let result: string = '';
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((value: any) => {
                    if (value.id === policyPlan.value) {
                        result = hasLocalization(policyPlan.value, TranslationType)
                            ? translateForType(policyPlan.value, TranslationType)
                            : value.name;
                    }
                });
            }

            return result;
        });

        function applyDbDiscount(discount: number): void {
            dbDiscount.value = discount;
        }

        function applyNextStepUrl(value: string) {
            nextStepUrl.value = value;
        }

        function applyActiveRisks(value: DynamicDictionary) {
            activeRisks.value = value;
        }

        function policyPlanButtonClasses(newPolicyPlan: string): string {
            let result: string = CssClass.Red;
            if (policyPlan.value === newPolicyPlan) {
                result = CssClass.Hidden;
            } else {
                if (policyPlanIsSelected.value) {
                    result = CssClass.Outside;
                }
            }

            return result;
        }

        function policyPlanClasses(newPolicyPlan: string): string {
            const result: string[] = [];
            if (policyPlan.value === newPolicyPlan) {
                result.push(CssClass.Active);
            }
            if (btaBase.isVerticalMode.value) {
                if (visibleMobilePlan.value !== newPolicyPlan) {
                    result.push(CssClass.Hidden);
                }
            }

            return result.join(' ');
        }

        function miniPlanClasses(policy: string): string {
            const result: string[] = [];
            if (visibleMobilePlan.value === policy) {
                result.push(CssClass.Active);
            }

            return result.join(' ');
        }

        function selectPolicyPlan(event: VueEvent): void {
            policyPlan.value = event.params.id;
            form.touch();
        }

        function selectAndSubmit(event: VueEvent): void {
            policyPlan.value = event.params.id;
            submit();
        }

        function showPlanOnMobile(event: VueEvent): void {
            visibleMobilePlan.value = event.params.id;
        }

        function showPolicyPlanCovered(event: VueEvent): void {
            showCoveredPopup(event.params.id);
        }

        function showSelectedPlanCovered(): void {
            if (policyPlan.value !== '') {
                showCoveredPopup(policyPlan.value);
            }
        }

        function enableCustomPolicyPlan(): void {
            customPolicyEnabled.value = true;
        }

        function selectCustomPolicyPlan(event: VueEvent): void {
            policyPlan.value = event.params.id;
        }

        function formattedPrice(targetPrice: string | number): string {
            return price(targetPrice, false);
        }

        function sparsePrice(targetPrice: number, noCents: boolean = false): string {
            return sparse(targetPrice, noCents);
        }

        function policyPlanPrice(targetPrice: number, currency: string): string {
            let result: string = '<span class="zero-price"></span>';
            if (targetPrice > 0) {
                result = '<span>' + sparse(targetPrice, true) + '</span><span>' + currency + '</span>';
            }

            return result;
        }

        function productPriceById(productId: string): string {
            let result: string = '';
            if (!btaBase.userStorage.storageIsEmpty) {
                btaBase.userStorage.storageData.forEach((product: any) => {
                    if (product.id === productId) {
                        result = btaBase.user.isLogged() ? product.authenticatedPrice : product.guestPrice;
                    }
                });
                result = sparse(parseFloat(result), false);
            }

            return result;
        }

        function policyPlanBadgeIsVisible(index: number): boolean {
            let result: boolean;
            if (btaBase.isVerticalMode.value) {
                result = true;
            } else {
                result = index === 1;
            }

            return result;
        }

        function basePrice(productId: string): string {
            const percentage: number = 100;
            const productPrice: number = Number(productPriceById(productId).split(' ').join(''));

            return sparsePrice((productPrice * percentage) / (percentage - activeDiscount(productId)));
        }

        function activeDiscount(productId: string): number {
            let discount: number = 0;
            if (hasActiveDbDiscount.value) {
                discount = dbDiscount.value;
            } else {
                if (!btaBase.userStorage.storageIsEmpty) {
                    const currentProduct: DynamicDictionary = btaBase.userStorage.storageData.filter(
                        (product: DynamicDictionary) => product.id === productId,
                    );
                    discount = btaBase.user.isLogged()
                        ? currentProduct[0].authenticatedDiscountPercent
                        : currentProduct[0].guestDiscountPercent;
                }
            }

            return discount;
        }

        function discountText(productId: string): string {
            return [translate('btar_policy_discount_save'), activeDiscount(productId) + '%'].join(' ');
        }

        function isProductDiscountAvailable(policyPlanId: string): boolean {
            return hasActiveDbDiscount.value || planHasActiveJsonDiscount(policyPlanId);
        }

        function planHasActiveJsonDiscount(policyPlanId: string): boolean {
            let result: boolean = false;
            if (!btaBase.userStorage.storageIsEmpty) {
                const productsWithDiscount: DynamicDictionary[] = btaBase.userStorage.storageData.filter(
                    (product: DynamicDictionary) => {
                        const productDiscount: number = btaBase.user.isLogged()
                            ? product.authenticatedDiscountPercent
                            : product.guestDiscountPercent;
                        return isSet(productDiscount) && productDiscount > 0 && product.id === policyPlanId;
                    },
                );
                result = productsWithDiscount.length > 0;
            }

            return result;
        }

        function applyBadges(newBadges: string): void {
            badges.value = JSON.parse(useTransforms().transformedVueSafeString(newBadges));
        }

        function multiSumOptions(sums: string[]): InputOption[] {
            const result: InputOption[] = [];
            sums.forEach((value: string) => {
                const money: string = sparse(parseInt(value, 10), true);
                result.push(
                    new (class implements InputOption {
                        public name: string = money + '&nbsp;&euro;';
                        public value: string | number | boolean = String(value);
                    })(),
                );
            });

            return result;
        }

        function enableCustomBlock(): void {
            useCustomPolicy.value = true;
        }

        function submit(): void {
            dataLayer.pushDataLayer(policyPlan.value);
            nextTick(() => {
                prepareSubmit();
                if (btaBase.settings.metaApiEnabled()) {
                    sendMetaEvent();
                }
                if (redirectToAdditionalRisks()) {
                    nextStep.value = OptionsStep;
                    stepsSubmitter.addSubmitCustomParam('additionalOptions', additionalOptions());
                    SubmitterUrls.getInstance().applyNextStepUrl(nextStepUrl.value);
                }
                stepsSubmitter.proceedStep('', nextStep.value);
            });
        }

        function additionalOptions(): string[] {
            const coverageId: string = policyPlan.value === '' ? visibleMobilePlan.value : policyPlan.value;

            return activeRisks.value[coverageId];
        }

        function redirectToAdditionalRisks(): boolean {
            const coverageId: string = policyPlan.value === '' ? visibleMobilePlan.value : policyPlan.value;
            return !!activeRisks.value[coverageId] && (activeRisks.value[coverageId] as []).length > 0;
        }

        function onUserStorageReady(): void {
            btaBase.userStorage.storageData.forEach((product: any, index: number): void => {
                product.featuredRisks.forEach((risk: DynamicDictionary): void => {
                    if (index === LastProduct && isSet(risk.sums)) {
                        const fieldName: string = String(product.id + '_' + risk.id);
                        form.addField(new FormField(fieldName, String(risk.sum)));
                    }
                });
            });
        }

        function onMultiSumChange(currentValue: any): void {
            if (currentValue && isAppReady.value) {
                doAdditionalSubmit();
            }
        }

        function prepareSubmit(): void {
            stepsSubmitter.addSubmitCustomParam('policyPlanId', policyPlan.value);
            stepsSubmitter.addSubmitCustomParams(btaBase.userStorage.stepStorageData);
            prepareCustomSums();
        }

        function prepareCustomSums(): void {
            const customSums: DynamicDictionary = {};
            const productCustomSum: DynamicDictionary = {};
            btaBase.userStorage.storageData.forEach((product: DynamicDictionary) => {
                product.featuredRisks.forEach((risk: DynamicDictionary) => {
                    if (isSet(risk.sums)) {
                        const fieldName: string = String(product.id + '_' + risk.id);
                        const value: number = Number(form.field(fieldName).value);
                        if (value !== risk.sum) {
                            customSums[risk.id] = value;
                            if (policyPlan.value === product.id) {
                                productCustomSum[product.id] = btaBase.user.isLogged()
                                    ? product.authenticatedPrice
                                    : product.guestPrice;
                            }
                        }
                    }
                });
            });
            if (useDefine().assocArrayLength(customSums) > 0) {
                stepsSubmitter.addSubmitCustomParam('customSums', JSON.stringify(customSums));
            }
            if (useDefine().assocArrayLength(productCustomSum) > 0) {
                stepsSubmitter.addSubmitCustomParam('productCustomSum', JSON.stringify(productCustomSum));
            }
        }

        function doAdditionalSubmit(): void {
            prepareAdditionalSubmit();
            stepsSubmitter.submitMethod(Method.Get);
            stepsSubmitter.addAdditionalRequestCallback(onAdditionalRequestReceive);
            stepsSubmitter.submitAdditionalRequest(Url.Ajax.travelInsurance, false);
        }

        function prepareAdditionalSubmit(): boolean {
            stepsSubmitter.clearParams();
            stepsSubmitter.clearCustomParams();
            stepsSubmitter.addSubmitParam('destination', btaBase.userStorage.stepStorageData.destination, false);
            stepsSubmitter.addSubmitParam(
                'travelStartDate',
                btaBase.userStorage.stepStorageData.travelStartDate,
                false,
            );
            stepsSubmitter.addSubmitParam('multipleTrips', btaBase.userStorage.stepStorageData.multipleTrips, false);
            stepsSubmitter.addSubmitParam('travelers', btaBase.userStorage.stepStorageData.travelers, false);
            stepsSubmitter.addSubmitParam('activityType', btaBase.userStorage.stepStorageData.activityType, false);
            stepsSubmitter.addSubmitCustomParam('activityName', btaBase.userStorage.stepStorageData.activityName);
            stepsSubmitter.addSubmitCustomParam('multipleTrips', btaBase.userStorage.stepStorageData.multipleTrips);
            stepsSubmitter.addSubmitParam('travelEndDate', btaBase.userStorage.stepStorageData.travelEndDate, false);
            if (isSet(btaBase.userStorage.stepStorageData.tripDuration)) {
                stepsSubmitter.addSubmitParam('tripDuration', btaBase.userStorage.stepStorageData.tripDuration, false);
            }
            if (isSet(btaBase.userStorage.stepStorageData.activityStartDate)) {
                stepsSubmitter.addSubmitParam(
                    'activityStartDate',
                    btaBase.userStorage.stepStorageData.activityStartDate,
                    false,
                );
            }
            if (isSet(btaBase.userStorage.stepStorageData.activityEndDate)) {
                stepsSubmitter.addSubmitParam(
                    'activityEndDate',
                    btaBase.userStorage.stepStorageData.activityEndDate,
                    false,
                );
            }
            let result: boolean = false;
            const customSums: any = {};
            let productId: string = '';
            btaBase.userStorage.storageData.forEach((product: any, index: number): void => {
                product.featuredRisks.forEach((risk: DynamicDictionary) => {
                    if (index === LastProduct && isSet(risk.sums)) {
                        const fieldName: string = String(product.id + '_' + risk.id);
                        customSums[risk.id] = String(form.field(fieldName).value);
                        productId = product.id;
                        if (useDefine().assocArrayLength(customSums) > 0) {
                            result = true;
                            stepsSubmitter.addSubmitCustomParam('customSums', JSON.stringify(customSums));
                            stepsSubmitter.addSubmitParam('recalculate', 'true', false);
                            stepsSubmitter.addSubmitCustomParam('product', productId);
                        }
                    }
                });
            });
            if (v2PoliciesEnabled.value) {
                stepsSubmitter.addSubmitParam('travelTarget', btaBase.userStorage.stepStorageData.travelTarget, false);
            }

            return result;
        }

        function enablePoliciesV2(): void {
            v2PoliciesEnabled.value = true;
        }

        function onAdditionalRequestReceive(value: DynamicDictionary): void {
            if (value.data.length > 0) {
                const index: number = value.data.length - 1;
                const productId: string = value.data[index].id;
                const authenticatedPrice: string = value.data[index].authenticatedPrice;
                const guestPrice: string = value.data[index].guestPrice;
                btaBase.userStorage.storageData.forEach((product: any) => {
                    if (product.id === productId) {
                        product.authenticatedPrice = authenticatedPrice;
                        product.guestPrice = guestPrice;
                    }
                });
            } else {
                btaBase.error.show(
                    ErrorType.Error,
                    btaBase.facility() + '::onAdditionalRequestReceive()',
                    'no_products_updated',
                );
            }
        }

        function showCoveredPopup(id: string): void {
            prepareCoveredPopup(id);
            PopupService.getInstance().show(new OnePopup().withType().coveredTravel);
            nextTick(() => {
                btaBase.initPopupTooltips();
            });
        }

        function prepareCoveredPopup(id: string): void {
            coveredPopup.build(id, badges.value);
        }

        function setupForm(): void {
            if (useCustomPolicy.value) {
                customPolicy.setupCustomPolicyForm();
            }
            form.setReady();
        }

        function onBeforeFormRestored(): void {
            if (Array.isArray(btaBase.userStorage.storageData)) {
                stepsSubmitter.submitMethod(Method.Get);
                prepareAdditionalSubmit();
                stepsSubmitter.storeInitialCacheForUrl(Url.Ajax.travelInsurance);
            }
        }

        function onAfterFormRestored(): void {
            form.validate().then();
            btaBase.userStorage.storageData.forEach((value: DynamicDictionary, index: number): void => {
                if (index === 1) {
                    visibleMobilePlan.value = value.id;
                }
            });
            let additionalSumsHasChanges: boolean = false;
            btaBase.userStorage.storageData.forEach((product: DynamicDictionary, index: number): void => {
                product.featuredRisks.forEach((risk: DynamicDictionary): void => {
                    if (index === LastProduct && isSet(risk.sums)) {
                        const fieldName: string = String(product.id + '_' + risk.id);
                        if (String(form.field(fieldName).value) !== String(risk.sum)) {
                            additionalSumsHasChanges = true;
                        }
                    }
                });
            });
            if (additionalSumsHasChanges) {
                doAdditionalSubmit();
            }
        }

        function onAppReady(): void {
            isAppReady.value = true;
            btaBase.dynamicStepper.applyEnabled(TravelSteps.default());
        }

        function sendMetaEvent(): void {
            request
                .post(Url.Ajax.addToCartEvent, {
                    productCategory: 'TravelInsurance',
                    productName: 'Travel',
                    productIds: stepsSubmitter.getAllParams()['customParam[policyPlanId]'],
                    policyPrice: productPriceById(policyPlan.value),
                })
                .then();
        }

        function priceDescription(): string {
            return travelersCount.value > 1
                ? translateForType('price_for_all_travelers', TranslationType)
                : translateForType('price_for_one_traveler', TranslationType);
        }

        function coverageDescription(): string {
            return travelersCount.value > 1
                ? translateForType('coverage_for_all_travelers', TranslationType)
                : translateForType('coverage_for_one_traveler', TranslationType);
        }

        return {
            ...btaBase,
            ...{
                form,
                priceDescription,
                coverageDescription,
                policyPlan,
                coveredPopup,
                CurrentStep,
                customPolicy,
                applyDbDiscount,
                policyPlanButtonClasses,
                policyPlanClasses,
                selectPolicyPlan,
                selectAndSubmit,
                showPlanOnMobile,
                showPolicyPlanCovered,
                showSelectedPlanCovered,
                enableCustomPolicyPlan,
                selectCustomPolicyPlan,
                formattedPrice,
                sparsePrice,
                policyPlanPrice,
                productPriceById,
                policyPlanBadgeIsVisible,
                basePrice,
                activeDiscount,
                discountText,
                isSet,
                isProductDiscountAvailable,
                planHasActiveJsonDiscount,
                applyBadges,
                multiSumOptions,
                enableCustomBlock,
                submit,
                onUserStorageReady,
                onMultiSumChange,
                miniPlanClasses,
                setupForm,
                onBeforeFormRestored,
                onAfterFormRestored,
                onAppReady,
                policyPlanLocalized,
                applyNextStepUrl,
                applyActiveRisks,
                enablePoliciesV2,
            },
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();

        this.setStep(this.CurrentStep);
        this.setFacility('one-travel');
        this.setStorageUsage(true);
        this.customPolicy.init(this.form);
        this.coveredPopup.init(this.form);
        this.setupForm();
        const onStorageReadySubscription = this.userStorage.onStorageDataIsReady.subscribe(() => {
            this.onUserStorageReady();
            onStorageReadySubscription.unsubscribe();
        });
        const onBeforeFormRestoredSubscription = this.userStorage.onBeforeFormStorageDataIsRestored.subscribe(() => {
            this.onBeforeFormRestored();
            onBeforeFormRestoredSubscription.unsubscribe();
        });
        const onAfterFormRestoredSubscription = this.userStorage.onFormStorageDataIsReady.subscribe(() => {
            this.onAfterFormRestored();
            onAfterFormRestoredSubscription.unsubscribe();
        });
        const onAppIsPreparedAndReady: Subscription = this.onAppIsPreparedAndReady.subscribe((): void => {
            this.onAppReady();
            onAppIsPreparedAndReady.unsubscribe();
        });
    },
});
</script>
