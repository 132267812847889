import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import AgreementTypes from '@/Components/Policies/PolicyBlock/Enums/AgreementTypesEnum';
import { ref, Ref } from 'vue';
import ClaimDetailsContent from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContent';
import ClaimDetailsContentTabs from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContentTabs';
import ClaimDetailsContentTab from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContentTab';
import DrawerContent from '@/Components/Modals/Drawer/Interfaces/drawer.content.interface';
import ClaimDetails from '@/Components/Claims/ClaimDetails/Services/ClaimDetailsService';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';

export default class ClaimDetailsContentBuilder {
    private drawerContent: DrawerContent = new (class implements DrawerContent {
        public component: string = 'AppClaimDetails';
        public content: DynamicDictionary = new (class implements ClaimDetailsContent {
            public type: string = '';
            public title: string = '';
            public description: string = '';
            public claim!: ClaimDetailsContent;
            public tabs: ClaimDetailsContentTabs = new (class implements ClaimDetailsContentTabs {
                public active: Ref<number> = ref(0);
                public elements: ClaimDetailsContentTab[] = [];
            })();
        })();
        public translationsType: string = '';
    })();

    public withTranslationsType(translationsType: string): ClaimDetailsContentBuilder {
        this.drawerContent.translationsType = translationsType;

        return this;
    }

    public withClaim(value: Claim): ClaimDetailsContentBuilder {
        this.drawerContent.content.claim = value;

        return this;
    }

    public withType(type: AgreementTypes): ClaimDetailsContentBuilder {
        this.drawerContent.content.type = type;

        return this;
    }

    public withTitle(title: string): ClaimDetailsContentBuilder {
        this.drawerContent.content.title = title;

        return this;
    }

    public withDescriptionTitle(title: string): ClaimDetailsContentBuilder {
        this.drawerContent.content.description = title;

        return this;
    }

    public withTabs(tabs: ClaimDetailsContentTab[]): ClaimDetailsContentBuilder {
        this.drawerContent.content.tabs.elements = tabs;

        return this;
    }

    public withActiveTab(activeTabId: number): ClaimDetailsContentBuilder {
        this.drawerContent.content.tabs.active.value = activeTabId;

        return this;
    }

    public build(): DrawerContent {
        ClaimDetails.getInstance().addContent(this.drawerContent.content as ClaimDetailsContent);

        return this.drawerContent;
    }
}
