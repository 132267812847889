import { LimitedVariant } from '@/Types/LimitedVariantType';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export default class Debounce {
    private readonly DefaultTimeout: number = 200;
    private timeout: number = 0;
    private static instance: Debounce;

    private constructor() {}

    public static getInstance(): Debounce {
        if (!Debounce.instance) {
            Debounce.instance = new Debounce();
        }

        return Debounce.instance;
    }

    public applyTimeout(timeout: number): Debounce {
        this.timeout = timeout;

        return this;
    }

    public applyDebounce(callback: Function, context: DynamicDictionary | null = null): Function {
        let timer: NodeJS.Timeout;

        return (...args: LimitedVariant[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                if (context) {
                    callback.apply(context, args);
                } else {
                    callback(args);
                }
            }, this.timeoutValue());
        };
    }

    public applyDebouncePromise(callback: Function, context: DynamicDictionary): Function {
        let timer: NodeJS.Timeout;
        let resolves: Function[] = [];

        return (...args: LimitedVariant[]) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const result: DynamicDictionary = callback.apply(context, args);
                resolves.forEach((r) => r(result));
                resolves = [];
            }, this.timeoutValue());

            return new Promise((r) => resolves.push(r));
        };
    }

    private timeoutValue(): number {
        return this.timeout > 0 ? this.timeout : this.DefaultTimeout;
    }
}
