import { App, Component, createApp } from 'vue';
import { ComponentPublicInstance } from 'vue';

export default class VueModule<T extends Component> {
    public constructor(private readonly appComponent: T) {}

    public mount(): ComponentPublicInstance<T> {
        const container: HTMLDivElement = document.createElement('div');
        (this.appComponent as any).render = (): string => '';
        const app: App = createApp(this.appComponent);
        return app.mount(container) as ComponentPublicInstance<T>;
    }
}
