<script setup lang="ts">
import { computed, Ref } from 'vue';
import ContactUsButton from '@/Components/Other/ContactUs/Interfaces/ContactUsButtonInterface';
import ContactusButtonType from '@/Components/Other/ContactUs/Enums/ContactusButtonTypeEnum';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import ButtonBackground from '@/Components/Buttons/ButtonWithCallback/Enums/button.background.enum';
import ButtonBorder from '@/Components/Buttons/ButtonWithCallback/Enums/button.border.enum';
import ButtonIcon from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.enum';
import ButtonIconColor from '@/Components/Buttons/ButtonWithCallback/Enums/button.icon.color.enum';

const props = defineProps({
    title: { type: String, default: '' },
    buttons: {
        type: Array,
        default: () => {
            return [];
        },
    },
});

const urlButtons: Ref<ContactUsButton[]> = computed(() => {
    return props.buttons.filter((button) => {
        return (button as ContactUsButton).type === ContactusButtonType.Url;
    }) as ContactUsButton[];
});

const mailButtons: Ref<ContactUsButton[]> = computed(() => {
    return props.buttons.filter((button) => {
        return (button as ContactUsButton).type === ContactusButtonType.Mail;
    }) as ContactUsButton[];
});

const phoneButtons: Ref<ContactUsButton[]> = computed(() => {
    return props.buttons.filter((button) => {
        return (button as ContactUsButton).type === ContactusButtonType.Phone;
    }) as ContactUsButton[];
});

const buttonParams: ButtonWithCallbackParams = {
    backgroundColor: ButtonBackground.White,
    backgroundColorHover: ButtonBackground.White,
    borderColor: ButtonBorder.Transparent,
    borderColorHover: ButtonBorder.Grey,
    iconColor: ButtonIconColor.Green,
};

const buttonUrlParams: ButtonWithCallbackParams = Object.assign({}, buttonParams);
</script>

<template>
    <div class="contact-us">
        <div class="title">{{ props.title }}</div>
        <div class="buttons">
            <app-button-with-callback
                v-for="(button, index) in urlButtons"
                :key="'url-' + index"
                v-bind="buttonUrlParams"
                :title="button.title"
                :url="button.actions"
                :icon="ButtonIcon.World"
            ></app-button-with-callback>
            <app-button-with-callback
                v-for="(button, index) in mailButtons"
                :key="'mail-' + index"
                v-bind="buttonUrlParams"
                :title="button.title"
                :url="'mailto:' + button.actions"
                :icon="ButtonIcon.Mail"
            ></app-button-with-callback>
            <app-button-with-callback
                v-for="(button, index) in phoneButtons"
                :key="'phone-' + index"
                v-bind="buttonUrlParams"
                :title="button.title"
                :url="'tel:' + button.actions"
                :icon="ButtonIcon.Phone"
            ></app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.contact-us {
    border-radius: var(--size-tiny);
    background-color: var(--background-light);
    padding: var(--size-small);
    width: 100%;
    max-width: 656px;

    .title {
        font-size: var(--font-size-normal);
        color: var(--button-color-background-primary-green-hover);
        font-weight: 600;
        line-height: var(--line-height-accented);
    }

    .buttons {
        display: flex;
        gap: var(--size-pico);
        flex-direction: column;
        margin-top: var(--size-small);

        a {
            min-height: 52px;
            justify-content: flex-start;
            font-size: var(--font-size-nano);
            font-weight: 600;
        }
    }
}
</style>
