import Fieldset from '@/Components/Lists/FieldsetList/Interfaces/Fieldset.interface';
import Field from '@/Components/Lists/FieldsetList/Interfaces/Field.interface';
import FieldsetLayout from '@/Components/Lists/FieldsetList/Enums/FieldsetLayout.enum';

export default class FieldsetBuilder {
    private fieldset: Fieldset = {
        fields: [],
        layout: '',
    };

    public withField(value: Field): FieldsetBuilder {
        this.fieldset.fields.push(value);

        return this;
    }

    public withLayout(value: FieldsetLayout): FieldsetBuilder {
        this.fieldset.layout = value;

        return this;
    }

    public build(): Fieldset {
        return this.fieldset;
    }
}
