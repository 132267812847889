import AccidentClaims from '@/interfaces/url.claims.accident.interface';
import UrlAddress from '@/interfaces/url.address.interface';
import UrlAjax from '@/interfaces/url.address.ajax.interface';
import UrlApi from '@/interfaces/url.address.api.interface';
import UrlSrsStatement from '@/interfaces/url.srs.statement.interface';
import PaymentsApi from '@/interfaces/url.payments.api.interface';
import SubscriptionApi from '@/interfaces/url.subscription.api.interface';

export default class Url {
    public static readonly Ajax: UrlAjax = new (class implements UrlAjax {
        public formStore: string = '/ajax/actions/formStore';
        public stepsStore: string = '/ajax/actions/stepsStore';
        public activeOffersCount: string = '/ajax/actions/offersCount';
        public agreeToTerms: string = '/ajax/actions/agreeToTerms';
        public regions: string = '/ajax/actions/regions';
        public fetchCompanyTypes: string = '/ajax/actions/companyTypes';
        public fetchPaymentMethods: string = '/ajax/actions/paymentMethods';
        public fetchRecurringPaymentCards: string = '/ajax/actions/recurringPaymentCards';
        public fetchAgreeTerms: string = '/ajax/actions/fetchAgreeTerms';
        public repairServices: string = '/ajax/actions/repairServices';
        public partnerServices: string = '/ajax/actions/partnerServices';
        public travelTargetUnmapped: string = '/ajax/actions/travelTargets';
        public addToCartEvent: string = '/ajax/actions/addToCartEvent';
        public viewContentEvent: string = '/ajax/actions/viewContentEvent';
        public invoicePrintout: string = '/ajax/actions/invoicePrintout';
        public menuUrl: string = '/ajax/actions/menuUrl';
        public payByLink: string = '/ajax/payByLink';
        public flashPaymentsGenerate: string = '/ajax/flashPaymentsGenerate';
        public filesByLinkGenerate: string = '/ajax/FilesByLinkGenerateForm/filesByLinkGenerate';
        public photoUpload: string = '/ajax/PurchaseThankYou/photoUpload';
        public autoApi: string = '/ajax/AutoInsurance/api/';
        public autoApiPrimary: string = '/ajax/AutoInsurance/apiPrimary/';
        public autoSummary: string = '/ajax/AutoSummary/summary/';
        public remind: string = '/ajax/AutoPolicy/sendRemindQueryToBta';
        public filesByLinkGetResource: string = '/ajax/files-by-link-upload/filesByLink';
        public submitClaim: string = '/ajax/ClaimsSubmissionForm/submitClaim';
        public submitUpload: string = '/ajax/files-by-link-upload/submitUpload';
        public flashPaymentsLogout: string = '/ajax/flashPaymentsGenerate/logout';
        public dashboardGetClaims: string = '/ajax/dashboard/claims'; //INFO: legacy
        public dashboardGetClaim: string = '/ajax/dashboard/getClaim'; //INFO: legacy
        public oneDashboardInsurances: string = '/ajax/oneDashboard/insurances';
        public oneDashboardGetClaims: string = '/ajax/oneDashboard/claims';
        public oneDashboardGetClaim: string = '/ajax/oneDashboard/getClaim';
        public oneDashboardPayments: string = '/ajax/oneDashboard/payments';
        public oneDashboardPolicyDetails: string = '/ajax/oneDashboard/policyDetails';
        public dashboardGetPolicies: string = '/ajax/dashboard/policies';
        public dashboardGetPolicy: string = '/ajax/dashboard/policy';
        public dashboardCancelSubscription: string = '/ajax/dashboard/cancelSubscription';
        public addAttachmentsToClaimCase: string = '/ajax/dashboard/addAttachmentsToClaimCase'; //INFO: legacy
        public addOneAttachmentsToClaimCase: string = '/ajax/oneDashboard/addAttachmentsToClaimCase';
        public disableOnBoarding: string = '/ajax/dashboard/disableOnBoarding';
        public disableLegalOnBoarding: string = '/ajax/LegalPerson/disableLegalOnBoarding';
        public agreeToHealthTerms: string = '/ajax/dashboard/healthConsent';
        public updateContactInformation: string = '/ajax/dashboard/contactDataConsent';
        public submitGetInTouch: string = '/ajax/get-in-touch/submitGetInTouch';
        public healthInsuranceLimits: string = '/ajax/health-insurance-limits/limits';
        public travelInsurance: string = '/ajax/travel/insurance';
        public travelAdditional: string = '/ajax/travel/additional';
        public travelTraveller: string = '/ajax/travel/traveller';
        public travelSummary: string = '/ajax/travel/summary';
        public healthCards: string = '/ajax/claims-submission-form/healthCards';
        public submitHealthClaim: string = '/ajax/claims-submission-form/submitHealthClaim';
        public propertyInformation: string = '/ajax/property/information';
        public propertyAdditionalInformation: string = '/ajax/property/additionalInformation';
        public propertyAdditionalRisks: string = '/ajax/property/additional';
        public propertySummary: string = '/ajax/property/summary';
        public dashboardChangeCard: string = '/ajax/dashboard/changeCard';
        public dashboardUpdatePaymentCard: string = '/ajax/dashboard/updatePaymentCard';
        public dashboardAddCard: string = '/ajax/dashboard/addCard';
        public dashboardUpdateUser: string = '/ajax/dashboard/updateUser'; //INFO: legacy
        public oneDashboardUpdateUser: string = '/ajax/oneDashboard/updateUser'; //INFO: legacy
        public tripDuration: string = '/ajax/travel/tripDuration/';
        public hasTripType: string = '/ajax/travel/hasTripType/';
        public travelActivities: string = '/ajax/travel/travelActivities/';
        public travelTarget: string = '/ajax/travel/travelTargets/';
        public travelDestinations: string = '/ajax/travel/travelDestinations/';
        public travelDestinationsEstonia: string = '/ajax/travel/travelDestinationsEstonia/';
        public fetchContractualInstitutions: string = '/ajax/ContractualInstitutions/institutions';
        public fetchMedicalInstitutions: string = '/ajax/ContractualInstitutions/medical';
        public happenedWithOptions: string = '/ajax/ClaimsProperty/happenedWithOptions';
        public happenedWithOptionsLtSaikas: string = '/ajax/ClaimsProperty/happenedWithOptionsLtSaikas';
        public eventsByPropertyTypeAndRisks: string = '/ajax/ClaimsProperty/eventsByPropertyTypeAndRisks';
        public claimsPropertyRecipient: string = '/ajax/ClaimsProperty/recipientData';
        public claimsPropertyObjects: string = '/ajax/ClaimsProperty/propertyObjects';
        public claimsPropertyObject: string = '/ajax/ClaimsProperty/propertyObject';
        public claimsCascoObjects: string = '/ajax/ClaimsCasco/insuredObjects';
        public claimsCascoObject: string = '/ajax/ClaimsCasco/insuredObject';
        public claimsCascoAccidentDataInitial: string = '/ajax/ClaimsCasco/claimsCascoAccidentDataInitial';
        public claimsCascoAvailableDamageTypes: string = '/ajax/ClaimsCasco/availableDamageTypes';
        public claimsCascoDamageTypes: string = '/ajax/ClaimsCasco/damageTypes';
        public claimsCascoEnabledPanels: string = '/ajax/ClaimsCasco/enabledPanels';
        public claimsCascoWhatHappened: string = '/ajax/ClaimsCasco/whatHappened';
        public claimsCascoReportTheAccident: string = '/ajax/ClaimsCasco/reportTheAccident';
        public claimsCascoDescriptionOfAccident: string = '/ajax/ClaimsCasco/descriptionOfAccident';
        public claimsCascoSubmitClaim: string = '/ajax/ClaimsCasco/submitClaim';
        public claimsCascoReportedTo: string = '/ajax/ClaimsCasco/reportedTo';
        public selectEntity: string = '/ajax/SelectAccount/selectEntity';
        public legalPersonPolicyDetails: string = '/ajax/LegalPerson/policyDetails';
        public legalPersonDocumentUpload: string = '/ajax/LegalPerson/document';
        public legalPersonEditPersons: string = '/ajax/LegalPerson/editPersons';
        public legalPersonAddPersons: string = '/ajax/LegalPerson/addPersons';
        public legalPersonEditPerson: string = '/ajax/LegalPerson/editPerson';
        public legalPersonDeletePersons: string = '/ajax/LegalPerson/deletePersons';
        public legalPersonExportPersons: string = '/ajax/LegalPerson/exportExcel';
        public accidentSingleDayPurchase: string = '/ajax/AccidentSingleDay/purchase';
        public oneDayPolicies: string = '/ajax/AccidentSingleDay/policies';
        public exitPreviewMode: string = '/ajax/actions/exitPreviewMode';
        public humanBodyInjury: string = '/ajax/actions/humanBodyInjury';
        public accidentType: string = '/ajax/actions/accidentType';
        public movableProperties: string = '/ajax/ItemsAndBelongings/policies';
        public movablePropertiesPurchase: string = '/ajax/ItemsAndBelongings/purchase';
        public bicycles: string = '/ajax/Bicycle/policies';
        public bicyclePolicyPurchase: string = '/ajax/Bicycle/purchase';
        public mtplRenewalEditOffer: string = '/ajax/PayByLink/editOffer';
        public mtplRenewalFetchOffer: string = '/ajax/PayByLink/fetchOffer';
        public mtplRenewalRecalculateOffer: string = '/ajax/PayByLink/recalculateOffer';
        public acceptNameChange: string = '/ajax/oneDashboard/acceptNameChange';
        public declineNameChange: string = '/ajax/oneDashboard/declineNameChange';
        public claimsMtplObjects: string = '/ajax/ClaimsMtpl/insuredObjects';
        public claimsMtplObject: string = '/ajax/ClaimsMtpl/insuredObject';
        public claimsMtplSubmitClaim: string = '/ajax/ClaimsMtpl/submitClaim';
        public solarPanelsProducts: string = '/ajax/SolarPanels/products';
        public solarPanelsPurchase: string = '/ajax/SolarPanels/purchase';
        public renters: string = '/ajax/Renters/products';
        public rentersPurchase: string = '/ajax/Renters/purchase';
        public nextBestAction: string = '/ajax/oneDashboard/nextBestAction';
        public nextBestActionUpdatePopupShownAt: string = '/ajax/oneDashboard/updateNbaPopupShownAt';
        public nestedArticles: string = '/ajax/Articles/nestedArticles';
    })();

    public static AccidentClaims: AccidentClaims = new (class implements AccidentClaims {
        private readonly Prefix: string = '/ajax/claimsAccident';
        public accidentTypes: string = `${this.Prefix}/accidentTypes`;
        public accidentTypesWithRisks: string = `${this.Prefix}/accidentTypesWithRisks`;
        public openPolicies: string = `${this.Prefix}/openPolicies`;
        public policies: string = `${this.Prefix}/policies`;
        public selectedInjuryData: string = `${this.Prefix}/selectedInjuryData`;
        public uploadCategories: string = `${this.Prefix}/uploadCategories`;
        public submit: string = `${this.Prefix}/submitClaim`;
        public accidentDescriptions: string = `${this.Prefix}/accidentDescriptions`;
        public isInjuriesBlockVisible: string = `${this.Prefix}/isInjuriesBlockVisible`;
    })();

    public static PaymentsApi: PaymentsApi = new (class implements PaymentsApi {
        public paymentsPay: string = '/PaymentsApi/pay';
        public paymentsFinishWaiting: string = '/PaymentsApi/finishWaiting';
        public printout: string = '/PaymentsApi/getPrintout';
        public travelPrintouts: string = '/PaymentsApi/getPrintout';
    })();

    public static SubscriptionApi: SubscriptionApi = new (class implements SubscriptionApi {
        public recurringRegister: string = '/SubscriptionApi/register';
        public recurringRegisterWithoutPayment: string = '/SubscriptionApi/registerWithoutPayment';
    })();

    public static Api: UrlApi = new (class implements UrlApi {
        public logout: string = '/api/logout';
    })();

    public static readonly Address: UrlAddress = new (class implements UrlAddress {
        public suggest: string = '/address/suggest/?term=';
        public suggestSettlement: string = '/addressSettlement/suggest/?term=';
        public suggestPostal: string = '/address/getPostalCodes/?term=';
        public administrativeCodes: string = '/address/getAdministrativeCodes';
        public country: string = '/address/getCountry/?lang=';
        public county: string = '/address/getVariants/?kind=D&parent=';
        public municipality: string = '/address/getVariants/?kind=N&parent=';
        public region: string = '/address/getVariants/?kind=N&parent=';
        public city: string = '/address/getVariants/?kind=P&parent=';
        public parish: string = '/address/getVariants/?kind=R&parent=';
        public district: string = '/address/getVariants/?kind=V&parent=';
        public village: string = '/address/getVariants/?kind=V&parent=';
        public street: string = '/address/getVariants/?kind=S&parent=';
        public postal: string = '/address/getPostalCodes/?parent=';
    })();

    public static SrsStatement: UrlSrsStatement = new (class implements UrlSrsStatement {
        public controller: string = 'SrsStatement';
        public checkReport: string = '/' + this.controller + '/checkReport';
        public submit: string = '/' + this.controller + '/submit';
    })();
}
