export default class ItemsHorizontalSlick {
    private breakPointSlides: number = 812;
    private readonly slides: number[] = [];
    private container: string = '.items-horizontal-slick .wrapper .carousel .list';
    private readonly arrowBack: string =
        '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" ' +
        'xmlns="http://www.w3.org/2000/svg"><path d="M7 1L1 7L7 13" ' +
        'stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    private readonly arrowForward: string =
        '<svg width="8" height="14" viewBox="0 0 8 14" fill="none" ' +
        'xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L1 13" ' +
        'stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';

    public constructor() {
        const lastSlide: number = 4;
        this.slides = [1, 2, lastSlide];
    }

    public init(): void {
        const slickElementDom: any = $(this.container);
        const interactionSlides: number = this.slidesCount(this.slides[1], this.slides[2]);
        if (slickElementDom) {
            slickElementDom.slick({
                infinite: false,
                slidesToScroll: interactionSlides - 1,
                slidesToShow: interactionSlides,
                prevArrow: '<button type="button" class="slick-prev">' + this.arrowBack + '</button>',
                nextArrow: '<button type="button" class="slick-next">' + this.arrowForward + '</button>',
                responsive: [
                    {
                        breakpoint: 1100,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 970,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 813,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 648,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });
        }
    }

    private slidesCount(breakpointSm: number, breakpointLg: number): number {
        if (this.windowWidth() <= this.breakPointSlides) {
            return breakpointSm;
        }

        return breakpointLg;
    }

    private windowWidth(): number {
        const widthElementDom: number | undefined = $(window).width();
        if (!widthElementDom || typeof widthElementDom === 'undefined') {
            return 0;
        }

        return widthElementDom;
    }
}
