import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import ClaimDetailsContentTab from '@/Components/Claims/ClaimDetails/Interface/ClaimDetailsContentTab';

export default class ClaimDetailsContentTabsBuilder {
    private currentTab: number = -1;
    private tabs: ClaimDetailsContentTab[] = [];

    public reset(): ClaimDetailsContentTabsBuilder {
        this.tabs = [];

        return this;
    }

    public startNewTab(tabId: string = ''): ClaimDetailsContentTabsBuilder {
        this.currentTab++;
        const id: string = tabId === '' ? String(this.currentTab) : tabId;
        this.tabs.push(
            new (class implements ClaimDetailsContentTab {
                public id: string = id;
                public title: string = '';
                public badge: number = 0;
                public module: string = '';
                public content: DynamicDictionary = {};
            })(),
        );

        return this;
    }

    public withTitle(title: string): ClaimDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].title = title;

        return this;
    }

    public withBadgeValue(badgeValue: number): ClaimDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].badge = badgeValue;

        return this;
    }

    public withModule(module: DynamicDictionary): ClaimDetailsContentTabsBuilder {
        this.checkCurrentTab();
        this.tabs[this.currentTab].module = module.component;
        this.tabs[this.currentTab].content = module.content;

        return this;
    }

    public build(): ClaimDetailsContentTab[] {
        return this.tabs;
    }

    private checkCurrentTab(): void {
        if (this.currentTab === -1) {
            this.startNewTab();
        }
    }
}
