<script setup lang="ts">
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import FormField from '@/assets/libraries/form/form-field';
import AdditionalOptionEmitters from '@/Components/Lists/AdditionalOptionsList/Enums/AdditionalOptionEmitters';
import { PropType } from 'vue';

defineProps({
    formField: { type: FormField, default: () => new FormField('') },
    dataStoreDisabled: { type: Boolean, default: false },
    options: { type: Array as PropType<{ component: string }[]>, default: () => [] },
});

const emit = defineEmits([
    AdditionalOptionEmitters.Toggle,
    AdditionalOptionEmitters.Mounted,
    AdditionalOptionEmitters.Confirm,
    AdditionalOptionEmitters.Edit,
]);

function onOptionToggle(optionEmit: DynamicDictionary): void {
    emit(AdditionalOptionEmitters.Toggle, optionEmit);
}

function onOptionMounted(optionEmit: DynamicDictionary): void {
    emit(AdditionalOptionEmitters.Mounted, optionEmit);
}

function onOptionConfirm(): void {
    emit(AdditionalOptionEmitters.Confirm);
}

function onOptionCancel(): void {
    emit(AdditionalOptionEmitters.Cancel);
}

function onOptionEdit(): void {
    emit(AdditionalOptionEmitters.Edit);
}

function onOptionClick(): void {
    emit(AdditionalOptionEmitters.Click);
}
</script>

<template>
    <div
        :id="formField.name"
        class="additional-options-list"
        :class="{ ...formField.classes() }"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="list-container">
            <component
                :is="option.component"
                v-for="(option, index) in options"
                :id="'additional-option-' + index"
                :key="index"
                :option="option"
                :data-store-disabled="true"
                @option-toggle="onOptionToggle($event)"
                @option-mounted="onOptionMounted($event)"
                @option-confirm="onOptionConfirm"
                @option-cancel="onOptionCancel"
                @option-edit="onOptionEdit"
                @option-click="onOptionClick"
            >
            </component>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.additional-options-list {
    width: 100%;

    .list-container {
        gap: var(--size-normal);
        display: flex;
        flex-direction: column;
    }
}
</style>
