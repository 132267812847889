<script setup lang="ts">
import { computed, PropType, Ref } from 'vue';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import { useTranslate } from '@/Composables/Translate';
import { ClaimStatus } from '@/Components/Claims/ClaimBlock/Enums/ClaimStatus.enum';
import { usePrice } from '@/Composables/Price';
import ButtonWithCallbackParams from '@/Components/Buttons/ButtonWithCallback/Enums/button.params';
import Claim from '@/Components/Claims/ClaimBlock/Interfaces/ClaimInterface';
import { useDefine } from '@/Composables/Define';

const props = defineProps({
    item: {
        type: Object as PropType<Claim>,
        default: () => {
            return {};
        },
    },
    buttons: {
        type: Array as PropType<ButtonWithCallbackParams[]>,
        default: () => {
            return [];
        },
    },
    translationType: { type: String, default: '' },
});

const { translateForType, translate } = useTranslate();
const { price } = usePrice();
const { isSet } = useDefine();

const submitDate: Ref<string> = computed(() => {
    return translated('submit_date', { '%date%': props.item.submitDate });
});

const claimNumber: Ref<string> = computed(() => {
    return translated('claim_number', { '%number%': props.item.claimNumber });
});

const compensation: Ref<string> = computed(() => {
    return translated('compensation_amount', { '%amount%': price(props.item.sumApproved as string, false, 'EUR') });
});

const statusClass: Ref<string> = computed(() => {
    let result: string;
    switch (props.item.status) {
        case ClaimStatus.Approved:
        case ClaimStatus.DecisionDone:
            result = 'teal';
            break;
        case ClaimStatus.Declined:
        case ClaimStatus.DocumentsAwaited:
            result = 'red';
            break;
        case ClaimStatus.InProgress:
        case ClaimStatus.Registered:
        case ClaimStatus.InClaimHandling:
            result = 'orange';
            break;
        case ClaimStatus.Submitted:
            result = 'violet';
            break;
        default:
            result = 'default';
    }

    return result;
});

function isSumEmpty(item: Claim): boolean {
    return isSet(item.sumApproved) && item.sumApproved !== '0';
}

function translated(key: string, replacements?: DynamicDictionary): string {
    return translateForType(key, props.translationType, replacements);
}
</script>

<template>
    <div v-if="item" class="claims-block">
        <div class="info-wrapper">
            <div class="status-info">
                <span class="status-badge" :class="statusClass" v-html="translate(item.status)"> </span>
                <span class="submit-date sub-title" v-html="submitDate"> </span>
                <div v-if="item.attachments && item.attachments.length > 0" class="attachments">
                    <img src="images/one/components/claims/paperclip.svg" alt="" />
                    <span class="sub-title" v-html="item.attachments.length"></span>
                </div>
            </div>
            <div class="claim-info">
                <span class="name title" v-html="item.title"></span>
                <span v-if="item.reason" class="reason sub-title" v-html="item.reason"></span>
                <span v-if="isSumEmpty(item)" class="compensation sub-title" v-html="compensation"></span>
                <span class="claim-no sub-title" v-html="claimNumber"></span>
            </div>
        </div>
        <div class="buttons">
            <app-button-with-callback v-for="(button, index) in buttons" :key="index" v-bind="button">
            </app-button-with-callback>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.claims-block {
    display: flex;
    flex-direction: column;
    background-color: var(--component-color-background-base);
    border-radius: var(--size-pico);
    width: 100%;
    min-height: 119px;
    padding: var(--size-small);
    font-size: var(--font-size-nano);
    font-weight: 600;
    box-shadow: 0 1px 0 var(--black-300);
    gap: var(--size-small);

    @include respond-above('sm') {
        flex-direction: row;
        padding: var(--size-normal);
    }

    .info-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--size-small);
        margin-right: var(--size-small);

        .status-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--size-nano);

            @include respond-above('sm') {
                gap: var(--size-small);
            }

            .status-badge {
                padding: 7.5px var(--size-nano);
                color: var(--text-color-default);
                border-radius: 8px;
                background-color: var(--black-50);
                font-size: var(--font-size-nano);
                font-weight: 600;
                line-height: var(--line-height-basic);

                &.orange {
                    color: var(--system-color-warning-dark);
                    background-color: var(--orange-50);
                }

                &.red {
                    color: var(--system-color-error-dark);
                    background-color: var(--red-50);
                }

                &.teal {
                    color: var(--system-color-success-dark);
                    background-color: var(--teal-50);
                }

                &.blue {
                    color: var(--blue-800);
                    background-color: var(--blue-50);
                }

                &.violet {
                    color: var(--blue-800);
                    background-color: var(--blue-50);
                }
            }

            .attachments {
                display: flex;
                gap: var(--size-pico);
            }
        }

        .claim-info {
            display: flex;
            flex-direction: column;
            gap: var(--size-pico);

            @include respond-above('sm') {
                flex-direction: row;
                align-items: baseline;
                gap: var(--size-small);
            }

            .name {
                margin-bottom: var(--size-femto);

                @include respond-above('sm') {
                    margin-bottom: 0;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        gap: var(--size-nano);
        width: 100%;

        @include respond-above('sm') {
            flex-direction: row;
            gap: var(--size-small);
            width: unset;
        }

        .button-with-callback {
            min-height: 52px;
            padding: var(--size-tiny) var(--size-medium);
            width: 100%;

            @include respond-above('sm') {
                width: unset;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .title {
        font-size: var(--font-size-tiny);
        font-weight: 600;
    }

    .sub-title {
        font-weight: 500;
    }
}
</style>
