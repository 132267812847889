import PropertyObject from '@/interfaces/one_policy/property.object.interface';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PolicyDetailsObjects from '@/Components/Policies/PolicyDetailsObjects/Interfaces/PolicyDetailsObjectsInterface';
import PolicyDetailsObjectsContent from '@/Components/Policies/PolicyDetailsObjects/Interfaces/PolicyDetailsObjectsContentInterface';

export default class PolicyDetailsObjectsBuilder {
    private policyDetailsObjects: PolicyDetailsObjects = new (class implements PolicyDetailsObjects {
        public component: string = 'AppPolicyDetailsObjects';
        public content: PolicyDetailsObjectsContent = new (class implements PolicyDetailsObjectsContent {
            public objects: PropertyObject[] = [];
        })();
    })();
    private currentObjectIndex: number = -1;

    public startNewProperty(): PolicyDetailsObjectsBuilder {
        this.currentObjectIndex++;
        this.policyDetailsObjects.content.objects.push(
            new (class implements PropertyObject {
                public objectName: string = '';
                public propertyType: string = '';
                public risks: DynamicDictionary[] = [];
                //INFO: according to bta - information about "risks[] & property type" presence will be later
            })(),
        );

        return this;
    }

    public withPropertyName(name: string): PolicyDetailsObjectsBuilder {
        if (this.hasNoBlocks()) {
            this.startNewProperty();
        }
        this.policyDetailsObjects.content.objects[this.currentObjectIndex].objectName = name;

        return this;
    }

    public elementsCount(): number {
        return this.policyDetailsObjects.content.objects.length;
    }

    public build(): PolicyDetailsObjects {
        return this.policyDetailsObjects;
    }

    private hasNoBlocks(): boolean {
        return this.currentObjectIndex < 0;
    }
}
