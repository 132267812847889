<script lang="ts">
import { defineComponent } from 'vue';
import OneBaseService from '@/services/OneBaseService';
import OneBase from '@/interfaces/OneBaseInterface';

export default defineComponent({
    setup() {
        const btaBase: OneBase = OneBaseService.getInstance();
        return {
            ...btaBase,
            ...{},
        };
    },

    mounted() {
        this.applyApp(this);
        this.initBtaBase();
    },
});
</script>
