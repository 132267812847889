import { Subject } from 'rxjs';
import PopupType from '@/Enums/PopupTypeEnum';
import PopupVariables from '@/interfaces/popup.variables.interface';
import Url from '@/Enums/UrlEnum';
import { Ref, nextTick, ref, reactive, UnwrapNestedRefs } from 'vue';
import VueEvent from '@/Classes/VueEventClass';
import VueModel from '@/services/vue.model.service';
import { LimitedVariant } from '@/Types/LimitedVariantType';
import LazyLoader from '@/services/lazy.loader.service';
import { useTranslate } from '@/Composables/Translate';
import { useDefine } from '@/Composables/Define';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';
import PopupService from '@/services/custom.popup.service';
import OnePopup from '@/assets/libraries/popups/one.popup';
import OneBaseService from '@/services/OneBaseService';

export default class Popup {
    public readonly SinglePopup: string = '.single-popup';
    public readonly CancelSubscriptionPopup: string = '.cancel-subscription';
    public readonly InfoPopup: string = '.info-popup';
    public readonly AgreementPopup: string = '.agreement';
    public readonly PopupWrapper: string = '.wrapper';
    public whenClose: Subject<string> = new Subject();
    private popupType: Ref<string> = ref(PopupType.None);
    private returnPopup: string = '';
    private static instance: Popup;

    private model: UnwrapNestedRefs<PopupVariables> = reactive(
        new (class implements PopupVariables {
            public errorElement: HTMLElement | undefined = $('section.form').get(0);
            public errorTitle: string = '';
            public errorDetails: string = '';
            public errorCode: string = '';
            public errorButtonText: string = '';
            public allowErrorSimpleClose: boolean = false;
            public textTitle: string = '';
            public textDescription: string = '';
            public textAgreeText: string = '';
            public callbackModel: string = '';
            public callbackModelParams: LimitedVariant = null;
            public callbackContext: any = null;
            public textAgreeCheckbox: boolean = false;
            public onCloseTextCallbackModel: string = '';
            public onCloseCallbackContext: any = null;
            public noCloseOnLogin: boolean = false;
            public additionalInfo: string = '';
            public logoutOnClose: boolean = true;
            public loadingWaitStepTranslationKeyPrefix: string = 'default';
        })(),
    );

    public static getInstance(): Popup {
        if (!Popup.instance) {
            Popup.instance = new Popup();
        }

        return Popup.instance;
    }

    public showPopup(type: string): Popup {
        if (this.popupType.value !== PopupType.TextAgree) {
            if (type === PopupType.None) {
                this.hidePopup();
            } else {
                this.popupType.value = type;
                switch (type) {
                    case PopupType.Login:
                        history.pushState('login-popup', document.title);
                        break;
                    default:
                }
            }
        }
        nextTick((): void => {
            OneBaseService.getInstance()!.changeBodyVerticalScrollState();
            this.focusPopup();
            new LazyLoader().init();
        });

        return this;
    }

    public hidePopup(): void {
        const closedType: string = this.popupType.value;
        this.popupType.value = PopupType.None;
        if (this.returnPopup) {
            this.popupType.value = this.returnPopup;
            this.returnPopup = '';
        }
        if (this.model.callbackModel !== '' && this.model.callbackContext !== null) {
            VueModel.callMethodByName(
                this.model.callbackModel as string,
                this.model.callbackContext,
                this.model.callbackModelParams,
            );
            this.model.callbackModel = '';
            this.model.callbackContext = null;
        } else if (this.model.callbackModel !== '' && useDefine().isCallable(this.model.callbackModel as Function)) {
            (this.model.callbackModel as Function)();
        }
        this.whenClose.next(closedType);
        this.model.noCloseOnLogin = false;
        this.model.errorButtonText = '';
        this.model.allowErrorSimpleClose = false;
    }

    public popupTypeIs(type: string): boolean {
        return this.popupType.value === type;
    }

    public closePopupWithCallback(): void {
        this.model.callbackModel = '';
        this.model.callbackContext = null;
        if (this.model.onCloseTextCallbackModel !== '' && this.model.onCloseCallbackContext !== null) {
            VueModel.callMethodByName(this.model.onCloseTextCallbackModel as string, this.model.onCloseCallbackContext);
            this.model.onCloseTextCallbackModel = '';
            this.model.onCloseCallbackContext = null;
        } else if (
            this.model.onCloseTextCallbackModel !== '' &&
            useDefine().isCallable(this.model.onCloseTextCallbackModel as Function)
        ) {
            (this.model.onCloseTextCallbackModel as Function)();
        }
        this.hidePopup();
    }

    public onTextAgreeButtonClick(event: VueEvent): void {
        if ((this.model.textAgreeText !== '' && this.model.textAgreeCheckbox) || this.model.textAgreeText === '') {
            this.hidePopup();
            if (this.model.callbackModel && useDefine().isCallable(this.model.callbackModel as Function)) {
                (this.model.callbackModel as Function)();
            }
        }
    }

    public onCloseErrorPopup(event: VueEvent): void {
        PopupService.getInstance().hide();
        if (this.model.onCloseCallbackContext) {
            this.closePopupWithCallback();
        } else {
            this.showPopup(PopupType.None);
            if (this.model.errorElement) {
                this.model.errorElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
                this.applyErrorElement($('section.form').get(0));
            }
        }
    }

    public get type(): string {
        return this.popupType.value;
    }

    public get errorTitle(): string {
        return this.model.errorTitle;
    }

    public get errorDetails(): string {
        return this.model.errorDetails;
    }

    public get errorButtonText(): string {
        return this.model.errorButtonText;
    }

    public get textTitle(): string {
        return this.model.textTitle;
    }

    public get textDescription(): string {
        return this.model.textDescription;
    }

    public get textAgreeText(): string {
        return this.model.textAgreeText;
    }

    public get closeIsVisible(): boolean {
        return !this.model.noCloseOnLogin;
    }

    public get additionalInfo(): string {
        return this.model.additionalInfo;
    }

    public get errorElement(): HTMLElement | undefined {
        return this.model.errorElement as HTMLElement | undefined;
    }

    public get allowErrorSimpleClose(): boolean {
        return this.model.allowErrorSimpleClose;
    }

    public get loadingWaitStepTranslationKeyPrefix(): string {
        return this.model.loadingWaitStepTranslationKeyPrefix;
    }

    public applyErrorTitle(errorTitle: string): Popup {
        this.model.errorTitle = errorTitle;

        return this;
    }

    public applyErrorDetails(errorDetails: string): Popup {
        this.model.errorDetails = errorDetails;

        return this;
    }

    public applyErrorCode(errorCode: string): Popup {
        this.model.errorCode = errorCode;

        return this;
    }

    public applyErrorSimpleClose(): Popup {
        this.model.allowErrorSimpleClose = true;

        return this;
    }

    public applyTextTitle(title: string): Popup {
        this.model.textTitle = title;

        return this;
    }

    public applyTextDescription(description: string): Popup {
        this.model.textDescription = description;

        return this;
    }

    public applyAdditionalInfo(additionalInfo: string): Popup {
        this.model.additionalInfo = additionalInfo;

        return this;
    }

    public applyTextAgree(agreeText: string): Popup {
        this.model.textAgreeText = agreeText;

        return this;
    }

    public applyCallbackModel(
        callbackModel: string | Function,
        callbackContext: DynamicDictionary | null = null,
        callbackParams: LimitedVariant = null,
    ): Popup {
        this.model.callbackModel = callbackModel;
        this.model.callbackContext = callbackContext;
        this.model.callbackModelParams = callbackParams;

        return this;
    }

    public applyErrorButtonText(text: string): Popup {
        this.model.errorButtonText = text;

        return this;
    }

    public applyOnCloseCallback(callbackModel: string | Function, callbackContext: any): Popup {
        this.model.onCloseTextCallbackModel = callbackModel;
        this.model.onCloseCallbackContext = callbackContext;

        return this;
    }

    public applyErrorElement(element: HTMLElement | undefined): Popup {
        this.model.errorElement = element;

        return this;
    }

    public applyReturn(returnPopup: string): Popup {
        this.returnPopup = returnPopup;

        return this;
    }

    public applyNoCloseForLogin(): Popup {
        this.model.noCloseOnLogin = true;

        return this;
    }

    public applyLogoutOnClose(value: boolean): Popup {
        this.model.logoutOnClose = value;

        return this;
    }

    public applyLoadingWaitStepTranslationKeyPrefix(prefix: string): Popup {
        this.model.loadingWaitStepTranslationKeyPrefix = prefix;

        return this;
    }

    public closeTextAgreePopup(): void {
        this.closePopupWithCallback();
        if (this.model.logoutOnClose) {
            this.logout();
        }
    }

    public logout(): void {
        PopupService.getInstance().show(new OnePopup().withType().loading);
        window.location.href = '/' + useTranslate().language() + Url.Api.logout;
    }

    private focusPopup(): void {
        let focusablePopup: string = '';

        switch (this.popupType.value) {
            case PopupType.TextAgree:
                focusablePopup = this.SinglePopup + this.AgreementPopup;
                break;
            case PopupType.Info:
                focusablePopup = this.SinglePopup + this.InfoPopup;
                break;
            case PopupType.CancelSubscription:
                focusablePopup = this.CancelSubscriptionPopup;
                break;
            default:
                focusablePopup = this.SinglePopup + '.' + this.popupType.value;
        }

        nextTick(() => {
            const element: HTMLElement = $(focusablePopup).find(this.PopupWrapper)[0];
            if (element !== undefined && element !== null) {
                $(element).focus();
            }
        });
    }
}
