<script setup lang="ts">
import FormField from '@/assets/libraries/form/form-field';
import { onMounted, PropType, ref, Ref, watch, computed } from 'vue';
import Vehicles from '@/services/vehicles.service';
import { Subscription } from 'rxjs';
import { Vehicle } from '@/interfaces/resources/vehicle.interface';
import Form from '@/assets/libraries/form/form';
import AppInputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import { useTranslate } from '@/Composables/Translate';
import { useStrings } from '@/Composables/Strings';
import { InputOption } from '@/interfaces/InputOptionInterface';
import { InputOptionBuilder } from '@/Builders/InputOptionBuilder';

const props = defineProps({
    formField: { type: Object as PropType<FormField<Vehicle | undefined>>, default: () => new FormField('') },
    placeholder: { type: String, default: () => '' },
    label: { type: String, default: '' },
    vehicleTypeWeb: { type: String, default: '' },
    skipOwnValidation: { type: Boolean, default: false },
    dataStoreDisabled: { type: Boolean, default: false },
});

const emit = defineEmits(['change']);

const { translate } = useTranslate();
const { capitalize } = useStrings();

watch(
    () => props.formField.value,
    () => {
        if (props.formField.value) {
            if (props.formField.value.id.toString() !== form.field('vehicleId').value) {
                form.field('vehicleId').patch(props.formField.value.id.toString());
            }
        } else {
            form.field('vehicleId').patch('other');
        }
        props.formField.validate();
        emit('change', props.formField.value);
    },
);

watch(
    () => props.skipOwnValidation,
    () => {
        if (!props.skipOwnValidation) {
            applyChildFieldValidators();
        }
        props.formField.validate().then();
    },
);

const vehiclesService: Vehicles = Vehicles.getInstance();
const form: Form = new Form();
let onExternalDataIsReadySubscription!: Subscription;
const vehicles: Ref<Vehicle[]> = ref([]);
const options: Ref<InputOption[]> = computed((): InputOption[] => {
    const source: Vehicle[] = props.vehicleTypeWeb
        ? vehicles.value.filter(
              (vehicle: Vehicle): boolean =>
                  vehicle.vehicleTypeWeb === '' || vehicle.vehicleTypeWeb.includes(props.vehicleTypeWeb),
          )
        : vehicles.value;
    return source.map(
        (vehicle: Vehicle): InputOption =>
            new InputOptionBuilder().setValue(vehicle.id.toString()).setName(capitalized(vehicle.model)).build(),
    );
});

onMounted((): void => {
    if (vehiclesService.ready) {
        init();
    } else {
        onExternalDataIsReadySubscription = vehiclesService.onExternalDataIsReady.subscribe((): void => {
            init();
        });
        onExternalDataIsReadySubscription.unsubscribe();
    }
});

function onSelectedVehicleChange(vehicleId: string): void {
    props.formField.patch(vehicles.value.find((vehicle: Vehicle): boolean => vehicle.id.toString() === vehicleId));
}

function init(): void {
    setupForm();
    if (!props.skipOwnValidation) {
        applyChildFieldValidators();
    }
    fetchVehicles();
    addOtherVehicle();
    if (!props.formField.isEmpty()) {
        form.field('vehicleId').patch(props.formField.value?.id.toString());
    }
}

function setupForm(): void {
    form.addField(new FormField('vehicleId'));
}

function applyChildFieldValidators(): void {
    form.field('vehicleId').addValidators('required');
}

function fetchVehicles(): void {
    vehicles.value = JSON.parse(JSON.stringify(vehiclesService.vehicleModels()));
}

function addOtherVehicle(): void {
    vehicles.value.push({
        id: 'other',
        model: translate('repair_partners_vehicle_make_other'),
        vehicleTypeWeb: '',
    } as Vehicle);
}

function capitalized(value: string): string {
    return value
        .split(' ')
        .map((word: string): string => capitalize(word))
        .join(' ');
}
</script>

<template>
    <div
        :id="formField.name"
        class="input input-vehicle-make"
        :class="formField.classes()"
        :data-store="dataStoreDisabled ? '' : formField.name"
        :data-store-value="dataStoreDisabled ? '' : JSON.stringify(formField.value)"
    >
        <div class="wrapper select">
            <app-input-select
                :options="options"
                :placeholder="placeholder"
                :label="label"
                :form-field="form.field('vehicleId')"
                :data-store-disabled="true"
                @change="onSelectedVehicleChange"
            >
                <template #app-tooltipster>
                    <slot name="app-tooltipster"></slot>
                </template>
            </app-input-select>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.input-vehicle-make {
    width: 100%;
    scroll-margin-top: 4em;

    @include respond-above('md') {
        width: 350px;
    }

    > .wrapper {
        height: 100%;
    }
}
</style>
