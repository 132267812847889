import AppModal from '@/Components/Modals/Modal/Modal.vue';
import AppModalOverlayMessage from '@/Components/Modals/ModalOverlayMessage/ModalOverlayMessage.vue';
import AppLegalModal from '@/Components/Modals/LegalModal/LegalModal.vue';
import AppDrawer from '@/Components/Modals/Drawer/Drawer.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppModal: AppModal,
    AppModalOverlayMessage: AppModalOverlayMessage,
    AppLegalModal: AppLegalModal,
    AppDrawer: AppDrawer,
} as Components;
