import CssClass from '@/Enums/CssClassEnum';
import { nextTick } from 'vue';

export default class Sticky {
    private sticker: JQuery | null = null;
    private maxHeight: number = 0;

    public init(): void {
        this.sticker = $('.sticky-top');
        if (this.sticker.length > 0) {
            const el: JQuery = $(this.sticker[0]);
            const offset: JQuery.Coordinates | undefined = el.offset();
            if (offset) {
                this.maxHeight = offset.top;
            }
            window.addEventListener('scroll', () => {
                this.scrollCallback();
            });
            nextTick(() => {
                this.scrollCallback();
            });
        }
    }

    private scrollCallback(): void {
        if (this.sticker) {
            if (window.scrollY > this.maxHeight) {
                this.sticker!.addClass(CssClass.Fixed);
            } else {
                this.sticker!.removeClass(CssClass.Fixed);
            }
        }
    }
}
