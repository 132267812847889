import VueEvent from '@/Classes/VueEventClass';
import VueModel from '@/services/vue.model.service';
import TriggeredEvent = JQuery.TriggeredEvent;
import { useModel } from '@/Composables/Model';
import DynamicDictionary from '@/interfaces/dynamic.dictionary.interface';

export const useBroadcaster = (): BroadcasterParams => {
    const initBroadcasters = (): void => {
        $('[data-broadcast]').each((i: number, dom: HTMLElement): void => {
            let broadcaster: JQuery = $(dom);
            const broadcastMessage: string = $(dom).data('broadcast');
            const broadcastParams: string = $(dom).data('param');
            const externalBroadcasterId: string | undefined = $(dom).data('broadcaster-id');
            if (externalBroadcasterId) {
                broadcaster = $('#' + externalBroadcasterId);
                broadcaster.data('broadcast', broadcastMessage);
                broadcaster.data('param', broadcastParams);
                $(dom).empty().remove();
            }
            broadcaster.on('click', broadcast);
        });
    };

    const broadcast = (event: TriggeredEvent): void => {
        event.preventDefault();
        const currentTarget: JQuery = event.currentTarget;
        const broadcastMessage: string = $(currentTarget).data('broadcast');
        const broadcasterEvent: VueEvent = new VueEvent(event);
        const context: DynamicDictionary | null = useModel().contextByMemberName(broadcastMessage);
        VueModel.callMethodByName(broadcastMessage, context, broadcasterEvent);
    };

    return {
        initBroadcasters,
        broadcast,
    };
};

export interface BroadcasterParams {
    initBroadcasters: () => void;
    broadcast: (event: TriggeredEvent) => void;
}
