import AppInputText from '@/Components/Inputs/InputText/InputText.vue';
import AppInputTextAjax from '@/Components/Inputs/InputTextAjax/InputTextAjax.vue';
import InputSelect from '@/Components/Inputs/InputSelect/InputSelect.vue';
import InputSelectionPanels from '@/Components/Inputs/InputSelectionPanels/InputSelectionPanels.vue';
import InputDateWithDropdown from '@/Components/Inputs/InputDateWithDropdown/InputDateWithDropdown.vue';
import InputDateWithCalendar from '@/Components/Inputs/InputDateWithCalendar/InputDateWithCalendar.vue';
import InputRadio from '@/Components/Inputs/InputRadio/InputRadio.vue';
import AppInputCheckbox from '@/Components/Inputs/InputCheckbox/InputCheckbox.vue';
import InputSwitch from '@/Components/Inputs/InputSwitch/InputSwitch.vue';
import InputRadioSwitch from '@/Components/Inputs/InputRadioSwitch/InputRadioSwitch.vue';
import InputNumber from '@/Components/Inputs/InputNumber/InputNumber.vue';
import AppInputCounter from '@/Components/Inputs/InputCounter/InputCounter.vue';
import InputMultiCounter from '@/Components/Inputs/InputMultiCounter/InputMultiCounter.vue';
import InputCountry from '@/Components/Inputs/InputCountry/InputCountry.vue';
import InputPlateNumber from '@/Components/Inputs/InputPlateNumber/InputPlateNumber.vue';
import AppInputTextarea from '@/Components/Inputs/InputTextarea/InputTextarea.vue';
import AppInputTime from '@/Components/Inputs/InputTime/InputTime.vue';
import AppInputTimeWithDropdown from '@/Components/Inputs/InputTimeWithDropdown/InputTimeWithDropdown.vue';
import InputCompanyType from '@/Components/Inputs/InputCompanyType/InputCompanyType.vue';
import InputMultiCheckbox from '@/Components/Inputs/InputMultiCheckbox/InputMultiCheckbox.vue';
import InputBankInstitution from '@/Components/Inputs/InputBankInstitution/InputBankInstitution.vue';
import InputCreditCardNumber from '@/Components/Inputs/InputCreditCardNumber/InputCreditCardNumber.vue';
import InputCreditCardDate from '@/Components/Inputs/InputCreditCardDate/InputCreditCardDate.vue';
import InputEmail from '@/Components/Inputs/InputEmail/InputEmail.vue';
import AppInputSmartRadio from '@/Components/Inputs/InputSmartRadio/InputSmartRadio.vue';
import InputBankAccount from '@/Components/Inputs/InputBankAccount/InputBankAccount.vue';
import AppInputSearch from '@/Components/Inputs/InputSearch/InputSearch.vue';
import AppInputDocumentUpload from '@/Components/Inputs/InputDocumentUpload/InputDocumentUpload.vue';
import InputVehicleMake from '@/Components/Inputs/InputVehicleMake/InputVehicleMake.vue';
import InputDate from '@/Components/Inputs/InputDate/InputDate.vue';
import InputSearchVehicleMake from '@/Components/Inputs/InputSearchVehicleMake/InputSearchVehicleMake.vue';
import InputRadioOverlayed from '@/Components/Inputs/InputRadioOverlayed/InputRadioOverlayed.vue';
import InputCount from '@/Components/Inputs/InputCount/InputCount.vue';
import AppInputIdentityNumber from '@/Components/Inputs/InputIdentityNumber/InputIdentityNumber.vue';
import PhoneWithCountry from '@/Components/Inputs/PhoneWithCountry/PhoneWithCountry.vue';
import MultiCountry from '@/Components/Inputs/MultiCountry/MultiCountry.vue';
import CountryRegions from '@/Components/Inputs/CountryRegions/CountryRegions.vue';
import CountryRegionsMulti from '@/Components/Inputs/CountryRegionsMulti/CountryRegionsMulti.vue';
import SecurityDeviceConsent from '@/Components/Inputs/SecurityDeviceConsent/SecurityDeviceConsent.vue';
import CustomForm from '@/Components/Inputs/CustomForm/CustomForm.vue';
import AppAddressFinder from '@/Components/Inputs/AddressFinder/AddressFinder.vue';
import Components from '@/Core/App/Interfaces/ComponentsInterface';

export default {
    AppInputText: AppInputText,
    AppInputTextAjax: AppInputTextAjax,
    AppInputSelect: InputSelect,
    AppInputSelectionPanels: InputSelectionPanels,
    AppInputDateWithDropdown: InputDateWithDropdown,
    AppInputDateWithCalendar: InputDateWithCalendar,
    AppInputRadio: InputRadio,
    AppInputCheckbox: AppInputCheckbox,
    AppInputSwitch: InputSwitch,
    AppInputRadioSwitch: InputRadioSwitch,
    AppInputNumber: InputNumber,
    AppInputCounter: AppInputCounter,
    AppInputMultiCounter: InputMultiCounter,
    AppInputCountry: InputCountry,
    AppInputPlateNumber: InputPlateNumber,
    AppInputTextarea: AppInputTextarea,
    AppInputTime: AppInputTime,
    AppInputTimeWithDropdown: AppInputTimeWithDropdown,
    AppInputCompanyType: InputCompanyType,
    AppInputMultiCheckbox: InputMultiCheckbox,
    AppInputBankInstitution: InputBankInstitution,
    AppInputCreditCardNumber: InputCreditCardNumber,
    AppInputCreditCardDate: InputCreditCardDate,
    AppInputEmail: InputEmail,
    AppInputSmartRadio: AppInputSmartRadio,
    AppInputBankAccount: InputBankAccount,
    AppInputSearch: AppInputSearch,
    AppInputDocumentUpload: AppInputDocumentUpload,
    AppInputVehicleMake: InputVehicleMake,
    AppInputDate: InputDate,
    AppInputSearchVehicleMake: InputSearchVehicleMake,
    AppInputRadioOverlayed: InputRadioOverlayed,
    AppInputCount: InputCount,
    AppInputIdentityNumber: AppInputIdentityNumber,
    AppMultiCountry: MultiCountry,
    AppPhoneWithCountry: PhoneWithCountry,
    AppCountryRegions: CountryRegions,
    AppCountryRegionsMulti: CountryRegionsMulti,
    AppSecurityDeviceConsent: SecurityDeviceConsent,
    AppCustomForm: CustomForm,
    AppAddressFinder: AppAddressFinder,
} as Components;
