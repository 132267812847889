export const useNumbers = (): NumbersParams => {
    const arraySum = (value: number[]): number => {
        return Number(
            value
                .map((element: number): number => parseFloat(String(element)))
                .reduce((partial: number, next: number): number => partial + next, 0)
                .toFixed(2),
        );
    };

    return {
        arraySum,
    };
};

export interface NumbersParams {
    arraySum: (value: number[]) => number;
}
